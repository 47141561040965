import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppPaymentLog, AppPaymentLogState } from '../types';

const initialState: AppPaymentLogState = {
    list: [],
    loading: true,
}

const paymentLogSlice = createSlice({
    name: 'paymentLog',
    initialState,
    reducers: {
        paymentLogLoading(state) {
            state.loading = true;
        },
        paymentLogLoaded(state, action: PayloadAction<Array<AppPaymentLog>>) {
            const log = action.payload? action.payload: null;

            state.list = log? log: [];
            state.loading = false;
        },
        paymentLogLoadingFailed(state){
            state.loading = false;
        },
        resetPaymentLogState(state) {
            return initialState;
        }
    },
})

export const {
    paymentLogLoading,
    paymentLogLoaded,
    paymentLogLoadingFailed,
    resetPaymentLogState,
} = paymentLogSlice.actions;

export default paymentLogSlice.reducer;

