import { AppPagination } from "../../config/constants"
import { AppPayment } from "../Payments/types";
import * as yup from 'yup'
import { nameof } from "../../utils/nameOfPropertyHelper";

/* Entities */
export interface AppBooking {
    bookingId: number,
    bookingGuid?: number,
    bookingNumber: string,
    carId: string,
    bookingStatus: number,
    tripStatus: number,
    paymentStatus: number,
    price: number,
    serviceCharge?: number,
    serviceChargeExVat?: number,
    originalPrice?:number,
    cancellationFee?: number,
    originalCancellationFee?: number,
    createdDate: string,
    pickupTime: string,
    fromFullAddress: string,
    fromAddressId?: number,
    toAddressId?: number,
    toFullAddress: string,
    numberOfPassengers: number,
    customerId?: number | null,
    customerPhone: string | null,
    customerName: string | null,
    providerName: string,
    customerEmail: string,
    driverName: string,
    driverNumber: string,
    discountName?: string,
    discountAmount?: number // calculated, actual
    discount?: number; // % or NOK, initial 
    discountAmountTypeId?: number; //discount type: % or NOK
    clientId?: number | null
    clientName?: string | null
    tripCategory: number | null
    contractPrice?: number | null
    contractNumber?: string | null
    isPreOrder: boolean

}
export interface AppBookingStop {
    bookingId: number,
    addressId: number,
    fullAddress: string
}

export interface AppBookingLog {
    bookingId: number,
    description: string,
    userId: number,
    userFullName: string,
    type: BookingLogType,
    date: string,
    statusId: number
}

export interface AppPaymentLog {
    paymentLogId: number,
    statusId: number,
    paymentId: number,
    amount: number,
    providerReservationId: string,
    transactionId: string,
    errorMessage: string,
    date: string,
    userId?: number | null,
    userFullName?: string | null
}

/* enums, constants  */

export enum AppBookingStatus {

    OfferRequested = 1,
    OfferConfirmed = 2,
    OfferRejected = 3,
    BookingRequested = 4,
    BookingConfirmed = 5,
    CancelledByDispatcher = 6,
    CancellationRequested = 7,
    CancellationConfirmed = 8,
    BookingCompleted = 9,
    NoShow = 10,
    SystemError = 100
}

export enum AppTripStatus {
    Unassigned = 0,
    Assigned = 1,
    OnItsWay = 2,
    Arrived = 3,
    PassengerOnBoard = 4,
    Finished = 5,
    NoShow = 6, // passender has not come
    Cancelled = 7,
    PreOrder = 8
}

export enum AppBookingPaymentStatus {
    NotPaid = 1,
    Paid = 2,
    ApprovedForDelivery = 3
}

export enum AppPaymentStatus {
    NotStarted = 0,
    ReserveContactingProvider = 1,
    ReservePending = 2,
    ReserveRetrying = 3,
    Reserved = 4,
    ReserveFailedTechnical = 5,
    ReserveFailedRejected = 6,
    ReleaseRequested = 7,
    Released = 8,
    ReleaseFailed = 9,
    CaptureContactingProvider = 11,
    Captured = 12,
    CaptureFailedRejected = 13,
    CaptureFailedTechnical = 14,
    CaptureRetrying = 15,
    RefundRequested = 16,
    RefundPending = 17,
    Refunded = 18,
    RefundFailed = 19,
    RefundRetrying = 20,
    VoidedBySystem = 100
}

export enum BookingLogType {
    BookingLog = 1,
    TripLog = 2,
    PaymentLog = 3
}

interface GetLogStatusDataResponse {
    text: string,
    color: string,
    description: string
}

export const getLogStatusData = (status: AppBookingStatus | AppTripStatus, statusType: BookingLogType): GetLogStatusDataResponse | null => {

    if (!statusType || status === null || status === undefined) {
        return null;
    }

    status = parseInt(status.toString());

    if (statusType === BookingLogType.BookingLog) {
        return getAppBookingStatusData(status as AppBookingStatus);
    }
    else if (statusType === BookingLogType.TripLog) {
        return getAppTripStatusData(status as AppTripStatus);
    }
    else {
        return getAppBookingPaymentStatusData(status);
    }
}

export const getAppBookingStatusData = (status: AppBookingStatus): GetLogStatusDataResponse => {
    const getBookingStatusDescription = (status: string) => `Booking status changed to ${status}`;

    switch (status) {
        case AppBookingStatus.OfferRequested:
            return { text: 'OfferRequested', color: 'grey', description: getBookingStatusDescription('OfferRequested') }
        case AppBookingStatus.OfferConfirmed:
            return { text: 'OfferConfirmed', color: 'green', description: getBookingStatusDescription('OfferConfirmed') }
        case AppBookingStatus.OfferRejected:
            return { text: 'OfferRejected', color: 'orange', description: getBookingStatusDescription('OfferRejected') }
        case AppBookingStatus.BookingRequested:
            return { text: 'BookingRequested', color: 'yellow', description: getBookingStatusDescription('BookingRequested') }
        case AppBookingStatus.BookingConfirmed:
            return { text: 'BookingConfirmed', color: 'green', description: getBookingStatusDescription('BookingConfirmed') }
        case AppBookingStatus.CancelledByDispatcher:
            return { text: 'CancelledByDispatcher', color: 'red', description: getBookingStatusDescription('CancelledByDispatcher') }
        case AppBookingStatus.CancellationConfirmed:
            return { text: 'CancellationConfirmed', color: 'green', description: getBookingStatusDescription('CancellationConfirmed') }
        case AppBookingStatus.CancellationRequested:
            return { text: 'CancellationRequested', color: 'blue', description: getBookingStatusDescription('CancellationRequested') }
        case AppBookingStatus.BookingCompleted:
            return { text: 'BookingCompleted', color: 'green', description: getBookingStatusDescription('BookingCompleted') }
        case AppBookingStatus.NoShow:
                return { text: 'NoShow', color: 'grey', description: getBookingStatusDescription('NoShow') }
        case AppBookingStatus.SystemError:
            return { text: 'SystemError', color: 'red', description: getBookingStatusDescription('SystemError') }
        default:
            return { text: 'Unknown', color: 'black', description: getBookingStatusDescription('Unknown') }
    }

}

export const getAppTripStatusData = (status: AppTripStatus): GetLogStatusDataResponse => {
    const getTripStatusDescription = (status: string) => `Trip status changed to ${status}`;

    switch (status) {
        case AppTripStatus.Unassigned:
            return { text: 'Unasssigned', color: 'yellow', description: getTripStatusDescription('Unasssigned') }
        case AppTripStatus.Assigned:
            return { text: 'Assigned', color: 'olive', description: getTripStatusDescription('Assigned') }
        case AppTripStatus.OnItsWay:
            return { text: 'OnItsWay', color: 'teal', description: getTripStatusDescription('OnItsWay') }
        case AppTripStatus.Arrived:
            return { text: 'Arrived', color: 'blue', description: getTripStatusDescription('Arrived') }
        case AppTripStatus.PassengerOnBoard:
            return { text: 'PassengerOnBoard', color: 'green', description: getTripStatusDescription('PassengerOnBoard') }
        case AppTripStatus.Finished:
            return { text: 'Finished', color: 'pink', description: getTripStatusDescription('Finished') }
        case AppTripStatus.NoShow:
            return { text: 'NoShow', color: 'orange', description: getTripStatusDescription('NoShow') }
        case AppTripStatus.Cancelled:
            return { text: 'Cancelled', color: 'red', description: getTripStatusDescription('Cancelled') }
        default:
            return { text: 'Unknown', color: 'black', description: getTripStatusDescription('Unknown') }
    }
}

export const getAppBookingPaymentStatusData = (status: any): GetLogStatusDataResponse => {
    const getStatusDescription = (status: string) => `Payment status changed to ${status}`;

    switch (status) {
        case AppBookingPaymentStatus.NotPaid:
            return { text: 'NotPaid', color: 'orange', description: getStatusDescription('NotPaid') }
        case AppBookingPaymentStatus.Paid:
            return { text: 'Paid', color: 'green', description: getStatusDescription('Paid') }
        case AppBookingPaymentStatus.ApprovedForDelivery:
            return { text: 'ApprovedForDelivery', color: 'blue', description: getStatusDescription('ApprovedForDelivery') }
        default:
            return { text: 'Unknown', color: 'black', description: getStatusDescription('Unknown') }
    }
}


export const getAppPaymentStatusData = (status: any): { text: string, color: 'green' | 'orange' | 'teal' | 'red' | 'black' | 'blue' | 'grey' } => {

    switch (status) {
        case AppPaymentStatus.NotStarted:
            return { text: 'NotStarted', color: 'grey' }
        case AppPaymentStatus.ReserveContactingProvider:
            return { text: 'ReserveContactingProvider', color: 'grey' }
        case AppPaymentStatus.ReservePending:
            return { text: 'ReservePending', color: 'blue' }
        case AppPaymentStatus.ReserveRetrying:
            return { text: 'ReserveRetrying', color: 'teal' }
        case AppPaymentStatus.Reserved:
            return { text: 'Reserved', color: 'green' }
        case AppPaymentStatus.ReserveFailedRejected:
            return { text: 'ReserveFailedRejected', color: 'red' }
        case AppPaymentStatus.ReleaseRequested:
            return { text: 'ReleaseRequested', color: 'blue' }
        case AppPaymentStatus.Released:
            return { text: 'Released', color: 'green' }
        case AppPaymentStatus.ReleaseFailed:
            return { text: 'ReleaseFailed', color: 'red' }
        case AppPaymentStatus.ReserveFailedTechnical:
            return { text: 'ReserveFailTechnical', color: 'red' }
        case AppPaymentStatus.CaptureContactingProvider:
            return { text: 'CaptureContactingProvider', color: 'blue' }
        case AppPaymentStatus.Captured:
            return { text: 'Captured', color: 'green' }
        case AppPaymentStatus.CaptureFailedRejected:
            return { text: 'CaptureFailRejected', color: 'red' }
        case AppPaymentStatus.CaptureFailedTechnical:
            return { text: 'CaptureFailTechnical', color: 'red' }
        case AppPaymentStatus.CaptureRetrying:
            return { text: 'CaptureRetrying', color: 'teal' }
        case AppPaymentStatus.RefundRequested:
            return { text: 'RefundRequested', color: 'grey' }
        case AppPaymentStatus.RefundPending:
            return { text: 'RefundPending', color: 'blue' }
        case AppPaymentStatus.Refunded:
            return { text: 'Refunded', color: 'green' }
        case AppPaymentStatus.RefundFailed:
            return { text: 'RefundFailed', color: 'red' }
        case AppPaymentStatus.RefundRetrying:
            return { text: 'RefundRetrying', color: 'teal' }
        case AppPaymentStatus.VoidedBySystem:
            return { text: 'VoidedBySystem', color: 'grey' }
        default:
            return { text: 'Unknown', color: 'black' }
    }
}

/** state */
export interface AppBookingFilter {
    createdFrom: string | null,
    createdTo: string | null,
    searchText: string | null,
    isAsc: boolean,
    showOffers:boolean,
    searchBookingNumber: string | null,
    searchTelephone: string | null
}

export interface AppBookingsState {
    list: Array<AppBooking>,
    loading: boolean,
    filters: AppBookingFilter,
    pagination: AppPagination,
    currentItem: AppBooking | null,
    currentItemLoading: boolean
}

export interface AppBookingLogState {
    list: Array<AppBookingLog>,
    loading: boolean
}

export interface AppPaymentLogState {
    list: Array<AppPaymentLog>,
    loading: boolean
}

export interface AppBookingStopState {
    list: Array<AppBookingStop>
}

/* Responses, requests from server */

export interface BookingInfoResponse {
    bookingLogs: Array<AppBookingLog>
    payments: Array<AppPayment>
    paymentLogs: Array<AppPaymentLog>
    booking: AppBooking,
    bookingStops: Array<AppBookingStop>
}

export interface AppBookingsResponse {
    bookings: Array<AppBooking>,
    isLastPage: false
}

export interface AppBookingsRequest {
    pageNumber: number,
    numberOfItems: number | null,
    searchStr?: string | null,
    from?: string | null,
    to?: string | null,
    showOffers: boolean,
    searchBookingNumber?: string | null,
    searchTelephone?: string | null
}

export interface AppRefundRequest {
    amount: number,
    paymentId?: number | null,
    userId: number
}

/** VALIDATION  */

export const refundFormValidation = yup.object().shape({
    [nameof<AppRefundRequest>('amount')]: yup.number().min(0.00001, "Bør være mer enn 0").required("Beløp er påkrevd")
})
