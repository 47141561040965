import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, FeatureGroup } from "react-leaflet";
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import 'leaflet/dist/leaflet.css';
import '../../styles/map.css';

const configureMap = () => {
    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow
    });

    // react -leaflet do not show marker icons, images is broken. 
    // this is fix for it.
    L.Marker.prototype.options.icon = DefaultIcon;
}

configureMap();


interface AppMapProps {
    markers: Array<any>
}

export const AppMap = (props: React.PropsWithChildren<AppMapProps>) => {

    const {markers} = props;

    const [map, setMap] = useState<any>(null);
    const featureGroupRef = useRef<any>(null);

   
    useEffect(() => {
        if (!map || !featureGroupRef || !featureGroupRef.current)
            return;

        const bounds = featureGroupRef.current.getBounds();

        try {
            map.fitBounds(bounds);
        }
        catch (e) {
            console.log("no bounds");
        }

    }, [map]);

    return <div data-testid='test-map' id="map-wrapper" >
        <MapContainer center={[58.14671, 7.9956]} zoom={12} scrollWheelZoom={true}
            whenCreated={setMap}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <FeatureGroup ref={featureGroupRef}>
                {markers && markers.length > 0 ? markers : null}
            </FeatureGroup>


        </MapContainer>
    </div>
}