import React from 'react';
import { Spinner } from '../../../components';
import { HideEditingBtns } from '../../Price/types';
import { DrivingAreaGeo } from '../types';
import { DrivingAreasTabs } from './DrivingAreasTabs';
import { MapWithDrivingAreasProps } from './MapWithDrivingAreas';


export interface DrivingAreasOnMapViewPropsPropsFromReducer extends MapWithDrivingAreasProps  {
    currentRegionId: number | null,
    drivingAreaGeosLoading: boolean,
    countryRegDrLoading : boolean,
    points: Array<DrivingAreaGeo>
    
}

interface DrivingAreaGeosViewProps extends HideEditingBtns {
    allProps: DrivingAreasOnMapViewPropsPropsFromReducer
}


export function DrivingAreasView(props: DrivingAreaGeosViewProps) {

    const { drivingAreaGeosLoading, countryRegDrLoading, points, drivingAreas } = props.allProps || {};

    if ( countryRegDrLoading || drivingAreaGeosLoading) {
        return <div style={{marginTop:'100px'}}><Spinner /></div>;
    }

    return (<div className="main-content-item driving-areas">
        <DrivingAreasTabs hideEditingBtns={false} points={points} drivingAreas={drivingAreas} />

    </div>);
}

