
interface Props{
    text:string,
    style?: object
}

export const AppTitleMobileOnly: React.FC<Props> = (props) => {
    const {text, style} = props;

    return <span style={style} className="mobile-only app-text-bold app-title-mobile" >{text || ''}</span>
}


