import React, { useEffect, useState } from 'react';
import '../../styles/booking.css'
import { resetFilters, resetList, resetPage, setBookingsPage, setShowOffers } from './reducer/bookingReducer';
import { AppPagination } from '../../config/constants';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { usePrevious } from '../../hooks/usePrevious';
import { AppBooking, AppBookingFilter, AppBookingsRequest, AppBookingsState, AppRefundRequest } from './types';
import { getBookings, getBookingAdditionalData } from './actions/bookingsActions';
import { BookingDetailsView } from './child-components/BookingDetailsView';
import { BookingsView } from './child-components/BookingsView';
import { refundPayment } from '../Payments/actions/paymentsAction';
import { AppPaymentState } from '../Payments/types';
import { BookingsUrlParams, RoutesConfig } from '../../config/routes';
import { history } from '../../config/historyConfig';


interface BookingsContainerProps {
   filters: AppBookingFilter
   bookingsPagination: AppPagination
   bookingsLoading: boolean
   bookings: Array<AppBooking>
   refundPaymentPending: number | null,
   apiGetBookings: (args: AppBookingsRequest) => any
   set_Page: () => void
   reset_Page: () => void
   get_BookingAdditionalData: (bookingId: number) => void,
   reset_filters: () => void,
   reset_List: () => void,
   set_showOffers: (args: boolean) => void,
   refund_payment: (args: AppRefundRequest) => Promise<any>;
   
}

const BookingsContainer: React.FC<BookingsContainerProps> = (props) => {

   const { filters, bookingsPagination, bookingsLoading, bookings,
      apiGetBookings, set_Page, reset_Page, get_BookingAdditionalData, 
      reset_filters, reset_List, refund_payment, set_showOffers} = props;

   let { fromUrlBookingId, backFromDetails} = useParams<BookingsUrlParams>();

   const [refundingPaymentId, setRefundedPaymentId] = useState<number | null>(null);

   let prevPageValue = usePrevious((bookingsPagination || {}).page);

   const numberOfItemsPerPage = 50;

   const currentBookingId: number = fromUrlBookingId ? parseInt(fromUrlBookingId) : 0;

   const searchBookings = (so: boolean) => { 
      apiGetBookings({
         pageNumber: 1,
         numberOfItems: numberOfItemsPerPage,
         searchStr: filters.searchText,
         from: filters.createdFrom,
         to: filters.createdTo,
         showOffers: so,
         searchBookingNumber: filters.searchBookingNumber,
         searchTelephone: filters.searchTelephone
      });
   }


   useEffect(() => {

      return () => {
         reset_Page();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {

      if (currentBookingId) {

         get_BookingAdditionalData(currentBookingId);
      }
      else {

         // if returned from menu for example, reset all filters, but if
         // user returned from Booking details, filters is preserved
         if (!backFromDetails){
            reset_filters();
            set_showOffers(false);
         }

         apiGetBookings({
            pageNumber: backFromDetails? bookingsPagination.page : 1,
            numberOfItems: numberOfItemsPerPage,
            searchStr: backFromDetails? filters.searchText: null,
            from:  backFromDetails? filters.createdFrom: null,
            to: backFromDetails? filters.createdTo: null,
            showOffers: backFromDetails? filters.showOffers: false,
            searchBookingNumber: backFromDetails? filters.searchBookingNumber: null,
            searchTelephone: backFromDetails?  filters.searchTelephone: null,
         });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [currentBookingId])


   useEffect(() => {

      if (!bookingsPagination.isLastPage && prevPageValue !== bookingsPagination.page && bookingsPagination.page > 1) {

         apiGetBookings({
            pageNumber: bookingsPagination.page,
            numberOfItems: numberOfItemsPerPage,
            searchStr: filters.searchText,
            from: filters.createdFrom,
            to: filters.createdTo,
            showOffers: filters.showOffers,
            searchBookingNumber: filters.searchBookingNumber,
            searchTelephone: filters.searchTelephone

         });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [bookingsPagination.page])


   const goToBookingDetails = (bookingId: number) => {

      if (!bookingId || bookingId === 0) {
         return;
      }

      history.push({ pathname: `${RoutesConfig.Bookings.navigatePath}/${bookingId}` });

   }

   const goBackToBookingsList = () => {
      history.push(`${RoutesConfig.Bookings.navigatePath}/0/1`)
   }

   const getBookingsOnScroll = (e: any) => {

      var bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;

      if (bottom && !bookingsPagination.isLastPage && !bookingsLoading) {
         set_Page();
      }

   }

   const openRefundEditor = (paymentId: number | null): void => {
      setRefundedPaymentId(paymentId);
   }

   const handleRefund = (data: AppRefundRequest): void => {
      // close popup
      setRefundedPaymentId(null);

      refund_payment(data).then((data) => {
      })
         .catch((err) => {
            console.log("err");
            console.log(err);
         })
         .finally(() => {
            // get new logg recoreds and booking status.
            if (currentBookingId !== null) {
               get_BookingAdditionalData(currentBookingId);

            }
         });


   }

   const doSearch = () => {
      reset_List();
      searchBookings(filters.showOffers);
   }

   const doSearchByDate = (dateFrom: string | null, dateTo: string | null) => {

      reset_List();

      apiGetBookings({
         pageNumber: 1,
         numberOfItems: numberOfItemsPerPage,
         searchStr: filters.searchText,
         from: dateFrom,
         to: dateTo,
         showOffers: filters.showOffers,
         searchBookingNumber: filters.searchBookingNumber,
         searchTelephone: filters.searchTelephone
      });
   }

   const doShowOfferChange = (showOffers: boolean) => {
      reset_List();
      set_showOffers(showOffers);
      searchBookings(showOffers);
   }

   const resetFilters = () => {

      reset_filters();

      apiGetBookings({
         pageNumber: 1,
         numberOfItems: numberOfItemsPerPage,
         showOffers: filters.showOffers
      });
   }



   if (currentBookingId) {
      return (<BookingDetailsView
         bookingId={currentBookingId}
         goToBookingsList={goBackToBookingsList}
         refundingPaymentId={refundingPaymentId}
         setRefundedPaymentId={openRefundEditor}
         handleRefund={handleRefund}

      />)
   }


   return (<BookingsView bookingsLoading={bookingsLoading}
      bookings={bookings}
      showOffers={filters.showOffers}
      goToBookingDetails={goToBookingDetails}
      getBookingsOnScroll={getBookingsOnScroll}
      resetFilters={resetFilters}
      doSearch={doSearch}
      doSearchByDate={doSearchByDate}
      showOfferChange={doShowOfferChange}
   />)
};


const mapStateToProps = (state: any) => {
   const bookingsState = state.bookings as AppBookingsState;
   const paymentState = state.payments as AppPaymentState;

   return {
      filters: bookingsState.filters,
      bookingsPagination: bookingsState.pagination,
      bookingsLoading: bookingsState.loading,
      bookings: bookingsState.list,
      refundPaymentPending: paymentState.refundPaymentPending
   };
};


const mapDispatchToProps = (dispatch: any) => {

   return {
      apiGetBookings: (args: AppBookingsRequest) => { dispatch(getBookings(args)) },
      set_Page: () => { dispatch(setBookingsPage()) },
      reset_Page: () => { dispatch(resetPage()) },
      reset_filters: () => { dispatch(resetFilters()) },
      get_BookingAdditionalData: (bookingId: number) => { dispatch(getBookingAdditionalData(bookingId)) },
      reset_List: () => dispatch(resetList()),
      refund_payment: (args: AppRefundRequest) => dispatch(refundPayment(args)),
      set_showOffers: (args: boolean) => { dispatch(setShowOffers(args)) }
   };
};

export default withRouter(
   connect(mapStateToProps, mapDispatchToProps)(BookingsContainer)
);


