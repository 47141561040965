import { History } from 'history';
import { combineReducers } from 'redux';
import authReducer from '../../features/Login/reducers/authReducers'
import usersReducer from '../../features/Users/reducers/usersReducer'
import { connectRouter } from 'connected-react-router';
import countryPriceConfigReducer from '../../features/Price/CountryPriceConfig/reducers/countryPriceConfigReducer';
import regionPriceConfigsReducer from '../../features/Price/RegionPriceConfigs/reducers/regionPriceConfigsReducer';
import bookingsLogReducer from '../../features/Bookings/reducer/bookingsLogReducer';
import bookingReducer from '../../features/Bookings/reducer/bookingReducer';
import paymentLogReducer from '../../features/Bookings/reducer/paymentLogReducer';
import paymentReducer from '../../features/Payments/reducers/paymentReducer';
import countriesReducer from '../../features/Countries/reducers/countriesReducer';
import regionsReducers from '../../features/Regions/reducers/regionsReducers';
import regionPriceConfig from '../../features/Price/RegionPriceConfigs/reducers/regionPriceConfigReducer';
import drivingAreaPriceConfigsReducer from '../../features/Price/DrvingAreaPriceConfig/reducers/drivingAreaPriceConfigsReducers';
import drivingAreaPriceConfigReducer from '../../features/Price/DrvingAreaPriceConfig/reducers/drivingAreaPriceConfigReducers';
import drivingAreasReducers from '../../features/DrivingAreas/reducers/drivingAreasReducers';
import bookingStopsReducer from '../../features/Bookings/reducer/bookingStopsReducer';
import priceTestCasesReducer from '../../features/Price/PriceConfigsTest/reducers/priceTestCasesReducer';
import drivingAreaGeosReducers from '../../features/DrivingAreas/reducers/drivingAreaGeoPointsReducer';
import financialReportReducers from '../../features/Reports/reducer/financialReportReducers';
import discountReducers from '../../features/Discount/reducers/discountsReducers';
import discountStatisticReducer from '../../features/Discount/reducers/discountStatisticReducer';
import discountCustomersReducer from '../../features/Discount/reducers/discountCustomers';


const rootReducer = (history: History) =>  combineReducers({
  auth: authReducer,
  users: usersReducer,
  bookings: bookingReducer,
  bookingLog: bookingsLogReducer,
  paymentLog: paymentLogReducer,
  bookingStops: bookingStopsReducer,
  payments: paymentReducer,
  countries: countriesReducer,
  regions: regionsReducers,
  drivingAreas: drivingAreasReducers,
  drivingAreaGeos: drivingAreaGeosReducers,
  countryPriceConfig: countryPriceConfigReducer,
  regionPriceConfigs: regionPriceConfigsReducer,
  regionPriceConfig: regionPriceConfig,
  drivingAreaPriceConfigs: drivingAreaPriceConfigsReducer,
  drivingAreaPriceConfig: drivingAreaPriceConfigReducer,
  priceTestCases: priceTestCasesReducer,
  financialReport: financialReportReducers,
  discounts: discountReducers,
  discountStatistic: discountStatisticReducer,
  discountCustomers: discountCustomersReducer,
  router: connectRouter(history),
 
});

export default rootReducer;

const result = rootReducer({} as History);

export type RootState = ReturnType<typeof result>

