
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DayTimeZoneEditor, DayTimeZoneEditorOwnProps } from '../../CommonComponents/DayTimeZoneEditor';
import { PriceConfigDayTimeZone } from '../../types';

import { addOrUpdateDayTimeZone, selectDateTimeZoneConfig } from '../reducers/countryPriceConfigReducer';
import { CountryPriceConfActions } from '../types';



const mapStateToProps = (state: any, ownProps: any) => {
    const props: {currentIndex: number, actions: CountryPriceConfActions } = ownProps
    const currentIndex = props.currentIndex;

    const result: DayTimeZoneEditorOwnProps = {
        currentIndex,
        dayTimeZoneFactor: currentIndex || currentIndex === 0 ? selectDateTimeZoneConfig(state, currentIndex) : null,
        closeEditor: ()=> props.actions.closeEditor()
    }

    return result;
};


const mapDispatchToProps = (dispatch: any) => {

    return {
        update_date_type_zone: (dayTimeZone: PriceConfigDayTimeZone, index: number | null) => dispatch(addOrUpdateDayTimeZone({ dayTimeZone, index })),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DayTimeZoneEditor)
);
