/** ENTITIES */
export interface Country {
    readonly countryId: number,
    readonly name: string
}

export const getCountryName = (item: Country): string => item && item.name? item.name: '';
export const getCountryId = (item: Country):number => item && item.countryId? item.countryId: 0;

/** STATE */

export interface CountriesState {
    list: Array<Country>,
    loading: boolean,
    currentItemId: number | null
}