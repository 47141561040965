import React from 'react';
import { History } from 'history'
import { Route, Switch } from 'react-router';
import { BookingsContainer, 
  ForgotPasswordContainer, 
  HomeContainer, 
  CountryPriceConfigContainer, 
  LoginContainer, 
  UserProfileContainer, 
  UsersContainer, 
  RegionPriceConfigContainer, 
  DrivingAreaPriceConfigContainer } from './features/index';
import { RoutesConfig } from './config/routes';
import RootApp from './features/RootApp/RootApp';
import { PriceConfigTestContainer } from './features/Price';
import { BookingFinancialReportContainer } from './features/Reports';
import { DiscountContainer } from './features/Discount';


const App: React.FC<{ history: History }> = ({ history }) => {

  return <>
    <Switch>
      <Route exact path={RoutesConfig.Login.path} component={LoginContainer} />
      <Route exact path={RoutesConfig.ForgotPassword.path} component={ForgotPasswordContainer} />

      <RootApp>
        <Route exact path={RoutesConfig.Home.path} component={HomeContainer} />
        <Route exact path={RoutesConfig.Users.path} component={UsersContainer} />
        <Route exact path={RoutesConfig.Bookings.path} render={(props) => <BookingsContainer {...props} key={Date.now()} />} />

        <Route exact path={RoutesConfig.CountryPriceConfig.path} component={CountryPriceConfigContainer} />
        <Route exact path={RoutesConfig.RegionPriceConfig.path} component={RegionPriceConfigContainer} />
        <Route exact path={RoutesConfig.DrivingAreaPriceConfig.path} component={DrivingAreaPriceConfigContainer} />
        <Route exact path={RoutesConfig.PriceConfigTest.path} component={PriceConfigTestContainer} />
        <Route exact path={RoutesConfig.FinancialReport.path} component={BookingFinancialReportContainer} />
        <Route exact path={RoutesConfig.Discount.path} component={DiscountContainer} />

        <Route exact path={RoutesConfig.UserProfile.path} component={UserProfileContainer} />
      </RootApp>

    </Switch >
  </>
};

export default App;