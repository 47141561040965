import { PriceTestCaseApi } from "../../../../api/priceTestCase.service";
import { deletePriceTestCase, priceTestCasesLoading, setPriceTestCases } from "../reducers/priceTestCasesReducer";
import { AddOrUpdatePriceTestCaseRequest, DeletePriceTestCaseRequest, PriceTestCaseBase, PriceTestCasesServerData } from "../types";

export const getPriceTestCases = (countryId: number, withSpecialLocations:boolean, withCarTypes:boolean, isTest:boolean = false) => {

    return async (dispatch: any) => {

        dispatch(priceTestCasesLoading(true));

        try {
            const result = await PriceTestCaseApi.getPriceTestCases(countryId, withSpecialLocations, withCarTypes);
            const data = (result.data || null) as PriceTestCasesServerData;

            if (!isTest){
                setTimeout(()=>{
                    dispatch(setPriceTestCases(data));
                }, 300)
            }
            else {
                dispatch(setPriceTestCases(data));
            }

      

            return;
        }
        catch (error) {
            console.log(error);
            dispatch(priceTestCasesLoading(false));
            throw error;
        }
    }
};


export const addPriceTestCase = (priceTestCase: PriceTestCaseBase) => {
    return async (dispatch: any) => {

        dispatch(priceTestCasesLoading(true));

        try {
            const dataToSend: AddOrUpdatePriceTestCaseRequest = {
                priceTestCase: priceTestCase
            };

            let result = await PriceTestCaseApi.addPriceTestCases(dataToSend);
            const id = (result.data || null) as number;

            return id;
        }
        catch (error) {
            dispatch(priceTestCasesLoading(false));
            throw error;
        }
    }
};

export const updatePriceTestCase = (priceTestCase: PriceTestCaseBase) => {
    return async (dispatch: any) => {

        dispatch(priceTestCasesLoading(true));

        try {
            const dataToSend: AddOrUpdatePriceTestCaseRequest = {
                priceTestCase: priceTestCase
            };

            let result = await PriceTestCaseApi.updatePriceTestCases(dataToSend);
            const dataFromServer = (result.data || null) as boolean;

            return dataFromServer;
        }
        catch (error) {
            dispatch(priceTestCasesLoading(false));
            throw error;
        }
    }
};


export const deletePriceTestCaseById = (priceTestCaseId: number) => {
    return async (dispatch: any) => {

        try {
            const dataToSend: DeletePriceTestCaseRequest = {
                priceTestCaseId
            };

            let result = await PriceTestCaseApi.deletePriceTestCases(dataToSend);
            const isOk = (result.data || null) as boolean;

            if (isOk){
                dispatch(deletePriceTestCase(priceTestCaseId));
            }

            return isOk;
        }
        catch (error) {
            throw error;
        }
    }
};
