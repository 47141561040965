import { AxiosResponse } from 'axios';
import { ApiResponse } from '../commonTypes';
import {  UserEditorRequestData } from '../features/Login/types';
import { BaseService } from './base.service';

class UsersService extends BaseService {
  private static _authService: UsersService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): UsersService {
    return this._authService || (this._authService = new this('users'));
  }

  public async getUsers(): Promise<ApiResponse> {

    let axiosResponse = null;

    try {
        axiosResponse = await this.$http.get('getUsers');
        return {response: axiosResponse, data: axiosResponse.data}; 
    }
    catch (error) {
        this.handleAllErrors(error);
        throw error;
    }
  }


  public async addOrUpdateUser(user: UserEditorRequestData): Promise<AxiosResponse | null> {
    let axiosResponse = null;

    try {
        if (user.userId){
          axiosResponse = await this.$http.post('updateUser', user);
        }
        else {
          axiosResponse = await this.$http.post('createUser', user);
        }
    }
    catch (error) {
        this.handleAllErrors(error);
        throw error;
    }

    return axiosResponse;
  }

  public async deleteUser(userId: number): Promise<AxiosResponse | null> {
    var axiosResponse = null;
    
    await this.$http.post('deleteUser', {userId})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error);
        throw error;
    });
   
    return axiosResponse;
  }

}

export const UsersApi = UsersService.Instance;
