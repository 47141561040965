import React from 'react';
import { AppBreadcrumbs, Spinner } from '../../../../components';
import { AppModal } from '../../../../components/AppModal';
import { PageHeaderWrapper } from '../../CommonComponents/PageHeaderWrapper';
import { MapIcon } from '../../../../components/Icon';
import { RegionPriceConfActions, RegionPriceConfigModalType } from '../types';
import { RegionPriceConfigTabs } from './RegionPriceConfigTabs';
import { ModalTypeData } from '../../../../config/constants';
import MainRegionPriceConfigEditor from './MainRegionPriceConfigEditor';
import { CommentEditor } from '../../CommonComponents/CommentEditor';
import CountryRegionDrivingAreaPicker from '../../../CountryRegionDrivingAreaPicker/CountryRegionDrivingAreaPicker';
import { HideEditingBtns } from '../../types';
import { Region } from '../../../Regions/types';


export interface RegionPriceConfigViewPropsFromReducer {
    currentCountryId: number | null,
    currentRegionId: number | null,
    regionsLoading: boolean,
    countriesLoading: boolean,
    comment: string | null,
    currentRegion: Region | null
}


interface RegionPriceConfigViewProps extends HideEditingBtns {
    allProps: RegionPriceConfigViewPropsFromReducer,
    modalState: ModalTypeData | null,
    actions: RegionPriceConfActions
}

export const RegionPriceConfigView: React.FC<RegionPriceConfigViewProps> = (props) => {

    const { countriesLoading, currentCountryId, currentRegionId, regionsLoading, comment, currentRegion } = props.allProps || {};
    const { modalState, actions, hideEditingBtns } = props;

    if (countriesLoading || regionsLoading) {
        return <Spinner />
    }

    let modalTitleText = null;
    let modalContentHtml = null;

    if (modalState) {
        if (modalState.modalType === RegionPriceConfigModalType.regionPriceFactors) {
            modalTitleText = "Rediger/Legg til regionale variabler";
            modalContentHtml = <MainRegionPriceConfigEditor actions={actions} />
        }
        else if (modalState.modalType === RegionPriceConfigModalType.versionInfo) {
            modalTitleText = "Rediger/Legg til kommentar";
            modalContentHtml = <CommentEditor
                comment={comment}
                update_comment={actions.updateComment}

            />;
        }
    }

    return (<div className="main-content-item region-price-config-page">

        <AppBreadcrumbs pages={[{ name: 'Pris admin / Regionale variabler' }]} />

        <main className="region-price-config-page-main">

            <PageHeaderWrapper >

                <div className="price-config-area-picker-wrapper display-flex-center-y">

                    <div style={{ marginRight: '4px' }}><MapIcon /></div>

                    {
                        hideEditingBtns ?
                            <div style={{ fontWeight: 'bold', fontSize:'16px' }}> {'Publisert versjon: ' + (currentRegion?.name || '') }</div>
                            : <CountryRegionDrivingAreaPicker
                                showRegions={true}
                                itemsPosition={'row'}
                                showCurrentRegionLabel={false}
                                currentCountryId={currentCountryId}
                                onRegionChanged={actions.changeRegion}
                                onCountryChanged={actions.changeCountry}
                                propToResetState={currentCountryId}
                                currentRegionId={currentRegionId || null}
                            />
                    }

                </div>

            </PageHeaderWrapper>

            <RegionPriceConfigTabs actions={actions} hideEditingBtns={hideEditingBtns} />

        </main>

        {
            modalContentHtml ?
                <AppModal
                    onClose={actions.closeEditor}
                    isOpen={modalState ? true : false}
                    header={<>{modalTitleText}</>} >

                    {modalContentHtml}

                </AppModal>
                : ''
        }


    </div>
    )
};


