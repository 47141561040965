import React from 'react';
import { AppBreadcrumbs, AppButton, MyCheckbox, Spinner } from '../../../../components';
import { AppModal } from '../../../../components/AppModal';
import { PageHeaderWrapper } from '../../CommonComponents/PageHeaderWrapper';
import { CloseIcon, MapIcon, PlusIcon, SendIcon } from '../../../../components/Icon';
import { PriceTestCaseContainerActions, PriceTestCaseModalType } from '../types';
import { ModalTypeData } from '../../../../config/constants';
import CountryRegionDrivingAreaPicker from '../../../CountryRegionDrivingAreaPicker/CountryRegionDrivingAreaPicker';
import PriceTestCaseList from './PriceTestCaseList';
import PriceTestCaseEditor from './PriceTestCaseEditor';

export interface PriceConfigTestViewPropsFromReducer {
    currentCountryId: number | null,
    countriesLoading: boolean,
    isTest: boolean
}

interface PriceConfigTestViewPropsFromLocalState {
    modalState: ModalTypeData | null,
    actions: PriceTestCaseContainerActions,
    withSpecialLocations: boolean,
    withCarTypes: boolean
    allProps: PriceConfigTestViewPropsFromReducer,
}

type PriceConfigTestViewProps = PriceConfigTestViewPropsFromLocalState;

export const PriceConfigTestView: React.FC<PriceConfigTestViewProps> = (props) => {

    const { countriesLoading, currentCountryId } = props.allProps;
    const { modalState, actions, withSpecialLocations, withCarTypes } = props;

    if (countriesLoading) {
        return <Spinner />
    }

    let modalTitleText = null;
    let modalContentHtml = null;

    if (modalState?.modalType === PriceTestCaseModalType.priceTestCaseEditor) {
        modalTitleText = "Legg til/Rediger ";
        modalContentHtml = <PriceTestCaseEditor currentPriceTestCaseId={modalState.index} actions={actions} />
    }


    return (<div className="main-content-item price-test-cases">

        <AppBreadcrumbs pages={[{ name: 'Pris admin / Pris test' }]} />

        <main className="price-test-cases-main">

            <PageHeaderWrapper className="price-test-case-header" >

                <div style={{ justifyContent: 'space-between' }} className="price-config-area-picker-wrapper display-flex-center-y">
                    <div style={{ marginRight: '4px' }}><MapIcon /></div>

                    <CountryRegionDrivingAreaPicker
                        itemsPosition={'row'}
                        showRegions={false}
                        showCurrentRegionLabel={false}
                        currentCountryId={currentCountryId}
                        onCountryChanged={actions.changeCountry}
                        propToResetState={null}
                    />

                </div>

                {
                    !currentCountryId ?
                        '' : (<div data-testid='test-price-test-case-btns-right' className="price-test-case-btns-right" >
                            <div className="ui segment price-test-case-filters" >
                                <MyCheckbox
                                    label={'Spesiallokasjoner'}
                                    name={'withSpecialLocations'}
                                    value={withSpecialLocations}
                                    onChange={actions.changeFilterWithSpecialLocations}
                                    dataTestId={'test-with-special-locations-filter'}
                                />
                                <span style={{ marginRight: '10px' }}></span>
                                <MyCheckbox
                                    label={'Biltype'}
                                    name={'withCarTypes'}
                                    value={withCarTypes}
                                    onChange={actions.changeFilterWithCarTypes}
                                    dataTestId={'test-with-car-type-filter'}
                                />

                                <div style={{ marginRight: '10px' }}></div>
                                <AppButton size='tiny'
                                    designType="primary"
                                    isIcon={true}
                                    dataTestId="test-filter-price-test-cases"
                                    onClick={actions.getPriceTestCase} >

                                    <SendIcon />
                                </AppButton>
                                <AppButton size='tiny'
                                    designType="primary"
                                    isIcon={true}
                                    disabled={!withSpecialLocations && !withCarTypes}
                                    dataTestId="test-reset-filters-price-test-cases"
                                    onClick={actions.resetFilters} >
                                    <CloseIcon />
                                </AppButton>
                            </div>

                            <div style={{ marginRight: '10px' }}></div>

                            <AppButton size='medium'
                                designType="teal"
                                isIcon={true}
                                dataTestId="test-add-special-location"
                                onClick={() => actions.openEditor(null, PriceTestCaseModalType.priceTestCaseEditor)} >

                                <PlusIcon />
                            </AppButton>
                        </div>)}

            </PageHeaderWrapper>

            <div className="price-test-cases-list-wrapper ui segment">
                <PriceTestCaseList
                    withSpecialLocations={withSpecialLocations}
                    withCarTypes={withCarTypes}
                    actions={actions}
                />
            </div>

        </main>

        {
            modalContentHtml ?
                <AppModal
                    onClose={actions.closeEditor}
                    isOpen={modalState ? true : false}
                    header={<>{modalTitleText}</>} >
                    {modalContentHtml}

                </AppModal>
                : ''
        }


    </div>
    )
};


