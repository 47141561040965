/* Discount */

import { AppPagination, CURRENCY } from "../../config/constants"
import * as yup from 'yup';

export interface AppDiscount {
    discountId?: number | null,
    name: string,
    amountTypeId: AppDiscountAmountType,
    code?: string,
    amount: number,
    numberOfTimesPerCustomer?: number | null,
    maxValueForPercentType?: number | null,
    minPriceForApplication?:number | null,
    isActive: boolean,
    publishedDate?: string | null,
    forAllCustomers:boolean,
    start?: string | null,
    end?: string | null
}

export interface AppDiscountListElement extends AppDiscount {
    totalNumberOfTimesUsed: number,
    customers?: Array<string> | null
}

export interface AppDiscountEditor extends AppDiscount {
    customers?: Array<string> | null
}

export interface AppDiscountStatistic {
    customerId:number,
    phoneNumber:string,
    used:number
}

export interface AppDiscountCustomers {
    customerId:number,
    phoneNumber:string,
    discountId:number
}

export enum AppDiscountAmountType {
    Percent = 1,
    Amount = 2,
}

export const getDiscountAmountTypeName = (amountType: AppDiscountAmountType): {text: string | ''} => {

    switch (amountType) {
        case AppDiscountAmountType.Amount:
          return {text: "Beløp"}
        case AppDiscountAmountType.Percent:
            return {text: "Prosent"}
        default:
          return {text: ''}
      }
}

export const getDiscountAmountTypeTextShort = (amountType: AppDiscountAmountType): {text: string | ''} => {

    switch (amountType) {
        case AppDiscountAmountType.Amount:
          return {text: CURRENCY}
        case AppDiscountAmountType.Percent:
            return {text: "%"}
        default:
          return {text: ''}
      }
  
}


/** state */
export interface AppDiscountsFilter {
   // from: string | null,
   // to: string | null,
    searchText: string | null,
    showActive:boolean
}

export interface AppDiscountsState {
    list: Array<AppDiscountListElement>,
    loading: boolean,
    filters: AppDiscountsFilter,
    pagination: AppPagination,
    saving: boolean,
    activationPending: boolean,
    deletePending: boolean
}

export interface AppDiscountsStatisticState {
    discountStatistics: Array<AppDiscountStatistic>,
    loading: boolean,
    discountId: number | null
}

export interface AppDiscountCustomersState {
    discountCustomers: Array<AppDiscountCustomers>,
    loading: boolean,
    discountId: number | null
}


/* Responses, requests from server */

export interface AppDiscountsRequest {
    pageNumber: number,
    numberOfItems: number | null,
    searchStr?: string | null,
    //showActive: boolean
}

export interface AppActivateDeactivateRequest {
    discountId: number | null,
    isActive: boolean
}

export interface AppActivateDeactivateResponse {
    discountId: number,
}

export interface AppDiscountsResponse {
    discounts: Array<AppDiscountListElement>,
    isLastPage: boolean
}

export interface AppDeleteDiscountRequest {
    discountId: number | null,
}

/* Actions */

export interface DiscountsActions {
    getItemsOnScroll:(e:any) => any,
    getStatistics: (discountId: number) => any,
    resetFilters: ()=>any,
    doSearch: ()=>any,
    doOpenStatistics: (modalType: DiscountModalType, id:number | null)=>any,
    openEditor: (modalType: DiscountModalType, id:number | null)=> void,
    closeEditor:() => void,
    addOrUpdateDiscount: (dataToSend: AppDiscountEditor, currentId: number | null) => void,
    deleteDiscount: (args: AppDeleteDiscountRequest) => any,
    duplicateDiscount: (discountId: number) => any,
    activateDeactivateDiscount: (args: AppActivateDeactivateRequest) => void
}

/** get empty item */

export const getEmptyDiscountObject = (): AppDiscountEditor => {
    return {
        discountId: null,
        name: '',
        amountTypeId: AppDiscountAmountType.Amount,
        code: '',
        amount: 0,
        numberOfTimesPerCustomer: 1,
        maxValueForPercentType:  null,
        minPriceForApplication: null,
        isActive: false,
        customers: null,
        forAllCustomers:false,
        start: null,
        end:null
    }
}


export enum DiscountModalType {
    discountEditor = 1,
    discountStatistic = 2
}



/* Validation */
export const discountValidation = yup.object().shape({
    name: yup.string().typeError('Navn er påkrevd.').required('Navn er påkrevd'),
    code: yup.string().typeError('Kode er streng.').required('Kode er påkrevd.'),
    minPriceForApplication: yup.number().nullable().min(1, "Må være større enn 0."),
    maxValueForPercentType: yup.number().nullable().min(1, "Må være større enn 0."),
    amount: yup.number().typeError("Beløp er påkrevd").min(1, "Beløp må være større enn 0.").required('Beløp er påkrevd.'),
    start: yup.date().nullable(),
    end: yup.date().nullable()
})