import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { MyTextInput } from '../../../../components';
import { AreYouSureAppButton } from '../../../../components/AppButton';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { prepareAndRoundNumber } from '../../../../utils/commonHelpers';
import { formPrepareAndRoundNumber, getInputValue } from '../../../../utils/formHelpers';
import { nameof } from '../../../../utils/nameOfPropertyHelper';
import { selectPaymentsById } from '../../../Payments/reducers/paymentReducer';
import { AppPayment } from '../../../Payments/types';
import { AppRefundRequest, refundFormValidation } from '../../types';


interface RefundFormOwnProps {
    handleRefund: (data: AppRefundRequest) => void,
    refundingPaymentId: number | null
};

interface RefundFormReduxProps {
    paymentToRefund: AppPayment,
    userId: number
};

const RefundForm: React.FC<RefundFormOwnProps & RefundFormReduxProps> = (props) => {

    const { handleRefund, paymentToRefund, userId } = props;

    let currentCaptured = 0;

    if (paymentToRefund && paymentToRefund.captured > 0){
        currentCaptured = paymentToRefund.captured - (paymentToRefund.refunded || 0);
        if (currentCaptured > 0){
            currentCaptured = prepareAndRoundNumber(currentCaptured);
        }
    }

    const initFormFields: AppRefundRequest = {
        amount: paymentToRefund ? currentCaptured : 0,
        userId
    }



    const handleFormSubmit = async (values: any, setErrors: any) => {

        if (!values) {
            return;
        }

        const amount = values[nameof<AppRefundRequest>('amount')];

        if (!amount) {
            return;
        }

        const result = prepareAndRoundNumber(amount);

        if (isNaN(result)) {
            return;
        }

        if (result > currentCaptured) {
            setErrors({ [nameof<AppRefundRequest>('amount')]: "Beløp kan ikke være mer enn " + currentCaptured });
            return;
        }

        const data: AppRefundRequest = { amount: result, paymentId: paymentToRefund.paymentId, userId};

        if (typeof handleRefund === 'function') {
            handleRefund(data);
        }

    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initFormFields}
            validationSchema={refundFormValidation}
            onSubmit={(values, { setErrors }) => { handleFormSubmit(values, setErrors) }}
        >
            {(formik) => (
                <Form data-testid="refund-form" className='ui form' onSubmit={formik.handleSubmit} autoComplete='off'>

                    <MyTextInput
                        data-testid={"input-" + nameof<AppRefundRequest>('amount')}
                        label={'Beløp å refundere'}
                        name={nameof<AppRefundRequest>('amount')}
                        type="number"
                        onChange={(e)=>{
                            e.preventDefault();

                            formPrepareAndRoundNumber(getInputValue(e), (newValue:any)=>{
                                formik.setFieldValue(nameof<AppRefundRequest>('amount'), newValue)
                            });
                            
                        }}
                    />


                    <div className="footer-modal">

                        <AreYouSureAppButton
                            mainBtndesignType={'teal'}
                            dataTestIdOk={'test-ok-btn'}
                            dataTestIdConfirm={'test-confirm-btn'}
                            size='mini'
                            type="submit"
                            text='Refunder' onClick={formik.handleSubmit} />

                    </div>

                </Form>
            )}
        </Formik>
    )
};

const mapStateToProps = (state: RootState, ownProps: any): RefundFormOwnProps & RefundFormReduxProps => {

    const { refundingPaymentId = 0, handleRefund } = ownProps as RefundFormOwnProps;

    const userState = state.auth;

    return {
        handleRefund,
        paymentToRefund: selectPaymentsById(state.payments, (refundingPaymentId || 0)) as AppPayment,
        refundingPaymentId,
        userId: userState.user && userState.user.userId ? userState.user.userId: 0
    };
};


const mapDispatchToProps = (dispatch: any) => {

    return {
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RefundForm)
);