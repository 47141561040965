import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import { history } from '../../../config/historyConfig';
import { RoutesConfig } from '../../../config/routes';
import { DrivingAreaPriceConfActions, DrivingAreaPriceConfigModalType } from './types';
import { DrivingAreaPriceConfigView, DrivingAreaPriceConfigViewPropsFromReducer } from './child-components/DrivingAreaPriceConfigView';
import { getLastDrivingAreaPriceConfig, publishOrSaveDrivingAreaPriceConfig, resetDrivingAreaPriceConfigDraft } from './actions/drivingAreaPriceConfigActions';
import { setCurrentRegion } from '../../Regions/reducers/regionsReducers';
import { setCurrentItem } from '../../Countries/reducers/countriesReducer';
import {addOrUpdateSpecialLocation, deleteSpecialLocation, resetDrivingAreaPriceConfigState, updateComment } from './reducers/drivingAreaPriceConfigReducers';
import { useCountryIdAssigner } from '../../../hooks/useCountryIdAssigner';
import { useRegionIdAssigner } from '../../../hooks/useRegionIdAssigner';
import '../../../styles/priceConfig.css';
import { setCurrentDrivingArea, selectDrivingAreaById } from '../../DrivingAreas/reducers/drivingAreasReducers';
import { DrivingAreaSpecialLocation } from '../types';
import { useDrivingAreaIdAssigner } from '../../../hooks/useDrivingAreaIdAssigner';
import { useIdPublishedVersionAssignerModule } from '../../../hooks/useIsPublishedVersionAssigner';


interface ActionProps {
    get_driving_area_price_config: (regionId: number, drivingAreaId: number, isPublished: boolean) => void,
    set_current_country: (countryId: number) => void,
    set_current_region: (regionId: number | null) => void,
    set_current_driving_area: (drivingAreaId: number | null) => void,
    publish_or_save_price_config: (isSave: any) => Promise<any>,
    add_or_update_special_location: (specialLocation: DrivingAreaSpecialLocation, index: number | null) => void,
    delete_special_location: (index: number) => void,
    reset_driving_area_price_config_draft: () => Promise<any>,
    reset_driving_area_price_config: () => void,
    update_comment: (comment: string | null) => void

}

type DrivingAreaPriceConfigContainerProps = ActionProps & DrivingAreaPriceConfigViewPropsFromReducer;

const DrivingAreaPriceConfigContainer: React.FC<DrivingAreaPriceConfigContainerProps> = (props) => {

    const { get_driving_area_price_config,
        set_current_country, set_current_region, set_current_driving_area,
        publish_or_save_price_config,
        add_or_update_special_location,
        delete_special_location,
        reset_driving_area_price_config_draft,
        reset_driving_area_price_config,
        update_comment
    } = props;

    const { currentCountryId,
         currentRegionId, 
         currentDrivingAreaId, 
         countriesLoading, 
         regionsLoading, 
         drivingAreasLoading, 
         comment, currentDrivingArea } = props;

    const [modalState, setModalState] = useState<{ modalType: DrivingAreaPriceConfigModalType, index: number | null } | null>(null);

    // updates reducer state with currentCountryId, regionId from url.
    useCountryIdAssigner(set_current_country);
    const fromUrlRegionId = useRegionIdAssigner(set_current_region);
    const fromUrlDrivingAreaId = useDrivingAreaIdAssigner(set_current_driving_area);

    const isPublished = useIdPublishedVersionAssignerModule.useIdPublishedVersionAssigner();
    const hideEditingBtns = isPublished;

    useEffect(() => {

        const currentRegionIdResult = fromUrlRegionId || currentRegionId;
        const currentDrivingAreaIdResult = fromUrlDrivingAreaId || currentDrivingAreaId; 

        if (currentDrivingAreaIdResult && currentRegionIdResult) {
            get_driving_area_price_config(currentRegionIdResult, currentDrivingAreaIdResult, isPublished);
        }

        return () => {
            reset_driving_area_price_config();
           
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const changeCountry = (countryId: number): void => {

        if (!countryId) {
            return;
        }

        set_current_country(countryId);
        set_current_region(null);
        set_current_driving_area(null);

        reset_driving_area_price_config();

        const url = RoutesConfig.DrivingAreaPriceConfig.navigatePath(countryId);
        history.push({ pathname: url });

    }

    const changeRegion = (regionId: number): void => {
        if (!regionId || !currentCountryId) {
            return;
        }

        set_current_region(regionId);
        set_current_driving_area(null);

        reset_driving_area_price_config();

        const url = RoutesConfig.DrivingAreaPriceConfig.navigatePath(currentCountryId, regionId);
        history.push({ pathname: url });

    }

    const changeDrivingArea = (drivingAreaId: number): void => {
        if (!drivingAreaId || !currentCountryId || !currentRegionId) {
            return;
        }

        set_current_driving_area(drivingAreaId);
        get_driving_area_price_config(currentRegionId, drivingAreaId, false);

        const url = RoutesConfig.DrivingAreaPriceConfig.navigatePath(currentCountryId, currentRegionId, drivingAreaId);
        history.push({ pathname: url });

    }

    const edit = (index: number | null, modalType: DrivingAreaPriceConfigModalType): void => {

        if (!modalType) {
            return;
        }

        index = index || index === 0 ? index : null;
        setModalState({ modalType, index });

    }

    const publishOrSaveConfig = (isSaving: boolean): void => {

        publish_or_save_price_config(isSaving).then(() => {

            if (currentRegionId && currentDrivingAreaId) {
                setTimeout(() => {
                    get_driving_area_price_config(currentRegionId, currentDrivingAreaId, false);
                }, 700)
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    const resetDraft = (): void => {

        reset_driving_area_price_config_draft().then(() => {

            if (currentRegionId && currentDrivingAreaId) {
                setTimeout(() => {
                    get_driving_area_price_config(currentRegionId, currentDrivingAreaId, false);
                }, 700)
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    const updateComment = (comment: string): void => {
        update_comment(comment);
        closeEditor();
    }

    const addOrUpdateSpecialLocation = (specialLocation: DrivingAreaSpecialLocation, index: number | null) => {
        
        if (specialLocation){
            add_or_update_special_location(specialLocation, index);
        }

    }

    const deleteSpecialLocation = (index: number) => {
        delete_special_location(index);
    }

    const goToPublishedVersion = (): string => {
        if (currentCountryId && currentRegionId && currentDrivingAreaId){
            const url = RoutesConfig.DrivingAreaPriceConfig.navigatePath(currentCountryId, currentRegionId, currentDrivingAreaId, true);
            return url;
        }

        return '';
    }

    const closeEditor = (): void => {
        setModalState(null);
    }

    const actions: DrivingAreaPriceConfActions = {
        changeCountry,
        changeRegion,
        changeDrivingArea,
        publishOrSaveConfig,
        edit,
        addOrUpdateSpecialLocation,
        deleteSpecialLocation,
        closeEditor,
        resetDraft,
        goToPublishedVersion,
        updateComment
    };

    const allProps: DrivingAreaPriceConfigViewPropsFromReducer = {
        currentCountryId: currentCountryId,
        currentRegionId: currentRegionId,
        currentDrivingAreaId: currentDrivingAreaId,
        regionsLoading: regionsLoading,
        countriesLoading: countriesLoading,
        drivingAreasLoading: drivingAreasLoading,
        comment,
        currentDrivingArea
    };

    return (<DrivingAreaPriceConfigView
        allProps={allProps}
        hideEditingBtns={hideEditingBtns}
        modalState={modalState}
        actions={actions} />)
};


const mapStateToProps = (state: RootState) => {
    const countriesState = state.countries;
    const regionsState = state.regions;
    const drivingAreasState = state.drivingAreas;
    const daPriceConfigState = state.drivingAreaPriceConfig;

    const currentDrivingArea = drivingAreasState.currentItemId? selectDrivingAreaById(state, drivingAreasState.currentItemId): null;

    const props: DrivingAreaPriceConfigViewPropsFromReducer = {
        currentCountryId: countriesState.currentItemId,
        currentRegionId: regionsState.currentItemId,
        currentDrivingAreaId: drivingAreasState.currentItemId,
        countriesLoading: countriesState.loading,
        regionsLoading: regionsState.loading,
        drivingAreasLoading: drivingAreasState.loading,
        comment: daPriceConfigState.comment || '',
        currentDrivingArea
         
    };

    return props;
};


const mapDispatchToProps = (dispatch: any) => {

    const actions: ActionProps = {
        get_driving_area_price_config: (regionId: number, drivingAreaId: number, isPublished:boolean) => dispatch(getLastDrivingAreaPriceConfig(regionId, drivingAreaId, isPublished)),
        set_current_country: (countryId: number) => dispatch(setCurrentItem(countryId)),
        set_current_region: (regionId: number | null) => dispatch(setCurrentRegion(regionId)),
        set_current_driving_area: (drivingAreaId: number | null) => dispatch(setCurrentDrivingArea(drivingAreaId)),
        publish_or_save_price_config: (isSave: any) => dispatch(publishOrSaveDrivingAreaPriceConfig({ isSave })),
        reset_driving_area_price_config_draft: () => dispatch(resetDrivingAreaPriceConfigDraft()),
        reset_driving_area_price_config: () => dispatch(resetDrivingAreaPriceConfigState()),
        add_or_update_special_location: (specialLocation: DrivingAreaSpecialLocation, index:number | null | undefined) => dispatch(addOrUpdateSpecialLocation({specialLocation, index})),
        delete_special_location: (index:number) =>  dispatch(deleteSpecialLocation(index)),
        update_comment: (comment: string | null) => dispatch(updateComment(comment))

    }

    return actions;
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DrivingAreaPriceConfigContainer)
);


