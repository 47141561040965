import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Region, RegionsState } from '../types';


const initialState: RegionsState = {
    list: [],
    loading: false,
    currentItemId: null
};

const countriesSlice = createSlice({
    name: 'regions',
    initialState,
    reducers: {
        regionsLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        regionsLoaded(state, action: PayloadAction<Array<Region>>) {
            const data = action.payload;

            state.loading = false;
            state.list = data || [];
        },
        setCurrentRegion(state, action: PayloadAction<number | null>){       
            state.currentItemId = action.payload || null;
        },
        resetState(state) {
            return initialState;
        }
    },
})

export const {
    regionsLoading,
    regionsLoaded,
    setCurrentRegion,
    resetState

} = countriesSlice.actions;

export default countriesSlice.reducer;


export const selectRegionById = (state: any, id: number | null): Region | null => {
    const items = state.regions as RegionsState;

    if (id) {
        return items.list.find(x => x.regionId == id) || null;
    }

    return null;
}

export const selectRegionsByCountryId = (state: any, countryId: number): Array<Region> | [] => {
    const items = state.regions as RegionsState;

    if (countryId) {
        return items.list.filter(x => x.countryId == countryId);
    }

    return [];
}



