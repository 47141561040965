import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Header, Label } from 'semantic-ui-react';
import { MyTextInput } from '../../../components';
import { ActionStatus } from '../../../config/constants';
import { nameof } from '../../../utils/nameOfPropertyHelper';
import { userLogin } from '../actions/authActions';
import { AuthUserState, LoginRequestData, loginRequestDataValidation } from '../types';



interface LoginFormProps {
  handleFormSubmit: (data: any) => void,
  pending: boolean
};

const LoginForm: React.FC<LoginFormProps> = (props) => {

  const { handleFormSubmit, pending } = props;
  const currentState: LoginRequestData = { email: '', password: '' };

  const handleAuthFormSubmit = async (values: LoginRequestData) => {
    await handleFormSubmit(values);
  }

  return (
    <Formik
      initialValues={Object.assign(currentState, { error: null })}
      validationSchema={loginRequestDataValidation}
      onSubmit={(values, { setErrors }) => { handleAuthFormSubmit(values) }}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
          <div className="login-title">Ridel Admin</div>

          <MyTextInput data-testid={nameof<LoginRequestData>('email')} name={nameof<LoginRequestData>('email')} placeholder='E-post' />

          <MyTextInput data-testid={nameof<LoginRequestData>('password')} type='password' name={nameof<LoginRequestData>('password')} placeholder='Passord' />

          <ErrorMessage
            name='error' render={() =>
              <Label style={{ marginBottom: 10 }} basic color='red' content={errors.error} />}
          />
          <Button loading={pending} style={{backgroundColor:"#48bb88"}} positive content='Logg inn' type='submit' fluid />
        </Form>
      )}
    </Formik>
  )
};

const mapStateToProps = (state: any) => {
  const stateAuth = state.auth as AuthUserState;
  return {
    pending: stateAuth.status === ActionStatus.PROCESS ? true : false
  };
};

const mapDispatchToProps = (dispatch: any, history: any) => {
  return {
    handleFormSubmit: (data: any) => { dispatch(userLogin(data)) },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
