import React, { useEffect, useState } from 'react';
import { ActionStatus } from '../../config/constants';
import '../../styles/dashbord.css'
import { getDashboard } from './actions/dashboardActions';
import { HomeView } from './child-components/HomeView';
import { AppDashboard, getEmptyDashboard } from './type';


interface HomeProps {}

export const HomeContainer: React.FC<HomeProps> = () => {

    // readonly state, not nessesary to have this in redux
    const [dashboard, setDashoard] = useState<AppDashboard>(getEmptyDashboard());
    const [dashboardPending, setDashboardPending] = useState(ActionStatus.NONE);

    useEffect(()=>{
        getDashboard(setDashoard, setDashboardPending);
    },[])


    return (<HomeView dashboard={dashboard} pending={dashboardPending} />)
};

