import { AppButton } from "../../../../components";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { setSearchText } from "../../reducers/discountsReducers";
import { AppDiscountsState } from "../../types";
import { RootState } from "../../../../config/reducerConfig/rootReducer";

export interface DiscountSearchBarProps {
    set_searchText: (args: string) => void,
    doSearchByText: () => void,
    searchText: string | null
}


const DiscountsSearchBar: React.FC<DiscountSearchBarProps> = (props) => {

    const { set_searchText, doSearchByText, searchText } = props;

    const onSearchTextChanged = (data: any) => {

        if (!data || !data.target) {
            return;
        }

        set_searchText(data.target.value);
    }

    const handleKeyPress = (e: any) => {
        var key = e.key;
        if (key === 'Enter') {
            doSearchByText();
        }

    }

    console.log('searchText');
    console.log(searchText);

    return (<div className="discount-search-bar">

        <div className="ui action input">
            <input 
            onChange={(data) => onSearchTextChanged(data)} 
            type="text" 
            value={searchText || ""} 
            placeholder="Søk... " 
            onKeyPress={(e) => handleKeyPress(e)} />

            <AppButton onClick={() => doSearchByText()} size='mini' >Søk</AppButton>
        </div>
    </div>)

};

const mapStateToProps = (state: RootState, ownProps: any) => {
    const discountState = state.discounts;

    return {
        searchText: discountState.filters.searchText,
        doSearchByText: ownProps.doSearchByText,
    };
};


const mapDispatchToProps = (dispatch: any) => {

    return {
        set_searchText: (args: string) => dispatch(setSearchText(args)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscountsSearchBar));
