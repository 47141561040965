import { schema } from "normalizr";

export const getEntityHelper = (normalizedData: { entities: any }) =>
  normalizedData && normalizedData.entities ? normalizedData.entities : {};

/** array of users  */

export const usersNormalizerConfig = new schema.Entity(
  "users",
  {},
  {
    idAttribute: "userId"
  }
);