interface BookingInfoElementProps {
    title: string | JSX.Element,
    text: string | number | JSX.Element,
    descriptionClassName?: string | null,
    testId?: string
}

export const BookingInfoElement: React.FC<BookingInfoElementProps> = (props) => {
    let { title, text, descriptionClassName, testId} = props;

    text = text || text === 0? text: '';

    return (<div data-testid={testId} className="booking-info-element">
        <div className="summary" style={{ fontWeight: 'bold' }}>
            {title || ''}
        </div>
        <div className={"description " + (descriptionClassName || '')}>
            {text}
        </div>
    </div>
    )
};
