import { DashboardApi } from "../../../api/dashboard.service";
import { ActionStatus } from "../../../config/constants";
import { AppDashboard } from "../type";


export const getDashboard = async (
    setDashboard: (dashboard: AppDashboard) => void,
    setDashboardStatus: (status: ActionStatus) => void) => {

    setDashboardStatus(ActionStatus.PROCESS);

    try {

        const result = await DashboardApi.getDashboard();

        if (result.data) {
            setDashboard(result.data as AppDashboard);
            setDashboardStatus(ActionStatus.SUCCESS);
        }
        else {
            setDashboardStatus(ActionStatus.FAIL);
        }

        return;
    }
    catch (error) {
        setDashboardStatus(ActionStatus.FAIL);
        return new Error(error);
    }

};
