import React from 'react';
import { AppTabs } from '../../../components';
import { MapIcon } from '../../../components/Icon';
import { isMobile } from '../../../utils';
import { HideEditingBtns } from '../../Price/types';
import { MapWithDrivingAreas, MapWithDrivingAreasProps } from './MapWithDrivingAreas';


export const DrivingAreasTabs: React.FC<HideEditingBtns & MapWithDrivingAreasProps> = (props) => {
    props = props || {};
    const {  points, drivingAreas} = props;

    let screenIsMobile = isMobile();

    let mainText = !screenIsMobile ? 'Kjøreområder' : '';

    const panes = [
        {
            menuItemText: mainText,
            menuItemIcon: <MapIcon />,
            testId:'driving-areas-map',
            tabPaneContent: <MapWithDrivingAreas points={points} drivingAreas={drivingAreas}/>
        }
    ]

    return <><AppTabs panes={panes} /></>
};
