import React from 'react';

/** modal footer with buttons */

export const ModalFormFooter = ({ children }: { children: React.ReactNode }) => {

    return <div className="footer-modal">
        {children || ''}
    </div>
}
