import { DiscountsApi } from "../../../api/discounts.service";
import { discountCustomersLoaded, discountCustomersLoading, discountCustomersLoadingFailed } from "../reducers/discountCustomers";
import { discountActivatedOrDeactivated, discountDeleted, discountDeleting, discountSaving, discountsLoaded, discountsLoading, discountsLoadingFailed, updateDiscountInList } from "../reducers/discountsReducers";
import { discountStatisticLoaded, discountStatisticLoading, discountStatisticsLoadingFailed } from "../reducers/discountStatisticReducer";
import { AppActivateDeactivateRequest, AppDeleteDiscountRequest, AppDiscountCustomers, AppDiscountEditor, AppDiscountsRequest, AppDiscountsResponse, AppDiscountStatistic } from "../types";

export const getDiscounts = (args: AppDiscountsRequest) => {

    return async (dispatch: any, getState: any) => {

        dispatch(discountsLoading());

        try {

            const result = await DiscountsApi.getDiscounts(args)
            if (!result) {
                dispatch(discountsLoadingFailed());
                return null;
            }

            const data = result.data as AppDiscountsResponse;

            if (!data) {
                dispatch(discountsLoadingFailed());
            }

           dispatch(discountsLoaded({ discounts: data.discounts, isLastPage: data.isLastPage, pageNumber: args.pageNumber}));

            return;
        }
        catch (error:any) {
            dispatch(discountsLoadingFailed());
            return new Error(error);
        }
    }
};

export const getDiscountCustomers = (discountId: number) => {

    return async (dispatch: any) => {

        dispatch(discountCustomersLoading());

        try {

            const result = await DiscountsApi.getDiscountCustomers({discountId});
            const resultData: Array<AppDiscountCustomers> = result.data || {};

            dispatch(discountCustomersLoaded({discountId, items: resultData}));
  
            return resultData;
        }
        catch (error: any) {
            dispatch(discountCustomersLoadingFailed());
            return new Error(error);
        }
    }
};

export const getDiscountCustomersForDuplicate = (discountId: number) => {

    return async (dispatch: any) => {

        dispatch(discountCustomersLoading());

        try {

            const result = await DiscountsApi.getDiscountCustomers({discountId});
            const resultData: Array<AppDiscountCustomers> = result.data || {};

            dispatch(discountCustomersLoadingFailed());
  
            return resultData;
        }
        catch (error: any) {
            dispatch(discountCustomersLoadingFailed());
            return new Error(error);
        }
    }
};

export const addDiscount = (dataToSend: AppDiscountEditor) => {
    return async (dispatch: any) => {

        dispatch(discountsLoading());
        dispatch(discountSaving(true));

        try {

            let result = await DiscountsApi.addDiscount(dataToSend);
            const id = (result.data || null) as number;

            dispatch(discountSaving(false));

            return id;
        }
        catch (error) {
            dispatch(discountsLoadingFailed());
            dispatch(discountSaving(false));
            throw error;
        }
    }
};

export const updateDiscount = (dataToSend: AppDiscountEditor) => {
    return async (dispatch: any) => {

        dispatch(discountsLoading());
        dispatch(discountSaving(true));

        try {

            let result = await DiscountsApi.updateDiscount(dataToSend);
            const dataFromServer = (result.data || null) as boolean;

            dispatch(discountSaving(false));
            return dataFromServer;
        }
        catch (error) {
            dispatch(discountsLoadingFailed());
            dispatch(discountSaving(false));
            throw error;
        }
    }
};

export const activateDeactivateDiscount = (dataToSend: AppActivateDeactivateRequest) => {
    return async (dispatch: any) => {

        dispatch(discountSaving(true));

        try {

            let result = await DiscountsApi.activateOrDeactivateDiscount(dataToSend);
        
            if (result){
                dispatch(discountActivatedOrDeactivated({discountId: dataToSend.discountId as number}));
            }

            return result;
        }
        catch (error) {

            dispatch(discountSaving(false));
            throw error;
        }
    }
};

export const deleteDiscount = (dataToSend: AppDeleteDiscountRequest) => {
    return async (dispatch: any) => {

        dispatch(discountDeleting(true));

        try {

            let result = await DiscountsApi.deleteDiscount(dataToSend);
       
            if (result){
                
                dispatch(discountDeleted(dataToSend.discountId as number));
            }

            return result;
        }
        catch (error) {

            dispatch(discountDeleting(false));
            throw error;
        }
    }
};

export const getDiscountUsage = (discountId: number) => {

    return async (dispatch: any) => {

        dispatch(discountStatisticLoading(discountId));

        try {

            const result = await DiscountsApi.getDiscountUsage({discountId});
            const resultData: Array<AppDiscountStatistic> = result.data || {};

            dispatch(discountStatisticLoaded({discountId, items: resultData}));


            return;
        }
        catch (error: any) {
            dispatch(discountStatisticsLoadingFailed());
            return new Error(error);
        }
    }
};

