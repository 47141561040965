import React from 'react';
import { AppButton, TableHeaderMobileOnly } from '../../../components';
import { EditIcon } from '../../../components/Icon';
import { showIntWith0Decimal } from '../../../utils/commonHelpers';
import { dateTimeHelper } from '../../../utils/dateTimeHelper';
import { HideEditingBtns, PriceConfigDayTimeZone } from '../types';


export const TIMEZONE_TABLE_TEXTS = {
    time: 'Tid',
    faktor: 'Faktor'
}

interface DayTimeZonesProps extends HideEditingBtns {
    dayTimeZoneFactors: Array<PriceConfigDayTimeZone>,
    edit: (index: number) => void
}

export const DayTimeZones = (props: DayTimeZonesProps) => {
    const { dayTimeZoneFactors, edit, hideEditingBtns } = props || {};

    let htmlResult = null;

    if (dayTimeZoneFactors && dayTimeZoneFactors.length > 0) {

        htmlResult = dayTimeZoneFactors.map((item, index) => {
            return <DayTimeZoneRow
                key={index}
                index={index}
                dateTimeZone={item}
                hideEditingBtns={hideEditingBtns}
                editRow={() => edit(index)}
            />
        })
    }


    return <div className="day-time-zones-wrapper mobile-100-table">

        <table className="day-time-zones-table ui collapsing table">
            <thead className="mobile-hidden">
                <tr className="day-time-zones-table-header">
                    <th >{TIMEZONE_TABLE_TEXTS.time}</th>
                    <th>{TIMEZONE_TABLE_TEXTS.faktor}</th>

                    {
                        hideEditingBtns? null:  <th style={{ textAlign: 'right' }} className="three wide">Rediger</th>
                    }

                   
                </tr>

            </thead>

            <TableHeaderMobileOnly text={'Døgnsone-faktor'} />

            {htmlResult && htmlResult.length > 0 ? <tbody>{htmlResult}</tbody> : <tbody><tr><td>Ingen</td></tr></tbody>}

        </table>

    </div>

}

interface DayTimeZoneRowProps extends HideEditingBtns {
    dateTimeZone: PriceConfigDayTimeZone,
    index: number,
    editRow: () => void
}

const DayTimeZoneRow: React.FC<DayTimeZoneRowProps> = (props) => {
    let { dateTimeZone, index, editRow, hideEditingBtns } = props;

    if (!dateTimeZone) {
        return null;
    }

    const fromTime = dateTimeHelper.removeSecondsFromTimeStr(dateTimeZone.fromTime);
    const toTime = dateTimeHelper.removeSecondsFromTimeStr(dateTimeZone.toTime);

    const startend = (fromTime || '') + " - " + (toTime || '');

    return (<tr data-testid={'test-day-time-zone-factor-row-' + index} className="daytimezone-row-data" >
        <td >
            <span className="mobile-only app-text-bold" >{TIMEZONE_TABLE_TEXTS.time}</span> {startend}
        </td>
        <td >
            <span className="mobile-only app-text-bold" >{TIMEZONE_TABLE_TEXTS.faktor}</span> 
            {showIntWith0Decimal(dateTimeZone.priceFactor) || dateTimeZone.priceFactor} 
        </td>

        {
            hideEditingBtns ? null: <td className="price-row-data-edit-btns" style={{ textAlign: 'right' }}>

                <div className="ui icon buttons">
                    <AppButton dataTestId={"test-edit-day-time-zone-" + index} size='mini' onClick={editRow}><><EditIcon /></></AppButton>
                </div>

            </td>
        }


    </tr>)
}


