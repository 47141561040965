import React from 'react';

interface Props { }

export const Logo: React.FC<Props> = () => {
    return (
        <div className="app-logo item">
          <img src="/assets/ridel-logo.png" alt="logo" />
          <span className="app-logo-text"> admin </span>
        </div>
    )
};
