import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppButton, AppNoItems, AppTitleMobileOnly, Spinner, TableHeaderMobileOnly } from '../../../../components';
import { EditIcon } from '../../../../components/Icon';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { DrivingAreaPriceConfig } from '../../types';
import { RegionPriceConfActionsProps } from '../types';

interface Props {
    drivingAreaPriceConfigs: Array<DrivingAreaPriceConfig>,
    drivingAreasPriceConfigsLoading: boolean,
}

//type OwnProps = CountryPriceConfActionsProps;

export const DRIVING_AREA_FACTORS_TABLE_TEXTS = {
    name: 'Navn',
    faktor: 'Prisfaktor',
    minPrice: 'Minstepris'
}

const DrivingAreasWithPriceFactors = (props: Props & RegionPriceConfActionsProps) => {
    const { drivingAreaPriceConfigs, drivingAreasPriceConfigsLoading, actions } = props || {};


    if (drivingAreasPriceConfigsLoading) {
        return <AppNoItems text={<Spinner />} />
    }

    if (!drivingAreaPriceConfigs || drivingAreaPriceConfigs.length <= 0) {
        return <AppNoItems text={'Ingen kjøreområder er lagt til'} />
    }

    const htmlResult = drivingAreaPriceConfigs.map((item, index) => {
        return <DrivingAreaPriceConfigRow
            key={index}
            index={index}
            drivingAreaPriceConfigId={item.drivingAreaPriceConfigId}
            drivingAreaPriceConfig={item}
            navigateToEditor={() => actions.goToDrivingAreaPriceConfigEditor(item.drivingAreaId) }
        />
    })


    return <div className="driving-area-factors-wrapper tab-page mobile-100-table">

        <table className="driving-area-factors-table ui collapsing table " style={{ minWidth: '35vw' }}>
            <thead className="mobile-hidden">

                <tr className="driving-area-factors-table-header">
                    <th style={{ textAlign: 'left' }} >{DRIVING_AREA_FACTORS_TABLE_TEXTS.name}</th>
                    <th style={{ textAlign: 'left' }} >{DRIVING_AREA_FACTORS_TABLE_TEXTS.faktor}</th>
                    <th style={{ textAlign: 'left' }} >{DRIVING_AREA_FACTORS_TABLE_TEXTS.minPrice}</th>
                    <th style={{ textAlign: 'left' }} >{''}</th>
                    <th style={{ textAlign: 'left' }} >{''}</th>
                </tr>

            </thead>

            <TableHeaderMobileOnly text={'Kjøreområder'} />

            {htmlResult && htmlResult.length > 0 ? <tbody>{htmlResult}</tbody> : <tbody>...</tbody>}

        </table>


    </div>

}

interface DrivingAreaPriceConfigRowProps {
    drivingAreaPriceConfig: DrivingAreaPriceConfig
    index: number,
    drivingAreaPriceConfigId: number | null,
    navigateToEditor: any
}

const DrivingAreaPriceConfigRow: React.FC<DrivingAreaPriceConfigRowProps> = (props) => {
    let { drivingAreaPriceConfig, index, drivingAreaPriceConfigId, navigateToEditor } = props;

    if (!drivingAreaPriceConfig) {
        return null;
    }

    return (<tr data-testid={'test-driving-area-price-factor-row-' + index} className="driving-area-price-factor-row-data" >
        <td style={{ textAlign: 'left' }}>
            <AppTitleMobileOnly text={DRIVING_AREA_FACTORS_TABLE_TEXTS.name} />  {drivingAreaPriceConfig.drivingAreaName || ''}
        </td>
        <td style={{ textAlign: 'left' }}>
            <AppTitleMobileOnly text={DRIVING_AREA_FACTORS_TABLE_TEXTS.faktor} /> {drivingAreaPriceConfig.priceFactor || 0.00}
        </td>

        <td style={{ textAlign: 'left' }}>
            <AppTitleMobileOnly text={DRIVING_AREA_FACTORS_TABLE_TEXTS.minPrice} /> {drivingAreaPriceConfig.minPrice || 0.00}

        </td>

        <td style={{ textAlign: 'left' }}>
            {drivingAreaPriceConfig.isDraft ? <span> (Utkast)</span>
                : ''
            }

            {
                !drivingAreaPriceConfigId ? <span> (Mangler info)</span> : ''
            }
        </td>

        <td style={{ textAlign: 'right' }}>
            <div className="ui icon buttons">
                <AppButton dataTestId={"test-region-pf-editor-" + index} size='mini' onClick={navigateToEditor}><>
                    <EditIcon /></>
                </AppButton>
            </div>

        </td>
    </tr>)
}


const mapStateToProps = (state: RootState, ownProps:any) => {

    const drivingAreaPriceConfState = state.drivingAreaPriceConfigs;

    return {
        drivingAreaPriceConfigs: drivingAreaPriceConfState.list,
        drivingAreasPriceConfigsLoading: drivingAreaPriceConfState.loading,
        actions: ownProps.actions
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DrivingAreasWithPriceFactors)
);

