import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FetchingState from "../../components/FetchingState";
import { AdminLayout } from "../../components/Layout/AdminLayout";
import { ActionStatus } from "../../config/constants";
import { RootState } from "../../config/reducerConfig/rootReducer";
import { getCountries } from "../Countries/actions/countryActions";
import { CountriesState } from "../Countries/types";
import { getDrivingAreas } from "../DrivingAreas/actions/drivingAreaActions";
import { getCurrentUser } from "../Login/actions/authActions";
import { AuthUserState } from "../Login/types";
import { getRegions } from "../Regions/actions/regionActions";
import { RegionsState } from "../Regions/types";


interface Props {
    children: JSX.Element,
    isTest?: boolean, // to disable all delays. it is difficult to test with delays
    get_current_user: ()=>void,
    get_regions: ()=>void,
    get_countries: ()=>void,
    get_drivingAreas: ()=>void,
    loading: boolean,
    fetchState: ActionStatus
}

const RootApp: React.FC<Props> =  props => {
    const { children, isTest=false, get_current_user, get_regions, get_countries, get_drivingAreas, fetchState, loading} = props;
    
    useEffect(() => {
        get_current_user();

    }, [get_current_user]);


    useEffect(() => {
        get_countries();
        get_regions();
        get_drivingAreas();

    }, [get_regions, get_countries, get_drivingAreas]);

    const isPending = loading === true? ActionStatus.PROCESS: fetchState;

    return (
        <FetchingState isTest={isTest} fetchState={isPending} height="100vh" >
            <AdminLayout>  
                {children}
            </AdminLayout>
        </FetchingState>
    );
};

const mapStateToProps = (state: RootState, ownProps: any) => {
    const stateAuth = state.auth as AuthUserState;
    const countriesState = state.countries as CountriesState;
    const regionsState = state.regions as RegionsState;

    return {
      loading: countriesState.loading || regionsState.loading,
      fetchState: stateAuth.status,
      children: ownProps.children
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        get_current_user: () => {
            dispatch(getCurrentUser());
        },
        get_regions: ()=> dispatch(getRegions()),
        get_countries: () => dispatch(getCountries()),
        get_drivingAreas: ()=> dispatch(getDrivingAreas())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RootApp)
);
