import { PriceIcon } from "../../../../components/Icon";
import { CURRENCY } from "../../../../config/constants";
import { AppDiscountAmountType } from "../../../Discount/types";
import { BookingDetailsBoxWrapper } from "./BookingDetailsBoxWrapper";
import { BookingInfoElement } from "./BookingInfoElement";
import { BookingInfoHeader } from "./BookingInfoHeader";

interface PriceBoxProps {
    price: number;
    serviceCharge?: number | null;
    originalPrice: number | null;
    discountAmount?: number | undefined;
    discount?:number | null;
    discountAmountTypeId?: number | null;
    cancellationFee?: number | null;
    originalCancellationFee?: number | null;
    discountName: string | null;
    isOrdinaryBooking: boolean;
    contractPrice: number | null
}

export const PriceBox: React.FC<PriceBoxProps> = (props) => {

    const { price, serviceCharge, contractPrice, discountAmount, originalPrice, cancellationFee, originalCancellationFee, discountName, discount, discountAmountTypeId, isOrdinaryBooking} = props;
    const priceResult = price && price >= 1 ? ` ${price} ${CURRENCY}` : '-';
    const originalPriceResult = originalPrice && originalPrice !== price ? ` ${originalPrice} ${CURRENCY}` : null;

    const cancellationFeeResult =  cancellationFee? `${cancellationFee} ${CURRENCY}`: null;
    const originalCancellationFeeResult = originalCancellationFee && (originalCancellationFee !== cancellationFee) ? `${originalCancellationFee} ${CURRENCY}`: null;

    let discountAmountResult = discountAmount && discountAmount >= 1 ? ` ${discountAmount} ${CURRENCY}` : '-';

    const priceText = 'Pris:';
    const originalPriceText = originalPrice? 'Pris (før rabatt):': '';

    let extraInfo = '';

    if (discountAmountTypeId){
        extraInfo = discountAmountTypeId === AppDiscountAmountType.Percent? `%`: `${CURRENCY}`;
    }

    const discountInfo = discountName && discount && discountAmount ? ` ${discountName} (${discount} ${extraInfo}) ` : null;
    const cancellationFeeText =  'Gebyr:';

    const priceTextNotOrd = "Ref_pris (ink.MVA):";
    const contractPriceResult =  contractPrice? ` ${contractPrice} ${CURRENCY}` : '-';
    const contractPriceText = "Avtalepris:";

    const serviceChargeResult = serviceCharge? ` ${serviceCharge} ${CURRENCY}` : '-';
    
    return <BookingDetailsBoxWrapper elementClassName='booking-details-price-box'>
        <> <BookingInfoHeader text={'Pris'} icon={<PriceIcon />} />

            <div className="ui divider"></div>

            {
                isOrdinaryBooking?
                 <div data-testid='test-price-box' style={{ textAlign: 'left' }}>
                    <BookingInfoElement title={priceText} text={priceResult} />
                </div>: null
            }

            {
                isOrdinaryBooking && serviceCharge?
                 <div data-testid='test-price-box-service-charge' style={{ textAlign: 'left' }}>
                    <BookingInfoElement title={'Servicetillegg:'} text={serviceChargeResult} />
                </div>: null
            }
           
            {
                originalPriceResult &&  isOrdinaryBooking? <div data-testid='test-original-price-box' 
                style={{ textAlign: 'left' }}>
                <BookingInfoElement title={originalPriceText} text={originalPriceResult} />
                </div>: null
            }

            {
                cancellationFeeResult && isOrdinaryBooking ? <div data-testid='test-fee-box' 
                style={{ textAlign: 'left' }}>
                <BookingInfoElement title={cancellationFeeText} text={cancellationFeeResult} />
                </div>: null
            }

            {
                originalCancellationFeeResult && isOrdinaryBooking ? <div data-testid='test-original-fee-box' 
                style={{ textAlign: 'left' }}>
                <BookingInfoElement title={'Gebyr (før rabatt):'} text={originalCancellationFeeResult} />
                </div>: null
            }

            {
                discountInfo && isOrdinaryBooking?  <div data-testid='test-price-box-discount-info' style={{ textAlign: 'left' }}>
                <BookingInfoElement title={'Rabatt info: '} text={discountInfo} />
                </div>: null
            }

            {
                isOrdinaryBooking? <div data-testid='test-price-box-discount-amount' style={{ textAlign: 'left' }}>
                    <BookingInfoElement title={'Rabatt : '} text={discountAmountResult} />
                </div>:null
            }

            {
                !isOrdinaryBooking? <div data-testid='test-not-ord-book-price-box' 
                style={{ textAlign: 'left' }}>
                <BookingInfoElement title={priceTextNotOrd} text={priceResult} />
                </div>: null
            }

            {
                !isOrdinaryBooking? <div data-testid='test-contract-price-box' 
                style={{ textAlign: 'left' }}>
                <BookingInfoElement title={contractPriceText} text={contractPriceResult} />
                </div>: null
            }

            
        </>
    </BookingDetailsBoxWrapper>
}
