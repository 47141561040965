import React from 'react';
import { CountUp } from 'use-count-up'


interface Props {
    totalCustomers: number,
    newCustomersPerDay: number,
    newCustomersPerMonth: number
}

export const CustomersWidget: React.FC<Props> = (props) => {
    let { totalCustomers, newCustomersPerDay, newCustomersPerMonth } = props;

    newCustomersPerMonth = newCustomersPerMonth || 0;
    newCustomersPerDay = newCustomersPerDay || 0;


    return (<div className="customers-info">
        <div className="ui padded grid  customer-info-list">
            <div className="customers-info-item customers-info-total sixteen wide mobile column ">
                <div data-testid="total-customers-test" className="customers-info-item-value ">
                    {totalCustomers || 0}
                </div>
                <div className="customers-info-item-label">
                    Kunder totalt</div>
            </div>

            <div className="customers-info-item customers-info-month sixteen wide mobile column ">
                <div className="customers-info-item-value ">
                    +<CountUp isCounting end={newCustomersPerMonth} duration={1.6} />
                </div>
                <div className="customers-info-item-label">
                    Nye kunder (denne måneden)</div>
            </div>

            <div className="customers-info-item customers-info-today sixteen wide mobile column">
                <div className="customers-info-item-value ">
                    +<CountUp isCounting end={newCustomersPerDay} duration={2} />
                </div>
                <div className="customers-info-item-label">
                    Nye kunder (i dag)</div>
            </div>
        </div>
    </div>
    )
};

