import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppNoItems, Spinner } from '../../../../components';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { PublishBtns } from '../../CommonComponents/PublishBtns';
import { VersionInfo } from '../../CommonComponents/VersionInfo';
import { HideEditingBtns, RegionPriceConfig } from '../../types';
import { RegionPriceConfActionsProps, RegionPriceConfigModalType } from '../types';
import { MainRegionPriceConfigInfo } from './MainRegionPriceConfigInfo';


interface PropsFromReducer {
    regionPriceConfig: RegionPriceConfig | null,
    regionPriceConfigLoading: boolean,
    regionPriceConfigIsEdited: boolean,
    publishing: boolean,
    saving: boolean,
    comment: string | null,
    resetting: boolean
}

type OwnProps = RegionPriceConfActionsProps & HideEditingBtns;

const MainRegionPriceConfig: React.FC<PropsFromReducer & OwnProps> = (props) => {
    const { regionPriceConfig,
        regionPriceConfigLoading,
        regionPriceConfigIsEdited,
        publishing,
        saving,
        resetting } = props;

    const { actions, hideEditingBtns } = props;

    const wrapperStyle = { minHeight: '40vh' };

    if (regionPriceConfigLoading) {
        return <div className="display-flex-center-both" style={wrapperStyle}><Spinner /></div>
    }

    if (!regionPriceConfig) {
        return <AppNoItems text={'Velg en region'} />
    }

    return <div style={wrapperStyle} className="region-price-config-main">

        {
            hideEditingBtns ? '' : <div className="price-config-publish-reset-btns" style={{ width: '100%', display: 'flex', padding: '0px 0px', justifyContent: 'flex-end', alignItems: 'center' }}>

                <PublishBtns
                    isPublished={!regionPriceConfig.isDraft}
                    publishing={publishing}
                    resetting={resetting}
                    saving={saving}
                    isEdited={regionPriceConfigIsEdited}
                    hasPublishedRecords={regionPriceConfig.publishedDate !== null}
                    publish={actions.publishOrSaveConfig}
                    resetChanges={() => {
                        actions.resetDraft()
                    }} />

            </div>

        }

        <div className="tab-page region-price-config-main-cols">

            <div className="region-price-config-main-col1 price-conf-col" >
                <div className="region-price-config-el">
                    <VersionInfo
                        hideEditingBtns={hideEditingBtns}
                        publishedComment={regionPriceConfig.publishedComment || ''}
                        publishDate={regionPriceConfig.publishedDate}
                        publishedBy={regionPriceConfig.publishedByName}
                        draftComment={regionPriceConfig.draftComment}
                        isDraft={regionPriceConfig.isDraft}
                        draftLastModified={regionPriceConfig.draftLastModifiedDate}
                        draftBy={regionPriceConfig.draftModifiedByName}
                        goToPublishedVersion={actions.goToPublishedVersion}
                        onEdit={() => actions.edit(null, RegionPriceConfigModalType.versionInfo)}
                    />

                </div>

            </div>

            <div className="region-price-config-main-col2 price-conf-col" >
                <div className="region-price-config-el">
                    <MainRegionPriceConfigInfo hideEditingBtns={hideEditingBtns} actions={actions} regionPriceConfig={regionPriceConfig} />
                </div>
            </div>

        </div>

    </div>
}


const mapStateToProps = (state: RootState, ownProps: any) => {

    const regionPriceConfState = state.regionPriceConfig;
    const props = ownProps as OwnProps;

    return {
        actions: props.actions,
        regionPriceConfig: regionPriceConfState.priceConfig,
        regionPriceConfigLoading: regionPriceConfState.loading,
        regionPriceConfigIsEdited: regionPriceConfState.isEdited,
        publishing: regionPriceConfState.publishing || false,
        saving: regionPriceConfState.saving,
        comment: regionPriceConfState.comment || null,
        resetting: regionPriceConfState.resettingDraft || false,

    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainRegionPriceConfig)
);



