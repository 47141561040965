import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppPayment, AppPaymentState } from '../types';

const initialState: AppPaymentState = {
    payments: [],
    loading: true,
    refundPaymentPending: null
}

const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        paymentsLoading(state) {
            state.loading = true;
        },
        paymentsLoaded(state, action: PayloadAction<Array<AppPayment>>) {

            const items = action.payload? action.payload: null;
            state.payments = items? items: [];
            state.loading = false;
        },
        paymentsLoadingFailed(state){
            state.loading = false;
        },
        setRefundPending(state, action:PayloadAction<{refundePaymentPending: number | null}>){
            const {refundePaymentPending } = action.payload;
            state.refundPaymentPending = refundePaymentPending || null; 
        },
        resetPaymentsState(state) {
            return initialState;
        }
    },
})

export const {
    paymentsLoading,
    paymentsLoaded,
    paymentsLoadingFailed,
    setRefundPending,
    resetPaymentsState,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;

export const selectPayments = (state: AppPaymentState) : Array<AppPayment> | null => {
    const result = (state || {}).payments || [];
    return result;
}

export const selectPaymentsById = (state: AppPaymentState, paymentId:number): AppPayment | null => {
    let result = ((state || {}).payments || []).find(x=>x.paymentId === paymentId);
    return result || null;
}

export const selectPaymentsByBookingId = (state: AppPaymentState, bookingId:number): Array<AppPayment> | null => {
   
    let result = ((state || {}).payments || []).filter(x=>x.bookingId === bookingId);
    return result;
}