import React from 'react';
import AppMenu from './AppMenu';
import UserMenu from './UserMenu';
import '../../styles/adminLayout.css'

interface Props {
    children: JSX.Element
}

export const AdminLayout: React.FC<Props> = ({ children }) => {
    return (<>
        <nav className="ui top fixed inverted menu mobile-hidden user-menu-wrapper" style={{ backgroundColor: 'transparent' }}>
            <UserMenu />
        </nav>
        <AppMenu />
        <div className="ui bottom attached segment pushable" >

            <div className="main-content">
                {children}
            </div>
        </div></>
    )
};

