import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RootState } from '../../config/reducerConfig/rootReducer';
import { getDrivingAreaGeoPointsByRegionId } from './actions/drivingAreaActions';
import { DrivingAreasOnMapViewPropsPropsFromReducer, DrivingAreasView } from './child-components/DrivingAreasView';
import 'leaflet/dist/leaflet.css';
import { resetDrivingAreaGeosState } from './reducers/drivingAreaGeoPointsReducer';


interface ActionProps {
    get_driving_area_geo_points: (regionId: number) => void,
    reset_driving_area_geos_state: () => void
}

type DrivingAreasOnMapProps = ActionProps & DrivingAreasOnMapViewPropsPropsFromReducer;

const DrivingAreasOnMapContainer: React.FC<DrivingAreasOnMapProps> = (props) => {

    const { get_driving_area_geo_points, reset_driving_area_geos_state
    } = props;

    const { currentRegionId, points, drivingAreas, drivingAreaGeosLoading, countryRegDrLoading,
    } = props;


    useEffect(() => {

        if (currentRegionId) {
             get_driving_area_geo_points(currentRegionId);
        }
 
        return () => {
            reset_driving_area_geos_state();

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRegionId])


    const allProps: DrivingAreasOnMapViewPropsPropsFromReducer = {
        currentRegionId: currentRegionId,
        drivingAreaGeosLoading: drivingAreaGeosLoading,
        countryRegDrLoading: countryRegDrLoading,
        points,
        drivingAreas
    };


    return <DrivingAreasView allProps={allProps} hideEditingBtns={false} />
};


const mapStateToProps = (state: RootState, ownProps: any) => {
    const countries = state.countries;
    const regionsState = state.regions;
    const drivingAreasState = state.drivingAreas;
    const drGeosState = state.drivingAreaGeos;

    const props: DrivingAreasOnMapViewPropsPropsFromReducer = {
        currentRegionId: ownProps.currentRegionId,
        drivingAreaGeosLoading: drGeosState.loading,
        countryRegDrLoading: regionsState.loading || drivingAreasState.loading || countries.loading,
        points: drGeosState.list,
        drivingAreas: drivingAreasState.list
    };

    return props;
};


const mapDispatchToProps = (dispatch: any) => {

    const actions: ActionProps = {
        get_driving_area_geo_points: (regionId: number) => dispatch(getDrivingAreaGeoPointsByRegionId(regionId)),
        reset_driving_area_geos_state: () => resetDrivingAreaGeosState()
    }

    return actions;
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DrivingAreasOnMapContainer)
);


