
interface JwtHelperInterface {
    getJwt: () => string | null,
    setJwt: (jwt: string) => void,
    removeJwt: () => void
}

export const jwtHelper = (():JwtHelperInterface => {
    return {
        getJwt: () => localStorage.getItem('managability-jwt'),
        setJwt: (jwt: string) => {
            localStorage.setItem('managability-jwt', jwt);
        },
        removeJwt: () => {
            localStorage.removeItem('managability-jwt')
        },
    }
})();

