import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import { useCountryIdAssigner } from '../../../hooks/useCountryIdAssigner';
import { useRegionIdAssigner } from '../../../hooks/useRegionIdAssigner';
import '../../../styles/priceConfig.css';
import { setCurrentItem } from '../../Countries/reducers/countriesReducer';
import { setCurrentRegion, selectRegionById} from '../../Regions/reducers/regionsReducers';
import { getDrivingAreaPriceConfigs } from '../DrvingAreaPriceConfig/actions/drivingAreaPriceConfigActions';
import { getLastRegionPriceConfig, publishOrSaveRegionPriceConfig, resetRegionPriceConfigDraft } from './actions/regionPriceConfigActions';
import { RegionPriceConfigView, RegionPriceConfigViewPropsFromReducer } from './child-components/RegionPriceConfigView';
import { resetRegionPriceConfigState, updateComment } from './reducers/regionPriceConfigReducer';
import { RegionPriceConfActions, RegionPriceConfigModalType } from './types';
import { history } from '../../../config/historyConfig';
import { RoutesConfig } from '../../../config/routes';
import { resetDrivingAreaPriceConfigsState } from '../DrvingAreaPriceConfig/reducers/drivingAreaPriceConfigsReducers';
import { setCurrentDrivingArea } from '../../DrivingAreas/reducers/drivingAreasReducers';
import { useIdPublishedVersionAssignerModule } from '../../../hooks/useIsPublishedVersionAssigner';


interface Props {
    get_region_price_config: (countryId: number, regionId: number, isPublished: boolean) => void,
    set_current_country: (countryId: number) => void,
    set_current_region: (regionId: number | null) => void,
    publish_or_save_price_config: (isSave: boolean) => Promise<any>,
    reset_region_price_config_draft: () => Promise<any>,
    update_comment:(comment:string)=> void,
    reset_region_price_config: ()=>void,
    get_driving_area_price_configs: (regionId:number) => void,
    reset_driving_area_price_configs: () => void,
    reset_current_driving_area: () =>void
}

type RegionPriceConfigContainerProps = Props & RegionPriceConfigViewPropsFromReducer;

const RegionPriceConfigContainer: React.FC<RegionPriceConfigContainerProps> = (props) => {

    const {
        get_region_price_config,
        set_current_country,
        set_current_region,
        publish_or_save_price_config,
        reset_region_price_config_draft,
        get_driving_area_price_configs,
        update_comment,
        reset_region_price_config,
        reset_driving_area_price_configs,
        reset_current_driving_area
    } = props;

    const { currentCountryId, currentRegionId, countriesLoading, regionsLoading, comment, currentRegion} = props;

    const [modalState, setModalState] = useState<{ modalType: RegionPriceConfigModalType, index: number | null } | null>(null);

    const fromUrlCountryId = useCountryIdAssigner(set_current_country);
    const fromUrlRegionId = useRegionIdAssigner(set_current_region);

    const isPublished = useIdPublishedVersionAssignerModule.useIdPublishedVersionAssigner();
    
    const hideEditingBtns = isPublished;
   
    useEffect(() => {
        
        const currentCountryIdResult = fromUrlCountryId || currentCountryId;
        const currentRegionIdResult = fromUrlRegionId || currentRegionId;

        if (currentCountryIdResult && currentRegionIdResult) {
            get_region_price_config(currentCountryIdResult, currentRegionIdResult as number, isPublished);
            get_driving_area_price_configs(currentRegionIdResult); 
        }

        return () => {
            reset_region_price_config();
            reset_driving_area_price_configs();
           
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeCountry = (countryId: number): void => {

        if (!countryId) {
            return;
        }

        set_current_country(countryId);
        set_current_region(null);
        reset_region_price_config();
        reset_driving_area_price_configs();

        const url = RoutesConfig.RegionPriceConfig.navigatePath(countryId);
        history.push({pathname: url});
        
    }

    const changeRegion = (regionId: number): void => {
        if (!regionId || !currentCountryId) {
            return;
        }

        set_current_region(regionId);
        get_region_price_config(currentCountryId, regionId as number, false);
        get_driving_area_price_configs(regionId);
        reset_current_driving_area();

        const url = RoutesConfig.RegionPriceConfig.navigatePath(currentCountryId, regionId);
        history.push({pathname: url});

    }

    const edit = (index: number | null, modalType: RegionPriceConfigModalType): void => {

        if (!modalType) {
            return;
        }

        index = index || index === 0 ? index : null;
        setModalState({ modalType, index });

    }

    const closeEditor = (): void => {
        setModalState(null);
    }

    const publishOrSaveConfig = (isSaving: boolean): void => {

        publish_or_save_price_config(isSaving).then(() => {

            if (currentCountryId && currentRegionId) {
                setTimeout(() => {
                    get_region_price_config(currentCountryId, currentRegionId as number, false);

                }, 700)
            }
        }).catch((err) => {
            console.log(err);
        })


    }

    const resetDraft = (): void => {

        reset_region_price_config_draft().then(() => {

            if (currentCountryId && currentRegionId) {
                setTimeout(() => {
                    get_region_price_config(currentCountryId, currentRegionId as number, false);

                }, 700)
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const updateComment = (comment:string): void => {
        update_comment(comment);
        closeEditor();
    }

    const goToDrivingAreaPriceConfigEditor = (drivingAreaId: number) => {
              
        if (!drivingAreaId || !currentCountryId || !currentRegionId) {
            return;
        }

       const url = RoutesConfig.DrivingAreaPriceConfig.navigatePath(currentCountryId, currentRegionId, drivingAreaId);
       history.push({pathname: url});

    }

    const goToPublishedVersion = (): string => {

        if (currentCountryId && currentRegionId){
            const url = RoutesConfig.RegionPriceConfig.navigatePath(currentCountryId, currentRegionId, true);
            return url;
        }

        return '';
    }


    const actions: RegionPriceConfActions = {
        changeCountry,
        changeRegion,
        publishOrSaveConfig,
        edit,
        closeEditor,
        resetDraft,
        updateComment,
        goToDrivingAreaPriceConfigEditor,
        goToPublishedVersion
    };

    const allProps: RegionPriceConfigViewPropsFromReducer = {
        currentCountryId: currentCountryId,
        currentRegionId: currentRegionId,
        regionsLoading: regionsLoading,
        countriesLoading: countriesLoading,
        currentRegion,
        comment
    };

    return (<RegionPriceConfigView
        hideEditingBtns={hideEditingBtns}
        allProps={allProps}
        modalState={modalState}
        actions={actions} />)
};


const mapStateToProps = (state: RootState) => {

    const countryState = state.countries;
    const regionState = state.regions;
    const regionPriceConfigState = state.regionPriceConfig;
    const currentRegion = regionState && regionState.currentItemId? selectRegionById(state, regionState.currentItemId): null;

    return {
        currentCountryId: countryState.currentItemId,
        currentRegionId: regionState.currentItemId, 
        countriesLoading: countryState.loading,
        regionsLoading: regionState.loading,
        comment: regionPriceConfigState.comment,
        currentRegion
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        get_region_price_config: (countryId: number, regionId: number, isPublished: boolean) => dispatch(getLastRegionPriceConfig(countryId, regionId, isPublished)),
        set_current_country: (countryId: number) => dispatch(setCurrentItem(countryId)),
        set_current_region: (regionId: number | null) => dispatch(setCurrentRegion(regionId)),
        publish_or_save_price_config: (isSave: boolean) => dispatch(publishOrSaveRegionPriceConfig({ isSave })),
        reset_region_price_config_draft: () => dispatch(resetRegionPriceConfigDraft()),
        update_comment: (comment: string) => dispatch(updateComment(comment)),
        reset_region_price_config: () => dispatch(resetRegionPriceConfigState()),
        get_driving_area_price_configs: (regionId:number) => dispatch(getDrivingAreaPriceConfigs(regionId)),
        reset_driving_area_price_configs: () => dispatch(resetDrivingAreaPriceConfigsState()),
        reset_current_driving_area: () => dispatch(setCurrentDrivingArea(null))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RegionPriceConfigContainer)
);

