import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { CountryPriceConfigView, CountryPriceConfigViewPropsFromReducer } from './child-components/CountryPriceConfigView';
import { CountryPriceConfActions, ModalType } from './types';
import { getCountryPriceConfig, publishOrSaveCountryPriceConfig, resetCountryPriceConfigDraft } from './actions/countryPriceConfigActions';
import { getRegionsPriceConfigs } from '../RegionPriceConfigs/actions/regionPriceConfigActions';
import { setCurrentItem, selectCountryById } from '../../Countries/reducers/countriesReducer';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import '../../../styles/priceConfig.css';
import { updateComment } from './reducers/countryPriceConfigReducer';
import { history } from '../../../config/historyConfig';
import { RoutesConfig } from '../../../config/routes';
import { setCurrentRegion } from '../../Regions/reducers/regionsReducers';
import { useIdPublishedVersionAssignerModule } from '../../../hooks/useIsPublishedVersionAssigner';
import { useCountryIdAssigner } from '../../../hooks/useCountryIdAssigner';


interface Props {   
    get_regions_price_configs: (countryId: number) =>void,
    get_country_price_config: (countryId:number, isPublished: boolean) => void,
    set_current_item:(countryId: number)=>void,
    publish_or_save_price_config: (isSaving:boolean) => Promise<any>,
    reset_draft: () => Promise<any>
    update_comment: (comment:string) => any,
    reset_current_region: () => void
}

type CountryPriceConfigContainerProps = Props & CountryPriceConfigViewPropsFromReducer;

const CountryPriceConfigContainer: React.FC<CountryPriceConfigContainerProps> = (props) => {

    const { get_regions_price_configs,
         get_country_price_config, set_current_item,  
         publish_or_save_price_config,
         reset_draft,
         update_comment,
         reset_current_region,
        } = props;
    const {currentCountryId, countriesLoading, countries, comment, currentCountry} = props;

    const [modalState, setModalState] = useState<{modalType: ModalType, index: number | null} | null>(null);

    const fromUrlCountryId = useCountryIdAssigner(set_current_item);

    const isPublished = useIdPublishedVersionAssignerModule.useIdPublishedVersionAssigner();
    const hideEditingBtns = isPublished;

    useEffect(() => {

        const currentCountryIdResult = fromUrlCountryId || currentCountryId;

        if (currentCountryIdResult){
            get_country_price_config(currentCountryIdResult, isPublished);
            get_regions_price_configs(currentCountryIdResult);
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeCountry = (countryId: number):void => {

        if (!countryId){
            return;
        }

        get_country_price_config(countryId, false);
        get_regions_price_configs(countryId);
        set_current_item(countryId);
        reset_current_region();
        
    }


    const edit = (index: number | null, modalType: ModalType): void => {

        if (!modalType){
            return;
        }

        index = index || index === 0? index: null;
        setModalState({modalType, index});
        
    }

    const closeEditor = ():void => {
        setModalState(null);
    }


    const publishConfig = (isSaving: boolean):void => {

        publish_or_save_price_config(isSaving).then(()=>{
            
            if (currentCountryId){
                setTimeout(()=> {
                    get_country_price_config(currentCountryId, false);
                }, 700)
            }
        })
        .catch((err)=>{
            console.log(err);
        })

       
    }

    const resetDraft = (): void => {

        reset_draft().then(()=>{

            if (currentCountryId){
                setTimeout(()=> {
                    get_country_price_config(currentCountryId, false);
                }, 700)
            }
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    const updateComment = (comment:string): void => {

        update_comment(comment);
        closeEditor();
    }

    const goToRegionPriceConfigEditor = (regionId: number) => {
      
        if (!regionId || !currentCountryId) {
            return;
        }
        

       const url = RoutesConfig.RegionPriceConfig.navigatePath(currentCountryId, regionId);
       history.push({pathname: url});

    }

    const goToPublishedVersion = (): string => {

        if (currentCountryId){
            const url = RoutesConfig.CountryPriceConfig.navigatePath(currentCountryId, true);
            return url;
        }

        return '';
    }

    const actions: CountryPriceConfActions = {
        changeCountry,
        publishConfig,
        edit,
        closeEditor,
        resetDraft,
        updateComment,
        goToRegionPriceConfigEditor,
        goToPublishedVersion
    };

    return (<CountryPriceConfigView 
            hideEditingBtns={hideEditingBtns}
            countries={countries}
            comment={comment}
            currentCountry={currentCountry}
            currentCountryId={currentCountryId}
            countriesLoading={countriesLoading} 
            modalState={modalState} 
            actions={actions} />)
};


const mapStateToProps = (state: RootState) => {

    const countryState = state.countries;
    const countryPriceConfigState = state.countryPriceConfig;

    const currentCountry = countryState && countryState.currentItemId? selectCountryById(state, countryState.currentItemId): null;


    return {
        currentCountryId: countryState.currentItemId,
        currentCountry,
        countriesLoading: countryState.loading,
        countries: countryState.list,
        comment: countryPriceConfigState.comment || '',
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        get_regions_price_configs: (countryId: number) => dispatch(getRegionsPriceConfigs(countryId)),
        get_country_price_config: (countryId:number, isPublished: boolean) => { return dispatch(getCountryPriceConfig(countryId, isPublished)) },
        set_current_item: (countryId:number)=> dispatch(setCurrentItem(countryId)),
        publish_or_save_price_config: (isSave:any) => dispatch(publishOrSaveCountryPriceConfig({isSave})),
        reset_draft: () => dispatch(resetCountryPriceConfigDraft()),
        update_comment: (comment: string | null) => dispatch(updateComment(comment)),
        reset_current_region: () => dispatch(setCurrentRegion(null))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CountryPriceConfigContainer)
);

