import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import '../../../styles/priceConfigTest.css';
import { setCurrentItem } from '../../Countries/reducers/countriesReducer';
import { setCurrentRegion } from '../../Regions/reducers/regionsReducers';
import { addPriceTestCase, deletePriceTestCaseById, getPriceTestCases, updatePriceTestCase } from './actions/priceTestCasesActions';
import { PriceConfigTestView, PriceConfigTestViewPropsFromReducer } from './child-components/PriceConfigTestView';
import { resetPriceTestCasesState } from './reducers/priceTestCasesReducer';
import { PriceTestCaseBase, PriceTestCaseContainerActions, PriceTestCaseModalType } from './types';


interface Props {
    set_current_country:(countryId: number)=>void,
    get_price_test_cases : (countryId: number, withSpecialLocations:boolean, withCarTypes:boolean, isTest:boolean) => void,
    add_price_test_case: (priceTestCase: PriceTestCaseBase) => Promise<any>,
    update_price_test_case:(priceTestCase: PriceTestCaseBase) => Promise<any>,
    delete_price_test_case: (priceTestCaseId:number) => Promise<any>,
    reset_price_test_cases_state: () =>void,
    reset_current_region: ()=>void
 }


type PriceConfigTestContainerProps = Props & PriceConfigTestViewPropsFromReducer;

const PriceConfigTestContainer: React.FC<PriceConfigTestContainerProps> = (props) => {

    const {currentCountryId, countriesLoading, isTest} = props;
    const {set_current_country, 
        get_price_test_cases,
        add_price_test_case,
        update_price_test_case,
        reset_price_test_cases_state, 
        reset_current_region,
        delete_price_test_case} = props;

    const [modalState, setModalState] = useState<{ modalType: any, index: number | null } | null>(null);

    /** filters */
    const [withSpecialLocations, setWithSpecialLocations] = useState<boolean>(false);
    const [withCarTypes, setWithCarTypes] = useState<boolean>(false);

   
    useEffect(() => {
        if (currentCountryId){
            get_price_test_cases(currentCountryId, withSpecialLocations, withCarTypes, isTest);
        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeCountry = (countryId: number): void => {

        if (!countryId) {
            return;
        }

        reset_price_test_cases_state();
        set_current_country(countryId);
        get_price_test_cases(countryId, withSpecialLocations, withCarTypes, isTest);
        reset_current_region(); 

    }

    const openEditor = (id: number | null, modalType: PriceTestCaseModalType): void => {

        if (!modalType) {
            return;
        }

        setModalState({ modalType, index: id });

    }

    const addOrUpdatePriceTestCase = (priceTestCase: PriceTestCaseBase, id:number | null):void => {

        const getPriceCases = () => {
            if (currentCountryId){
                get_price_test_cases(currentCountryId, withSpecialLocations, withCarTypes, isTest);
            }
        }

        // add request
        if (id){
            update_price_test_case(priceTestCase).then(()=>{
                getPriceCases();
            })
        }
        // update request
        else {
            add_price_test_case(priceTestCase).then(()=>{
                getPriceCases();
            })
        }

    }

    const deletePriceTestCase = (id:number):void => {        
        delete_price_test_case(id);
    }

    const closeEditor = (): void => {
        setModalState(null);
    }

    const changeFilterWithSpecialLocations = () => {
        setWithSpecialLocations(!withSpecialLocations);
    }

    const changeFilterWithCarTypes = () => {
        setWithCarTypes(!withCarTypes);
    }

    const resetFilters = () => {
        setWithSpecialLocations(false);
        setWithCarTypes(false);

        if (currentCountryId){
            get_price_test_cases(currentCountryId, false, false, isTest);
        }

    }

    const getPriceTestCase = () => {

        if (currentCountryId){
            get_price_test_cases(currentCountryId, withSpecialLocations, withCarTypes, isTest);
        }
    }

    const allProps: PriceConfigTestViewPropsFromReducer = {
        currentCountryId: currentCountryId,
        countriesLoading: countriesLoading,
        isTest
    };

    const actions: PriceTestCaseContainerActions = {
        changeCountry,
        openEditor,
        closeEditor,
        addOrUpdatePriceTestCase,
        deletePriceTestCase,
        changeFilterWithSpecialLocations,
        changeFilterWithCarTypes,
        resetFilters,
        getPriceTestCase
    };

    return (< PriceConfigTestView 
                actions={actions} 
                allProps={allProps}  
                withSpecialLocations={withSpecialLocations}
                withCarTypes={withCarTypes}
                modalState={modalState} 
                />)
};


const mapStateToProps = (state: RootState, ownProps: any) => {

    const countriesState = state.countries;
    const isTest = ownProps.isTest;

    return {
        currentCountryId: countriesState.currentItemId,
        countriesLoading: countriesState.loading,
        isTest
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        set_current_country: (countryId:number)=> dispatch(setCurrentItem(countryId)),
        reset_current_region: () => dispatch(setCurrentRegion(null)),
        get_price_test_cases : (countryId: number, withSpecialLocations:boolean, withCarTypes:boolean, isTest:boolean) => dispatch(getPriceTestCases(countryId, withSpecialLocations, withCarTypes, isTest)),
        add_price_test_case: (priceTestCase: PriceTestCaseBase) => dispatch(addPriceTestCase(priceTestCase)),
        update_price_test_case:(priceTestCase: PriceTestCaseBase) => dispatch(updatePriceTestCase(priceTestCase)),
        delete_price_test_case: (priceTestCaseId:number) => dispatch(deletePriceTestCaseById(priceTestCaseId)),
        reset_price_test_cases_state: ()=> dispatch(resetPriceTestCasesState())
    
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PriceConfigTestContainer)
);

