export const getAllServerValidationErrors = (fieldErrors: ValidationErrors): string | null => {

    if (!fieldErrors)
        return null;

    let result = '';

    let tempArray: Array<string> = [];

    for (var key in fieldErrors) {

        let str = fieldErrors[key];

        str.forEach(x=>{
            if (!tempArray.find(y=>y === x)){
                tempArray.push(x);
            }
            
        })

    }

    result = tempArray.join(' ');
    return result;
};

export interface ValidationErrors {
    [key: string]: Array<string>
}