import axios, { AxiosInstance } from 'axios';
import { appAlert } from '../utils/alert';
import { jwtHelper } from '../utils/jwtHelper';
import { getAllServerValidationErrors } from '../utils/serverValidationErrors';

// CommonJS
//const Swal = require('sweetalert2')

export abstract class BaseService {
  protected readonly $http: AxiosInstance;

  //10 min.
  protected constructor(controllerName: string, requestTimeout: number = 600000) {

  
    this.$http = axios.create({
      timeout: requestTimeout,    
      baseURL: process.env.REACT_APP_API_URL + '/api/' + controllerName + '/'
    });

    this.$http.interceptors.request.use(config => {

      const token = jwtHelper.getJwt();
      if (token) config.headers.Authorization = `Bearer ${token}`
      return config;
    })

  }



  public handleAllErrors =(error: {response: any}) => {

    const errorResponse = error.response;
    let errorStatus = errorResponse? errorResponse.status: null;

    if (errorStatus === 401) {    // Unauthorized
        var url = `/Login`;
        window.location.href = url;
        return;
    }

    else if (errorStatus === 400){
     
      const serverValidationErrorData =  (errorResponse.data && errorResponse.data.errors) || "";
      const serverValidationErrors = serverValidationErrorData? getAllServerValidationErrors(serverValidationErrorData): null;
      const errMessage = serverValidationErrors != null && serverValidationErrors !== `` ? serverValidationErrors: `Valideringsfeil! `;
      
      appAlert('Valideringsfeil',  errMessage, 'warning');
      
    }
    else if (errorStatus === 500){

      const errorData =  errorResponse && errorResponse.data? errorResponse.data: {};
      const errMessage = errorData ? errorData.errorMessage: "Beklager, det har skjedd en server feil";
      const technicalInfo = errorData && errorData.technicalInfo? " Teknisk info: " + errorData.technicalInfo: "";

      appAlert('Feil', errMessage + "<br/>"  + technicalInfo, 'error');
      
    }
    else {
      appAlert('Error', 'Beklager, det har skjedd en server feil. </br> Teknisk info: ' + error);
    }

    throw(error);
  }

}

