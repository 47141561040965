import { AxiosResponse } from "axios";
import { AppLabelColor } from "./components/AppLabel";


export interface ApiResponse {
    data: any,
    response: AxiosResponse
}

export enum DAY_TYPES {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
    Holiday = 8
}


export const getDayOfWeekConfig = (dayOfWeek: any): {color: AppLabelColor, text: string | '', shortText: string} => {

    switch (dayOfWeek) {
        case DAY_TYPES.Monday:
          return {text: "Mandag",color: 'blue', shortText: 'Mon.'}
        case DAY_TYPES.Tuesday:
            return {text: "Tirsdag",color: 'teal', shortText: 'Tue.'}
        case DAY_TYPES.Wednesday:
            return {text: "Onsdag",color: 'grey', shortText: 'Wed.'}
        case DAY_TYPES.Thursday:
            return {text: "Torsdag", color: 'green', shortText: 'Thu.'}
        case DAY_TYPES.Friday:
            return {text: "Fredag", color: 'orange', shortText: 'Fri.'}
        case DAY_TYPES.Saturday:
            return {text: "Lørdag",color: 'olive', shortText: 'Sat.'}
        case DAY_TYPES.Sunday:
                return {text: "Søndag", color: 'brown', shortText: 'Sun.'}
        case DAY_TYPES.Holiday:
            return {text: "Helligdag", color: 'red', shortText: 'Sun.'}
        default:
          return {text: '', color: 'black', shortText: ''}
      }
  
}