import { RegionApi } from "../../../api/region.service";
import { regionsLoaded, regionsLoading } from "../reducers/regionsReducers";
import { Region } from "../types";


export const getRegions = () => {

    return async (dispatch: any) => {

        dispatch(regionsLoading(true));

        try {
            const result = await RegionApi.getRegions();
            const data: Array<Region> = result ? result.data : [];

            dispatch(regionsLoaded(data));

            return data;
        }
        catch (error) {
            dispatch(regionsLoading(true));
            return new Error(error);
        }
    }
};