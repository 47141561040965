import { ApiResponse } from '../commonTypes';
import { CountryPriceConfigSaveOrPubPostData, ResetDraftPostData } from '../features/Price/CountryPriceConfig/types';
import { BaseService } from './base.service';

class CountryPriceConfigService extends BaseService {
  private static _authService: CountryPriceConfigService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): CountryPriceConfigService {
    return this._authService || (this._authService = new this('countryPriceConfig'));
  }

  public async getLastValidCountryPriceConfig(countryId: number, isPublished:boolean): Promise<ApiResponse | {data: any}> {

    let axiosResponse = null;

    try {
        let url = 'getLastValidCountryPriceConfig?countryId=' + countryId;

        if (isPublished){
          url += '&isPublished=' + true;
        }

        axiosResponse = await this.$http.get(url);

        return {response: axiosResponse, data: axiosResponse.data}; 
    }
    catch (error: any) {
        this.handleAllErrors(error);
        throw error;
    }
  }

  public async saveOrPublishCountryPriceConfig(priceConfig: CountryPriceConfigSaveOrPubPostData): Promise<ApiResponse> {
    let axiosResponse = null;

    try {
      axiosResponse = await this.$http.post('saveOrPublishCountryPriceConfig', priceConfig);
    }
    catch (error:any) {
        this.handleAllErrors(error);
        throw error;
    }

    return {response: axiosResponse, data: axiosResponse.data}; 
  }

  public async resetCountryPriceConfigDraft(data: ResetDraftPostData): Promise<ApiResponse> {
    let axiosResponse = null;

    try {
      axiosResponse = await this.$http.post('resetPriceConfigDraft', data);
    }
    catch (error:any) {
        this.handleAllErrors(error);
        throw error;
    }

    return {response: axiosResponse, data: axiosResponse.data}; 
  }
}

export const CountryPriceConfigsApi = CountryPriceConfigService.Instance;
