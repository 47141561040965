import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionStatus } from '../../../config/constants';
import { AppUser, AppUsers, UsersState } from '../../Login/types';


const initialState: UsersState = {
  list: {},
  loading: ActionStatus.NONE,
  saving: false,
  deleting: false,
  currentUserId: null
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    usersLoading(state) {
      state.loading = ActionStatus.PROCESS;
    },
    usersLoaded(state, action: PayloadAction<AppUsers>) {
      const users = action.payload;

      if (!users)
        return state;

      state.list = users;
      state.loading = ActionStatus.NONE;
    },
    usersLoadingFail(state) {
      state.loading = ActionStatus.NONE;
    },
    setCurrentUserId(state, action: PayloadAction<number>) {
      state.currentUserId = action.payload;
    },
    savingUser(state) {
      state.saving = true;
    },
    resetSaving(state) {
      state.saving = false;
    },
    setUser(state, action: PayloadAction<AppUser>) {
      const user = action.payload;

      if (!user || !user.userId) {
        return state;
      }

      state.list[user.userId] = user;
      state.saving = false;
    },
    deletingUser(state) {
      state.deleting = true;
    },
    removeUserFromList(state, action: PayloadAction<number>) {
      const userId = action.payload;
      if (!userId)
        return state;

      state.deleting = false;
      delete state.list[userId];
    },
    resetState(state) {
      return initialState;
    }
  },
})

export const {
  usersLoading,
  usersLoaded,
  savingUser,
  usersLoadingFail,
  setUser,
  resetSaving,
  setCurrentUserId,
  deletingUser,
  removeUserFromList,
  resetState
} = usersSlice.actions;

export default usersSlice.reducer;

export const selectUsers = (state: any): Array<AppUser> => state.users ? Object.values(state.users.list) : [];

export const selectCurrentUser = (state: any): AppUser | null => state.users ? state.users.list[state.users.currentUserId] : null;

export const selectSaving = (state: any) => state.users ? state.users.saving : false;

