import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Country, CountriesState } from '../types';

const initialState: CountriesState = {
    list: [],
    loading: false,
    currentItemId: null
};

const countriesSlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        countriesLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        countriesLoaded(state, action: PayloadAction<Array<Country>>) {
            const data = action.payload;

            state.loading = false;
            state.list = data || [];

            if (data && data.length === 1){
               state.currentItemId = data[0].countryId;
            }
        },
        setCurrentItem(state, action: PayloadAction<number | null>){           
            state.currentItemId = action.payload || null;
        },
        resetState(state) {
            return initialState;
        }
    },
})

export const {
    countriesLoading,
    countriesLoaded,
    setCurrentItem,
    resetState

} = countriesSlice.actions;

export default countriesSlice.reducer;


export const selectCountryByIndex = (state: any, index: number): Country | null => {
    const countries = state.countries as CountriesState;
  
    if (index || index === 0) {
      return countries[index];
    }
  
    return null;
  }

  export const selectCountryById = (state: any, id: number): Country | null => {
    const countries = state.countries as CountriesState;
  
    if (id && countries.list && countries.list.length > 0) {
      return countries.list.find(x=>x.countryId === id) || null;
    }
  
    return null;
  }



