import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppLabel, Spinner } from '../../../../components';
import { CalenderIcon, PhoneIcon, EmailIcon, StartIcon, WpIcon, FinishIcon, TaxiIcon, UserIcon, UserIcon2, UsersIcon, FileIcon } from '../../../../components/Icon';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { dateTimeHelper } from '../../../../utils/dateTimeHelper';
import { resetPaymentsState, selectPayments } from '../../../Payments/reducers/paymentReducer';
import { AppPayment, AppPaymentState } from '../../../Payments/types';
import { AppBooking, AppBookingsState, getLogStatusData, BookingLogType, AppPaymentLog, AppRefundRequest, AppBookingStop } from '../../types';
import { PriceBox } from './PriceBox';
import { BookingInfoElement } from './BookingInfoElement';
import { BookingInfoHeader } from './BookingInfoHeader';
import BookingLog from './BookingLog';
import { PaymentsDetails } from './PaymentDetails';
import { PaymentLog } from './PaymentLog';
import { BookingDetailsBoxWrapper } from './BookingDetailsBoxWrapper';
import { resetPaymentLogState } from '../../reducer/paymentLogReducer';
import { resetBookingLogState } from '../../reducer/bookingsLogReducer';
import { AppModal } from '../../../../components/AppModal';
import RefundForm from './RefundForm';
import { resetCurrentItem } from '../../reducer/bookingReducer';
import { selectBookingStopsByBookingId } from '../../reducer/bookingStopsReducer';
import { getAppBookingTripCategory } from '../../../TripCategory/types';

export interface BookingDetailsProps { // from props
    refundingPaymentId: number | null,
    setRefundedPaymentId: (paymentId: number | null) => void,
    handleRefund: (data: AppRefundRequest) => void
    bookingId?: number
}

interface PropertyProps { // from reducer
    booking?: AppBooking | null,
    bookingLoading: boolean,
    payments: Array<AppPayment>,
    paymentLoading: boolean,
    paymentLogLoading: boolean,
    paymentLog: Array<AppPaymentLog>,
    refundPaymentPending: number | null,
    bookingStops: Array<AppBookingStop> | null
}

interface FuncProps {
    reset_state: () => void
}

type Props = PropertyProps & FuncProps & BookingDetailsProps;


const BookingDetails: React.FC<Props> = (props) => {
    let { booking, bookingLoading, payments, paymentLoading, paymentLogLoading, paymentLog, refundingPaymentId, 
        refundPaymentPending, bookingStops,
        reset_state, setRefundedPaymentId, handleRefund } = props;

    useEffect(() => {

        return () => {
            reset_state();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!booking || bookingLoading) {
        return <Spinner />
    }

    const tripStatusData = booking ? getLogStatusData(booking.tripStatus, BookingLogType.TripLog) : null;
    const bookingStatusData = booking ? getLogStatusData(booking.bookingStatus, BookingLogType.BookingLog) : null;
    const paymentStatusData = booking ? getLogStatusData(booking.paymentStatus, BookingLogType.PaymentLog) : null;

    let customerName = '';
    const customerIcon = getAppBookingTripCategory(booking.tripCategory);

    if (booking.clientName){
        customerName = booking.clientName;
    }
    else if (booking.customerName){
        customerName = booking.customerName;
    }

    const customerIconResult = booking.tripCategory === 0? '': (customerIcon.icon  || '');

    return (<div className="bookings-details" data-testid="testBookingDetails">
        <BookingDetailsBoxWrapper elementClassName='booking-info'>
            <>
                <BookingInfoHeader text={'Info'} icon={<TaxiIcon />} />
                <div className="ui divider"></div>
                {
                    booking.isPreOrder? 
                    <BookingInfoElement title={<> <CalenderIcon />Forhåndsbestilt til kl:</>} text={dateTimeHelper.getLocalFromUtcStrFormated((booking?.pickupTime || '-')) || "-"}/>
                    : null
                }
                
                <BookingInfoElement title={'Bookingstatus'} text={<AppLabel color={bookingStatusData ? bookingStatusData.color : "black"} text={bookingStatusData ? bookingStatusData.text : '-'} />} />
                <BookingInfoElement title={'Turstatus'} text={<AppLabel color={tripStatusData ? tripStatusData.color : "black"} text={tripStatusData ? tripStatusData.text : '-'} />} />
                <BookingInfoElement title={'Betalingsstatus'} text={<AppLabel color={paymentStatusData ? paymentStatusData.color : "black"} text={paymentStatusData ? paymentStatusData.text : '-'} />} />

                <BookingInfoElement title={'Bookingnummer'} text={ booking?.bookingNumber || '-'} />

                <BookingInfoElement title={'Sjåførnummer'} text={booking?.driverNumber || '-'} />
                <BookingInfoElement title={'Sjåførnavn'} text={booking?.driverName || '-'} />

                <BookingInfoElement title={'Løyve'} text={booking?.carId || '-'} />
                <BookingInfoElement testId={'test-booking-company-name'} title={'Selskap'} text={booking?.providerName || '-'} />
                {
                    booking.fromAddressId? (<BookingInfoElement testId={'test-booking-address-from'} title={<><StartIcon /> Addresse fra: </>} text={<address>{booking?.fromFullAddress}</address>} />):null
                }
                {
                    bookingStops != null && bookingStops.length > 0 ?  <BookingStops bookingStops={bookingStops} />: ''
                }
                {
                    booking.toAddressId? (<BookingInfoElement testId={'test-booking-address-to'} title={<><FinishIcon />Addresse  til: </>} text={<address>{booking?.toFullAddress}</address>} />):null
                }
                
                <BookingInfoElement title={<><CalenderIcon /> Oprettet</>} text={dateTimeHelper.getLocalFromUtcStrFormated((booking?.createdDate || '-')) || "-"} />
            </>
        </BookingDetailsBoxWrapper>

        <div className="bookings-details-col-2">

            <BookingDetailsBoxWrapper elementClassName='booking-user-info'>
                <>
                    <BookingInfoHeader text={'Kunde'} icon={<UserIcon />} />
                    <div className="ui divider"></div>
                 <BookingInfoElement testId={'test-customer-name'} title={<>{customerIconResult} Navn</>} text={customerName || "-"} />

                    {
                        booking.clientId ? null: (<>
                             <BookingInfoElement testId={'test-customer-phone-number'} title={<><PhoneIcon />Telefonnummer</>} text={booking?.customerPhone || ""} />
                            <BookingInfoElement testId={'test-customer-email-address'} title={<><EmailIcon />Epost</>} text={booking?.customerEmail || ""} />
                        </>)
                    }
                   
                    <BookingInfoElement  testId={'test-customer-number-of-passengers'} title={<><UsersIcon /> Passasjerer</>} text={booking?.numberOfPassengers || 0} />
                    { 
                        booking.bookingGuid && !booking.clientId?
                            <div test-id={'test-customer-booking-receipt'} >
                                {<><FileIcon /> <a className='receipt-link' target="_blank" href={`${process.env.REACT_APP_RECEIPT_URL}/${booking.bookingGuid}`} rel="noreferrer"> 
                                Kvittering </a></>} 
                            </div>:null
                    }   

                </>
            </BookingDetailsBoxWrapper>

            <PriceBox price={booking?.price || 0} 
                serviceCharge={booking?.serviceCharge || null}
                originalPrice={booking?.originalPrice || null}
                originalCancellationFee={booking.originalCancellationFee || null}
                cancellationFee={booking.cancellationFee || null}
                discountAmount={booking.discountAmount}
                discountAmountTypeId={booking.discountAmountTypeId}
                discountName={booking.discountName || null}
                discount={booking?.discount}
                contractPrice={booking.contractPrice || null}
                isOrdinaryBooking={getAppBookingTripCategory(booking.tripCategory).isOrdinary || false}
                 />

            {
                (payments && payments.length <= 1 && getAppBookingTripCategory(booking.tripCategory).isOrdinary) ?
                    <PaymentsDetails
                        payments={payments || []}
                        loading={paymentLoading}
                        setRefundedPaymentId={setRefundedPaymentId}
                        refundPaymentPending={refundPaymentPending}
                    /> : ''
            }

        </div>

        <div className="booking-logs">
            {
                (payments && payments.length > 1) ?
                    <PaymentsDetails
                        payments={payments}
                        loading={paymentLoading}
                        setRefundedPaymentId={setRefundedPaymentId}
                        refundPaymentPending={refundPaymentPending}
                    /> : ''
            }

            {
                !getAppBookingTripCategory(booking.tripCategory).isOrdinary? null:
                ( <BookingDetailsBoxWrapper elementClassName='payment-log'>{}
                    <PaymentLog paymentLog={paymentLog} logLoading={paymentLogLoading} />
                </BookingDetailsBoxWrapper>)
                }
           

            <BookingDetailsBoxWrapper elementClassName='booking-log'>
                <BookingLog />
            </BookingDetailsBoxWrapper>
        </div>


        <AppModal
            onClose={() => setRefundedPaymentId(null)}
            isOpen={refundingPaymentId ? true : false}
            triggerComponent={null}
            header={<>Refunder</>} >

            <RefundForm handleRefund={handleRefund} refundingPaymentId={refundingPaymentId} />

        </AppModal>

    </div>
    )
};

interface BookingStopsProps {
    bookingStops: Array<AppBookingStop> 
}

const BookingStops = (props: BookingStopsProps) => {
    const {bookingStops} = props;

    const bookingStopsItems = bookingStops.map((bookingStop, index) => {
        return <div key={index} data-testid="test-booking-extra-stop">{bookingStops.length > 1? '-': ''} <address>{bookingStop.fullAddress}</address></div>
    })

    return <div style={{marginLeft: '2px'}} className="booking-extra-stops" data-testid="test-booking-extra-stops">
       <div className="booking-info-element">
        <div className="summary" style={{ fontWeight: 'bold' }}>
         <WpIcon />Waypoint(s):
        </div>
        {bookingStopsItems}
    </div>
    </div>
}

const mapStateToProps = (state: RootState, ownProps: any): PropertyProps & BookingDetailsProps => {
    const bookingsState = state.bookings as AppBookingsState;
    const paymentState = state.payments as AppPaymentState;

    const booking = bookingsState.currentItem || null;
    const bookingLoading = bookingsState.currentItemLoading;

    const payments = selectPayments(state.payments) || [];
    const paymentLoading = state.payments.loading;

    const paymentLog = state.paymentLog.list || [];
    const paymentLogLoading = state.paymentLog.loading;
    const refundPaymentPending = paymentState.refundPaymentPending || null;

    const bookingStops = booking && booking.bookingId? selectBookingStopsByBookingId(state, booking.bookingId): [];


    return {
        booking: booking || null,
        bookingStops,
        bookingLoading,
        payments,
        paymentLoading,
        paymentLog,
        paymentLogLoading,
        setRefundedPaymentId: ownProps.setRefundedPaymentId,
        refundingPaymentId: ownProps.refundingPaymentId,
        handleRefund: ownProps.handleRefund,
        refundPaymentPending
    };
};


const mapDispatchToProps = (dispatch: any): FuncProps => {

    return {
        reset_state: () => {
            dispatch(resetPaymentLogState());
            dispatch(resetBookingLogState());
            dispatch(resetPaymentsState());
            dispatch(resetCurrentItem());
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BookingDetails)
);





