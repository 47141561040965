import { DatePicker, DateTimePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import '../styles/timePicker.css';

// pick a date util library
import LuxonUtils from '@date-io/luxon';
import { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface TimeResponceData {
    hour: number,
    minute: number,
    seconds: number,
    jsDate: Date | null,
    dateTime: DateTime | null
}

interface Props {
    customStyle?: object,
    clearable?: boolean,
    ampm?: boolean,
    label?: string,
    selectedDate: Date | null,
    timeChangedHandler: (timeData: TimeResponceData) => void
}

/** Based on Material UI timepicker https://material-ui-pickers.dev/getting-started/usage
 *  and luxon */
export const AppTimePicker = (props: Props) => {
    const { clearable = false, ampm = false, label = "", selectedDate, timeChangedHandler } = props;
    let { customStyle } = props;

    const [timeState, setTimeState] = useState<Date>();

    useEffect(() => {

        if (selectedDate) {
            setTimeState(selectedDate);
        }

    }, [selectedDate]);

    const onTimeChanged = (value: DateTime) => {

        /*
        const hour = value? value.get("hour"): 0;
        const minute = value? value?.get("minute"): 0;
        const seconds = value? value?.get("second"): 0;
        */

        let jsDate = null;
        if (value && typeof value.toJSDate === 'function') {
            jsDate = value.toJSDate();
            setTimeState(value.toJSDate());
        }

        const hour = value ? value.hour : 0;
        const minute = value ? value.minute : 0;
        const seconds = value ? value.second : 0;


        if (typeof timeChangedHandler === 'function') {
            timeChangedHandler({ hour, minute, seconds, jsDate, dateTime: value });
        }
    }

    if (!customStyle) {
        customStyle = { width: '100%' };
    }

    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>

            <KeyboardTimePicker
                clearable={clearable}
                ampm={ampm}
                label={label}
                value={timeState || null}
                format="HH:mm"
                mask="__:__"
                onChange={(value: any) => onTimeChanged(value)}
            />


        </MuiPickersUtilsProvider>
    );
}

export interface DateResponseData {
    jsDate: Date | null,
    value?: DateTime | null
}

interface MonthPickerProps {
    selectedDateInit: Date | null,
    dateChangedHandler: (timeData: DateResponseData) => void
}


/** Based on Material UI timepicker https://material-ui-pickers.dev/getting-started/usage
 *  and luxon */
export const MonthPicker = (props: MonthPickerProps) => {
    const { selectedDateInit, dateChangedHandler } = props;

    const [selectedDate, setDateState] = useState<Date | null>();

    useEffect(() => {

        if (selectedDateInit) {
            setDateState(selectedDateInit);
        }

    }, [selectedDateInit]);

    const onDateChanged = (value: MaterialUiPickersDate) => {

        const res = value ? value.toJSDate() : null;
        setDateState(res);

        if (typeof dateChangedHandler == 'function') {
            dateChangedHandler({ jsDate: res });
        }
    }

    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker
                variant="static"
                openTo="month"
                views={["year", "month"]}
                helperText="Start from year selection"
                value={selectedDate}
                onChange={onDateChanged}
            />
        </MuiPickersUtilsProvider>
    );
}

interface DateTimePickerProps {
    selectedDateInit: DateTime | null,
    dateChangedHandler: (timeData: DateResponseData) => void
}


/** Based on Material UI date time picker 
 *  and luxon */
export const AppDateTimePicker = (props: DateTimePickerProps) => {
    const { selectedDateInit, dateChangedHandler } = props;

    const [selectedDate, setDateState] = useState<any>(null);
    let prevDate = useRef<DateTime | null>(null);


    // to clear the date from parent
    useEffect(() => {

        const prevValue = (prevDate || {}).current;

        if((prevValue != null && selectedDateInit == null) || (prevValue == null && selectedDateInit != null) ||
         ( prevValue != null && selectedDateInit != null && !prevValue?.equals(selectedDateInit)) ){

            setDateState(selectedDateInit);
        }
        else {
            console.log('date is the same');
        }

        prevDate.current = selectedDateInit;

    }, [selectedDateInit]);
    

    const onDateChanged = (value: MaterialUiPickersDate) => {

        const res = value ? value.toJSDate() : null;
        setDateState(res);

        if (typeof dateChangedHandler == 'function') {
            dateChangedHandler({ jsDate: res, value: value });
        }
    }

    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
           <DateTimePicker value={selectedDate} onChange={onDateChanged} disablePast={true} minDateMessage={''}
              ampm={false}
            clearable={true} />
        </MuiPickersUtilsProvider>
    );
}