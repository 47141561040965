

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectCountryById } from '../../Countries/reducers/countriesReducer';
import { selectAuthUser } from '../../Login/reducers/authReducers';
import { selectRegionById } from '../../Regions/reducers/regionsReducers';
import { saveUser } from '../actions/usersAction';
import { selectCurrentUser, selectSaving } from '../reducers/usersReducer';
import { UserEditorForm, UserEditorFormProps } from './UserEditorForm';

const UsersEditor = (props: UserEditorFormProps) => {
  return <UserEditorForm {...props} />
}


const mapStateToProps = (state: any) => {

  const currentUser = selectCurrentUser(state);
  const currentRegion = currentUser && currentUser.regionId ? selectRegionById(state, currentUser.regionId) : null;

  const currentCountryId = currentRegion && currentRegion.countryId ? currentRegion.countryId : null;
  const authUser = selectAuthUser(state);


  return {
    pending: selectSaving(state),
    user: selectCurrentUser(state),
    currentUserId: authUser.userId || 0,
    userCountry: currentCountryId ? selectCountryById(state, currentCountryId) : null,
    showRoles: true,
    showRegion: authUser.regionId === null && authUser.userId !== currentUser?.userId ? true : false
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleFormSubmit: (data: any, userId: number | null, currentUserId: number) => { dispatch(saveUser(data, userId, currentUserId)) },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersEditor)
);
