import { CountryPriceConfigsApi } from "../../../../api/countryPriceConfigs";
import { RootState } from "../../../../config/reducerConfig/rootReducer";
import { changePublishingState, changeSavingState, priceConfigLoading, resettingDraft, setPriceConfig } from "../reducers/countryPriceConfigReducer";
import { CountryPriceConfigSaveOrPubPostData, CountryPriceConfigServerData, CountryPriceConfigState, ResetDraftPostData } from "../types";

/** draft or published */
export const getCountryPriceConfig = (countryId: number, isPublished:boolean) => {

    return async (dispatch: any) => {

        dispatch(priceConfigLoading(true));

        try {

            const result = await CountryPriceConfigsApi.getLastValidCountryPriceConfig(countryId, isPublished);
            const data = (result.data || null) as CountryPriceConfigServerData;

            dispatch(setPriceConfig({ priceConfig: data, countryId }));

            return;
        }
        catch (error) {
            console.log(error);
            dispatch(priceConfigLoading(false));
            throw error;
        }
    }
};



export const publishOrSaveCountryPriceConfig = ({ isSave }: { isSave: boolean }) => {
    return async (dispatch: any, getState: any) => {

        if (isSave) {
            dispatch(changeSavingState(true));
        }
        else {
            dispatch(changePublishingState(true));
        }

        try {

            const state: RootState = getState();
            let countryPriceConfigState = getState().countryPriceConfig as CountryPriceConfigState;
            const authUser = state.auth || {};

            if (!countryPriceConfigState || !countryPriceConfigState.priceConfig) {
                throw new Error(" countryPriceConfig is null or empty");
            }

            const dataToSend: CountryPriceConfigSaveOrPubPostData = {
                countryPriceConfig: countryPriceConfigState.priceConfig,
                dayTypes: countryPriceConfigState.dayTypes || [],
                dayTimeZones: countryPriceConfigState.dayTimeZones || [],
                carTypes: countryPriceConfigState.carTypes || [],
                extraSavingInfo: {
                    isEdited: countryPriceConfigState.isEdited,
                    isSave: isSave,
                    comment: countryPriceConfigState.comment || null,
                    editorId: authUser.user && authUser.user.userId ? authUser.user.userId : 0
                }
            };


            let result = await CountryPriceConfigsApi.saveOrPublishCountryPriceConfig(dataToSend);
            const data = (result.data || null) as CountryPriceConfigServerData;

            return data;
        }
        catch (error) {
            dispatch(changeSavingState(false));
            dispatch(changePublishingState(false));
            throw error;
        }
    }
};


export const resetCountryPriceConfigDraft = () => {
    return async (dispatch: any, getState: any) => {

        dispatch(resettingDraft(true));

        try {

            const state: RootState = getState();
            const authUser = state.auth || {};
            const priceConfig = state.countryPriceConfig.priceConfig ? state.countryPriceConfig.priceConfig : null;
            const countryPriceConfigId = priceConfig && priceConfig.countryPriceConfigId ? priceConfig.countryPriceConfigId : 0;
            const countryId = priceConfig && priceConfig.countryId ? priceConfig.countryId : 0;

            const dataToSend: ResetDraftPostData = {
                countryPriceConfigId,
                countryId,
                userId: authUser.user && authUser.user.userId ? authUser.user?.userId : 0
            };

            let result = await CountryPriceConfigsApi.resetCountryPriceConfigDraft(dataToSend);
            return result;
        }
        catch (error) {
            dispatch(resettingDraft(false));
            throw error;
        }
    }
};
