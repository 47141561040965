import { prepareAndRoundNumber } from "./commonHelpers";

export const formPrepareAndRoundNumber = (currentValue: number | null, 
    setValueFunc: (newValue: number | null | string)=>void, numberOfDecimals: number | null = 2) => {

        let newValue: number | string | null = '';
        if (currentValue === 0){
            newValue = 0;
        }
        else if (currentValue){
            try {
                newValue = prepareAndRoundNumber(currentValue, numberOfDecimals);
                newValue = isNaN(newValue as number) ? '': newValue;
            }
            catch (error){
                console.log(error);
            }
          
        }  
        
        setValueFunc(newValue);
        
}

export const getInputValue = (e: any) => {
    if (e && typeof e.preventDefault === 'function') e.preventDefault();
    return e && e.target && (e.target.value || e.target.value === 0)? e.target.value: null;
}


