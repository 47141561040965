import { BookingsApi } from "../../../api/bookings.service";
import { AppRefundRequest } from "../../Bookings/types";
import { setRefundPending } from "../reducers/paymentReducer";


export const refundPayment = (args: AppRefundRequest) => {

    return async (dispatch: any, getState: any) => {

        const paymentId = args && args.paymentId ? args.paymentId: null;
        dispatch(setRefundPending({refundePaymentPending: paymentId}));

        try {

            const result = await BookingsApi.refundBookingPayment(args)
            const data = result? result.data: null;

            dispatch(setRefundPending({refundePaymentPending: null}));

            return data;
        }
        catch (error) {
            dispatch(setRefundPending({refundePaymentPending: null}));
            throw error;
        }
    }
};