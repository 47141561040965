import { ApiResponse } from '../commonTypes';
import { BaseService } from './base.service';

class DrivingAreaService extends BaseService {
  private static _authService: DrivingAreaService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): DrivingAreaService {
    return this._authService || (this._authService = new this('drivingArea'));
  }

  public async getDrivingAreas(): Promise<ApiResponse | { data: any }> {

    let axiosResponse = null;

    try {
      axiosResponse = await this.$http.get('getDrivingAreas');
      return { response: axiosResponse, data: axiosResponse.data };
    }
    catch (error: any) {
      this.handleAllErrors(error);
      throw error;
    }
  }

  public async getDrivingAreaPoints(regionId: number ): Promise<ApiResponse | { data: any }> {

    let axiosResponse = null;

    if (!regionId){
      console.error('getDrivingAreaPoints has no regionId');
      throw new Error('getDrivingAreaPoints has no regionId');
    }

    try {

      axiosResponse = await this.$http.get('getDrivingAreaPoints?regionId=' + regionId);
      return { response: axiosResponse, data: axiosResponse.data };

    }
    catch (error: any) {
      this.handleAllErrors(error);
      throw error;
    }
  }
  
}

export const DrivingAreaApi = DrivingAreaService.Instance;
