
import React, { FormEvent, useEffect, useState } from 'react'

export type BtnDesignType = 'primary' | 'secondary' | 'negative' | 'positive' | 'green' | 'grey' | 'teal';

interface AppButtonProps {
    loading?: boolean,
    designType?: BtnDesignType,
    children?: JSX.Element | string,
    isIcon?: boolean,
    disabled?: boolean,
    size?: 'small' | 'mini' | 'tiny' | 'medium' | 'large' | 'tiny-mini',
    onClick:  (args: number | string | object | undefined | null | FormEvent | any) => any | void;
    fluid?: boolean,
    type?: 'submit' | 'button',
    dataTestId?: string
}

export const AppButton: React.FC<AppButtonProps> = (props) => {

    const [pending, setPending] = useState(false);
    

    useEffect(()=>{
        
        let timer: any;
        
        if (props.loading === true){
            setPending(true);
        }
        else if (props.loading === false && pending === true){
            timer = setTimeout(()=> {
                setPending(false);
            },1000)
        }

        return () => {
            if (timer){
                clearTimeout(timer);
            }
           
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.loading])


    const {size, loading, designType, children, isIcon = false, dataTestId, disabled = false, onClick = () => { }} = props;
    const className = `ui ${pending ? 'loading' : ''} ${designType ? designType : ''}  ${isIcon ? 'icon' : ''}  ${size ? size : ''} 
        ${disabled || loading ? 'disabled' : ''}
        button `;

    return <button type={props.type} data-testid={dataTestId? dataTestId: ''} onClick={(e) => { if (e) e.preventDefault(); onClick(e)}} className={className} >
        {children}
    </button>
}

export const AppButtonSubmit: React.FC<AppButtonProps> = (props) => {

    return <AppButton type="submit" {...props} size='medium' >
        {props.children}
    </AppButton>
}



interface AreYouSureAppButtonProps extends AppButtonProps {
    cancelText?:string
    confirmationText?:string
    text:string | JSX.Element,
    dataTestIdOk?: string
    dataTestIdConfirm?: string
    mainBtndesignType?: BtnDesignType | null
}

export const AreYouSureAppButton: React.FC<AreYouSureAppButtonProps> = (props) => {

    const { onClick, cancelText='Avbryt', confirmationText='OK', text='', mainBtndesignType='negative'} = props;
    const [clickCount, setClickCount] = useState(0);

    const increaseClickCount = (e: any) => {
        if (e) e.preventDefault();
        setClickCount(clickCount + 1);
    }

    const confirmAction = (e: any) => {
        if (e) e.preventDefault();

        setClickCount(0);
        if (typeof onClick === 'function')
            onClick({});
    }

    const resetClickCount = (e: any) => {
        if (e) e.preventDefault();
        setClickCount(0);
    }

    let buttons = null;

    if (clickCount === 0) {
        buttons = (
            <AppButton {...props} dataTestId={props.dataTestIdConfirm || ''} designType={mainBtndesignType || 'negative'} onClick={(e) => { increaseClickCount(e) }} >{text}</AppButton>)
    }
    else if (clickCount === 1) {

        buttons = (<div className={`ui buttons ${props.size? props.size: ''}` }>
            <AppButton {...props} dataTestId={props.dataTestIdOk || ''} designType='negative' onClick={(e) => { confirmAction(e) }} >{confirmationText}</AppButton>
            <AppButton {...props} dataTestId={''} designType='teal'  onClick={(e) => { resetClickCount(e) }} >{cancelText}</AppButton>

        </div>)
    }

    return <>{buttons}</>
}
