import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { PriceTestCaseBase, PriceTestCasesServerData, PriceTestCasesState } from '../types';

const initialState: PriceTestCasesState = {
  testCases: [],
  drivingAreasWithPriceTestCases: [],
  loading: false
};

const priceTestCasesSlice = createSlice({
  name: 'priceTestCases',
  initialState,
  reducers: {
    priceTestCasesLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPriceTestCases(state, action: PayloadAction<PriceTestCasesServerData>) {
      const data = action.payload? action.payload : null;
      const {drivingAreasWithPriceTestCases, testCases} = data || {};
      state.drivingAreasWithPriceTestCases = drivingAreasWithPriceTestCases && drivingAreasWithPriceTestCases.length > 0? drivingAreasWithPriceTestCases: [];
      state.testCases = testCases && testCases.length > 0 ? testCases: [];
      state.loading = false;
    },
    deletePriceTestCase(state, action: PayloadAction<number>){
      const id = action.payload;

      if (!id){
        return state;
      }

      state.drivingAreasWithPriceTestCases.forEach((priceTestCase)=>{
        priceTestCase.priceTestCases = priceTestCase.priceTestCases.filter(x=>x.priceTestCaseId !== id);
      })

      state.testCases = state.testCases.filter(x=>x.priceTestCaseId !== id);

    },
    resetPriceTestCasesState(state) {
      return initialState;
    }
  },
})

export const {
  priceTestCasesLoading,
  setPriceTestCases,
  deletePriceTestCase,
  resetPriceTestCasesState

} = priceTestCasesSlice.actions;

export default priceTestCasesSlice.reducer;

export const selectPriceTestCaseById = (state: RootState, id: number): PriceTestCaseBase | null => {
  const conf = state.priceTestCases;

  if (id && conf && conf.testCases && conf.testCases.length > 0) {
      const current = conf.testCases.find(x=>x.priceTestCaseId === id);
      return current || null;
  }

  return null;
}



