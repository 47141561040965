import { ApiResponse } from '../commonTypes';
import { RegionPriceConfigSaveOrPubPostData, ResetRegionPriceConfigDraftPostData } from '../features/Price/RegionPriceConfigs/types';
import { BaseService } from './base.service';

class RegionPriceConfigService extends BaseService {
    private static _authService: RegionPriceConfigService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): RegionPriceConfigService {
        return this._authService || (this._authService = new this('regionPriceConfig'));
    }

    public async getRegionPriceConfigs(countryId: number): Promise<ApiResponse | { data: any }> {

        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.get('getRegionPriceConfigs?countryId=' + countryId);
            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async getLastValidRegionPriceConfig(regionId: number, isPublished:boolean): Promise<ApiResponse | { data: any }> {

        let axiosResponse = null;

        try {
            let url = 'getLastValidRegionPriceConfig?regionId=' + regionId;

            if (isPublished){
                url += '&isPublished=' + true;
            }

            axiosResponse = await this.$http.get(url);

            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async saveOrPublishRegionPriceConfig(priceConfig: RegionPriceConfigSaveOrPubPostData): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('saveOrPublishRegionPriceConfig', priceConfig);
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }

        return { response: axiosResponse, data: axiosResponse.data };
    }

    public async resetRegionPriceConfigDraft(data: ResetRegionPriceConfigDraftPostData): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('resetRegionPriceConfigDraft', data);
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }

        return { response: axiosResponse, data: axiosResponse.data };
    }

}

export const RegionPriceConfigsApi = RegionPriceConfigService.Instance;
