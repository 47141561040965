import { RegionPriceConfigsApi } from "../../../../api/regionPriceConfig.service";
import { RootState } from "../../../../config/reducerConfig/rootReducer";
import { RegionPriceConfig } from "../../types";
import { changePublishingState, changeSavingState, priceConfigLoading, resettingDraft, setPriceConfig } from "../reducers/regionPriceConfigReducer";
import { priceConfigsLoading, setPriceConfigs } from "../reducers/regionPriceConfigsReducer";
import { RegionPriceConfigSaveOrPubPostData, RegionPriceConfigServerData, ResetRegionPriceConfigDraftPostData } from "../types";



export const getRegionsPriceConfigs = (countryId: number) => {

    return async (dispatch: any) => {

        dispatch(priceConfigsLoading(true));

        try {
            const result = await RegionPriceConfigsApi.getRegionPriceConfigs(countryId);
            const data: Array<RegionPriceConfig> = result ? result.data : [];
            dispatch(setPriceConfigs(data));

            return data;
        }
        catch (error: any) {
            dispatch(priceConfigsLoading(false));
            return new Error(error);
        }
    }
};

export const getLastRegionPriceConfig = (countryId:number, regionId: number, isPublished: boolean) => {

    return async (dispatch: any) => {

        dispatch(priceConfigLoading(true));

        try {

            const result = await RegionPriceConfigsApi.getLastValidRegionPriceConfig(regionId, isPublished);
            const data = (result.data || null) as RegionPriceConfigServerData;

            dispatch(setPriceConfig({ priceConfig: data, countryId, regionId }));

            return;
        }
        catch (error) {
            console.log(error);
            dispatch(priceConfigLoading(false));
            throw error;
        }
    }
};

export const publishOrSaveRegionPriceConfig = ({ isSave }: { isSave: boolean }) => {
    return async (dispatch: any, getState: any) => {

        if (isSave) {
            dispatch(changeSavingState(true));
        }
        else {
            dispatch(changePublishingState(true));
        }

        try {

            const state: RootState = getState();
            let regionPriceConfigState = state.regionPriceConfig;
            const authUser = state.auth || {};

            if (!regionPriceConfigState || !regionPriceConfigState.priceConfig) {
                throw new Error(" regionPriceConfig is null or empty");
            }

            const dataToSend: RegionPriceConfigSaveOrPubPostData = {
                regionPriceConfig: regionPriceConfigState.priceConfig,
                extraSavingInfo: {
                    isEdited: regionPriceConfigState.isEdited,
                    isSave: isSave,
                    comment: regionPriceConfigState.comment || null,
                    editorId: authUser.user && authUser.user.userId ? authUser.user.userId : 0
                }
            };

            let result = await RegionPriceConfigsApi.saveOrPublishRegionPriceConfig(dataToSend);
            const data = (result.data || null) as boolean;
            return data;
        }
        catch (error) {
            dispatch(changeSavingState(false));
            dispatch(changePublishingState(false));
            throw error;
        }
    }
};


export const resetRegionPriceConfigDraft = () => {
    return async (dispatch: any, getState: any) => {

        dispatch(resettingDraft(true));

        try {

            const state: RootState = getState();
            const authUser = state.auth || {};
            const priceConfig = state.regionPriceConfig.priceConfig ? state.regionPriceConfig.priceConfig : null;
            const regionPriceConfigId = priceConfig && priceConfig.regionPriceConfigId ? priceConfig.regionPriceConfigId : 0;
            const regionId = priceConfig && priceConfig.regionId ? priceConfig.regionId : 0;

            const dataToSend: ResetRegionPriceConfigDraftPostData = {
                regionPriceConfigId,
                regionId,
                userId: authUser.user && authUser.user.userId ? authUser.user?.userId : 0
            };

            let result = await RegionPriceConfigsApi.resetRegionPriceConfigDraft(dataToSend);
            return result;
        }
        catch (error) {
            dispatch(resettingDraft(false));
            throw error;
        }
    }
};

