import React from "react";
import { AppTitleMobileOnly, Spinner } from "../../../../components";
import { AppIconWithPopup, CalenderIcon, InfoListIcon } from "../../../../components/Icon";
import { dateTimeHelper } from "../../../../utils/dateTimeHelper";
import { AppPaymentLog, getAppPaymentStatusData } from "../../types";
import { BookingInfoHeader } from "./BookingInfoHeader";

const TABLE_TEXTS = {
    paymentId: 'Payment-ID',
    amount: 'Beløp',
    status: 'Betalingsstatus',
    transaction: 'TransaksjonsId',
    created: 'Opprettet'
}


interface Props {
    paymentLog: Array<AppPaymentLog>
    logLoading: boolean
}

export const PaymentLog: React.FC<Props> = (props) => {
    let { paymentLog, logLoading } = props;

    return (<div data-testid="test-payment-log">

        <BookingInfoHeader text={'Betalingshistorikk'} icon={<InfoListIcon />} />
        <div className="ui divider"></div>
        {
            logLoading === false ? (<div className="booking-log-list">
                <PaymentLogTable data={paymentLog} />
            </div>) : <Spinner />
        }

    </div>)
};

interface PaymentLogTableProps {
    data: Array<AppPaymentLog>
}

const PaymentLogTable: React.FC<PaymentLogTableProps> = (props) => {
    const { data } = props;

    let result = null;

    if (data && data.length > 0) {
        result = data.map((el, index) => {
            return <PaymentLogElement key={index} item={el} />
        })
    }

    return (<table className="app-booking-list ui very basic table ">
        <thead className="app-booking-list-table-header">
            <tr className="">
                <th>{TABLE_TEXTS.paymentId}</th>
                <th>{TABLE_TEXTS.amount}</th>
                <th>{TABLE_TEXTS.status}</th>
                <th>{TABLE_TEXTS.transaction}</th>
                <th>{TABLE_TEXTS.created}</th>
            </tr>
        </thead>
        {result && result.length > 0 ?
            <tbody  data-testid='test-payment-log-table' className="">{result}</tbody>
            : <tbody ><tr style={{ height: "10vh" }}></tr></tbody>}
    </table>)
}

interface PaymentLogElementProps {
    item: AppPaymentLog
}

const PaymentLogElement: React.FC<PaymentLogElementProps> = (props) => {
    let { item } = props;

    item = item || {};

    const nubmerLength = 20;

    const logStatusData = getAppPaymentStatusData(item.statusId);


    let transactionId = item.transactionId || '-';
    let concatinatedTransId = false;
    if (item.transactionId && item.transactionId.length > nubmerLength){
        transactionId =  item.transactionId.toString().slice(0,nubmerLength);
        concatinatedTransId = true;
    }

    return (<tr
        className="app-bookings-booking">
        <td style={{paddingLeft: '10px'}} > <AppTitleMobileOnly text={TABLE_TEXTS.paymentId}/> 
            { item.paymentId }
        </td>   
        <td> <AppTitleMobileOnly text={TABLE_TEXTS.amount}/>  {item.amount || '-'}</td>
        <td> <AppTitleMobileOnly text={TABLE_TEXTS.status}/>  {logStatusData ? logStatusData.text : '-'}
            {item.errorMessage ? <AppIconWithPopup color='orange' iconName='exclamation triangle' content={item.errorMessage} /> : ''}
            {item.userId? <AppIconWithPopup color='grey' iconName='user' content={item.userFullName || ''} /> : ''}
        </td>
        <td> 
            <AppTitleMobileOnly text={TABLE_TEXTS.transaction}/>
            {concatinatedTransId? 
                <>{transactionId}... <AppIconWithPopup color='grey' iconName='info' content={item.transactionId || '-'} /></>:
                transactionId
            }
             
         </td>
        <td> <AppTitleMobileOnly text={TABLE_TEXTS.created}/>  <CalenderIcon />
            {dateTimeHelper.getLocalFromUtcStrFormated(item.date, 'dd.MM.yy, HH:mm') || "-"} </td>
    </tr>)
}


