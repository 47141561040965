import React from 'react';
import { ActionStatus } from '../../../config/constants';
import '../../../styles/login.css'
import { ForgotPasswordForm } from './ForgotPasswordForm';

interface Props {
    pending: ActionStatus,
    setSendingStatus: (status: ActionStatus) => void
};

export const ForgotPasswordPageView: React.FC<Props> = (props) => {
    const { setSendingStatus, pending } = props;

    return (<div className="login-form-wrapper ui middle aligned center aligned grid">
        <div style={{ marginTop: '25vh' }} className="login-form four wide tablet four wide computer ten wide mobile column" >
            

            <ForgotPasswordForm pending={pending} setSendingStatus={setSendingStatus} />
            {
                pending === ActionStatus.SUCCESS ? <div style={{color:'green', marginTop: '10px'}}>Passord er sendt til din epost!</div> : ''
            }
            {
                pending === ActionStatus.FAIL ? <div style={{color:'red', marginTop: '10px'}}>Det oppstod en feil. Prøv igjen eller kontakt teknisk support.</div> : ''
            }

        </div>
    </div>)
};
