
export interface AppDashboard {
    totalNumberOfCustomers: number,
    numberOfCustomersLastMonth: number,
    numberOfCustomersLastDay: number

}

export const getEmptyDashboard = (): AppDashboard => {
    return {
        totalNumberOfCustomers: 0,
        numberOfCustomersLastMonth: 0,
        numberOfCustomersLastDay: 0
    }
}

