import { AppButton } from "../../../../components"
import { EditIcon } from "../../../../components/Icon"
import { showIntWith0Decimal } from "../../../../utils/commonHelpers";
import { HideEditingBtns, RegionPriceConfig } from "../../types";
import { RegionPriceConfActionsProps, RegionPriceConfigModalType } from "../types";


interface Props extends HideEditingBtns {
    regionPriceConfig: RegionPriceConfig
}

export const MainRegionPriceConfigInfo = (props: Props & RegionPriceConfActionsProps) => {

    const { regionPriceConfig, actions, hideEditingBtns } = props;
    const { minPrice, priceFactor } = regionPriceConfig || {};

    return <div> <table className="ui collapsed table">
        <thead>
            <tr>
                <th className="main-config-header" style={{ display: 'flex' }}>
                    <span className="ui header main-config-header">
                        Prisfaktorer
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr style={{ boxShadow: 'none !important' }}>
                <td style={{ padding: '24px' }}>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Prisfaktor:</div>
                    <span data-testid='test-region-conf-price-factor' >
                        {showIntWith0Decimal(priceFactor) || priceFactor || '-'}
                    </span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Minstepris:</div>
                    <span data-testid='test-region-conf-min-price'>NOK
                        {(minPrice || minPrice === 0 ? ' ' + minPrice : ' -')}</span>

                </td>
            </tr>

        </tbody>

        {
            hideEditingBtns ? null : <tfoot style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }} >
                <tr style={{ boxShadow: 'none !important' }}><td>
                    <div className="ui icon buttons">

                        <AppButton
                            size='mini'
                            dataTestId="edit-main-region-price-conf-btn"
                            onClick={e => actions.edit(null, RegionPriceConfigModalType.regionPriceFactors)}>
                            <><EditIcon />
                            </>
                        </AppButton>

                    </div>
                </td></tr>
            </tfoot>
        }

    </table></div>

}