import { AppButton } from "../../../../components"
import { EditIcon } from "../../../../components/Icon"
import { showIntWith0Decimal } from "../../../../utils/commonHelpers";
import { DrivingAreaPriceConfig, HideEditingBtns, ServiceChargeType, getServiceChargeType } from "../../types";
import { DrivingAreaPriceConfActionsProps, DrivingAreaPriceConfigModalType } from "../types";


interface Props extends HideEditingBtns {
    priceConfig: DrivingAreaPriceConfig
}

export const MainDrivingAreaPriceConfigInfo = (props: Props & DrivingAreaPriceConfActionsProps) => {

    const { priceConfig, actions, hideEditingBtns } = props;
    const { minPrice, priceFactor, serviceChargeVatRate, serviceCharge, serviceChargeTypeId } = priceConfig || {};

    return <div> <table className="ui collapsed table">
        <thead>
            <tr>
                <th className="main-config-header" style={{ display: 'flex' }}>
                    <span className="ui header main-config-header">
                        Prisfaktorer
                    </span>
                </th>
            </tr>
        </thead>

        <tbody>
            <tr style={{ boxShadow: 'none !important' }}>
                <td style={{ padding: '24px' }}>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Prisfaktor:</div>
                    <span data-testid='test-driving-area-conf-price-factor' >
                        { showIntWith0Decimal(priceFactor) || priceFactor || '-'}
                    </span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Minstepris:</div>
                    <span data-testid='test-driving-area-conf-min-price'>NOK
                        {(minPrice || minPrice === 0 ? ' ' + minPrice : ' -')}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Servicetillegg:
                    </div>
                    <span data-testid='test-driving-area-conf-service-charge'>
                    { showIntWith0Decimal(serviceCharge, 1) || serviceCharge || '-'} 
                    {
                        serviceChargeVatRate && serviceChargeTypeId ? ' (' + (getServiceChargeType(serviceChargeTypeId) || {}).text + ')' : ''
                    }
                    </span>
                    
                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        MVA til servicetillegg:</div>
                        <span data-testid='test-driving-area-conf-service-charge-vat'>
                        { showIntWith0Decimal(serviceChargeVatRate) || serviceChargeVatRate || '-'}
                    
                        </span>

                </td>
            </tr>

        </tbody>

        {
            hideEditingBtns ? null :
                <tfoot style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }} >
                    <tr style={{ boxShadow: 'none !important' }}><td>
                        <div className="ui icon buttons">

                            <AppButton
                                size='mini'
                                dataTestId="edit-main-driving-area-price-conf-btn"
                                onClick={e => actions.edit(null, DrivingAreaPriceConfigModalType.drivingAreaPriceFactors)}>
                                <><EditIcon />
                                </>
                            </AppButton>

                        </div>
                    </td></tr>
                </tfoot>
        }

    </table></div>

}