import { AppButton } from "../../../../components"
import { EditIcon } from "../../../../components/Icon"
import { CountryPriceConfig, HideEditingBtns } from "../../types"
import { CountryPriceConfActionsProps, ModalType } from "../types";

interface Props extends HideEditingBtns {
    countryPriceConfig: CountryPriceConfig
}

export const MainConfigInfo = (props: Props & CountryPriceConfActionsProps) => {

    const { countryPriceConfig, actions, hideEditingBtns  } = props;
    const { pricePerKm, startPrice, minPrice, preOrderAddition, waypointAddition, vatRate,
        lateCancelMaxPrice, noShowMaxPrice, startHolidayExtraHours, endHolidayExtraHours, 
        cancellationGraceTime, maximumNumberOfActiveBookingsPerCustomer, maximumNumberOfPreOrderBookingsPerCustomer} = countryPriceConfig || {};

    return <div> <table className="ui collapsed table">
        <thead>
            <tr>
                <th className="main-config-header" style={{ display: 'flex' }}>
                    <span className="ui header main-config-header">
                        Prisfaktorer
                    </span>
                </th>
            </tr>
        </thead>

        <tbody>
            <tr style={{ boxShadow: 'none !important' }}>
                <td style={{ padding: '24px' }}>
                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }}>
                        Startpris:</div>
                    <span data-testid='test-country-conf-startprice'>NOK {startPrice || '0.00'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Pris per km:</div>
                    <span data-testid='test-country-conf-pricePerKm'>NOK {pricePerKm || '0.00'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Minstepris:</div>
                    <span data-testid='test-country-conf-min-price'>NOK {minPrice || '0.00'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Waypoint tillegg:</div>
                    <span data-testid='test-country-conf-waypoint-addition' >NOK (per stopp) {waypointAddition || '0.00'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Forhåndsbestilling:</div>
                    <span data-testid='test-country-conf-preorder-addition'>NOK {preOrderAddition || '0.00'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Start helligdagperiode:</div>
                    <span data-testid='test-country-conf-start-holiday-period'>Timer  {startHolidayExtraHours || '0'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Slutt helligdagperiode:</div>
                    <span data-testid='test-country-conf-end-holiday-period'>Timer  {endHolidayExtraHours || '0'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        MVA-sats:</div>
                    <span data-testid='test-country-conf-mva'> {vatRate || '0.00'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        LateCancel maks gebyr:</div>
                    <span data-testid='test-country-conf-late-cancel-max-price'>NOK {lateCancelMaxPrice || '0.00'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        NoShow maks gebyr:</div>
                    <span data-testid='test-country-conf-no-show-max-price'>NOK {noShowMaxPrice || '0.00'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Cancelation grace time (sek.):</div>
                    <span data-testid='test-country-conf-cancel-grace-time'>Sekunder {cancellationGraceTime || '0'}</span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Maks. antall aktive bestillinger (per kunde):</div>
                    <span data-testid='test-driving-area-conf-maximumNumberOfActiveBookingsPerCustomer' >
                        { maximumNumberOfActiveBookingsPerCustomer || '-'}
                    </span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Maks. antall forhåndsbestillinger (per kunde):</div>
                    <span data-testid='test-driving-area-conf-maximumNumberOfPreOrderBookingsPerCustomer'>
                        { maximumNumberOfPreOrderBookingsPerCustomer || '-'}</span>
                    
                </td>
            </tr>

        </tbody>

        {
            hideEditingBtns ? null 
                :
                <tfoot style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }} >
                    <tr style={{ boxShadow: 'none !important' }}><td>
                        <div className="ui icon buttons">

                            <AppButton
                                size='mini'
                                dataTestId="edit-main-country-price-conf-btn"
                                onClick={e => actions.edit(null, ModalType.countryPriceFactors)}>
                                <><EditIcon />
                                </>
                            </AppButton>

                        </div>
                    </td></tr>
                </tfoot>

        }

    </table></div>

}