import { useEffect } from "react";
import { useParams } from "react-router";
import { RegionUrlParam } from "../config/routes";

export function useRegionIdAssigner(set_current_region: (regionId:number)=> void) {

    let { fromUrlRegionId: fromUrlRegionIdStr } = useParams<RegionUrlParam>();

    let fromUrlRegionId = 0;

    try {
        fromUrlRegionId = fromUrlRegionIdStr? parseInt(fromUrlRegionIdStr): 0;
     }
     catch (e) {
        console.log("Could not parseInt(fromUrlRegionIdStr)");
     }

   
    useEffect(() => {

        if (fromUrlRegionId){
            set_current_region(fromUrlRegionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return fromUrlRegionId;
  }