import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { DrivingAreaPriceConfig, DrivingAreaSpecialLocation, getEmptyDayTimeZones, getEmptyDayTimeZonesForPreOrderDate, getEmptyDrivingAreaPriceConfig, getEmptyPreOrderWeekDayTimeZones, getEmptyWaypointMainInfo, PriceConfigDayTimeZone } from '../../types';
import {  DrivingAreaPriceConfigServerData, DrivingAreaPriceConfigState, PreOrderConfig } from '../types';

const initialState: DrivingAreaPriceConfigState = {
    priceConfig: null,
    dayTimeZones: [],
    specialLocations: [],
    preOrderConfig: { 
        preOrderWeeklyTimes: [],
        preOrderDateTimes: [],
        preOrderDateAllowed: [],
        preOrderDateTimesBeforeNow: [],
        minimumTimeUntilPickupInMinutes: null,
        maximumTimeUntilPickupInMinutes: null,
    },
    loading: false,
    publishing: false,
    saving: false,
    isEdited: false,
    comment: null,
    resettingDraft: false,
};

const daPriceConfigSlice = createSlice({
    name: 'drivingAreaPriceConfig',
    initialState,
    reducers: {
        priceConfigLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setPriceConfig(state, action: PayloadAction<{ priceConfig: DrivingAreaPriceConfigServerData, regionId: number, drivingAreaId: number }>) {
            const data = action.payload ? action.payload.priceConfig : null;

            const drivingAreaId = action.payload ? action.payload.drivingAreaId : 0;
            const regionId = action.payload ? action.payload.regionId : 0;

            state.preOrderConfig = data && data.preOrderConfig? data.preOrderConfig : state.preOrderConfig;
            state.preOrderConfig.preOrderWeeklyTimes = data && data.preOrderConfig &&  data.preOrderConfig.preOrderWeeklyTimes && data.preOrderConfig.preOrderWeeklyTimes.length > 0 ? data.preOrderConfig.preOrderWeeklyTimes : getEmptyPreOrderWeekDayTimeZones();
            state.preOrderConfig.preOrderDateTimes = data && data.preOrderConfig && data.preOrderConfig.preOrderDateTimes && data.preOrderConfig.preOrderDateTimes.length > 0 ? data.preOrderConfig.preOrderDateTimes : [];
            state.preOrderConfig.preOrderDateAllowed = data && data.preOrderConfig && data.preOrderConfig.preOrderDateAllowed && data.preOrderConfig.preOrderDateAllowed.length > 0 ? data.preOrderConfig.preOrderDateAllowed : [];
            state.preOrderConfig.preOrderDateTimesBeforeNow = data && data.preOrderConfig && data.preOrderConfig.preOrderDateTimesBeforeNow && data.preOrderConfig.preOrderDateTimesBeforeNow.length > 0 ? data.preOrderConfig.preOrderDateTimesBeforeNow : [];

            state.dayTimeZones = data && data.dayTimeZones && data.dayTimeZones.length > 0 ? data.dayTimeZones : getEmptyDayTimeZones();
            state.priceConfig = data && data.drivingAreaPriceConfig ? data.drivingAreaPriceConfig : getEmptyDrivingAreaPriceConfig(regionId, drivingAreaId);
            state.specialLocations = data && data.specialLocations && data.specialLocations.length > 0 ? data.specialLocations : [];
            state.comment = data && data.drivingAreaPriceConfig && data.drivingAreaPriceConfig.isDraft ? data.drivingAreaPriceConfig.draftComment : null

            state.loading = false;
            state.isEdited = false;
            state.publishing = false;
            state.saving = false;
            state.resettingDraft = false;
        },
        updatePriceConfig(state, action: PayloadAction<DrivingAreaPriceConfig>) {

            const priceConfig = action.payload || null;

            if (priceConfig) {
                state.priceConfig = priceConfig;
                state.isEdited = true;
            }

        },
        addOrUpdateDayTimeZone(state, action: PayloadAction<{ dayTimeZone: PriceConfigDayTimeZone, index: number | null | undefined }>) {
            const { dayTimeZone, index } = action.payload;

            if (!state.dayTimeZones) {
                state.dayTimeZones = [];
            }

            //update
            if (dayTimeZone && (index || index === 0)) {
                state.dayTimeZones[index] = dayTimeZone;
                state.isEdited = true;
            }
            // add
            else if (dayTimeZone) {
                state.dayTimeZones.push(dayTimeZone);
                state.isEdited = true;
            }
        },
        addOrUpdateSpecialLocation(state, action: PayloadAction<{ specialLocation: DrivingAreaSpecialLocation, index: number | null | undefined }>) {
            const { specialLocation, index } = action.payload;

            if (!state.specialLocations) {
                state.specialLocations = [];
            }

            //update
            if (specialLocation && (index || index === 0)) {
                state.specialLocations[index] = specialLocation;
                state.isEdited = true;
            }
            // add
            else if (specialLocation) {
                state.specialLocations.push(specialLocation);
                state.isEdited = true;
            }
        },
        deleteSpecialLocation(state, action: PayloadAction<number>) {
            const index = action.payload;
            if (index || index === 0) {
                if (state.specialLocations && state.specialLocations.length > 0) {
                    state.specialLocations.splice(index, 1);
                    state.isEdited = true;
                }
            }

        },
        updatePreOrderMainInfo(state, action: PayloadAction<PreOrderConfig>) {

            const preOrderConfig = action.payload || null;

            if (preOrderConfig) {
                state.preOrderConfig.minimumTimeUntilPickupInMinutes = preOrderConfig.minimumTimeUntilPickupInMinutes || null;
                state.preOrderConfig.maximumTimeUntilPickupInMinutes = preOrderConfig.maximumTimeUntilPickupInMinutes || null;
                state.isEdited = true;
            }

        },
        updatePreOrderWeeklyTimesAllowed(state, action: PayloadAction<{ maxNumberOfPreOrdersPerAllCustomers: number, id: number | null | undefined }>) {
            const { maxNumberOfPreOrdersPerAllCustomers, id } = action.payload;

            const preOrderWeeklyTimes = ((state || {}).preOrderConfig || {}).preOrderWeeklyTimes || [];

            if (!preOrderWeeklyTimes || preOrderWeeklyTimes.length <= 0){
                return;
            }


            if (id && id > 0){
               
                //update
                const index = preOrderWeeklyTimes.findIndex(x=>x.drivingAreaPreOrderWeeklyTimesAllowedId == id)

                if (index < 0){
                    return;
                }

                state.preOrderConfig.preOrderWeeklyTimes[index].maxNumberOfPreOrdersPerAllCustomers = maxNumberOfPreOrdersPerAllCustomers;
                state.isEdited = true;
            }
            
        },
        addPreOrderDate(state, action: PayloadAction<{date:string}>){
            const { date } = action.payload;
            if (!date){
                return;
            }
            
            const preOrderDateAllowed = ((state || {}).preOrderConfig || {}).preOrderDateAllowed || [];


            const dateFound = preOrderDateAllowed.find(x=>x === date);
            if (dateFound){
                return;
            }

            preOrderDateAllowed.push(date);
            const newArr = getEmptyDayTimeZonesForPreOrderDate(date);
            state.preOrderConfig.preOrderDateTimes = state.preOrderConfig.preOrderDateTimes.concat(newArr);
            state.isEdited = true;

        },
        updatePreOrderDateTime(state, action: PayloadAction<{ maxNumberOfPreOrdersPerAllCustomers: number, index: number | null | undefined }>) {
            const { maxNumberOfPreOrdersPerAllCustomers, index } = action.payload;

            const preOrderDateTimes = ((state || {}).preOrderConfig || {}).preOrderDateTimes;

            if (!preOrderDateTimes) {
                state.preOrderConfig.preOrderDateTimes = [];
            }

            //update
            if (index || index === 0) {
                state.preOrderConfig.preOrderDateTimes[index].maxNumberOfPreOrdersPerAllCustomers = maxNumberOfPreOrdersPerAllCustomers;
                state.isEdited = true;
            }

        },
        deletePreOrderDateTime(state, action: PayloadAction<number>) {
            const index = action.payload;

            if (!((state || {}).preOrderConfig || {}).preOrderDateAllowed || ((state || {}).preOrderConfig || {}).preOrderDateAllowed.length <= 0) {
                return;
            }

            if (!((state || {}).preOrderConfig || {}).preOrderDateTimes || ((state || {}).preOrderConfig || {}).preOrderDateTimes.length <= 0){
                return;
            }

            if (index || index === 0) {

                const itemToDelete = state.preOrderConfig.preOrderDateAllowed[index];
                if (!itemToDelete){
                    return;
                }

                state.preOrderConfig.preOrderDateAllowed.splice(index, 1);
                state.isEdited = true;

                const preOrderDateTimesAllowedUpdated = state.preOrderConfig.preOrderDateTimes.filter(x=>x.date != itemToDelete);
                state.preOrderConfig.preOrderDateTimes = preOrderDateTimesAllowedUpdated;
            }

        },
        changePublishingState(state, action: PayloadAction<boolean>) {
            state.publishing = action.payload;
        },
        changeSavingState(state, action: PayloadAction<boolean>) {
            state.saving = action.payload;
        },
        updateComment(state, action: PayloadAction<string | null>) {
            state.comment = action.payload;
            state.isEdited = true;
        },
        resetIsEdited(state) {
            state.isEdited = false;
        },
        resettingDraft(state, action: PayloadAction<boolean>) {
            state.resettingDraft = action.payload;
        },
        resetDrivingAreaPriceConfigState(state) {
            return initialState;
        }
    },
})

export const {
    priceConfigLoading,
    setPriceConfig,
    updatePriceConfig,
    updatePreOrderMainInfo,
    addOrUpdateDayTimeZone,
    addOrUpdateSpecialLocation,
    changePublishingState,
    changeSavingState,
    updateComment,
    resettingDraft,
    deleteSpecialLocation,
    resetDrivingAreaPriceConfigState,
    addPreOrderDate,
    updatePreOrderWeeklyTimesAllowed,
    updatePreOrderDateTime,
    deletePreOrderDateTime,
    resetIsEdited

} = daPriceConfigSlice.actions;

export default daPriceConfigSlice.reducer;

export const selectDateTimeZoneConfig = (state: RootState, index: number): PriceConfigDayTimeZone | null => {
    const conf = state.drivingAreaPriceConfig;

    if (index || index === 0) {

        if (conf && conf.dayTimeZones && conf.dayTimeZones.length > 0) {
            const current = conf.dayTimeZones[index] || null;
            return current;
        }
    }

    return null;
}


export const selectSpecialLocationByIndex = (state: RootState, index: number): DrivingAreaSpecialLocation | null => {
    const conf = state.drivingAreaPriceConfig;

    if (index || index === 0) {

        if (conf && conf.specialLocations && conf.specialLocations.length > 0) {
            const current = conf.specialLocations[index] || null;
            return current;
        }
    }

    return null;
}





