import React from 'react';
import { AppTabs } from '../../../../components';
import { MapIcon, PriceIcon } from '../../../../components/Icon';
import { isMobile } from '../../../../utils';
import { CountryPriceConfActionsProps } from '../types';
import  RegionsWithPriceFactors from './RegionsWithPriceFactors';
import MainConfig from './MainConfig';
import { AppTabProp } from '../../../../components/AppTabs';
import { HideEditingBtns } from '../../types';


export const CountryPriceConfigTabs: React.FC<CountryPriceConfActionsProps  & HideEditingBtns> = (props) => {
    props = props || {};
    const { actions, hideEditingBtns } = props;

    let screenIsMobile = isMobile();

    let mainText = !screenIsMobile ? 'Prisfaktorer' : '';
    let regionsText = !screenIsMobile ? 'Regioner' : '';

    const panes: Array<AppTabProp> = [
        {
            menuItemText: mainText,
            menuItemIcon: <PriceIcon />,
            testId: 'country-price-config-tab-test',
            tabPaneContent: <MainConfig actions={actions} hideEditingBtns={hideEditingBtns} />
        }
    ]

    if (!hideEditingBtns){
        panes.push({
            testId: 'region-price-config-tab-test',
            menuItemText: regionsText,
            menuItemIcon: <MapIcon />,
            tabPaneContent: <RegionsWithPriceFactors
                actions={actions}
            />
        });
    }

    return <><AppTabs panes={panes} /></>
};
