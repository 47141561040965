import React from "react";
import { useEffect } from "react";
import { Modal } from "semantic-ui-react";
import { AppButton } from ".";
import '../styles/appModal.css'

interface Props {
  triggerComponent?: any,
  header?: string | JSX.Element,
  scrolling?: boolean,
  onOpen?: any,
  onClose?: any,
  children: JSX.Element,
  isOpen?: boolean,
  dimmer?: 'inverted' | 'blurring',
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'
  btnText?: string,
  closeOnDimmerClick?: boolean
}

export const AppModal: React.FC<Props> = (props) => {

  const {
    triggerComponent,
    isOpen,
    header,
    scrolling = false,
    onOpen,
    children,
    onClose,
    dimmer = undefined,
    size = 'tiny',
    btnText,
    closeOnDimmerClick = true
  } = props;

  const [open, setOpen] = React.useState<boolean>(true)

  useEffect(() => {
    if (isOpen !== undefined && (open !== isOpen)) {
      setOpen(isOpen);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const closeModal = () => {

    if (typeof onClose === 'function') {
      onClose();
    }
    else {

      setOpen(false);
    }

  }

  return (
    <Modal
      size={size}
      onClose={() => closeModal()}
      open={open}
      closeIcon
      dimmer={dimmer}
      onOpen={onOpen}
      trigger={triggerComponent}
      closeOnDimmerClick={closeOnDimmerClick}
    >

      {header ? (<Modal.Header>{header}</Modal.Header>) : ''}

      <Modal.Content scrolling={scrolling}>
        {children}
      </Modal.Content>

      {
        btnText ? (<Modal.Actions>
          <AppButton onClick={() => closeModal()}>{btnText}</AppButton>
        </Modal.Actions>) : ''
      }

    </Modal>
  );
};

