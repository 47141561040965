import { AuthApi } from '../../../api/auth.service';
import { jwtHelper } from '../../../utils/jwtHelper';
import { login, loginSuccess, loginFailed, logout } from "../reducers/authReducers";
import { AppUser, ForgotPasswordRequestData, LoginRequestData } from "../types";
import {history} from '../../../config/historyConfig';
import { ActionStatus } from '../../../config/constants';
import { setCurrentRegion } from '../../Regions/reducers/regionsReducers';


export const userLogin = (args: LoginRequestData) => {
    return async (dispatch: any) => {

        dispatch(login());

        try {
            
            const result = await AuthApi.login(args);


            if (!result) {
                return null;
            }

            const data = result.data as AppUser;
            dispatch(loginSuccess(data));

            if (data.token){
                jwtHelper.setJwt(data.token);
            }         

            history.push('/');
            
            return result;
        }
        catch (error) {
            dispatch(loginFailed());
            return new Error(error);
        }
    }
};

export const userForgotPassword = async (args: ForgotPasswordRequestData, setSendingStatus: (status:ActionStatus)=>void) => {
  
    setSendingStatus(ActionStatus.PROCESS);

    try {
        
        await AuthApi.forgotPassword(args);
        setSendingStatus(ActionStatus.SUCCESS);
        return;
    }
    catch (error) {
        setSendingStatus(ActionStatus.FAIL);
        return new Error(error);
    }
    
};

export const getCurrentUser = () => {
    return async (dispatch: any) => {

        dispatch(login());

        try {
            
            const result = await AuthApi.getCurrentUser();

            if (!result) {
                return null;
            }

            const data = result.data as AppUser;
            
            const currentUserRegionId = data.regionId? data.regionId: null;

            if (currentUserRegionId !== null){
                dispatch(setCurrentRegion(currentUserRegionId));
            }

            dispatch(loginSuccess(data));

            return result;
        }
        catch (error) {
            dispatch(loginFailed());
            return new Error(error);
        }
    }
};

export const userLogout = () => {
    return (dispatch: any) => {

        dispatch(logout());
        jwtHelper.removeJwt();
        window.location.href = '/login';

        return;
    }
};