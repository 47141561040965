import { ApiResponse } from '../commonTypes';
import { BaseService } from './base.service';

class CountryService extends BaseService {
  private static _authService: CountryService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): CountryService {
    return this._authService || (this._authService = new this('country'));
  }

  public async getCountries(): Promise<ApiResponse | { data: any }> {

    let axiosResponse = null;

    try {
      axiosResponse = await this.$http.get('getCountries');
      //axiosResponse = { data: countriesMock, status: 200, statusText: '', headers: {}, config: {} }; //one country
      return { response: axiosResponse, data: axiosResponse.data };
    }
    catch (error: any) {
      this.handleAllErrors(error);
      throw error;
    }
  }

}

export const CountryApi = CountryService.Instance;
