import { CountryApi } from "../../../api/country.service";
import { countriesLoaded, countriesLoading } from "../reducers/countriesReducer";
import { Country } from "../types";


export const getCountries = () => {

    return async (dispatch: any) => {

        dispatch(countriesLoading(true));

        try {
            const result = await CountryApi.getCountries();
            const countries: Array<Country> = result ? result.data : {};
    
                dispatch(countriesLoaded(countries));        

            return countries;
        }
        catch (error) {
            dispatch(countriesLoading(false));
            return error;
        }
    }
};
