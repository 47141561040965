export interface Region {
    readonly regionId: number,
    name: string,
    readonly countryId: number
}

export interface RegionsState {
    list: Array<Region>,
    loading: boolean,
    currentItemId: number | null
}

export const getRegionId = (item: Region): number | null => item && item.regionId? item.regionId: null;
export const getRegionName = (item: Region): string | null => item && item.name? item.name: null;