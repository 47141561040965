import { DAY_TYPES } from "../../commonTypes";
import { Region } from "../Regions/types";
import * as yup from 'yup';
import { DrivingArea } from "../DrivingAreas/types";
import { DrivingAreaPreOrderDateTimesAllowed, DrivingAreaPreOrderWeeklyTimesAllowed, PreOrderConfig } from "./DrvingAreaPriceConfig/types";


/* ENTITIES */

export interface PriceConfigVersionInfo {
    isDraft: boolean,
    draftLastModifiedDate: string | null,
    publishedDate: string | null,
    draftModifiedByName: string | null,
    draftModifiedById: number | null,
    publishedByName: string | null,
    publishModifiedById: number | null,
    publishedComment: string | null,
    draftComment: string | null,
}

export interface CountryPriceConfig extends PriceConfigVersionInfo {
    countryPriceConfigId:number | null,
    countryId: number,
    startPrice: number,
    pricePerKm: number,
    startHolidayExtraHours:number,
    endHolidayExtraHours:number,
    minPrice: number,
    waypointAddition: number,
    preOrderAddition: number,
    vatRate: number,
    lateCancelMaxPrice: number,
    noShowMaxPrice: number,
    cancellationGraceTime?:number,
    maximumNumberOfActiveBookingsPerCustomer: number | null,
    maximumNumberOfPreOrderBookingsPerCustomer:number | null,
}

export interface PriceConfigDayTimeZone {
    readonly priceConfigDayTimeZoneId?: number,
    fromTime: string | null,
    toTime: string | null,
    readonly priceConfigId?: number | null,
    priceFactor: number
}

export interface CountryPriceConfigDayTimeZone extends PriceConfigDayTimeZone {}

export interface CountryPriceConfigDayTypeFactor {
    countryPriceConfigId: number,
    dayTypeId: number,
    priceFactor: number
}


export interface CarType {
    readonly carTypeId: number | null,
    readonly name: string,
    readonly numberOfSeats: number,
    readonly extraPassengersFrom: number
    readonly countryId?: number | null
}

export interface CountryPriceConfigCarType extends CarType {
    readonly priceFactor: number
    readonly countryPriceConfigId: number
}

export interface RegionPriceConfig extends Region, PriceConfigVersionInfo {
    regionPriceConfigId: number | null,
    regionId: number,
    priceFactor: number | null,
    minPrice:number | null
}

export interface DrivingAreaPriceConfig extends DrivingArea, PriceConfigVersionInfo {
    drivingAreaPriceConfigId: number | null,
    drivingAreaId: number,
    priceFactor: number | null,
    minPrice:number | null,
    drivingAreaName: string,
    serviceChargeVatRate?: number | null,
    serviceCharge?: number | null
    serviceChargeTypeId?: number | null
}

export enum ServiceChargeType {
    Percent = 1,
    Amount = 2,
}

export const getServiceChargeType = (amountType: ServiceChargeType): {text: string | ''} => {

    switch (amountType) {
        case ServiceChargeType.Amount:
          return {text: "Beløp"}
        case ServiceChargeType.Percent:
            return {text: "Prosent"}
        default:
          return {text: ''}
      }
}

export interface DrivingAreaPriceConfigDayTimeZone extends PriceConfigDayTimeZone {}

export interface DrivingAreaSpecialLocation {
    specialLocationId: number | null, // drivingAreaSpecialLocationId
    priceConfigId: number | null, // drivingAreaPriceConfigId
    name: string | null,
    latitude: number | null,
    longitude: number | null,
    radiusMeters: number | null,
    fromPriceFactor: number | null,
    toPriceFactor: number | null
}

export interface DrivingAreaPoint {
    drivingAreaPointId: number | null,
    drivingAreaId: number | null,
    latitude: number | null,
    longitude: number | null
}

export interface HideEditingBtns {
    hideEditingBtns: boolean
}

/** get empty  */

export const getEmptyCountryPriceConfig = (countryId: number): CountryPriceConfig => {
    return {
        countryPriceConfigId: null,
        countryId: countryId,
        startPrice: 0,
        pricePerKm: 0,
        minPrice: 0,
        waypointAddition: 0,
        preOrderAddition: 0,
        isDraft: true,
        draftLastModifiedDate: null,
        publishedDate: null,
        draftModifiedByName: null,
        draftModifiedById: null,
        publishedByName: null,
        publishModifiedById: null,
        publishedComment: null,
        draftComment: null,
        vatRate: 0.12,
        noShowMaxPrice: 0,
        lateCancelMaxPrice: 0,
        startHolidayExtraHours: 3,
        endHolidayExtraHours: 6,
        cancellationGraceTime:0,
        maximumNumberOfActiveBookingsPerCustomer: null,
        maximumNumberOfPreOrderBookingsPerCustomer: null
    }
}

export const getEmptyDayTimeZone = (): PriceConfigDayTimeZone => {
    return {
        fromTime: null,
        toTime: null,
        priceFactor: 0.0
    }
}
export const getEmptyDayTimeZones = (): Array<PriceConfigDayTimeZone> => {

    let result: Array<CountryPriceConfigDayTimeZone> = [];

    DAY_TIME_ZONES.forEach(x=>{

        const item : CountryPriceConfigDayTimeZone = {
            fromTime: x.fromTime, 
            toTime: x.toTime,
            priceConfigId: null,
            priceFactor: 1.0
         };
        result.push(item);
    })
    return result;
}

export const getEmptyPreOrderWeekDayTimeZones = (): Array<DrivingAreaPreOrderWeeklyTimesAllowed> => {

    let result: Array<DrivingAreaPreOrderWeeklyTimesAllowed> = [];

    for(let i = 1; i <= DAY_TYPES.Sunday; ++i) {

        let count = 1;
        DAY_TIME_ZONES.forEach(x=> {
            const temp : DrivingAreaPreOrderWeeklyTimesAllowed = {
                drivingAreaPreOrderWeeklyTimesAllowedId: parseInt(i + '' + count),
                divingAreaPriceConfigId: 0,
                startTime: x.fromTime,
                endTime: x.toTime,
                dayTypeId: i,
                maxNumberOfPreOrdersPerAllCustomers: 0
            };

            result.push(temp);
            count++;
     
        })
    }
   
    return result;
}

export const getEmptyDayTimeZonesForPreOrderDate = (date:string): Array<DrivingAreaPreOrderDateTimesAllowed> => {

    let result: Array<DrivingAreaPreOrderDateTimesAllowed> = [];

    DAY_TIME_ZONES.forEach(x=>{

        const item : DrivingAreaPreOrderDateTimesAllowed = {
            divingAreaPriceConfigId: 0,
            startTime: x.fromTime,
            endTime: x.toTime,
            date: date,
            maxNumberOfPreOrdersPerAllCustomers: 0
        };
        result.push(item);
    })
    return result;
}

export const getEmptyDayTypes = (): Array<CountryPriceConfigDayTypeFactor> => {

    let result: Array<CountryPriceConfigDayTypeFactor> = [];

    for(let i = 1; i <= DAY_TYPES.Holiday; ++i) {
       const temp : CountryPriceConfigDayTypeFactor = {
        priceFactor: 1.0,
        countryPriceConfigId: 0,
        dayTypeId: i
       };

       result.push(temp);
    }

    return result;
}

export const getEmptyCountryPriceConfigCarType = (): CountryPriceConfigCarType => {
    return {
        carTypeId: null,
        name: '',
        numberOfSeats: 0,
        extraPassengersFrom: 0,
        countryId: null,
        priceFactor: 0.0,
        countryPriceConfigId: 0
    }
}

export const getEmptyRegionPriceConfig = (countryId:number, regionId:number): RegionPriceConfig=> {

    return {
        regionPriceConfigId: null,
        regionId: regionId,
        priceFactor: null,
        countryId: countryId,
        minPrice: null,
        isDraft: true,
        name: '',
        draftLastModifiedDate: null,
        publishedDate: null,
        draftModifiedByName: null,
        draftModifiedById: null,
        publishedByName: null,
        publishModifiedById: null,
        publishedComment: null,
        draftComment: null,
    }
}

export const getEmptyDrivingAreaPriceConfig = (regionId: number ,drivingAreaId:number): DrivingAreaPriceConfig=> {
    return {
        drivingAreaPriceConfigId: null,
        drivingAreaId: drivingAreaId,
        regionId: regionId,
        name: '',
        drivingAreaName: '',
        minPrice: null,
        priceFactor: null,
        isDraft: true,
        draftLastModifiedDate: null,
        publishedDate: null,
        draftModifiedByName: null,
        draftModifiedById: null,
        publishedByName: null,
        publishModifiedById: null,
        publishedComment: null,
        draftComment: null,
    }

}

export const getEmptyWaypointMainInfo = (): PreOrderConfig=> {
    return {
        preOrderWeeklyTimes: getEmptyPreOrderWeekDayTimeZones(),
        preOrderDateTimes: [],
        preOrderDateAllowed: [],
        preOrderDateTimesBeforeNow: [],
        minimumTimeUntilPickupInMinutes: null,
        maximumTimeUntilPickupInMinutes: null,
    }

}

export const getEmptySpecialLocation = (priceConfigId: number): DrivingAreaSpecialLocation => {
    return {
        specialLocationId: null,
        priceConfigId: null,
        name: null,
        latitude: null,
        longitude: null,
        radiusMeters: null,
        fromPriceFactor: null,
        toPriceFactor: null
    }
}

/** common for all price configs, need to be send with config data */
export interface PriceConfigCommonData {
    isEdited: boolean,
    isSave: boolean, // save or publish
    comment: string | null,
    editorId: number
}

/** enums */

const dayTimeZonesTemp = [
    {fromTime: '00:00:00', toTime: '01:00:00'},
    {fromTime: '01:00:00', toTime: '02:00:00'},
    {fromTime: '02:00:00', toTime: '03:00:00'},
    {fromTime: '03:00:00', toTime: '04:00:00'},
    {fromTime: '04:00:00', toTime: '05:00:00'},
    {fromTime: '05:00:00', toTime: '06:00:00'},
    {fromTime: '06:00:00', toTime: '07:00:00'},
    {fromTime: '07:00:00', toTime: '08:00:00'},
    {fromTime: '08:00:00', toTime: '09:00:00'},
    {fromTime: '09:00:00', toTime: '10:00:00'},
    {fromTime: '10:00:00', toTime: '11:00:00'},
    {fromTime: '11:00:00', toTime: '12:00:00'},
    {fromTime: '12:00:00', toTime: '13:00:00'},
    {fromTime: '13:00:00', toTime: '14:00:00'},
    {fromTime: '14:00:00', toTime: '15:00:00'},
    {fromTime: '15:00:00', toTime: '16:00:00'},
    {fromTime: '16:00:00', toTime: '17:00:00'},
    {fromTime: '17:00:00', toTime: '18:00:00'},
    {fromTime: '18:00:00', toTime: '19:00:00'},
    {fromTime: '19:00:00', toTime: '20:00:00'},
    {fromTime: '20:00:00', toTime: '21:00:00'},
    {fromTime: '21:00:00', toTime: '22:00:00'},
    {fromTime: '22:00:00', toTime: '23:00:00'},
    {fromTime: '23:00:00', toTime: '00:00:00'}
];

export const DAY_TIME_ZONES = Object.freeze(dayTimeZonesTemp);


/** validation */
export const priceConfigCommonDataValidation = yup.object().shape({
    comment: yup.string().max(500, 'Kan ikke være mer enn 500 symboler.')
})
