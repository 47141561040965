import { Form, Formik } from 'formik';
import React from 'react';
import { ModalFormFooter, MyTextInput } from '../../../components';
import { AppButtonSubmit } from '../../../components/AppButton';
import { dateTimeHelper } from '../../../utils/dateTimeHelper';
import { formPrepareAndRoundNumber, getInputValue } from '../../../utils/formHelpers';
import { nameof } from '../../../utils/nameOfPropertyHelper';
import { dayTimeZoneFactorValidation } from '../CountryPriceConfig/types';
import { getEmptyDayTimeZone, PriceConfigDayTimeZone } from '../types';


export interface PropsFromReducer {
    update_date_type_zone: (dayTimeZone: PriceConfigDayTimeZone, index: number | null) => void
}

export interface DayTimeZoneEditorOwnProps {
    dayTimeZoneFactor: PriceConfigDayTimeZone | null,
    currentIndex: number | null,
    closeEditor: () => void
};

export type DayTimeZoneEditorProps = PropsFromReducer & DayTimeZoneEditorOwnProps;

export const DayTimeZoneEditor: React.FC<DayTimeZoneEditorProps> = (props) => {

    const { currentIndex, update_date_type_zone, closeEditor } = props;
    let dayTimeZoneFactor = props.dayTimeZoneFactor || getEmptyDayTimeZone();

    if (!dayTimeZoneFactor) {
        return <div>...</div>
    }

    const handleFormSubmit = async (values: any, setErrors: any) => {

        if (values && typeof update_date_type_zone === 'function') {
            update_date_type_zone(values, currentIndex);
            closeEditor();
        }

    }

    const marginButton = '10px';

    const fromTime = dateTimeHelper.removeSecondsFromTimeStr(dayTimeZoneFactor.fromTime);
    const toTime = dateTimeHelper.removeSecondsFromTimeStr(dayTimeZoneFactor.toTime);
    const text = `Prisfaktoren: ${fromTime} - ${toTime}.`;

    return (
        <Formik
            enableReinitialize={true}
            initialValues={dayTimeZoneFactor}
            validationSchema={dayTimeZoneFactorValidation}
            onSubmit={(values, { setErrors }) => { handleFormSubmit(values, setErrors) }}
        >
            {(formik) => (
                <Form className='ui form' onSubmit={formik.handleSubmit} autoComplete='off' data-testid='day-time-zone-factor-form'>
                    <div style={{ marginBottom: marginButton }}>

                        <MyTextInput
                            label={text}
                            data-testid={nameof<PriceConfigDayTimeZone>('priceFactor')}
                            name={nameof<PriceConfigDayTimeZone>('priceFactor')}
                            type="number"
                            step={0.05}
                            onChange={(e) => {
                                formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                    formik.setFieldValue(nameof<PriceConfigDayTimeZone>('priceFactor'), newValue)
                                },
                                2);

                            }}
                        />

                    </div>

                    <ModalFormFooter>
                        <AppButtonSubmit dataTestId="day-time-factor-form-submit" onClick={formik.handleSubmit}>Ferdig</AppButtonSubmit>
                    </ModalFormFooter>

                </Form>
            )}
        </Formik>
    )
};

