import React, { useState } from 'react';
import { DashboardIcon, GlobIcon, MapAlternateIcon, MapIcon, OffIcon, OrdersIcon, PeopleIcon, UserIcon, CalculatorIcon, TableIcon, DiscountIcon } from '../Icon';
import { history } from '../../config/historyConfig'
import { RoutesConfig } from '../../config/routes';
import { Logo } from './Logo';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isAdmin } from '../../features/Login/reducers/authReducers';
import { useLocation } from 'react-router-dom'
import { elastic as Menu } from 'react-burger-menu'
import '../../styles/burgerMenu.css'
import { userLogout } from '../../features/Login/actions/authActions';

interface Props {
    isAdmin: boolean,
    handleUserLogout: () => void
}

interface AppMenuProps {
    isAdmin: boolean,
    showUserMenu: boolean,
    isCurrentMenuItem: (path: string) => void,
    navigateToPage: (e: React.MouseEvent<HTMLAnchorElement>, path: string) => void,
    handleUserLogout?: () => void
}

const AppMenu: React.FC<Props> = (props) => {

    const location = useLocation();
    const currentPath = location ? location.pathname : '';

    const isCurrentMenuItem = (path: string) => {


        const currentPathToCompare = currentPath ? currentPath.split('/')[1] : null;

        return '/' + currentPathToCompare === path ? ' current-menu-item' : ''
    };

    const navigateToPage = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
        if (e) e.preventDefault();

        history.push(`${path}`);
    }

    return (<><div className="mobile-hidden">
        <ComputerAppMenu {...props} showUserMenu={false} isCurrentMenuItem={isCurrentMenuItem} navigateToPage={navigateToPage} />
    </div>
        <div className="mobile-only">
            <MobileAppMenu {...props} showUserMenu={true} isCurrentMenuItem={isCurrentMenuItem} navigateToPage={navigateToPage} />
        </div></>)
};

const ComputerAppMenu = (props: AppMenuProps) => {
    return <div><AppMenuList {...props} /></div>
}

const MobileAppMenu = (props: AppMenuProps) => {

    const { navigateToPage } = props;

    const [mobileMenuOpen, setMobileMenuStateOpen] = useState<boolean>(false);

    const navigateToPageHandler = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
        navigateToPage(e, path);
        setMobileMenuStateOpen(false);

    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    const handleBurgerMenuStateChanged = (state: any) => {
        setMobileMenuStateOpen(state.isOpen);
    }

    return <div className="mobile-app-menu">
        <Menu width={'100%'} isOpen={mobileMenuOpen} onStateChange={(state: any) => handleBurgerMenuStateChanged(state)} >
            <AppMenuList {...props} navigateToPage={navigateToPageHandler} />
        </Menu>
    </div>
}


const AppMenuList: React.FC<AppMenuProps> = (props) => {
    const { isCurrentMenuItem, navigateToPage, isAdmin, showUserMenu, handleUserLogout = () => { } } = props;

    return <div className="app-menu ui visible inverted left vertical sidebar menu fixed" style={{ paddingTop: '0vh', marginTop: '20px' }}>

        <Logo />

        <div className={"item"} >
            <div className="menu">
                <a href="/" className={"item" + isCurrentMenuItem(RoutesConfig.Home.path)} onClick={e => { navigateToPage(e, RoutesConfig.Home.path); }} >
                    <div>
                        <DashboardIcon /> Dashboard</div>
                </a>
                <a href="/" className={"item" + isCurrentMenuItem(RoutesConfig.Bookings.navigatePath)} onClick={e => { navigateToPage(e, RoutesConfig.Bookings.navigatePath); }} >
                    <div>
                        <OrdersIcon /> Bestillinger</div>
                </a>

                <a href={RoutesConfig.CountryPriceConfig.path}
                    className={"item" + isCurrentMenuItem(RoutesConfig.CountryPriceConfig.navigatePath())}
                    onClick={e => { navigateToPage(e, RoutesConfig.CountryPriceConfig.navigatePath()); }} >
                    <div>
                        <GlobIcon /> Land
                    </div>
                </a>

                <a href="/"
                    className={"item" + isCurrentMenuItem(RoutesConfig.RegionPriceConfig.navigatePath())}
                    onClick={e => { navigateToPage(e, RoutesConfig.RegionPriceConfig.navigatePath()); }} >
                    <div>
                        <MapIcon /> Regioner</div>
                </a>

                <a href="/"
                    className={"item" + isCurrentMenuItem(RoutesConfig.DrivingAreaPriceConfig.navigatePath())}
                    onClick={e => { navigateToPage(e, RoutesConfig.DrivingAreaPriceConfig.navigatePath()); }} >
                    <div>
                        <MapAlternateIcon /> Kjøreområder</div>
                </a>
                <a href="/"
                    className={"item" + isCurrentMenuItem(RoutesConfig.PriceConfigTest.navigatePath())}
                    onClick={e => { navigateToPage(e, RoutesConfig.PriceConfigTest.navigatePath()); }} >
                    <div>
                        <CalculatorIcon /> Pris test</div>
                </a>

                <a href="/"
                    className={"item" + isCurrentMenuItem(RoutesConfig.FinancialReport.navigatePath())}
                    onClick={e => { navigateToPage(e, RoutesConfig.FinancialReport.navigatePath()); }} >
                    <div>
                        <TableIcon /> Regnskapsrapport</div>
                </a>
                
                <a href="/"
                    className={"item" + isCurrentMenuItem(RoutesConfig.Discount.navigatePath())}
                    onClick={e => { navigateToPage(e, RoutesConfig.Discount.navigatePath()); }} >
                    <div>
                        <DiscountIcon /> Kampanje</div>
                </a>

                {
                    isAdmin ? (<a href="/" className={"item" + isCurrentMenuItem(RoutesConfig.Users.path)} onClick={e => { navigateToPage(e, RoutesConfig.Users.path); }} >
                        <div>
                            <PeopleIcon /> Brukere</div>
                    </a>) : ''
                }
            </div>


        </div>


        {showUserMenu ? (<div className={"item"}>
            <div className="header">
                Brukerprofil </div>
            <div className="menu">
                <a href="/" className={"item" + isCurrentMenuItem(RoutesConfig.UserProfile.path)} onClick={e => { navigateToPage(e, RoutesConfig.UserProfile.path); }} >
                    <div>
                        <UserIcon /> Brukerprofil</div>
                </a>
                <a href="/" className={"item"} onClick={e => { handleUserLogout(); }} >
                    <div>
                        <OffIcon /> Logg ut
                    </div>
                </a>
            </div>
        </div>) : ''}

    </div>
}


const mapDispatchToProps = (dispatch: any) => {
    return {
        handleUserLogout: () => { dispatch(userLogout()) },
    }
};

const mapStateToProps = (state: any) => {
    return {
        isAdmin: isAdmin(state),

    };
};


export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AppMenu)
);





