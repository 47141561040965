import { useField } from 'formik';
import { Form } from 'semantic-ui-react';
import InputMask from 'react-input-mask';


interface InputProps {
    className?:string,
    readonly?:boolean,
    placeholder?: string;
    name: string;
    type?: string;
    label?: string;
    hidden?: boolean,
    children?: JSX.Element,
    step?:number,
    disabled?:boolean,
    onChange?: (args:any)=>any,
    onEnter?: (args:any)=>any,
    value?: string | number | boolean | undefined,
    rows?: number | undefined,
    autoComplete?: "on" | "off" | "nope" | undefined
    
}

export interface ExtraProps extends InputProps {
    extraProps?: {
        fullWidth?: boolean | null,
        isTextarea?: boolean,
        withMask?:boolean
        mask?: any
    }
}

export type MyTextInputProps = InputProps & ExtraProps;

export const MyTextInput = (props: MyTextInputProps) => {
    const [field, meta] = useField(props.name); 
    let {isTextarea, fullWidth, withMask, mask}= props.extraProps || {};
    isTextarea = isTextarea? isTextarea: false;

    const rows = props.rows? props.rows: 4
    const autoComplete = props.autoComplete || "on";
    
    let fieldStyle = {};

    if (fullWidth){
        fieldStyle = {width: '100%'};
    }

    let inputProps  = {...props};
    if (inputProps.extraProps){
        delete inputProps.extraProps;
    }

    const onEnterClick = (e:any) => {

        if (e.keyCode === 13) {
            console.log('onEnter');
            console.log(e);

            e.stopPropagation();
            e.preventDefault();

            if (typeof props.onEnter == 'function'){
                props.onEnter(e);
            }
        }
    }


    let input = <input {...field} {...inputProps} autoComplete={autoComplete} />;

    if (withMask) {
        input = <InputMask mask={mask} {...field}  >
        {(inputProps: any) => <input {...inputProps} />}
        </InputMask>
    }
    
    const labelStyle = {
        opacity: props.disabled? '50%': '100%',
      };

    return (
        <Form.Field style={fieldStyle}  onKeyDown={onEnterClick} onChange={props.onChange? props.onChange: ()=>{}}  error={meta.touched && !!meta.error}>
            <label style={labelStyle}>{props.label}</label>
            {
                isTextarea? <textarea {...field} {...inputProps} rows={rows}  />:  input
            }
          
            {props.children? props.children: ''}
            {meta.touched && meta.error ? (
                 <div className="validation-error" style={{color:'red', textAlign:'left', marginTop:'2px'}}>{meta.error}</div>
               
            ) : null}
        </Form.Field>
    )
}






