


import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppButton, AreYouSureAppButton } from '../../../components';
import { AppUser } from '../../Login/types'
import { selectRegionById } from '../../Regions/reducers/regionsReducers';
import { Region } from '../../Regions/types';

interface Props {
    users?: Array<AppUser>,
    showRegion: boolean,
    setCurrentUser: (userId: any) => void,
    handleDeletUser: (userId: number) => void
}

export const UsersList: React.FC<Props> = (props) => {
    const { users = [], showRegion, setCurrentUser, handleDeletUser } = props;

    let usersResult = null;

    if (users && users.length > 0) {
        usersResult = users.map((el, index) => {
            return <UsersListElement showRegion={showRegion} key={index} user={el} setCurrentUser={setCurrentUser} handleDeletUser={handleDeletUser} />
        })
    }

    return (<table className="app-users ui very basic table fixed">
            <thead className="">
                <tr className="">
                    <th className="">Navn</th>
                    <th className="">E-post</th>
                    {showRegion?<th className="">Region</th>: <th></th>}
                    <th className="five wide"></th>
                    </tr>
            </thead>
            {usersResult && usersResult.length > 0 ?  <tbody className="">{usersResult}</tbody>: <tbody></tbody> }
    </table>)
}

interface UsersListElementProps {
    user: AppUser,
    region: Region | null,
    showRegion: boolean,
    setCurrentUser: (userId: any) => void,
    handleDeletUser: (userId: number) => void
}

const UsersListElementComponent: React.FC<UsersListElementProps> = (props) => {
    let { user, region, showRegion, setCurrentUser, handleDeletUser = () => { } } = props;

    user = user || {};

    const userRegion = region && region.name? region.name: 'Alle';

    return (<tr data-testid={'test-user-' +  user.userId} className="app-users-user">
            <td >{(user.firstName || '') + ' ' + (user.lastName || '')}
            </td>
            <td data-testid={'test-user-email' + user.userId}>{user.email}</td>

            {showRegion? <td data-testid={'test-user-region' + user.userId}>{userRegion}</td>: <td></td>}

            <td className="ui" style={{ textAlign: 'right'}}>
                <AppButton size='mini' designType='teal' onClick={(e)=> setCurrentUser(user.userId || 0)} >
                    Rediger
                </AppButton>
                <AreYouSureAppButton dataTestIdOk={'test-delete-user-ok-btn-' + user.userId} dataTestIdConfirm={'test-delete-user-confirm-btn-' + user.userId} size='mini' text={'Slett'} onClick={(e)=>{handleDeletUser(user.userId || 0)}} />

        </td>
    </tr>)
}


const mapStateToProps = (state: any, ownProps: any) => {

     const user: AppUser = ownProps.user;
     const regionId = user && user.regionId? user.regionId: null;
    
     
     return {
        user: ownProps.user,
        setCurrentUser: ownProps.setCurrentUser,
        handleDeletUser: ownProps.handleDeletUser,
        region: selectRegionById(state, regionId),
        showRegion: ownProps.showRegion

     };
 };
 
 const mapDispatchToProps = (dispatch: any) => {
 
     return {};
 };
 
 const UsersListElement = withRouter(
     connect(mapStateToProps, mapDispatchToProps)(UsersListElementComponent)
 );
 
 