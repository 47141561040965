import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppButton, AppNoItems, AppTitleMobileOnly, Spinner, TableHeaderMobileOnly } from '../../../../components';
import { EditIcon } from '../../../../components/Icon';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { RegionPriceConfig } from '../../types';
import { CountryPriceConfActionsProps } from '../types';

interface Props {
    regionPriceConfigs: Array<RegionPriceConfig>,
    regionsPriceConfigsLoading: boolean,
}

type OwnProps = CountryPriceConfActionsProps;

export const REGION_FACTOR_TABLE_TEXTS = {
    name: 'Navn',
    faktor: 'Prisfaktor',
    minPrice: 'Minstepris'
}

const RegionsWithPriceFactors = (props: Props & OwnProps) => {
    const { regionPriceConfigs, regionsPriceConfigsLoading, actions } = props || {};


    if (regionsPriceConfigsLoading) {
        return <AppNoItems text={<Spinner />} />
    }

    if (!regionPriceConfigs || regionPriceConfigs.length <= 0) {
        return <AppNoItems text={'Ingen regioner er lagt til'} />
    }

    const htmlResult = regionPriceConfigs.map((item, index) => {
        return <RegionPriceConfigRow
            key={index}
            regionPriceConfigId={item.regionPriceConfigId}
            index={index}
            regionPriceConfig={item}
            navigateToEditor={() => actions.goToRegionPriceConfigEditor(item.regionId) }
        />
    })


    return <div className="region-factors-wrapper tab-page mobile-100-table">

        <table className="region-factors-table ui collapsing table " style={{ minWidth: '35vw' }}>
            <thead className="mobile-hidden">

                <tr className="region-factors-table-header">
                    <th style={{ textAlign: 'left' }} >{REGION_FACTOR_TABLE_TEXTS.name}</th>
                    <th style={{ textAlign: 'left' }} >{REGION_FACTOR_TABLE_TEXTS.faktor}</th>
                    <th style={{ textAlign: 'left' }} >{REGION_FACTOR_TABLE_TEXTS.minPrice}</th>
                    <th style={{ textAlign: 'left' }} >{''}</th>
                    <th style={{ textAlign: 'left' }} >{''}</th>
                </tr>

            </thead>

            <TableHeaderMobileOnly text={'Regioner'} />

            {htmlResult && htmlResult.length > 0 ? <tbody>{htmlResult}</tbody> : <tbody>...</tbody>}

        </table>


    </div>

}

interface RegionPriceConfigRowProps {
    regionPriceConfig: RegionPriceConfig
    index: number,
    regionPriceConfigId:number | null,
    navigateToEditor: any
}

const RegionPriceConfigRow: React.FC<RegionPriceConfigRowProps> = (props) => {
    let { regionPriceConfig, index, navigateToEditor, regionPriceConfigId} = props;

    if (!regionPriceConfig) {
        return null;
    }

    return (<tr data-testid={'test-region-price-factor-row-' + index} className="region-price-factor-row-data" >
        <td style={{ textAlign: 'left' }}>
            <AppTitleMobileOnly text={REGION_FACTOR_TABLE_TEXTS.name} />  {regionPriceConfig.name || ''}
        </td>
        <td style={{ textAlign: 'left' }}>
            <AppTitleMobileOnly text={REGION_FACTOR_TABLE_TEXTS.faktor} /> 
            {regionPriceConfig.priceFactor  || regionPriceConfig.priceFactor === 0? regionPriceConfig.priceFactor: '-' }
        </td>

        <td style={{ textAlign: 'left' }}>
            <AppTitleMobileOnly text={REGION_FACTOR_TABLE_TEXTS.minPrice} /> 
            {regionPriceConfig.minPrice || 0.00}

        </td>

        <td style={{ textAlign: 'left' }}>
            {regionPriceConfig.isDraft ? <span> (Utkast)</span>
                : ''
            }
            {
                !regionPriceConfigId? <span> (Mangler info)</span>: ''
            }
        </td>

        <td style={{ textAlign: 'right' }}>
            <div className="ui icon buttons">
                <AppButton dataTestId={"test-region-pf-editor-" + index} size='mini' onClick={navigateToEditor}><>
                    <EditIcon /></>
                </AppButton>
            </div>

        </td>
    </tr>)
}


const mapStateToProps = (state: RootState, ownProps: any) => {

    const regionPriceConfState = state.regionPriceConfigs;
    const props = ownProps as OwnProps;

    return {
        actions: props.actions,
        regionPriceConfigs: regionPriceConfState.list,
        regionsPriceConfigsLoading: regionPriceConfState.loading
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RegionsWithPriceFactors)
);

