import { useEffect } from "react";
import { useState } from "react";

interface MyCheckboxProps {
    label?: string,
    name: string,
    value: any,
    onChange: (e: any) => void,
    customClassName?: string,
    dataTestId:string
}

export const MyCheckbox = (props: MyCheckboxProps) => {
    const { label, name, value = false, onChange, customClassName = '', dataTestId} = props;

    const [checkboxValue, setCheckboxValue] = useState<boolean>(false);

    useEffect(()=>{
        if (checkboxValue !== value){
            setCheckboxValue(value);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const checkBoxOnChange = (e: any) => {
        setCheckboxValue(!checkboxValue);

        if (typeof onChange === 'function') {
            onChange(e.target.checked);
        }
    }

    return <div className={"ui checkbox " + customClassName} >
        <input data-testid={dataTestId} name={name} type="checkbox" checked={checkboxValue} onChange={checkBoxOnChange} />
        <label> {label ? label : ''} </label>
    </div>

}


