import React from 'react';
import { AppButton, AppTitleMobileOnly, TableHeaderMobileOnly } from '../../../../components';
import { EditIcon } from '../../../../components/Icon';
import { showIntWith0Decimal } from '../../../../utils/commonHelpers';
import { CountryPriceConfigCarType, HideEditingBtns } from '../../types';
import { CountryPriceConfActionsProps, ModalType } from '../types';


export const CAR_TYPE_FACTORS_TABLE_TEXTS = {
    name: 'Bilkategori',
    faktor: 'Faktor'
}

interface CarTypesProps {
    carTypeFactors: Array<CountryPriceConfigCarType>
}

export const CarTypes = (props: CarTypesProps & CountryPriceConfActionsProps & HideEditingBtns) => {
    const { carTypeFactors, actions, hideEditingBtns } = props || {};

    let htmlResult = null;

    if (carTypeFactors && carTypeFactors.length > 0) {

        htmlResult = carTypeFactors.map((item, index) => {
            return <CarTypeFactorRow
                hideEditingBtns={hideEditingBtns}
                key={index}
                index={index}
                carTypeFactor={item}
                editRow={() => { actions.edit(index, ModalType.carTypeFactors) }}
            />
        })
    }

    return <div className="car-type-factors-wrapper mobile-100-table">

        <table className="car-type-factors-table ui collapsing table ">
            <thead className="mobile-hidden">

                <tr className="car-type-factors-table-header">
                    <th className="three wide" style={{ textAlign: 'left' }} >{CAR_TYPE_FACTORS_TABLE_TEXTS.name}</th>
                    <th  className="one wide" style={{ textAlign: 'center' }}>{CAR_TYPE_FACTORS_TABLE_TEXTS.faktor}</th>

                    {hideEditingBtns ? null: <th style={{ textAlign: 'right' }} className="three wide">Rediger</th>}
                </tr>

            </thead>

            <TableHeaderMobileOnly text={'Biltype-faktor'} />

            {htmlResult && htmlResult.length > 0 ? <tbody>{htmlResult}</tbody> : <tbody>...</tbody>}

        </table>


    </div>

}

interface CarTypeFactorRowProps extends HideEditingBtns {
    carTypeFactor: CountryPriceConfigCarType,
    index: number,
    editRow: (id: number) => void,
}

const CarTypeFactorRow: React.FC<CarTypeFactorRowProps> = (props) => {
    let { carTypeFactor, index, hideEditingBtns, editRow } = props;

    if (!carTypeFactor) {
        return null;
    }

    return (<tr data-testid={'test-cartypefactor-row-' + index} className="car-type-factor-row-data" >
        <td style={{ textAlign: 'left' }}>
            <AppTitleMobileOnly text={CAR_TYPE_FACTORS_TABLE_TEXTS.name} />
            {carTypeFactor.name || '-'}
        </td>

        <td className="car-type-factor">
            <AppTitleMobileOnly text={CAR_TYPE_FACTORS_TABLE_TEXTS.faktor} />
            {showIntWith0Decimal(carTypeFactor.priceFactor) || carTypeFactor.priceFactor}
        </td>

        { hideEditingBtns ? null
                :
            <td className="car-type-row-data-edit-btns" style={{ textAlign: 'right' }}>
                <div className="ui icon buttons">

                    <AppButton dataTestId={"test-edit-car-type-factor-" + index} size='mini' onClick={editRow}><><EditIcon /></>
                    </AppButton>

                </div>

            </td>
        }
    </tr>)
}


