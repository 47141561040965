import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import { DrivingArea, DrivingAreasState } from '../types';

const initialState: DrivingAreasState = {
    list: [],
    loading: false,
    currentItemId: null
};

const drivingAreasSlice = createSlice({
    name: 'drivingAreas',
    initialState,
    reducers: {
        drivingAreasLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        drivingAreasLoaded(state, action: PayloadAction<Array<DrivingArea>>) {
            const data = action.payload;

            state.loading = false;
            state.list = data || [];

            if (data && data.length === 1) {
                state.currentItemId = data[0].drivingAreaId;
            }
        },
        setCurrentDrivingArea(state, action: PayloadAction<number | null>) {
            state.currentItemId = action.payload || null;
        },
        resetDrivingAreaState(state) {
            return initialState;
        }
    },
})

export const {
    drivingAreasLoading,
    drivingAreasLoaded,
    setCurrentDrivingArea,
    resetDrivingAreaState

} = drivingAreasSlice.actions;

export default drivingAreasSlice.reducer;


export const selectDrivingAreaByIndex = (state: RootState, index: number): DrivingArea | null => {
    const items = state.drivingAreas;

    if (index || index === 0) {
        return items[index];
    }

    return null;
}


export const selectDrivingAreaById = (state: RootState, id: number): DrivingArea | null  => {
    const daState = state.drivingAreas;

    if (id && daState && daState.list) {
        const result = daState.list.find(x=> id === x.drivingAreaId)
        return result || null;
    }

    return null;
}



export const selectDrivingAreasByRegionId = (state: RootState, regionId: number): Array<DrivingArea> | [] => {
    const items = state.drivingAreas;

    if (regionId) {
        return items.list.filter(x => x.regionId == regionId);
    }

    return [];
}


