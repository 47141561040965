import React, { useEffect, useState } from 'react';
import 'react-day-picker/lib/style.css';
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { DateTime } from 'luxon';
import { XCloseIcon } from './Icon';
import DayPicker from 'react-day-picker/DayPicker';



interface Props {
    dateStr: string | null,
    placeholder: string
    handleDayChanged: (day: any) => void
}

export const AppDatePickerInput: React.FC<Props> = (props) => {
    const { dateStr, placeholder, handleDayChanged } = props;

    const [resetDate, setReset] = useState(false);

    useEffect(() => {

        let timer:any;

        if (!dateStr) {
            timer = reset();
        }

        return () => {
            if (timer){
                clearTimeout(timer);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateStr])

    const reset = () => {
        setReset(true);

        const timer = setTimeout(() => {
            setReset(false);
        }, 2)

        return timer;
    }

    const formatDate = (dateObject: any, luxonFormat: any) => {

        return DateTime.fromJSDate(dateObject).toFormat(luxonFormat)
    }

    const parseDate = (dateString: string, format: any) => {

        return DateTime.fromFormat(dateString, format).toJSDate()
    }

    if (resetDate) {
        return null;
    }

    return <div style={{display: 'flex', alignItems: 'center'}}>
        <DayPickerInput
            onDayChange={day => handleDayChanged(day)}
            component={(props:any ) => <input style={{cursor:'pointer'}} readOnly {...props} />}
            inputProps={{ className: 'dayPickerInput' }}
            placeholder={placeholder || ''}
            parseDate={parseDate}
            formatDate={formatDate}
            format={"dd.LL.yyyy"}
        />
        {
            dateStr? <div onClick={x=> handleDayChanged(null)} style={{marginLeft: '5px', color: 'gray'}}><XCloseIcon /></div>:''
        }
    </div>

}


interface AppDatePickerProps {
    handleDayClick: any,
    selectedDays: Array<any>
}

export const AppDatePicker: React.FC<AppDatePickerProps> = (props:AppDatePickerProps) => {
    const {handleDayClick , selectedDays} = props;

    const handleDayClickEvent = (date: Date) =>{
        
        const today = new Date();
        const sameDate = isSameDay(date, today);
        if (date < today && !sameDate){
            return;
        }

        handleDayClick(date);
    }

    const isSameDay = (date1: Date, date2: Date) => date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() &&  date1.getFullYear() === date2.getFullYear();

    const disablePastDates = (date: Date) => {
        const today = new Date();
        return date < today && !isSameDay(date, today);
      };
    

    return <div style={{display: 'flex', alignItems: 'center'}}>
          <DayPicker onDayClick={handleDayClickEvent} selectedDays={selectedDays}  disabledDays={disablePastDates} />
    </div>

}




