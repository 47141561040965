import React from 'react';
import '../../../styles/login.css';
import {history} from '../../../config/historyConfig';
import LoginForm from './LoginForm';
import { RoutesConfig } from '../../../config/routes';


interface Props { };

export const LoginPageView: React.FC<Props> = () => {

    return (<div className="login-form-wrapper ui middle aligned center aligned grid">
        <div style={{ marginTop: '25vh' }} className="login-form four wide tablet four wide computer ten wide mobile column" >
            <LoginForm />
            <div className="" style={{marginTop: '20px'}}>
                <a href={'/' + RoutesConfig.ForgotPassword.path} 
                onClick={(e) => {if (e) e.preventDefault(); history.push(RoutesConfig.ForgotPassword.path)}}>
                     Glemt passord?
                </a>
            </div>
        </div>
    </div>)
};
