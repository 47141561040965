import React from 'react';
import { AppBreadcrumbs, AppButton, Spinner } from '../../../../components';
import { AppModal } from '../../../../components/AppModal';
import {  DiscountIcon, PlusIcon } from '../../../../components/Icon';
import { AppPagination, ModalTypeData } from '../../../../config/constants';
import { PageHeaderWrapper } from '../../../Price/CommonComponents/PageHeaderWrapper';
import { AppDiscountListElement, AppDiscountsFilter, DiscountModalType, DiscountsActions } from '../../types';
import DiscountEditor from '../discountDetails/DiscountEditor';
import  DiscountsSearchBar from './DiscountFilters';
import { DiscountsList } from './DiscountList';
import DiscountUsageExcel from '../statistics/discountUsageReport';

export interface DiscountsViewPropsFromReducer {
   filters: AppDiscountsFilter
   discountsPagination: AppPagination
   discountsLoading: boolean
   discounts: Array<AppDiscountListElement>
   statisticsLoading:boolean,
   statisticsDiscountId:number | null
}

export interface DiscountsViewPropsFromComponent {
   actions: DiscountsActions,
   modalState: ModalTypeData | null,
   listTopEl:any,
   discountStatisticsBtn:any
}

export type DiscountsViewProps = DiscountsViewPropsFromReducer & DiscountsViewPropsFromComponent;

export const DiscountsView: React.FC<DiscountsViewProps> = (props) => {

   const { discounts, discountsLoading, modalState, listTopEl, discountStatisticsBtn, statisticsLoading, statisticsDiscountId} = props;
   const { getItemsOnScroll, openEditor, closeEditor, addOrUpdateDiscount, activateDeactivateDiscount, doSearch} = props.actions;

   let modalTitleText = null;
   let modalContentHtml = null;

   if (modalState?.modalType == DiscountModalType.discountEditor) {
       modalTitleText = "Legg til ";
       modalContentHtml = <DiscountEditor addOrUpdateDiscount={addOrUpdateDiscount} 
       currentId={modalState.index} 
       activateDeactivateDiscount={activateDeactivateDiscount} />
   }

   return (<div className="main-content-item booking-page">
      <AppBreadcrumbs pages={[{ name: 'Kampanjer /' }]} />

      <div className="app-discount-wrapper">

         <div style={{ marginBottom: '0px' }}></div>

         <PageHeaderWrapper className="price-test-case-header" >

            <div style={{ justifyContent: 'space-between' }} className="display-flex-center-y">
               <div style={{ marginRight: '0px', display: 'flex', alignItems:'center' }}>
                  <DiscountIcon />
                 
                  <div style={{marginRight: '10px'}}></div>
                  <DiscountsSearchBar doSearchByText={doSearch} />
               </div>
            </div>

            <div data-testid='test-price-test-case-btns-right' className="discounts-btns-right" >
               <AppButton size='medium'
                  designType="teal"
                  isIcon={true}
                  dataTestId="test-add-discount"
                  onClick={() => openEditor(DiscountModalType.discountEditor, null)} 
                  >

                  <PlusIcon />
               </AppButton>
            </div>

         </PageHeaderWrapper>

         <div style={{marginBottom: '3px'}}>

         </div>
      </div>

      <div onScroll={getItemsOnScroll} data-testid="appDiscountListWrapper" className="app-discount-list-wrapper">
         <div ref={listTopEl}></div>
         <DiscountsList 
            discounts={discounts} 
            actions={props.actions}
            statisticsLoading={statisticsLoading}
            statisticsDiscountId={statisticsDiscountId}
            discountStatisticsBtn={discountStatisticsBtn}
            goToDetails={(id:any) => openEditor(DiscountModalType.discountEditor, id)} />

         <DiscountUsageExcel button={<button style={{ display: 'none' }} ref={discountStatisticsBtn}></button>} />
         {
            discountsLoading ? <Spinner /> : ''
         }
      </div>

      {
            modalContentHtml ?
                <AppModal
                    onClose={closeEditor}
                    closeOnDimmerClick={false}
                    isOpen={modalState ? true : false}
                    header={<>{modalTitleText}</>} >
                    {modalContentHtml}

                </AppModal>
                : ''
        }

   </div>
   )
};

