import React from 'react';
import 'react-day-picker/lib/style.css';
import "react-day-picker/lib/style.css";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { AppBookingFilter, AppBookingsState } from '../../types';
import { searchByBookingNumber, searchByTelNumber, setFrom, setSearchText, setTo } from '../../reducer/bookingReducer';
import { dateTimeHelper } from '../../../../utils/dateTimeHelper';
import { AppButton, AppDatePickerInput } from '../../../../components';
import { AppIconWithPopup, CalenderIcon } from '../../../../components/Icon';


interface Props {
    filters: AppBookingFilter
    set_From: (args: string) => void
    set_To: (args: string) => void
    set_SearchText: (args: string) => void,
    search_ByBookingNumber: (args: string) => void,
    search_ByTelNumber: (args: string) => void,
    doSearch: () => void,
    doSearchByDate: (dateFrom: string | null, dateTo: string | null) => void

}

const BookingsFilterBar: React.FC<Props> = (props) => {
    const { filters, set_From, set_To, set_SearchText, doSearch, doSearchByDate, search_ByBookingNumber, search_ByTelNumber } = props;

    const handleDayChanged = (date: Date, isFrom: boolean) => {


        if (isFrom) {
            let resultday = dateTimeHelper.getStartOfDayFromDate(date);
            let resultStr = resultday ? dateTimeHelper.getUTCStringFromDateTime(resultday) : '';
            resultStr = resultStr || '';

            set_From(resultStr);
            doSearchByDate(resultStr, filters.createdTo);
        }
        else {

            let resultday = dateTimeHelper.getEndOfDayFromDate(date);
            let resultStr = resultday ? dateTimeHelper.getUTCStringFromDateTime(resultday) : '';
            resultStr = resultStr || '';

            set_To(resultStr);
            doSearchByDate(filters.createdFrom, resultStr);
        }
    }

     
    return (<div className="ui placeholder segment booking-filterbar">
        <div className="ui stackable center aligned two column grid">
            <div style={{display:'flex', flexDirection:'column', width: '100%'}}>
                <div  style={{width:'100%', display:'flex', justifyContent:'center'}} >

                    <div className='booking-search-fields' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <AppIconWithPopup color='black' iconName='info' content={"Det kan søkes etter bookingnummer, løyve, kundenavn, telefon, epost,  sjåførnummer, sjåførnavn, pris, adresse (både fra, til og stopp) og status. Addresse kan søkes slik som: 'Svensholmen 83a, Kristiansand S' med eller uten komma og det kan også søkes etter del av adressen: 'Svensholmen', 'Svensholmen 83', 'Kristiansand S'."} />

                        <div className="ui search booking-search-fields-el">
                            <BookingsSearchBar placeholder={null} doSearchByText={doSearch} setSearchText={set_SearchText} searchText={filters.searchText} />
                        </div>

                        <div className="ui search booking-search-fields-el" style={{marginLeft: '10px'}}>
                            <BookingsSearchBar placeholder={'Bookingnummer'} 
                                doSearchByText={doSearch} 
                                setSearchText={search_ByBookingNumber} 
                                searchText={filters.searchBookingNumber} />
                        </div>

                        <div className="ui search booking-search-fields-el" style={{marginLeft: '10px'}}>
                            <BookingsSearchBar placeholder={'Telefonnummer'} 
                                doSearchByText={doSearch} 
                                setSearchText={search_ByTelNumber} 
                                searchText={filters.searchTelephone} />
                        </div>

                    </div>
                </div>
                <div style={{width:'100%', display: 'flex', marginLeft: '0px', marginTop: '20px', alignItems:'center', justifyContent:'center'}}>
                    <div className="ui icon header">
                        
                    </div>
                    <div className="booking-filterbar-datetime-filter" style={{alignItems:'center'}}>
                    <CalenderIcon/>
                        <AppDatePickerInput placeholder={filters.createdFrom ? dateTimeHelper.formatDate(filters.createdFrom, 'dd.MM.yyyy') as string : " fra"} dateStr={filters.createdFrom} handleDayChanged={(day) => { handleDayChanged(day, true) }} />
                        <div style={{ paddingLeft: '10px' }}></div>
                        <AppDatePickerInput placeholder={filters.createdTo ? dateTimeHelper.formatDate(filters.createdTo, 'dd.MM.yyyy') as string : " til"} dateStr={filters.createdTo} handleDayChanged={(day) => { handleDayChanged(day, false) }} />

                    </div>
                </div>
            </div>
        </div>
    </div>
    )
};


interface BookingsSearchBarProps {
    setSearchText: (args: string) => void,
    doSearchByText: () => void,
    searchText: string | null,
    placeholder: string | null
}

const BookingsSearchBar: React.FC<BookingsSearchBarProps> = (props) => {

    const { setSearchText, doSearchByText, searchText, placeholder } = props;

    const onSearchTextChanged = (data: any) => {

        if (!data || !data.target) {
            return;
        }

        setSearchText(data.target.value);
    }

    const handleKeyPress = (e: any) => {
        var key = e.key;
        if (key == 'Enter') {
            doSearchByText();
        }

    }

    return (<div className="bookings-search-bar">

        <div className="ui action input">
            <input onChange={(data) => onSearchTextChanged(data)} type="text" value={searchText || ""} 
            placeholder={placeholder? placeholder: "Søk... "} onKeyPress={(e) => handleKeyPress(e)} />
            <AppButton onClick={() => doSearchByText()} size='mini' >{'Søk'}</AppButton>
        </div>
    </div>)

};


const mapStateToProps = (state: any, ownProps: any) => {
    const bookingsState = state.bookings as AppBookingsState;

    return {
        filters: bookingsState.filters,
        bookingsPagination: bookingsState.pagination,
        searchBookings: ownProps.searchBookings
    };
};


const mapDispatchToProps = (dispatch: any) => {

    return {
        set_From: (args: any) => { dispatch(setFrom(args)) },
        set_To: (args: any) => { dispatch(setTo(args)) },
        set_SearchText: (args: string) => { dispatch(setSearchText(args)) },
        search_ByTelNumber: (args:string) => {dispatch(searchByTelNumber(args))},
        search_ByBookingNumber: (args:string) => {dispatch(searchByBookingNumber(args))}
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BookingsFilterBar)
);