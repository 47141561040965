import { DrivingAreaPriceConfigsApi } from "../../../../api/drivingAreaPriceConfig.service";
import { RootState } from "../../../../config/reducerConfig/rootReducer";
import { DrivingAreaPriceConfig } from "../../types";
import { changePublishingState, changeSavingState, priceConfigLoading, resettingDraft, setPriceConfig } from "../reducers/drivingAreaPriceConfigReducers";
import { priceConfigsLoading, setPriceConfigs } from "../reducers/drivingAreaPriceConfigsReducers";
import { DrivingAreaPriceConfigServerData, DrivingAreaPriceConfigState, DrivingAreaSaveOrPublishPriceConfigData, ResetDrivingAreaPriceConfigDraftPostData } from "../types";


export const getDrivingAreaPriceConfigs = (regionId: number) => {

    return async (dispatch: any) => {

        dispatch(priceConfigsLoading(true));

        try {
            const result = await DrivingAreaPriceConfigsApi.getDrivingAreaPriceConfigs(regionId);
            const data: Array<DrivingAreaPriceConfig> = result ? result.data : [];
            dispatch(setPriceConfigs(data));

            return data;
        }
        catch (error: any) {
            dispatch(priceConfigsLoading(false));
            return new Error(error);
        }
    }
};

export const getLastDrivingAreaPriceConfig = (regionId:number, drivingAreaId: number, isPublished:boolean) => {

    return async (dispatch: any) => {

        dispatch(priceConfigLoading(true));

        try {

            const result = await DrivingAreaPriceConfigsApi.getLastValidDrivingAreaPriceConfig(drivingAreaId, isPublished);
            const data = (result.data || null) as DrivingAreaPriceConfigServerData;

            dispatch(setPriceConfig({ priceConfig: data, regionId, drivingAreaId }));

            return;
        }
        catch (error) {
            console.log(error);
            dispatch(priceConfigLoading(false));
            throw error;
        }
    }
};


export const publishOrSaveDrivingAreaPriceConfig = ({ isSave }: { isSave: boolean }) => {
    return async (dispatch: any, getState: any) => {

        if (isSave) {
            dispatch(changeSavingState(true));
        }
        else {
            dispatch(changePublishingState(true));
        }

        try {

            const state: RootState = getState();
            let drivingAreaPriceConfigState = getState().drivingAreaPriceConfig as DrivingAreaPriceConfigState;
            const authUser = state.auth || {};

            if (!drivingAreaPriceConfigState.priceConfig) {
                throw new Error(" driving area priceConfig is null or empty");
            }

            const dataToSend: DrivingAreaSaveOrPublishPriceConfigData = {
                drivingAreaPriceConfig: drivingAreaPriceConfigState.priceConfig,
                dayTimeZones: drivingAreaPriceConfigState.dayTimeZones || [],
                specialLocations: drivingAreaPriceConfigState.specialLocations || [],
                extraSavingInfo: {
                    isEdited: drivingAreaPriceConfigState.isEdited,
                    isSave: isSave,
                    comment: drivingAreaPriceConfigState.comment || null,
                    editorId: authUser.user && authUser.user.userId ? authUser.user.userId : 0
                },
                preOrderConfig: drivingAreaPriceConfigState.preOrderConfig
            };


            let result = await DrivingAreaPriceConfigsApi.saveOrPublishRegionPriceConfig(dataToSend);
            const data = (result.data || null) as boolean;

            return data;
        }
        catch (error) {
            dispatch(changeSavingState(false));
            dispatch(changePublishingState(false));
            throw error;
        }
    }
};

export const resetDrivingAreaPriceConfigDraft = () => {
    return async (dispatch: any, getState: any) => {

        dispatch(resettingDraft(true));

        try {

            const state: RootState = getState();
            const authUser = state.auth || {};
            const priceConfig: DrivingAreaPriceConfig | null = state.drivingAreaPriceConfig.priceConfig ? state.drivingAreaPriceConfig.priceConfig : null;
            const drivingAreaPriceConfigId = priceConfig && priceConfig.drivingAreaPriceConfigId ? priceConfig.drivingAreaPriceConfigId: 0;
            const drivingAreaId = priceConfig && priceConfig.drivingAreaId ? priceConfig.drivingAreaId : 0;

            const dataToSend: ResetDrivingAreaPriceConfigDraftPostData = {
                drivingAreaPriceConfigId,
                drivingAreaId,
                userId: authUser.user && authUser.user.userId ? authUser.user?.userId : 0
            };

            let result = await DrivingAreaPriceConfigsApi.resetDrivingAreaPriceConfigDraft(dataToSend);
            return result;
        }
        catch (error) {
            dispatch(resettingDraft(false));
            throw error;
        }
    }
};
