import { Menu, Tab } from "semantic-ui-react"

interface AppTabsProps{
  panes: Array<AppTabProp>
}

export interface AppTabProp {
    menuItemText: string,
    menuItemIcon?: JSX.Element 
    tabPaneContent: JSX.Element,
    testId?: string | null
}

export const AppTabs: React.FC<AppTabsProps> = (props) => {

    const {panes} = props;

    if (!panes || panes.length <= 0){
        return null;
    }

    const panesResult =  panes.map(pane => {return {
        menuItem:  (
          <Menu.Item key={pane.menuItemText} data-testid={pane.testId}>
           {pane.menuItemIcon || ''} {pane.menuItemText}
          </Menu.Item>
        ),
        render: () => <Tab.Pane>{pane.tabPaneContent}</Tab.Pane>,
      }});


    return <Tab panes={panesResult} />    
}



