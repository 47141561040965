import React from "react";
import { AppButton, AppLabel, AppTitleMobileOnly, Spinner } from "../../../../components";
import { PriceIcon } from "../../../../components/Icon";
import { AppPayment, getAppPaymentMethod } from "../../../Payments/types";
import { getAppPaymentStatusData } from "../../types";
import { BookingDetailsBoxWrapper } from "./BookingDetailsBoxWrapper";
import { BookingInfoElement } from "./BookingInfoElement";
import { BookingInfoHeader } from "./BookingInfoHeader";

export const TABLE_TEXTS = {
    paymentId: 'Payment-ID',
    amount: 'Beløp',
    refunded: 'Refundert',
    paymentMethod: 'Betalingsmetode',
    status: 'Status'
}

interface SetRefundEditorProps {
    setRefundedPaymentId: (paymentId: number | null) => void
}

interface Props {
    payments: Array<AppPayment>
    loading: boolean,
    refundPaymentPending: number | null
}

export const PaymentsDetails: React.FC<Props & SetRefundEditorProps> = (props) => {
    let { payments, loading, refundPaymentPending, setRefundedPaymentId } = props;
    let content;

    if (!payments) {
        return null;
    }

    if ((payments && payments.length <= 1) || (!payments)) {

        content = (
            <div data-testid="test-one-payment-info">
                <PaymentDetailsOnePayment refundPaymentPending={refundPaymentPending} setRefundedPaymentId={setRefundedPaymentId} payment={payments ? payments[0] : null} />
            </div>
        )
    }
    else {
        content = (<>
            {
                loading === false ? (<div className="payments-list" data-testid="test-morethenone-payment-info">
                    <PaymentsDetailsTable refundPaymentPending={refundPaymentPending} setRefundedPaymentId={setRefundedPaymentId} data={payments} />
                </div>) : <Spinner />
            }
        </>)
    }

    if (!content) {
        return null;
    }

    return <BookingDetailsBoxWrapper elementClassName='booking-payment-one-item'>
        <>
            <BookingInfoHeader text={'Betaling'} icon={<PriceIcon />} />
            <div className="ui divider"></div>
            {content || null}
        </>
    </BookingDetailsBoxWrapper>
};


interface PaymentDetailsOnePaymentProps {
    payment: AppPayment | null,
    refundPaymentPending: number | null
}

export const PaymentDetailsOnePayment: React.FC<PaymentDetailsOnePaymentProps & SetRefundEditorProps> = (props) => {

    let { payment, refundPaymentPending, setRefundedPaymentId } = props || {};
    payment = payment || {} as AppPayment;

    const paymentMethodId = payment?.paymentMethodId || null;
    const paymentStatus = payment?.status || payment.status === 0 ? getAppPaymentStatusData(payment?.status) : null;
    const paymentId = payment && payment.paymentId ? payment.paymentId : null;

    let currentCaptured = 0;

    if (payment.captured > 0){
        currentCaptured = payment.captured - (payment.refunded || 0);
    }

    return <>
        <div className="payment-status-text">
        {
            paymentStatus ? (<BookingInfoElement
                title={<>Betalingsstatus </>}
                text={<AppLabel color={paymentStatus.color} text={paymentStatus.text} />} />)
                :
                <BookingInfoElement
                    title={<>Betalingsstatus </>}
                    text={'-'} />
        }
        </div>
        {
            paymentMethodId ? (<BookingInfoElement title={'Betalingsmetode'}
                text={<AppLabel color={paymentMethodId ? getAppPaymentMethod(paymentMethodId).color : 'whtie'}
                    text={getAppPaymentMethod(paymentMethodId).text || '-'} />} />)
                :
                (<BookingInfoElement title={<>Betalingsmetode </>} text={'-'} />)
        }

        <BookingInfoElement descriptionClassName={'paymentNumber'} title={<>{TABLE_TEXTS.paymentId}</>} text={payment?.paymentId || '-'} />
        <BookingInfoElement title={<>{TABLE_TEXTS.amount}</>} text={payment?.amountToPay || '-'} />
        <BookingInfoElement title={<>{TABLE_TEXTS.refunded}</>} text={payment?.refunded || '-'} />


        {
            currentCaptured > 0 ?
                (<div style={{ textAlign: 'right' }}>
                    <AppButton
                        dataTestId="test-refund"
                        designType={'teal'}
                        size={'medium'}
                        disabled={refundPaymentPending? true: false}
                        loading={refundPaymentPending? true: false}
                        onClick={(e) => { e.preventDefault(); setRefundedPaymentId(paymentId); }} >
                        Refunder
                    </AppButton>
                </div>) : ''
        }
    </>
}

interface PaymentsDetailsTableProps {
    data: Array<AppPayment>,
    refundPaymentPending: number | null
}

const PaymentsDetailsTable: React.FC<PaymentsDetailsTableProps & SetRefundEditorProps> = (props) => {
    const { data, setRefundedPaymentId, refundPaymentPending} = props;

    let result = null;

    if (data && data.length > 0) {
        result = data.map((el, index) => {
            return <PaymentsDetailsElement refundPaymentPending={refundPaymentPending} setRefundedPaymentId={setRefundedPaymentId} key={index} item={el} />
        })
    }

    return (<table className="payments-list-table ui very basic table ">
        <thead className="payments-list-table-header mobile-hidden">
            <tr className="">
                <th >{TABLE_TEXTS.paymentId}</th>
                <th>{TABLE_TEXTS.amount}</th>
                <th>{TABLE_TEXTS.refunded}</th>
                <th>{TABLE_TEXTS.paymentMethod}</th>
                <th >{TABLE_TEXTS.status}</th>
                <th >Refunder</th>
            </tr>
        </thead>
        {result && result.length > 0 ?
            <tbody className="">{result}</tbody>
            : <tbody ><tr style={{ height: "10vh" }}></tr></tbody>}
    </table>)
}

interface PaymentDetailsElementtProps {
    item: AppPayment,
    refundPaymentPending: number | null
}

const PaymentsDetailsElement: React.FC<PaymentDetailsElementtProps & SetRefundEditorProps> = (props) => {
    let { item, refundPaymentPending, setRefundedPaymentId } = props;

    item = item || {};

    const paymentMethod = (getAppPaymentMethod(item.paymentMethodId) || {}).text;
    const status = getAppPaymentStatusData(item.status) || {};

    const style = { fontSize: '12px' };

    let currentCaptured = 0;

    if (item.captured > 0){
        currentCaptured = item.captured - (item.refunded || 0);
    }

    let paymentId = item.paymentId || '-';

    return (<tr
        className="app-bookings-booking">

        <td style={{fontSize: '12px', paddingLeft: '10px'}}> <AppTitleMobileOnly text={TABLE_TEXTS.paymentId} /> 
        {
            paymentId
        }
        </td>
        <td style={style}> <AppTitleMobileOnly text={TABLE_TEXTS.amount} />  {item.amountToPay || '-'}</td>
        <td style={style}> <AppTitleMobileOnly text={TABLE_TEXTS.refunded} />  {item.refunded || '-'}</td>
        <td style={style}> <AppTitleMobileOnly text={TABLE_TEXTS.paymentMethod} />  {paymentMethod || '-'}</td>
        <td style={{ fontSize: '11px' }}>  <AppTitleMobileOnly text={TABLE_TEXTS.status} /> {(status || {}).text}</td>
        <td>
            {
                currentCaptured > 0 ?
                    (<AppButton
                        dataTestId="test-refund"
                        designType={'teal'}
                        size={'tiny-mini'}
                        disabled={refundPaymentPending? true: false}
                        loading={refundPaymentPending === item.paymentId? true: false}
                        onClick={(e) => { e.preventDefault(); setRefundedPaymentId(item.paymentId) }} >
                        Refunder
                    </AppButton>) : '-'
            }
        </td>

    </tr>)
}



