import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import { AppBookingStop, AppBookingStopState } from '../types';

const initialState: AppBookingStopState = {
    list: []
}

const bookingStopsSlice = createSlice({
    name: 'bookingStops',
    initialState,
    reducers: {
        bookingStopsLoaded(state, action: PayloadAction<Array<AppBookingStop>>) {
            const items = action.payload? action.payload: null;
            state.list = items? items: [];
        },
        resetBookingStopsState(state) {
            return initialState;
        }
    },
})

export const {
    bookingStopsLoaded,
    resetBookingStopsState,
} = bookingStopsSlice.actions;

export default bookingStopsSlice.reducer;

export const selectBookingStopsByBookingId = (state: RootState, bookingId:number): Array<AppBookingStop> | null => {
    let result = ( ((state || {}).bookingStops || {}).list || []).filter(x=>x.bookingId === bookingId);
    return result;
}