import React from 'react';

interface Props {}

export const Spinner: React.FC<Props> = (props) => {
 
    return (
        <><div className="ui active centered inline loader"></div>
        </>
    )
};




