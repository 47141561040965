import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppBooking, AppBookingsState } from '../types';

let initFilters = {
    createdFrom: null,
    createdTo: null,
    searchText: null,
    isAsc: true,
    showOffers: false,
    searchBookingNumber: null,
    searchTelephone: null
};

const initPages = {
    isLastPage: false,
    page: 1
}

const initialState: AppBookingsState = {
    list: [],
    loading: true,
    filters: initFilters,
    pagination: initPages,
    currentItem: null,
    currentItemLoading: false
}

const bookingsSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
        bookingsLoading(state) {
            state.loading = true;
        },
        bookingsLoaded(state, action: PayloadAction<{ bookings: Array<AppBooking>, isLastPage: boolean }>) {
            const bookings = action.payload && action.payload.bookings ? action.payload.bookings : [];
            const isLastPage = action.payload ? action.payload.isLastPage : false;
            const resultList = state.list.concat(bookings);
            state.list = resultList;
            state.loading = false;
            state.pagination.isLastPage = isLastPage;
        },
        bookingsLoadingFailed(state) {
            state.loading = false;
        },
        searchedBookingsLoaded(state, action: PayloadAction<{ bookings: Array<AppBooking>, isLastPage: boolean }>) {
            const bookings = action.payload ? action.payload.bookings : null;
            const isLastPage = action.payload ? action.payload.isLastPage : false;

            if (!bookings)
                return state;

            state.list = bookings;
            state.loading = false;
            state.pagination.isLastPage = isLastPage;
        },
        setFrom(state, action: PayloadAction<string>) {
            state.filters.createdFrom = action.payload;
            state.list = [];
            state.pagination.page = 1;
            state.pagination.isLastPage = true;
        },
        setTo(state, action: PayloadAction<string>) {
            state.filters.createdTo = action.payload;
            state.list = [];
            state.pagination.page = 1;
            state.pagination.isLastPage = true;
        },
        //search all fields
        setSearchText(state, action: PayloadAction<string>) {
            state.filters.searchText = action.payload;
            state.filters.searchBookingNumber = null;
            state.filters.searchTelephone = null;
        },
        searchByBookingNumber(state, action: PayloadAction<string>){
            state.filters.searchBookingNumber = action.payload;
            state.filters.searchTelephone = null;
            state.filters.searchText = null;
        },
        searchByTelNumber(state, action: PayloadAction<string>){
            state.filters.searchBookingNumber = null;
            state.filters.searchTelephone = action.payload;
            state.filters.searchText = null;
        },
        setShowOffers(state, action: PayloadAction<boolean>) {
            state.list = [];
            state.filters.showOffers = action.payload;
        },
        setBookingsPage(state) {
            state.pagination.page = state.pagination.page + 1;
        },
        resetFilters(state) {

            state.filters.createdFrom = null;
            state.filters.createdTo = null;
            state.filters.searchText = null;
            state.filters.searchBookingNumber = null;
            state.filters.searchTelephone = null;
            state.filters.isAsc = true;
            state.pagination = initPages;
            state.list = [];
        },
        bookingLoading(state) {
            state.currentItemLoading = true;
        },
        bookingLoaded(state, action: PayloadAction<AppBooking>) {
            state.currentItemLoading = false;
            state.currentItem = action.payload;
        },
        bookingLoadingFailed(state) {
            state.currentItemLoading = false;
        },
        resetCurrentItem(state){
            state.currentItemLoading = false;
            state.currentItem = null;
        },
        resetList(state) {
            state.pagination = initPages;
            state.list = [];
        },
        resetPage(state) {
            state.pagination = initPages;
            state.list = [];
            state.loading = true;
            state.currentItem = null;
            state.currentItemLoading =false; 

            console.log(state.list);
        },
        resetState(state) {
            return initialState;
        }
    },
})

export const {
    bookingsLoading,
    bookingsLoaded,
    bookingsLoadingFailed,
    searchedBookingsLoaded,
    setFrom,
    setTo,
    setSearchText,
    searchByBookingNumber,
    searchByTelNumber,
    setShowOffers,
    setBookingsPage,
    resetState,
    bookingLoading,
    bookingLoadingFailed,
    bookingLoaded,
    resetList,
    resetPage,
    resetFilters,
    resetCurrentItem,
} = bookingsSlice.actions;

export default bookingsSlice.reducer;


