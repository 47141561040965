import React from 'react';
import { getDayOfWeekConfig } from '../../../../commonTypes';
import { AppButton, AppLabel, TableHeaderMobileOnly } from '../../../../components';
import { EditIcon } from '../../../../components/Icon';
import { showIntWith0Decimal } from '../../../../utils/commonHelpers';
import { CountryPriceConfigDayTypeFactor, HideEditingBtns } from '../../types';
import { CountryPriceConfActionsProps, ModalType } from '../types';


export const DAYTYPE_FACTORS_TABLE_TEXTS = {
    name: 'Dagtype',
    faktor: 'Faktor'
}

interface DayTypeFactorsProps extends HideEditingBtns {
    dayTypeFactors: Array<CountryPriceConfigDayTypeFactor>
}

export const DayTypes = (props: DayTypeFactorsProps & CountryPriceConfActionsProps) => {
    const { dayTypeFactors, actions, hideEditingBtns } = props || {};

    let htmlResult = null;

    if (dayTypeFactors && dayTypeFactors.length > 0) {

        htmlResult = dayTypeFactors.map((item, index) => {
            return <DayTypeFactorRow
                key={index}
                index={index}
                dayTimeFactor={item}
                hideEditingBtns={hideEditingBtns}
                editRow={() => { actions.edit(index, ModalType.dateTypeFactors) }}
            />
        })
    }


    return <div className="day-type-factors-wrapper mobile-100-table">

        <table className="day-type-factors-table ui collapsing table ">
            <thead className="mobile-hidden">

                <tr className="day-type-factors-table-header">
                    <th className="three wide" style={{ textAlign: 'left' }} >{DAYTYPE_FACTORS_TABLE_TEXTS.name}</th>
                    <th className="one wide" >{DAYTYPE_FACTORS_TABLE_TEXTS.faktor}</th>

                    {
                        hideEditingBtns? null :  <th style={{ textAlign: 'right' }} className="three wide">Rediger</th>
                    }
                    
                </tr>

            </thead>

            <TableHeaderMobileOnly text={'Dagtype-faktor'} />

            {htmlResult && htmlResult.length > 0 ? <tbody>{htmlResult}</tbody> : <tbody>...</tbody>}

        </table>


    </div>

}

interface DayTypeFactorRowProps extends HideEditingBtns {
    dayTimeFactor: CountryPriceConfigDayTypeFactor,
    index: number,
    editRow: (id: number) => void,
}

const DayTypeFactorRow: React.FC<DayTypeFactorRowProps> = (props) => {
    let { dayTimeFactor, index, hideEditingBtns, editRow } = props;

    if (!dayTimeFactor) {
        return null;
    }

    const { color, text } = getDayOfWeekConfig(dayTimeFactor.dayTypeId);

    return (<tr data-testid={'test-daytypefactor-row-' + index} className="daytimezone-row-data" >
        <td style={{ textAlign: 'left' }}>
            <span className="mobile-only app-text-bold">{DAYTYPE_FACTORS_TABLE_TEXTS.name}</span>

            <AppLabel color={color} text={text} />

        </td>
        <td >
            <span className="mobile-only app-text-bold" >{DAYTYPE_FACTORS_TABLE_TEXTS.faktor || 0} </span>
            {showIntWith0Decimal(dayTimeFactor.priceFactor) || dayTimeFactor.priceFactor} 
        </td>

        {
            hideEditingBtns ? null: <td className="price-row-data-edit-btns" style={{ textAlign: 'right' }}>
                <div className="ui icon buttons">

                    <AppButton dataTestId={"test-edit-day-type-factor-" + index} size='mini' onClick={editRow}><><EditIcon /></>
                    </AppButton>

                </div>

            </td> 

        }


    </tr>)
}


