import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import { DrivingAreaGeo, DrivingAreaGeosState } from '../types';

const initialState: DrivingAreaGeosState = {
    list: [],
    loading: false,
};

const drivingAreaGeosSlice = createSlice({
    name: 'drivingAreaGeos',
    initialState,
    reducers: {
        drivingAreaGeosLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        drivingAreaGeosLoaded(state, action: PayloadAction<Array<DrivingAreaGeo>>) {
            const data = action.payload;

            state.loading = false;
            state.list = data || [];
        },
        resetDrivingAreaGeosState(state) {
            return initialState;
        }
    },
})

export const {
    drivingAreaGeosLoading,
    drivingAreaGeosLoaded,
    resetDrivingAreaGeosState

} = drivingAreaGeosSlice.actions;

export default drivingAreaGeosSlice.reducer;


export const selectDrivingAreaGeoByIndex = (state: RootState, index: number): DrivingAreaGeo | null => {
    const items = state.drivingAreaGeos ? state.drivingAreaGeos.list: [];

    if (index || index === 0) {
        return items[index];
    }

    return null;
}


export const selectDrivingAreaGeoByDrivingAreaId = (state: RootState, drivingAreaId: number): DrivingAreaGeo | null => {
    const items = state.drivingAreaGeos;

    if (drivingAreaId && items.list && items.list.length > 0) {
        return items.list.find(x=>x.drivingAreaId === drivingAreaId) || null;
    }

    return null;
}
