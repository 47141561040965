import Swal from 'sweetalert2'
import '../styles/alert.css';

//https://sweetalert2.github.io/#usage

export const appAlert = (title:string, text:string, icon?: 'warning' | 'error') => {
    Swal.fire({
        title: title || 'Error!',
        html: text || '',
        icon: icon || 'error',
        confirmButtonText: 'OK',
        buttonsStyling: false,
        customClass: {confirmButton: 'confirm-alert-btn'}
    })
}