import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegionPriceConfig } from '../../types';
import { RegionPriceConfigsState } from '../types';

const initialState: RegionPriceConfigsState = {
  list: [],
  loading: false,
  publishingIndex: null
};

const priceConfigSlice = createSlice({
  name: 'regionPriceConfigs',
  initialState,
  reducers: {
    priceConfigsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPriceConfigs(state, action: PayloadAction<Array<RegionPriceConfig>>) {
      const data = action.payload || {};

      if (!data) {
        return state;
      }

      state.list = data || [];
      state.loading = false;
      state.publishingIndex = null;
    },
    updatePriceConfig(state, action: PayloadAction<{ item: RegionPriceConfig, index: number | null | undefined }>) {

      const { item, index } = action.payload;

      if (!item) {
        return state;
      }

      //update
      if (item && (index || index === 0) && state.list && state.list.length > 0 && state.list[index]) {
        state.list[index] = item;
      }

    },
    resetRegionPriceConfigState(state) {
      return initialState;
    }
  },
})

export const {
  priceConfigsLoading,
  setPriceConfigs,
  updatePriceConfig,
  resetRegionPriceConfigState
} = priceConfigSlice.actions;

export default priceConfigSlice.reducer;


export const selectRegionPriceConfig = (state: any, index: number): RegionPriceConfig | null => {
  const data = state.regionPriceConfigs as RegionPriceConfigsState;

  if (index || index === 0) {

    if (data && data.list && data.list.length > 0) {
      const current = data.list[index] || null;
      return current;
    }
  }

  return null;
}





