import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ModalFormFooter, MyTextInput } from '../../../../components';
import { AppButtonSubmit } from '../../../../components/AppButton';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { formPrepareAndRoundNumber, getInputValue } from '../../../../utils/formHelpers';
import { nameof } from '../../../../utils/nameOfPropertyHelper';
import { DrivingAreaSpecialLocation, getEmptySpecialLocation } from '../../types';
import { selectSpecialLocationByIndex } from '../reducers/drivingAreaPriceConfigReducers';
import { DrivingAreaPriceConfActionsProps, specialLocationPriceConfigValidation } from '../types';


interface OwnProps {
    priceConfigId: number,
    specialLocation: DrivingAreaSpecialLocation | null,
    addOrUpdateSpecialLocation: (specialLocation: DrivingAreaSpecialLocation) => void,
    closeEditor: ()=>void
};

type SpecialLocationEditorProps = OwnProps & DrivingAreaPriceConfActionsProps


const SpecialLocationEditor: React.FC<SpecialLocationEditorProps> = (props) => {

    const { specialLocation, priceConfigId, addOrUpdateSpecialLocation, closeEditor } = props;

    const initialData: DrivingAreaSpecialLocation = specialLocation ? specialLocation : getEmptySpecialLocation(priceConfigId);

    const handleFormSubmit = async (values: any, setErrors: any, setFieldValue: any) => {

        const priceFactorFrom = values[nameof<DrivingAreaSpecialLocation>('fromPriceFactor')];
        const priceFactorTo = values[nameof<DrivingAreaSpecialLocation>('toPriceFactor')];

        if (!priceFactorFrom && !priceFactorTo) {
            setErrors({ [nameof<DrivingAreaSpecialLocation>('fromPriceFactor')]: 
            "Du må legge til Fra prisfaktor og-eller Til prisfaktor." });
            return;
        }

        if (values && typeof addOrUpdateSpecialLocation === 'function') {

            let result: DrivingAreaSpecialLocation = {...values};
            result.fromPriceFactor = !result.fromPriceFactor || result.fromPriceFactor as unknown as string === '' ? 0: result.fromPriceFactor;
            result.toPriceFactor = !result.toPriceFactor || result.toPriceFactor as unknown as string === '' ? 0: result.toPriceFactor;

            addOrUpdateSpecialLocation(result);
            closeEditor();
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialData}
            validationSchema={specialLocationPriceConfigValidation}
            onSubmit={(values, { setErrors, setFieldValue}) => { handleFormSubmit(values, setErrors, setFieldValue) }}
        >
            {(formik) => (
                <Form className='ui form' onSubmit={formik.handleSubmit} autoComplete='off' data-testid="special-location-editor-form">

                        <MyTextInput
                            data-testid={nameof<DrivingAreaSpecialLocation>('name')}
                            label={'Navn'}
                            name={nameof<DrivingAreaSpecialLocation>('name')}
                            type="text"
                            onChange={(e) => {
                                const newValue = e && e.target && e.target.value && e.target.value === ' ' ? '': e.target.value;

                                formik.setFieldValue(nameof<DrivingAreaSpecialLocation>('name'), newValue)
                            }}
                        />
                        <MyTextInput
                            data-testid={nameof<DrivingAreaSpecialLocation>('latitude')}
                            label={'Latitude'}
                            name={nameof<DrivingAreaSpecialLocation>('latitude')}
                            type="number"
                        />

                        <MyTextInput
                            data-testid={nameof<DrivingAreaSpecialLocation>('longitude')}
                            label={'Longitude'}
                            name={nameof<DrivingAreaSpecialLocation>('longitude')}
                            type="number"
                        />

                        <MyTextInput
                            label={'Radius'}
                            data-testid={nameof<DrivingAreaSpecialLocation>('radiusMeters')}
                            name={nameof<DrivingAreaSpecialLocation>('radiusMeters')}
                            type="number"
                            onChange={(e) => {
                                formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                    formik.setFieldValue(nameof<DrivingAreaSpecialLocation>('radiusMeters'), newValue)
                                });
                            }}
                        />

                        <MyTextInput
                            label={'Fra rabatt'}
                            data-testid={nameof<DrivingAreaSpecialLocation>('fromPriceFactor')}
                            name={nameof<DrivingAreaSpecialLocation>('fromPriceFactor')}
                            type="number"
                            step={0.05}
                        
                            onChange={(e) => {
                                formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                    formik.setFieldValue(nameof<DrivingAreaSpecialLocation>('fromPriceFactor'), newValue)
                                },
                                2);

                            }}
                        />

                        <MyTextInput
                            label={'Til rabatt'}
                            data-testid={nameof<DrivingAreaSpecialLocation>('toPriceFactor')}
                            name={nameof<DrivingAreaSpecialLocation>('toPriceFactor')}
                            type="number"
                            step={0.05}
                            onChange={(e) => {
                                formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                    formik.setFieldValue(nameof<DrivingAreaSpecialLocation>('toPriceFactor'), newValue)
                                },
                                2);

                            }}
                        />
                    
                    <ModalFormFooter>
                        <AppButtonSubmit dataTestId="form-submit" onClick={formik.handleSubmit}>Ferdig</AppButtonSubmit>
                    </ModalFormFooter>

                </Form>
            )}
        </Formik>
    )
};


const mapStateToProps = (state: RootState, ownProps: any) => {
    const ownPropsResult: DrivingAreaPriceConfActionsProps = ownProps;
    let priceConfigState = state.drivingAreaPriceConfig;
    const currentSpecialLocationIndex = ownProps.currentSpecialLocation;

    return {
        priceConfigId: priceConfigState.priceConfig && priceConfigState.priceConfig.drivingAreaPriceConfigId ? priceConfigState.priceConfig.drivingAreaPriceConfigId : 0,
        specialLocation: currentSpecialLocationIndex || currentSpecialLocationIndex === 0? selectSpecialLocationByIndex(state, currentSpecialLocationIndex): null,
        addOrUpdateSpecialLocation: (item: DrivingAreaSpecialLocation) => ownPropsResult.actions.addOrUpdateSpecialLocation(item, currentSpecialLocationIndex),
        closeEditor: ownPropsResult.actions.closeEditor
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SpecialLocationEditor)
);
