import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { MyRadio, MyTextInput } from '../../../components';
import { AppButtonSubmit } from '../../../components/AppButton';
import { nameof } from '../../../utils/nameOfPropertyHelper';
import { Country } from '../../Countries/types';
import CountryRegionDrivingAreaPicker from '../../CountryRegionDrivingAreaPicker/CountryRegionDrivingAreaPicker';
import {
  AppUser, userAddRequestDataValidation, UserEditorRequestData,
  UserRolesEnum, userUpdateRequestDataValidation
} from '../../Login/types';


export interface UserEditorFormProps {
  handleFormSubmit: (data: any, userId: number | null, currentUserId: number) => void,
  pending: boolean,
  user: AppUser | null,
  currentUserId: number,
  userCountry?: Country | null,
  showRoles?: boolean,
  showRegion?: boolean
};

export const UserEditorForm: React.FC<UserEditorFormProps> = (props) => {

  const { handleFormSubmit, pending, user, currentUserId, showRoles = false, userCountry, showRegion=false} = props;

  const initialState = {
    userId: user?.userId || 0,
    email: user?.email || '',
    password: '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    id: user?.userId || 0,
    phoneNumber: user?.phoneNumber || '',
    passwordConfirmation: '',
    role: user?.role || UserRolesEnum.User,
    token: '',
    regionId: user?.regionId || null
  }

  const userId = user && user.userId && user.userId !== 0 ? user.userId : 0;
  const currenCountryId = userCountry && userCountry.countryId? userCountry.countryId: null;
  const regionId = user && user.regionId? user.regionId: null;

  const [currentUserRegionId, setRegionId] = useState<number | null>(regionId);
  const [currentUserCountryId, setCurrentUserCountry] = useState<number | null>(currenCountryId);
  

  useEffect(() => {
  
    setRegionId(regionId);
    setCurrentUserCountry(currenCountryId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  const countryChanged = (countryId: string | null) => {

    let id = countryId? parseInt(countryId): null;

    if (id === -1){
      id = null;
      userRegionChanged(null);
    }

    setCurrentUserCountry(id);
  }

  const userRegionChanged = (regionid: string | null) => {

    const id = regionid? parseInt(regionid): null;
   setRegionId(id);
  }

  const submit = (values: UserEditorRequestData) => {
    const userId = user?.userId || 0;
    values.regionId = currentUserRegionId;
    handleFormSubmit(values, userId, currentUserId);
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialState}
      validationSchema={userId && userId !== 0 ? userUpdateRequestDataValidation : userAddRequestDataValidation}
      onSubmit={(values, { setErrors }) => { submit(values as UserEditorRequestData) }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off' data-testid='test-user-editor-form'>

          <MyTextInput type='hidden' name={nameof<UserEditorRequestData>('userId')} placeholder='' />
          <MyTextInput data-testid={nameof<UserEditorRequestData>('email')} name={nameof<UserEditorRequestData>('email')} placeholder='E-post' />
          <MyTextInput data-testid={nameof<UserEditorRequestData>('firstName')} name={nameof<UserEditorRequestData>('firstName')} placeholder='Fornavn' />
          <MyTextInput data-testid={nameof<UserEditorRequestData>('lastName')} name={nameof<UserEditorRequestData>('lastName')} placeholder='Etternavn' />
          <MyTextInput data-testid={nameof<UserEditorRequestData>('password')} type='password' name={nameof<UserEditorRequestData>('password')} placeholder='Passord' />
          <MyTextInput data-testid={nameof<UserEditorRequestData>('passwordConfirmation')} type='password' name={nameof<UserEditorRequestData>('passwordConfirmation')} placeholder='Passord bekreftelse ' />
          <MyTextInput data-testid={nameof<UserEditorRequestData>('phoneNumber')} name={nameof<UserEditorRequestData>('phoneNumber')} placeholder='Telefonnummer ' />

          {
            showRegion? <div className="wrapperWithBorder" style={{ marginBottom: '20px', padding:'10px',  }}>
            <CountryRegionDrivingAreaPicker
              addAllregions={true}
              showRegions={true}
              itemsPosition="column"
              currentCountryId={currentUserCountryId}
              onRegionChanged={userRegionChanged}
              onCountryChanged={countryChanged}
              propToResetState={userId}
              currentRegionId={currentUserRegionId || null}
            />
          </div>: ''
          }

          {
            showRoles ? (<div role="group" aria-labelledby="my-radio-group">

              <MyRadio
                name={nameof<UserEditorRequestData>('role')}
                value={UserRolesEnum.Admin}

                label="Admin"
                onChange={() => {
                  setFieldValue(nameof<UserEditorRequestData>('role'), UserRolesEnum.Admin)
                }}

              />
              <MyRadio
                name={nameof<UserEditorRequestData>('role')}
                value={UserRolesEnum.User}
                label="User"
                onChange={() => {
                  setFieldValue(nameof<UserEditorRequestData>('role'), UserRolesEnum.User)
                }}
              />

            </div>) : ''

          }

          <div style={{ marginTop: '20px', textAlign: 'right' }}>
            <AppButtonSubmit dataTestId="send-btn-test" onClick={handleSubmit} loading={pending} fluid designType='teal' >Send</AppButtonSubmit>
          </div>
        </Form>
      )}
    </Formik>
  )
};


