import React, { useState } from "react";
import { ActionStatus } from "../../config/constants";
import { ForgotPasswordPageView } from "./child-components/ForgotPasswordView";


interface ForgotPasswordContainerProps {}

export const ForgotPasswordContainer: React.FC<ForgotPasswordContainerProps> = props => {

  const [sending, setSending] = useState<ActionStatus>(ActionStatus.NONE);

  return (<ForgotPasswordPageView pending={sending} setSendingStatus={setSending}  />);
};

