import { AppButton } from "../../../components";
import { AppIconWithPopup, EditIcon, LinkIcon } from "../../../components/Icon";
import { dateTimeHelper } from "../../../utils/dateTimeHelper";
import { HideEditingBtns } from "../types";

interface Props extends HideEditingBtns, LinkToPublishedVersionProps {
    publishedComment?: string | null,
    publishDate: string | null,
    publishedBy: string | null,
    isDraft: boolean,
    draftLastModified: string | null,
    draftBy: string | null,
    draftComment?: string | null,
    onEdit: () => void
}

export const VersionInfo = (props: Props) => {

    const { publishedComment, publishDate, publishedBy, draftLastModified, draftComment, draftBy, hideEditingBtns,
        onEdit, goToPublishedVersion } = props;

    // link to published version is not shown if it there are no draft
    let showLinkToPublishedVersion = publishDate && draftLastModified? true: false;

    // link to published version is not shown if it is a view for published version.
    showLinkToPublishedVersion = hideEditingBtns? false: showLinkToPublishedVersion;


    return <div className="version-info-config"> <table className="ui collapsed table">
        <thead>
            <tr>
                <th className="" style={{ display: 'flex' }}>
                    <span className="ui header version-info-header">
                        Info
                    </span>
                </th>
            </tr>
        </thead>

        <tbody>
            <tr data-testid="test-version-info" style={{ boxShadow: 'none !important' }}>
                <td style={{ padding: '24px' }}>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }}>
                        Publisert:</div>
                    <span data-testid='test-published-date'>{publishDate ?
                        <>{dateTimeHelper.getLocalFromUtcStrFormated(publishDate)}
                            {showLinkToPublishedVersion? <LinkToPublishedVersion goToPublishedVersion={goToPublishedVersion} />: ''}             
                        </>
                        : 'Ikke publisert'}
                    </span>

                    {
                        publishedBy ? <><div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }}>
                            Publisert av:</div>
                            <span data-testid='test-published-by'>{publishedBy}</span></> : ''
                    }

                    {
                        publishedComment ? <AppIconWithPopup color='grey' iconName='comment alternate' content={publishedComment || '-'} />

                            : ''
                    }

                    {
                        draftLastModified ? <>
                            <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }}>
                                Draft lagret:</div>
                            <span data-testid='test-draft-modified-date'>{draftLastModified ? dateTimeHelper.getLocalFromUtcStrFormated(draftLastModified) : ''}</span>
                        </> : ''
                    }

                    {
                        draftBy ? <><div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }}>
                            Draft endret av :</div>
                            <span data-testid='test-draft-modified-by'>{draftBy}</span></> : ''
                    }

                    {
                        draftComment ? <AppIconWithPopup color='grey' iconName='comment alternate' content={draftComment || '-'} />
                            : ''
                    }
                </td>


            </tr>

        </tbody>
        {
            hideEditingBtns ? null : <tfoot style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }} >
                <tr style={{ boxShadow: 'none !important' }}>

                    <td>

                        <div className="ui icon buttons">

                            <AppButton
                                size='mini'
                                dataTestId="edit-main-country-version-info-btn"
                                onClick={onEdit}>
                                <><EditIcon />
                                </>
                            </AppButton>

                        </div>
                    </td></tr>
            </tfoot>
        }

    </table></div>

}

interface LinkToPublishedVersionProps {
    goToPublishedVersion?: () => string
}

const LinkToPublishedVersion = (props: LinkToPublishedVersionProps) => {
    const {goToPublishedVersion = ()=> ''} = props;

    const urlToPublishedVersion = goToPublishedVersion();

    return <a data-testid='test-link-to-published-version' href={urlToPublishedVersion} target="_blank" rel="noreferrer"
        style={{ color: 'grey', cursor: 'pointer' }}>
        <LinkIcon />
    </a>
}
