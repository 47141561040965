import { useEffect } from "react";
import { useParams } from "react-router";
import { CountryUrlParam } from "../config/routes";

export function useCountryIdAssigner(set_current_country: (countryId:number)=> void) {

    let { fromUrlCountryId: fromUrlCountryIdStr} = useParams<CountryUrlParam>();
    let fromUrlCountryId = 0;

    try {
        fromUrlCountryId = fromUrlCountryIdStr? parseInt(fromUrlCountryIdStr): 0;
     }
     catch (e) {
        console.log("Could not parseInt(fromUrlCountryIdStr)");
     }

   
    useEffect(() => {

        if (fromUrlCountryId){
            set_current_country(fromUrlCountryId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return fromUrlCountryId;
  }