import React from 'react';
import { AppDropdown } from '.';
import { AppDropdownItemProps } from './AppDropdown';

interface AppItemPickerProps <T> {
    items: Array<T>,
    oneItemHeaderTitle: string,
    currentItemId: number | null,
    noElementsText?: string | null,
    placeholderText: string | null,
    width: string | null,
    showDisabledWhenEmpty?: boolean,
    testId?:string,
    getItemId: (item: T) => number | null,
    getItemName: (item: T) => string,
    changeCurrent: (id: string | number | null) => void
}

export const AppItemPicker = <T extends {}>(props: AppItemPickerProps<T>) => {

    let { items, testId, currentItemId, width, showDisabledWhenEmpty=false, placeholderText = 'Velg', noElementsText = 'Legg til minst 1 element', changeCurrent, getItemId, getItemName} = props;

    items = items || [];

    let currentId = currentItemId || '';

    if (showDisabledWhenEmpty === false && items.length <= 0) {
        return <div>{noElementsText}</div>;
    }

    const itemsResult: Array<AppDropdownItemProps> = items.map(item => {
        const itemId = getItemId(item);
        const name = getItemName(item);
        return {
             key: itemId? itemId.toString(): '', 
             text: name || '', 
             value: itemId? itemId.toString(): ''};
    })


    return (<div data-testid={testId} className="driving-area-picker"> 
    <AppDropdown showAsDisabledWhenEmpty={showDisabledWhenEmpty} 
        disabled={items.length <=0? true: false} 
        width={width} onChanged={(value) => changeCurrent(value)} items={itemsResult} 
        placeholderText={placeholderText || ''}
        currentValue={currentId?.toString()}/>
    </div>
    )
};
