interface AppLabelProps{
    color: AppLabelColor | string,
    text:string | any,
    dataTestId?: any
}

export type AppLabelColor = 'red' | 'blue' | 'olive' | 'yellow' | 'teal' | 'green' | 'brown' | 'grey' | 'black' | 'violet' | 'pink' | 'purple' | string;

export const AppLabel: React.FC<AppLabelProps> = (props) => {
    const {color, text, dataTestId} = props;

    return <div data-testid={dataTestId? dataTestId: 'app-label'} className={`ui ${color} horizontal label`}>{text}</div>
}
