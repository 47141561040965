import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppNoItems, Spinner } from '../../../../components';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { PublishBtns } from '../../CommonComponents/PublishBtns';
import { VersionInfo } from '../../CommonComponents/VersionInfo';
import { DayTimeZones } from '../../CommonComponents/DayTimeZones';
import {
    CountryPriceConfigCarType,
    CountryPriceConfig,
    CountryPriceConfigDayTimeZone, CountryPriceConfigDayTypeFactor, HideEditingBtns
} from '../../types';
import { CountryPriceConfActionsProps, ModalType } from '../types';
import { CarTypes } from './CarTypes';
import { DayTypes } from './DayTypes';
import { MainConfigInfo } from './MainConfigInfo';


interface PropsFromReducer {
    dayTimeZoneFactors: Array<CountryPriceConfigDayTimeZone>,
    dayTypeFactors: Array<CountryPriceConfigDayTypeFactor>,
    carTypeFactors: Array<CountryPriceConfigCarType>
    countryPriceConfig: CountryPriceConfig | null,
    countryPriceConfigLoading: boolean,
    countryPriceConfigIsEdited: boolean,
    publishing: boolean,
    saving: boolean,
    comment: string | null,
    resetting: boolean
}

type OwnProps = CountryPriceConfActionsProps  & HideEditingBtns;

const MainConfig: React.FC<PropsFromReducer & OwnProps> = (props) => {
    const { dayTimeZoneFactors, dayTypeFactors, countryPriceConfig, carTypeFactors,
        countryPriceConfigLoading, countryPriceConfigIsEdited, publishing, saving,
        resetting } = props;

    const { actions, hideEditingBtns } = props;

    const wrapperStyle = { minHeight: '40vh' };

    if (countryPriceConfigLoading) {
        return <div className="display-flex-center-both" style={wrapperStyle}><Spinner /></div>
    }

    if (!countryPriceConfig) {
        return <AppNoItems text={'Velg et country'} />
    }

    return <div style={wrapperStyle} className="country-price-config-main">

         {
            hideEditingBtns ? '' :
            <div className="price-config-publish-reset-btns" style={{ width: '100%', display: 'flex', padding: '0px 0px', justifyContent: 'flex-end', alignItems: 'center' }}>

                <PublishBtns
                    isPublished={!countryPriceConfig.isDraft}
                    publishing={publishing}
                    resetting={resetting}
                    saving={saving}
                    isEdited={countryPriceConfigIsEdited}
                    hasPublishedRecords={countryPriceConfig.publishedDate !== null}
                    publish={actions.publishConfig}
                    resetChanges={() => {
                        actions.resetDraft()
                    }} />
            </div>
        }

        <div className="tab-page country-price-config-main-cols">

            <div className="country-price-config-main-col1 price-conf-col" >
                <div className="country-price-config-el">
                    <VersionInfo
                        hideEditingBtns={hideEditingBtns}
                        publishedComment={countryPriceConfig.publishedComment || ''}
                        publishDate={countryPriceConfig.publishedDate}
                        publishedBy={countryPriceConfig.publishedByName}
                        draftComment={countryPriceConfig.draftComment}
                        isDraft={countryPriceConfig.isDraft}
                        draftLastModified={countryPriceConfig.draftLastModifiedDate}
                        draftBy={countryPriceConfig.draftModifiedByName}
                        goToPublishedVersion={actions.goToPublishedVersion}
                        onEdit={() => actions.edit(null, ModalType.versionInfo)}
                    />

                </div>
                <div className="country-price-config-el">
                    <MainConfigInfo 
                    actions={actions} 
                    countryPriceConfig={countryPriceConfig} 
                    hideEditingBtns={hideEditingBtns} />
                </div>

                {carTypeFactors && carTypeFactors.length > 0 ?
                    <div className="country-price-config-el">
                        <CarTypes 
                        actions={actions} 
                        carTypeFactors={carTypeFactors} 
                        hideEditingBtns={hideEditingBtns} />

                    </div> 
                : ''}
            </div>

            <div className="country-price-config-main-col2 price-conf-col" >
                <div className="country-price-config-el">
                    <DayTimeZones 
                    hideEditingBtns={hideEditingBtns}
                    edit={(index) => actions.edit(index, ModalType.dayTimeZoneFactors)} 
                    dayTimeZoneFactors={dayTimeZoneFactors} />
                </div>
            </div>

            <div className="country-price-config-main-col3 price-conf-col" >
                <div className="country-price-config-el">
                    <DayTypes 
                    hideEditingBtns={hideEditingBtns} 
                    actions={actions} 
                    dayTypeFactors={dayTypeFactors} />
                </div>
            </div>

        </div>

    </div>
}


const mapStateToProps = (state: RootState, ownProps: any) => {

    const countryPriceConfState = state.countryPriceConfig;
    const props = ownProps as OwnProps;

    return {
        actions: props.actions,
        dayTimeZoneFactors: countryPriceConfState.dayTimeZones || [],
        dayTypeFactors: countryPriceConfState.dayTypes || [],
        carTypeFactors: countryPriceConfState.carTypes,
        countryPriceConfig: countryPriceConfState.priceConfig,
        countryPriceConfigLoading: countryPriceConfState.loading,
        countryPriceConfigIsEdited: countryPriceConfState.isEdited,
        publishing: countryPriceConfState.publishing || false,
        saving: countryPriceConfState.saving,
        comment: countryPriceConfState.comment || null,
        resetting: countryPriceConfState.resettingDraft || false,

    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainConfig)
);



