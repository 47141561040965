import React from 'react';
import { AppButton, AppLabel, AreYouSureAppButton } from '../../../../components';
import { AppTitleMobileOnly } from '../../../../components/AppTitleMobileOnly';
import { CalenderIcon, CalenderOutlineIcon, DeleteIcon, DownloadIcon, EditIcon, EyeIcon, EyeIconSlash } from '../../../../components/Icon';
import { AppActiveStatus, AppDiscountStatus, AppPublishStatus, getAppActiveStatusData, getAppPublishedStatusData, getDiscountStatusData } from '../../../../config/constants';
import { AppDiscountListElement, DiscountsActions } from '../../types';
import { dateTimeHelper } from '../../../../utils/dateTimeHelper';
import { DateTime } from 'luxon';
import { getDiscountStatus } from '../../reducers/discountsReducers';

type goToDetailsFn = (id: number) => void;

interface Props {
    discounts?: Array<AppDiscountListElement>,
    statisticsLoading: boolean,
    statisticsDiscountId:number | null,
    goToDetails: any,
    discountStatisticsBtn:any,
    actions: DiscountsActions,
}

const TABLE_TEXTS = {
    start: 'Starttidspunkt',
    end: 'Slutttidspunkt',
    name: 'Kampanjenavn',
    isActive: 'Aktiv',
    code: 'Kode',
    status: 'Status',
    goingOn: 'I gang',
    used: 'Brukt',
    openAction: 'Åpne/Aktiver/Slett'
}

export const DiscountsList: React.FC<Props> = (props) => {
    const { discounts = [], goToDetails, actions, discountStatisticsBtn, statisticsLoading, statisticsDiscountId} = props;

    let result = null;

    if (discounts && discounts.length > 0) {
        result = discounts.map((el, index) => {
            return <DiscountListElement key={index} item={el} goToDetails={goToDetails} actions={actions} 
            discountStatisticsBtn={discountStatisticsBtn} statisticsLoading={statisticsLoading} statisticsDiscountId={statisticsDiscountId} />
        })
    }

    return (<table className="discounts-table ui collapsing table" style={{ width: '100%' }}>
        <thead className="mobile-hidden sticky" style={{ zIndex: 10 }}>

            <tr className="app-discount-table-header ">
                <th className="two wide" style={{ textAlign: 'left' }}>{TABLE_TEXTS.start}</th>
                <th className="two wide" style={{ textAlign: 'left' }}>{TABLE_TEXTS.end}</th>
                <th className="four wide" >{TABLE_TEXTS.name}</th>
                <th className="table-cell-content-center-non-mobile table-cell-content-left-mobile">{TABLE_TEXTS.code}</th>
                <th className="two table-cell-content-center-non-mobile table-cell-content-left-mobile">{TABLE_TEXTS.status}</th>
                <th className="table-cell-content-center-non-mobile table-cell-content-left-mobile">{TABLE_TEXTS.used}</th>
                <th className="two wide " style={{ textAlign: 'right' }}>{TABLE_TEXTS.openAction}</th>
            </tr>
        </thead>
        {result && result.length > 0 ? <tbody className="">{result}</tbody> : <tbody></tbody>}
    </table>)
}

const DiscountListElement: React.FC<{ item: AppDiscountListElement, goToDetails: goToDetailsFn, actions: DiscountsActions,
    discountStatisticsBtn:any, statisticsLoading: boolean, statisticsDiscountId:number | null
 }> = (props) => {
    let { item, statisticsLoading, statisticsDiscountId, goToDetails, actions } = props;

    item = item || {};

    const start = item.start? (dateTimeHelper.getLocalFromUtcStrFormated(item.start, 'dd.LL.yy, HH:mm ')) : "...";
    const end = item.end? (dateTimeHelper.getLocalFromUtcStrFormated(item.end, 'dd.LL.yy, HH:mm ')) : "...";

    const dateTypeEnd = item.end? dateTimeHelper.getLocalFromUtcStr(item.end): null;

    let stateOfDiscount: 'draft' | 'inactivePub' | 'activePub' | null = null;

    if (item.publishedDate == null && !item.isActive ){
        stateOfDiscount = 'draft';
    }
    else if (item.publishedDate != null && !item.isActive) {
        stateOfDiscount = 'inactivePub';
    }
    else if (item.publishedDate != null && item.isActive) {
        stateOfDiscount = 'activePub';
    }

    let discountStatus = getDiscountStatus(item);

    // to disable opportunity to activate finished discount
    const finished = item.end? dateTimeHelper.checkIfBeforeNow(dateTypeEnd as unknown as DateTime): false;

    return (<tr 
        data-testid={'test-discount-' + item.discountId} className={`app-discounts-item discount-${stateOfDiscount + '-'}status`}>
        <td data-testid="test-discount-start" > 
            <AppTitleMobileOnly text={TABLE_TEXTS.start} /> <CalenderIcon /> {start ||  ' - '}</td>
        <td data-testid="test-discount-end" > 
            <AppTitleMobileOnly text={TABLE_TEXTS.end} /> <CalenderIcon /> {end || ' - '}</td>
        <td data-testid="test-discount-name" > 
            <AppTitleMobileOnly text={TABLE_TEXTS.name} /> {item.name || '-'}</td>
        <td data-testid="test-discount-code" className='table-cell-content-center-non-mobile table-cell-content-left-mobile'> 
            <AppTitleMobileOnly text={TABLE_TEXTS.code} /> 
            {item.code || '-'}</td>
        <td data-testid="test-discount-status" className='table-cell-content-center-non-mobile table-cell-content-left-mobile'> 
            <AppTitleMobileOnly text={TABLE_TEXTS.status} />
                <AppLabel text={discountStatus.text} color={discountStatus.color} />
        </td>
        <td data-testid="test-discount-used" className='table-cell-content-center-non-mobile table-cell-content-left-mobile '> 
        <AppTitleMobileOnly text={TABLE_TEXTS.used} />
            <div className="table-cell-used" style={{display:'flex', justifyContent: 'center', alignItems:'center'}}>
               
                <div style={{width:'45px'}}> {item.totalNumberOfTimesUsed || 0} </div>

                <div className="ui icon buttons">
                    <AppButton 
                        designType="grey" 
                        loading={statisticsLoading && statisticsDiscountId === item.discountId}
                        size='mini' 
                        onClick={() => { actions.getStatistics(item.discountId as number) }}>
                            <>
                            <DownloadIcon />
                            </>
                    </AppButton>
                </div>
                
            </div>
         </td>
        <td data-testid="test-discount-btns" className="discount-element-edit-btns" style={{ textAlign: 'right' }}>

            <div className="ui icon buttons">
                <AppButton dataTestId={"test-discount-element-editor-" + item.discountId} 
                size='mini' onClick={() => goToDetails(item.discountId as number)}><>
                    <EditIcon /></>
                </AppButton>

                <div style={{ marginRight: '2px' }} />

                <AppButton disabled={finished && item.publishedDate != null} designType="teal" dataTestId={"test-discount-element-activate-" + item.discountId} size='mini'
                    onClick={() => { actions.activateDeactivateDiscount({ discountId: item.discountId as number, isActive: !item.isActive }) }}>
                    <>
                        {!item.isActive ?
                            <EyeIcon /> :
                            <EyeIconSlash />}
                    </>
                </AppButton>

                <div style={{ marginRight: '2px' }} />

                <AreYouSureAppButton
                    dataTestIdOk={"test-discount-do-delete-" + item.discountId}
                    dataTestIdConfirm={'test-discount-delete-confirm' + item.discountId}
                    mainBtndesignType="negative" 
                    size='mini'
                    text={<><DeleteIcon /></>}
                    onClick={() => {actions.deleteDiscount({discountId: item.discountId as number})}}
                />

            </div>

        </td>
    </tr>)
}

