import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppDropdown, ModalFormFooter, MyTextInput } from '../../../../components';
import { AppButtonSubmit } from '../../../../components/AppButton';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { formPrepareAndRoundNumber, getInputValue } from '../../../../utils/formHelpers';
import { nameof } from '../../../../utils/nameOfPropertyHelper';
import { DrivingAreaPriceConfig, ServiceChargeType, getServiceChargeType } from '../../types';
import { updatePriceConfig } from '../reducers/drivingAreaPriceConfigReducers';
import { DrivingAreaPriceConfActionsProps, drivingAreaPriceConfigValidation } from '../types';
import { AppDropdownItemProps } from '../../../../components/AppDropdown';



interface PropsFromReducer {
    priceConfig: DrivingAreaPriceConfig | null,
    update_price_config: (priceConfig: DrivingAreaPriceConfig) => void
};

type MainPriceConfigEditorProps = PropsFromReducer & DrivingAreaPriceConfActionsProps


const MainDrivingAreaPriceConfigEditor: React.FC<MainPriceConfigEditorProps> = (props) => {

    let { priceConfig, update_price_config, actions } = props;

    if (!priceConfig) {
        return null;
    }

    const handleFormSubmit = async (values: any, setErrors: any) => {

        if (values && typeof update_price_config === 'function') {
            update_price_config(values);
            actions.closeEditor();
        }

    }


    const serviceChargeDropdownTypes: Array<AppDropdownItemProps> = [];

    for (let i = ServiceChargeType.Percent; i <= ServiceChargeType.Amount; ++i) {
        const amountType = getServiceChargeType(i);

        const temp = {
            key: i.toString(),
            text: amountType.text || '',
            value: i.toString()
        };

        serviceChargeDropdownTypes.push(temp);
    }
    


    return (
        <Formik
            enableReinitialize={true}
            initialValues={priceConfig}
            validationSchema={drivingAreaPriceConfigValidation}
            onSubmit={(values, { setErrors }) => { handleFormSubmit(values, setErrors) }}
        >
            {(formik) => (
                <Form className='ui form' onSubmit={formik.handleSubmit} autoComplete='off' data-testid="driving-area-price-main-conf-form">

                    <MyTextInput
                        extraProps={{fullWidth: true}}
                        label={'Prisfaktor'}
                        step={0.05}
                        data-testid={nameof<DrivingAreaPriceConfig>('priceFactor')}
                        name={nameof<DrivingAreaPriceConfig>('priceFactor')}
                        type="number"
                        onChange={(e) => {
                            formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                formik.setFieldValue(nameof<DrivingAreaPriceConfig>('priceFactor'), newValue)
                            },
                            2);

                        }}
                    />

                    <MyTextInput
                        extraProps={{fullWidth: true}}
                        label={'Minstepris'}
                        data-testid={nameof<DrivingAreaPriceConfig>('minPrice')}
                        name={nameof<DrivingAreaPriceConfig>('minPrice')}
                        type="number"
                        onChange={(e) => {
                            formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                formik.setFieldValue(nameof<DrivingAreaPriceConfig>('minPrice'), newValue)
                            });

                        }}
                    />

                    <div style={{marginTop:'15px', border:'1px solid lightgray', borderRadius:'4px', padding: '15px'}}>

                        <MyTextInput
                            extraProps={{fullWidth: true}}
                            label={'Servicetillegg'}
                            step={1}
                            data-testid={nameof<DrivingAreaPriceConfig>('serviceCharge')}
                            name={nameof<DrivingAreaPriceConfig>('serviceCharge')}
                            type="number"
                            onChange={(e) => {
                                formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                    
                                    if (newValue == "" || newValue == null ){
                                        formik.setFieldValue(nameof<DrivingAreaPriceConfig>('serviceCharge'), null);
                                        formik.setFieldValue(nameof<DrivingAreaPriceConfig>('serviceChargeTypeId'), null);
                                    }
                                    else {
                                        formik.setFieldValue(nameof<DrivingAreaPriceConfig>('serviceCharge'), newValue)
                                    }
                                },
                                1);

                            }}
                        />

                        <div className='field discount-amount-type'>
                            <label>Prosent/beløp:</label>
                                <AppDropdown
                                    showAsDisabledWhenEmpty={false}
                                    width={'100%'}
                                    onChanged={(newValue) => {
                                        formik.setFieldValue(nameof<DrivingAreaPriceConfig>('serviceChargeTypeId'), parseInt(newValue));
                                    }}
                                    items={serviceChargeDropdownTypes}
                                    placeholderText={'Prosent/beløp'}
                                    currentValue={formik.values.serviceChargeTypeId? formik.values.serviceChargeTypeId.toString(): ''} />
                            </div>

                        <MyTextInput
                            extraProps={{fullWidth: true}}
                            label={'Servicetillegg, MVA'}
                            step={0.01}
                            data-testid={nameof<DrivingAreaPriceConfig>('serviceChargeVatRate')}
                            name={nameof<DrivingAreaPriceConfig>('serviceChargeVatRate')}
                            type="number"
                            onChange={(e) => {
                                formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {

                                    if (newValue == "" || newValue == null ){
                                        formik.setFieldValue(nameof<DrivingAreaPriceConfig>('serviceChargeVatRate'), null);
                                    }
                                    else {
                                        formik.setFieldValue(nameof<DrivingAreaPriceConfig>('serviceChargeVatRate'), newValue)
                                    }
                                },
                                2);
                            }}
                        />
                    </div>


                    <ModalFormFooter>
                        <AppButtonSubmit dataTestId="form-submit" onClick={formik.handleSubmit}>Ferdig</AppButtonSubmit>
                    </ModalFormFooter>

                </Form>
            )}
        </Formik>
    )
};


const mapStateToProps = (state: RootState, ownProps: any) => {

    const priceConfState = state.drivingAreaPriceConfig;
    const props = ownProps as DrivingAreaPriceConfActionsProps;

    return {
        actions: props.actions,
        priceConfig: priceConfState.priceConfig

    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        update_price_config: (priceConfig: DrivingAreaPriceConfig) => dispatch(updatePriceConfig(priceConfig)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainDrivingAreaPriceConfigEditor)
);
