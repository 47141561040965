import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Spinner } from "../../components";
import { AppItemPicker } from "../../components/AppItemPicker";
import { RootState } from "../../config/reducerConfig/rootReducer";
import { Country, getCountryId, getCountryName } from "../Countries/types";
import { selectDrivingAreasByRegionId } from "../DrivingAreas/reducers/drivingAreasReducers";
import { DrivingArea, getDrivingAreaId, getDrivingAreaName } from "../DrivingAreas/types";
import { selectRegionById, selectRegionsByCountryId } from "../Regions/reducers/regionsReducers";
import { getRegionId, getRegionName, Region } from "../Regions/types";


interface OwnProps {
    showDrivingAreas?: boolean,
    showRegions: boolean,
    itemsPosition?: 'row' | 'column',
    showCurrentRegionLabel?: boolean,
    addAllregions?: boolean,
    addAllDrivingAreas?: boolean,
    currentCountryId: number | null,
    loading: boolean,
    currentRegionId: number | null,
    currentRegion: Region | null,
    currentDrivingAreaId: number | null,
    propToResetState: any,
    onCountryChanged: (countryId: number | string | null) => void,
    onRegionChanged: (regionId: number | string | null) => void,
    onDrivingAreaChanged: (drivingAreaId: number | string | null) => void
}

interface Props {
    regions: Array<Region>
    countries: Array<Country>
    drivingAreas: Array<DrivingArea>

}

type CountryRegionDrivingAreaPickerProps = Props & OwnProps;

const CountryRegionDrivingAreaPicker = (props: CountryRegionDrivingAreaPickerProps) => {
    const { currentRegion, propToResetState, loading, itemsPosition = 'column',
        showCurrentRegionLabel = true } = props;

    const [resettingDropdown, resetDropdown] = useState<boolean>(false);


    useEffect(() => {

        resetDropdown(true);

        let timer = setTimeout(() => {
            resetDropdown(false);
        }, 100)

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };

    }, [propToResetState])

    const regionName = currentRegion ? currentRegion.name : 'Alle';

    return <div className="country-region-picker" style={itemsPosition === 'row' ? { display: 'flex' } : {}}>
        {showCurrentRegionLabel ?
            <div data-testid="test-user-region" style={{ marginBottom: '15px' }}>Region: {regionName}</div> :
            ''
        }

        {resettingDropdown || loading ? <div style={itemsPosition === 'column' ? { minHeight: '50px' } : {}}><Spinner /></div> :
            <CountryRegionDropdown {...props} />}

    </div>
}

const CountryRegionDropdown = (props: CountryRegionDrivingAreaPickerProps) => {
    const { countries, currentCountryId,
        regions, currentRegionId, currentDrivingAreaId,
        itemsPosition,
        onCountryChanged, onRegionChanged, onDrivingAreaChanged,
        drivingAreas, showDrivingAreas = false, showRegions = false,
    } = props;

    const marginOfElement = itemsPosition === 'column' ? { marginBottom: '5px' } : { marginRight: '2px' };

    return <><div data-testid="test-country-region-picker" style={marginOfElement}>
        <AppItemPicker
            width='100%'
            items={countries}
            noElementsText={null}
            placeholderText="Velg land"
            oneItemHeaderTitle={"Country"}
            currentItemId={currentCountryId}
            getItemId={(item: Country) => { return getCountryId(item) }}
            getItemName={(item: Country) => { return getCountryName(item) || '' }}
            changeCurrent={(el) => { onCountryChanged(el) }}
            showDisabledWhenEmpty={false} />
        </div>
        
        {showRegions ? <div data-testid="test-regions-picker" style={marginOfElement} >
                <AppItemPicker
                    width='100%'
                    items={regions || []}
                    noElementsText="Legg til minst 1 region."
                    placeholderText="Velg region"
                    oneItemHeaderTitle={"Region"}
                    currentItemId={currentRegionId}
                    getItemId={(item: Region) => { return getRegionId(item) }}
                    getItemName={(item: Region) => { return getRegionName(item) || '' }}
                    changeCurrent={(el) => { onRegionChanged(el) }}
                    showDisabledWhenEmpty={true}

                /></div> : ''
        }


        {showDrivingAreas ?
            <div data-testid="test-driving-area-picker">
                <AppItemPicker
                    width='100%'
                    items={drivingAreas || []}
                    noElementsText={''}
                    placeholderText="Velg kjøreområde"
                    oneItemHeaderTitle={"Kjøreområdet"}
                    currentItemId={currentDrivingAreaId}
                    getItemId={(item: DrivingArea) => { return getDrivingAreaId(item) }}
                    getItemName={(item: DrivingArea) => { return getDrivingAreaName(item) || '' }}
                    changeCurrent={(el) => { onDrivingAreaChanged(el) }}
                    showDisabledWhenEmpty={true}

                /></div> : ''
        }

    </ >


}

const mapStateToProps = (state: RootState, ownProps: any) => {

    let props = ownProps as OwnProps;
    const countrysState = state.countries;
    const regionState = state.regions;
    const drivingAreaState = state.drivingAreas;
    const currentCountryId = props.currentCountryId;
    const currentRegionId = props.currentRegionId;
    const currentDrivingAreaId = props.currentDrivingAreaId;

    const countries = countrysState.list || [];
    let countrysResult: Array<Country> = [...countries];

  

    if (ownProps.addAllregions) {

        if (countries && countries.length > 0) {
            const emptyCountry: Country = {
                name: 'Alle regioner',
                countryId: -1
            }
            countrysResult.unshift(emptyCountry);
        }
    }

    let drivingAreasResult: Array<DrivingArea> = currentRegionId ? selectDrivingAreasByRegionId(state, currentRegionId) : [];

    if (currentRegionId && ownProps.addAllDrivingAreas) {

        const emptyDa: DrivingArea = {
            name: 'Alle',
            regionId: currentRegionId,
            drivingAreaId: -1
        }

        drivingAreasResult.unshift(emptyDa);
    }

    return {
        loading: countrysState.loading || regionState.loading || drivingAreaState.loading,
        currentRegion: selectRegionById(state, currentRegionId),
        regions: currentCountryId ? selectRegionsByCountryId(state, currentCountryId) : [],
        drivingAreas: drivingAreasResult,
        currentCountryId: currentCountryId || null,
        countries: countrysResult,
        currentDrivingAreaId,
        propToResetState: props.propToResetState,
        onRegionChanged: props.onRegionChanged,
        onCountryChanged: props.onCountryChanged
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CountryRegionDrivingAreaPicker)
);

