import { DateTime } from "luxon"

const defaultFormat = 'dd LLLL yyyy, HH:mm ';

//DateTime.fromFormat("01/01/2021 " + currentState.toTime, "D hh:mm:ss");
const defaultTimeFormat = 'HH:mm';

export const dateTimeHelper = {
    getDateTimeNow: (): DateTime => {
        return DateTime.now();
    },
    getDateNow: (): Date => {
        return DateTime.now().toJSDate();
    },
    formatDate: (dateStr: string, format: string = defaultFormat):string => {
        if (!dateStr)
            return '';
            
        return DateTime.fromISO(dateStr).toFormat(format);
    },
    getTimeFromString: (timeStr: string, format: string = "D " + defaultTimeFormat ): Date | null => {
        if (!timeStr)
            return null;

        try {
            return DateTime.fromFormat("01/01/2021 " + timeStr, format).toJSDate();
        }
        catch (error){
            console.log("failed to parse time");
            console.log(error);
            return null;
        }

        
    },
    getTimeStrFromDateTime: (dateTime: DateTime, format: string = defaultTimeFormat) => {

        if (dateTime){
            try {
                dateTime.set({second: 0})
                return dateTime.toFormat(format);
            }
            catch (error) {
                return null;
            }
           
        }
        return null;
    },
    getStartOfMonth: (date: Date): Date | null=> {
        if (!date){
            return null;
        }

        return DateTime.fromJSDate(date).startOf('month').toJSDate();
    },
    getStartOfNextMonth: (date: Date): Date | null=> {
        if (!date){
            return null;
        }

        let thisMonthIndex = date.getMonth();
        const nextMonth = thisMonthIndex === 11? 0: thisMonthIndex + 1;

        let nextMonthYear = date.getFullYear();
        if (thisMonthIndex === 11){
            nextMonthYear = nextMonthYear + 1;
        }

        let nextMonthDate = new Date (nextMonthYear, nextMonth, 1, 0, 0, 0);
        return nextMonthDate;
    },
    getStartOfDayFromDate: (date: Date): DateTime | null=> {
        if (!date){
            return null;
        }
        return DateTime.fromJSDate(date).startOf('day');
    },
    getEndOfDayFromDate: (date: Date): DateTime | null => {
        if (!date){
            return null;
        }
        return DateTime.fromJSDate(date).endOf('day');
    },
    getUTCStringFromDateTime: (date: DateTime):string | null => {
        if (!date){
            return null;
        }
        try {
            return date.toUTC().toISO();
        }
        catch(er){
            alert(er);
            return null;
        }
    },
    getLocalIsoStringFromJsDate: (date: Date): string | null => {
        if (!date){
            return null;
        }

        const temp = DateTime.fromJSDate(date);
        return temp.toISO({includeOffset: false});
        
    },
    // string in format: 2021-03-05T20:15:00 from server converted to local datetime
    getLocalFromUtcStr: (timeAsStr: string): DateTime | null => {

        if (!timeAsStr || timeAsStr === "" || timeAsStr === "0001-01-01T00:00:00")
        {
            return null;
        }

        let offset = - (new Date(timeAsStr).getTimezoneOffset() / 60);
        let result = DateTime.fromISO(timeAsStr).plus({hours: offset});

        result = result || "";

        return result;
    
    },
    // format should inlcude time.
    getLocalFromUtcStrFormated: (timeAsStr: string, format: string = defaultFormat): string | null => {
        if (!timeAsStr)
        {
            return null;
        }

        const result =  dateTimeHelper.getLocalFromUtcStr(timeAsStr);

        if (!result){
            return null;
        }
        else {
            return result.toFormat(format);
        }

    },
    resetDateToStartOfDay:(date:Date):Date | null => {
        try {
            date.setMinutes(0);
            date.setHours(0);
            date.setSeconds(0);
            return date;
        }
        catch(err){
            console.log(err);
            return null;
        }
       
    },
    removeSecondsFromTimeStr: (timeStr: string | null): string | null => {
        if (!timeStr){
            return null;
        }

        return timeStr.slice(0, -3);
    },
    checkIfTodayIsInsideRange(startDate:DateTime = DateTime.now(), endDate:DateTime) {
        const today = DateTime.now();
        return startDate < today && endDate > today;
    },
    checkIfBeforeNow(endDate:DateTime) {
        const today = DateTime.now();
        return today > endDate;
    }

}