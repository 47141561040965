/* Entities */
export interface AppPayment {
    paymentId: number,
    bookingId: number,
    providerReservationId: string,
    paymentMethodId: number,
    status: number,
    amountToPay:number,
    captured:number,
    refunded: number,
    refundPaymentPending?: boolean
}

/* enums, etc */
export enum AppPaymentMethod {
    Vipps = 1,
    CreditCard = 2,
    Loopback = 3 // Betalingsmåte som kan brukes for stresstest. Den sier bare automatisk at betalingen er godkjent og at ingenting mer skal skje. 

}

export const getAppPaymentMethod = (status: AppPaymentMethod): {text: string, color: 'orange' | 'blue' | 'black'} => {

    switch (status) {
        case AppPaymentMethod.Vipps:
            return { text: 'Vipps', color: 'orange' }
        case AppPaymentMethod.CreditCard:
            return { text: 'Kredittkort ', color: 'blue'}
        default:
          return {text: 'Unknown', color: 'black'}
      }
}


export interface AppPaymentState {
    payments: Array<AppPayment>,
    loading: boolean,
    refundPaymentPending: number | null
}
