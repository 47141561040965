import { ApiResponse } from '../commonTypes';
import { BaseService } from './base.service';
import { DateTime } from 'luxon';


class DashboardService extends BaseService {
  private static _authService: DashboardService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): DashboardService {
    return this._authService || (this._authService = new this('dashboard'));
  }

  public async getDashboard(): Promise<ApiResponse> {

    let axiosResponse = null;

    /** gets local date converted to utc. Data in db is in utc format */
    const startOfDay = DateTime.local().startOf('day').toUTC().toISO();
    const startOfMonth = DateTime.local().startOf('month').toUTC().toISO();

    if (!startOfDay || !startOfMonth){
      throw new Error("StartODay or StartOfMonth missing");
    }

    try {
      // axiosResponse = await this.$http.get('GetDashboard?id=1&id2=2');
      axiosResponse = await this.$http.get(`getDashboard?dayStart=${startOfDay}&monthStart=${startOfMonth}`);
      return { response: axiosResponse, data: axiosResponse.data };
    }
    catch (error) {
      this.handleAllErrors(error);
      throw error;
    }
  }

}

export const DashboardApi = DashboardService.Instance;
