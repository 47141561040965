import { ApiResponse } from '../commonTypes';
import { DrivingAreaSaveOrPublishPriceConfigData, ResetDrivingAreaPriceConfigDraftPostData } from '../features/Price/DrvingAreaPriceConfig/types';
import { BaseService } from './base.service';

class DrivingAreaPriceConfigService extends BaseService {
    private static _authService: DrivingAreaPriceConfigService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): DrivingAreaPriceConfigService {
        return this._authService || (this._authService = new this('drivingAreaPriceConfig'));
    }

    public async getDrivingAreaPriceConfigs(regionId:number): Promise<ApiResponse | { data: any }> {

        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.get('getDrivingAreaPriceConfigsByRegionId?regionId=' + regionId);
            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async getLastValidDrivingAreaPriceConfig(drivingAreaId: number, isPublished:boolean): Promise<ApiResponse | { data: any }> {

        let axiosResponse = null;

        try {
            let url = 'getLastValidDrivingAreaPriceConfig?drivingAreaId=' + drivingAreaId;

            if (isPublished){
                url += '&isPublished=' + true;
            }
            
            axiosResponse = await this.$http.get(url); 

            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async saveOrPublishRegionPriceConfig(priceConfig: DrivingAreaSaveOrPublishPriceConfigData): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('saveOrPublishDrivingAreaPriceConfig', priceConfig); 
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }

        return { response: axiosResponse, data: axiosResponse.data };
    }

    public async resetDrivingAreaPriceConfigDraft(data: ResetDrivingAreaPriceConfigDraftPostData): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('resetDrivingAreaPriceConfigDraft', data);
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }

        return { response: axiosResponse, data: axiosResponse.data };
    }


}
   
export const DrivingAreaPriceConfigsApi = DrivingAreaPriceConfigService.Instance;
