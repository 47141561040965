import React from 'react';
import { AppBreadcrumbs, Spinner } from '../../../../components';
import { AppModal } from '../../../../components/AppModal';
import { AppItemPicker } from '../../../../components/AppItemPicker';
import { PageHeaderWrapper } from '../../CommonComponents/PageHeaderWrapper';
import { CountryPriceConfActions, ModalType } from '../types';
import { CountryPriceConfigTabs } from './MainTabs';
import MainPriceConfigEditor from './MainConfigEditor';
import { MapIcon } from '../../../../components/Icon';
import { getCountryId, getCountryName, Country } from '../../../Countries/types';
import CarTypeEditor from './CarTypeEditor';
import DayTypeEditor from './DayTypeEditor';
import DayTimeZoneEditor from './DayTimeZoneEditor';
import { ModalTypeData } from '../../../../config/constants';
import { CommentEditor } from '../../CommonComponents/CommentEditor';
import { HideEditingBtns } from '../../types';


export interface CountryPriceConfigViewPropsFromReducer {
    currentCountryId: number | null,
    countriesLoading: boolean,
    countries: Array<Country>,
    comment: string,
    currentCountry: Country | null
}

interface PropsFromLocalState extends HideEditingBtns {
    modalState: ModalTypeData | null,
    actions: CountryPriceConfActions
}

export const CountryPriceConfigView: React.FC<CountryPriceConfigViewPropsFromReducer & PropsFromLocalState> = (props) => {

    const { countriesLoading, currentCountryId, countries, comment, currentCountry } = props;
    const { modalState, actions, hideEditingBtns } = props;

    if (countriesLoading) {
        return <Spinner />
    }

    let modalTitleText = null;
    let modalContentHtml = null;

    if (modalState) {
        if (modalState.modalType === ModalType.dateTypeFactors) {
            modalTitleText = "Rediger";
            modalContentHtml = <DayTypeEditor currentIndex={modalState.index} actions={actions} />
        }
        else if (modalState.modalType === ModalType.dayTimeZoneFactors) {
            modalTitleText = modalState.index || modalState.index === 0 ? "Rediger" : "Legg til døgnsone";
            modalContentHtml = <DayTimeZoneEditor currentIndex={modalState.index} actions={actions} />
        }
        else if (modalState.modalType === ModalType.countryPriceFactors) {
            modalTitleText = "Rediger";
            modalContentHtml = <MainPriceConfigEditor actions={actions} />;
        }
        else if (modalState.modalType === ModalType.carTypeFactors) {
            modalTitleText = "Rediger biltype-faktor";
            modalContentHtml = <CarTypeEditor actions={actions} currentIndex={modalState.index} />;
        }
        else if (modalState.modalType === ModalType.versionInfo) {
            modalTitleText = "Rediger/Legg til kommentar";
            modalContentHtml = <CommentEditor
                update_comment={actions.updateComment}
                comment={comment}

            />;
        }
    }

    return (<div className="main-content-item country-price-config-page">

        <AppBreadcrumbs pages={[{ name: 'Pris admin / Nasjonale variabler' }]} />

        <main className="country-price-config-page-main">

            <PageHeaderWrapper >

                <div className="price-config-area-picker-wrapper display-flex-center-y">
                    <div style={{ marginRight: '4px' }}><MapIcon /></div>

                    {
                        hideEditingBtns ?
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}> {'Publisert versjon: ' + (currentCountry?.name || '')}</div>
                            : <AppItemPicker
                                testId="test-country-picker"
                                width='12vw'
                                items={countries || []}
                                noElementsText="Legg til minst 1 country."
                                placeholderText="Velg land"
                                oneItemHeaderTitle={"Country"}
                                currentItemId={currentCountryId}
                                getItemId={(item) => { return getCountryId(item) }}
                                getItemName={(item) => { return getCountryName(item) }}
                                changeCurrent={(el) => { actions.changeCountry(el as number) }}
                            />
                    }


                </div>

            </PageHeaderWrapper>


            <CountryPriceConfigTabs actions={actions} hideEditingBtns={hideEditingBtns} />

        </main>

        {
            modalContentHtml ?
                <AppModal
                    onClose={actions.closeEditor}
                    isOpen={modalState ? true : false}
                    header={<>{modalTitleText}</>} >

                    {modalContentHtml}

                </AppModal>
                : ''
        }


    </div>
    )
};


