import React, { useEffect } from "react";
import { LoginPageView } from "./child-components/LoginPageView";


interface LoginContainerProps {
}

export const LoginContainer: React.FC<LoginContainerProps> = props => {

 return (<LoginPageView />);
};

