import { ApiResponse } from '../commonTypes';
import { AddOrUpdatePriceTestCaseRequest, DeletePriceTestCaseRequest } from '../features/Price/PriceConfigsTest/types';
import { BaseService } from './base.service';

class PriceTestCaseService extends BaseService {
    private static _authService: PriceTestCaseService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): PriceTestCaseService {
        return this._authService || (this._authService = new this('priceTestCase'));
    }

    public async getPriceTestCases(countryId: number, withSpecialLocations: boolean, withCarTypes: boolean): Promise<ApiResponse | { data: any }> {

        let axiosResponse:any = null;

        try {
            axiosResponse = await this.$http.get('getPriceTestCases?countryId=' + countryId + "&withSpecialLocations=" + withSpecialLocations + "&withCarTypes=" + withCarTypes);

            return { response: axiosResponse, data: axiosResponse.data };
           
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async addPriceTestCases(dataToServer: AddOrUpdatePriceTestCaseRequest): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('addPriceTestCase', dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async updatePriceTestCases(dataToServer: AddOrUpdatePriceTestCaseRequest): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('updatePriceTestCase', dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };

        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }

    }

    public async deletePriceTestCases(dataToServer: DeletePriceTestCaseRequest): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('deletePriceTestCase', dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

}

export const PriceTestCaseApi = PriceTestCaseService.Instance;
