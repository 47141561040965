import React from 'react';
import { AppLabel, AppTitleMobileOnly } from '../../../components';
import { CalenderIcon } from '../../../components/Icon';
import { dateTimeHelper } from '../../../utils/dateTimeHelper';
import { AppBooking, getLogStatusData, BookingLogType } from '../types';
import { getAppBookingTripCategory } from '../../TripCategory/types';


interface Props {
    bookings?: Array<AppBooking>,
    goToBookingDetails: (bookingId: number) => void
}

const TABLE_TEXTS = {
    bookingNumber: 'Bookingnummer',
    license: 'Løyve',
    customer: 'Kunde',
    bookingStatus: 'Bookingstatus',
    tripStatus: 'Turstatus',
    paymentStatus: 'Betalingsstatus',
    created: 'Opprettet'
}


export const BookingsList: React.FC<Props> = (props) => {
    const { bookings = [], goToBookingDetails } = props;

    let result = null;

    if (bookings && bookings.length > 0) {
        result = bookings.map((el, index) => {
            return <BookingListElement key={index} booking={el} goToBookingDetails={goToBookingDetails} />
        })
    }

    return (<table className="app-booking-list ui very basic table fixed">
        <thead >

            <tr className="app-booking-table-header sticky" style={{zIndex:100, backgroundColor:'#ffffffeb', }}>
                <th className="">{TABLE_TEXTS.bookingNumber}</th>
                <th className="">{TABLE_TEXTS.license}</th>
                <th className="">{TABLE_TEXTS.customer}</th>
                <th className="app-booking-status">{TABLE_TEXTS.bookingStatus}</th>
                <th className="app-booking-status">{TABLE_TEXTS.tripStatus}</th>
                <th className="app-booking-status">{TABLE_TEXTS.paymentStatus}</th>
                <th className="">{TABLE_TEXTS.created}</th>
                
            </tr>
        </thead>
        {result && result.length > 0 ? <tbody className="">{result}</tbody> : <tbody></tbody>}
    </table>)
}

interface BookingListElementProps {
    booking: AppBooking,
    goToBookingDetails: (bookingId: number) => void
}

const BookingListElement: React.FC<BookingListElementProps> = (props) => {
    let { booking, goToBookingDetails } = props;

    booking = booking || {};

    const tripStatusData = getLogStatusData(booking.tripStatus, BookingLogType.TripLog);
    const bookingStatusData = getLogStatusData(booking.bookingStatus, BookingLogType.BookingLog);
    const paymentStatusData = getLogStatusData(booking.paymentStatus, BookingLogType.PaymentLog);

    console.log(booking.tripCategory);
    const customerIcon = getAppBookingTripCategory(booking.tripCategory);

    let customerData = '-';

    if (booking.customerId){
        customerData = booking.customerPhone || '-';
    }
    else if (booking.clientId){
        customerData = booking.clientName || '';
    }

    return (<tr style={{ cursor: 'pointer' }} 
        onClick={() => { goToBookingDetails(booking.bookingId) }} 
        data-testid={'test-booking-' + booking.bookingId} className="app-bookings-booking">
        <td> <AppTitleMobileOnly text={TABLE_TEXTS.bookingNumber} />
         {booking.bookingNumber || '-'} {booking.isPreOrder? <CalenderIcon />:null } </td>
        <td> <AppTitleMobileOnly text={TABLE_TEXTS.license} /> {booking.carId || '-'}</td>
        <td> <AppTitleMobileOnly text={TABLE_TEXTS.customer} /> 
            {
                customerIcon.icon? customerIcon.icon: null
            }
            {
                customerData || '-'
            }
        
        </td>
        <td className="app-booking-status" > <AppTitleMobileOnly text={TABLE_TEXTS.bookingStatus}  style={{textAlign: 'left'}} /><AppLabel color={bookingStatusData ? bookingStatusData.color : 'black'} text={bookingStatusData ? bookingStatusData.text : '-'} /></td>
        <td className="app-booking-status" > <AppTitleMobileOnly text={TABLE_TEXTS.tripStatus} /> <AppLabel color={tripStatusData ? tripStatusData.color : 'black'} text={tripStatusData ? tripStatusData.text : '-'} /></td>
        <td className="app-booking-status" > <AppTitleMobileOnly text={TABLE_TEXTS.paymentStatus} /> <AppLabel color={paymentStatusData ? paymentStatusData.color : 'black'} text={paymentStatusData ? paymentStatusData.text : '-'} /></td>
        <td> <AppTitleMobileOnly text={TABLE_TEXTS.created} /> {dateTimeHelper.getLocalFromUtcStrFormated(booking.createdDate) || "-"}</td>
    </tr>)
}
