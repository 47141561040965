

export const TableHeaderMobileOnly = (props: {text:string}) => {
    const {text} = props;

    if (!text){
        return null;
    }

    return (<thead className="mobile-only">
        <tr style={{ fontSize: 'large', textAlign:'left'}}>
            <th style={{fontWeight: 'bold'}}>{text}</th>
        </tr>
    </thead>);
}