import { AppButton } from "../../../../components"
import { EditIcon } from "../../../../components/Icon"
import {  HideEditingBtns } from "../../types";
import { DrivingAreaPriceConfActionsProps, DrivingAreaPriceConfigModalType, PreOrderConfig } from "../types";


interface Props extends HideEditingBtns {
    preOrderConfig: PreOrderConfig  | null,
}

export const PreOrderInfo = (props: Props & DrivingAreaPriceConfActionsProps) => {

    const { preOrderConfig, actions, hideEditingBtns } = props;
    const { minimumTimeUntilPickupInMinutes,
        maximumTimeUntilPickupInMinutes } = preOrderConfig || {};

    return <div  style={{ minWidth: '350px' }}> <table className="ui collapsed table">
        <thead>
            <tr>
                <th className="main-config-header" style={{ display: 'flex' }}>
                    <span className="ui header main-config-header" data-testid="preOrder-title">
                    &nbsp;
                    </span>
                </th>
            </tr>
        </thead>

        <tbody>
            <tr style={{ boxShadow: 'none !important' }}>
                <td style={{ padding: '24px' }}>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Min. tid før forhåndsbestilt tur(i minutter)</div>
                    <span data-testid='test-driving-area-conf-minimumTimeUntilPickupInMinutes' >
                        { minimumTimeUntilPickupInMinutes || '-'}
                    </span>

                    <div className="ui sub header" style={{ fontSize: 'medium', textTransform: 'none' }} >
                        Maks. tid for forhåndsbestilt tur (i minutter)</div>
                    <span data-testid='test-driving-area-conf-maximumTimeUntilPickupInMinutes' >
                        { maximumTimeUntilPickupInMinutes || '-'}
                    </span>

                </td>
            </tr>

        </tbody>

        {
            hideEditingBtns ? null :
                <tfoot style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }} >
                    <tr style={{ boxShadow: 'none !important' }}><td>
                        <div className="ui icon buttons">

                            <AppButton
                                size='mini'
                                dataTestId="edit-main-driving-area-price-conf-btn"
                                onClick={e => actions.edit(null, DrivingAreaPriceConfigModalType.preOrderInfo)}>
                                <><EditIcon />
                                </>
                            </AppButton>

                        </div>
                    </td></tr>
                </tfoot>
        }

    </table></div>

}