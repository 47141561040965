import { BookingsApi } from '../../../api/bookings.service';
import { paymentsLoaded, paymentsLoading, paymentsLoadingFailed } from '../../Payments/reducers/paymentReducer';
import { AppPayment } from '../../Payments/types';
import { bookingLoaded, bookingLoading, bookingLoadingFailed, bookingsLoaded, bookingsLoading, bookingsLoadingFailed, searchedBookingsLoaded } from '../reducer/bookingReducer';
import { bookingLogLoading, bookingLogsLoaded, bookingLogsLoadingFailed } from '../reducer/bookingsLogReducer';
import { bookingStopsLoaded } from '../reducer/bookingStopsReducer';
import { paymentLogLoaded, paymentLogLoading, paymentLogLoadingFailed } from '../reducer/paymentLogReducer';
import { AppBooking, AppBookingLog, AppBookingsRequest, AppBookingsResponse, AppBookingStop, AppPaymentLog, BookingInfoResponse } from '../types';


export const getBookings = (args: AppBookingsRequest) => {

    return async (dispatch: any, getState: any) => {

        dispatch(bookingsLoading());

        try {

            const result = await BookingsApi.getBookings(args)
            if (!result) {
                dispatch(bookingsLoadingFailed());
                return null;
            }

            const data = result.data as AppBookingsResponse;

            if (!data) {
                dispatch(bookingsLoadingFailed());
            }

           dispatch(bookingsLoaded({ bookings: data.bookings, isLastPage: data.isLastPage }));
          


            return;
        }
        catch (error:any) {
            dispatch(bookingsLoadingFailed());
            return new Error(error);
        }
    }
};

export const getBookingAdditionalData = (bookingId: number) => {

    return async (dispatch: any) => {

        dispatch(bookingLogLoading());
        dispatch(paymentLogLoading());
        dispatch(paymentsLoading());
        dispatch(bookingLoading())

        try {

            const result = await BookingsApi.getBookingsAdditionalData(bookingId);

            if (result == null){
               throw new Error('Booking additional data is not loaded');
            }

            const resultData: BookingInfoResponse = result.data || {};

            const bookingLogs: Array<AppBookingLog> = resultData.bookingLogs || [];
            const paymentLogs: Array<AppPaymentLog> = resultData.paymentLogs || [];
            const payments: Array<AppPayment> = resultData.payments || [];
            const booking: AppBooking = resultData.booking || null;
            const bookingStops: Array<AppBookingStop> = resultData.bookingStops || [];

            //litle delay, loading indicator looks better this way.
            setTimeout(() => {
                dispatch(bookingLogsLoaded(bookingLogs));
                dispatch(paymentLogLoaded(paymentLogs));
                dispatch(paymentsLoaded(payments));
                dispatch(bookingLoaded(booking));
                dispatch(bookingStopsLoaded(bookingStops));
            }, 200);
  
            return;
        }
        catch (error: any) {
            dispatch(bookingLogsLoadingFailed());
            dispatch(paymentsLoadingFailed());
            dispatch(paymentLogLoadingFailed());
            dispatch(bookingLoadingFailed());

            return new Error(error);
        }
    }
};
