import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppNoItems, Spinner } from '../../../../components';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { DrivingAreaPriceConfActionsProps, PreOrderConfig } from '../types';
import  AppWeekTimePicker  from './AppWeekTimePicker';
import '../../../../styles/preorder.css'
import { AppDatePicker } from '../../../../components/AppDatePickerInput';
import { DrivingAreaPriceConfig, HideEditingBtns } from '../../types';
import { addPreOrderDate } from '../reducers/drivingAreaPriceConfigReducers';
import { dateTimeHelper } from '../../../../utils/dateTimeHelper';
import { PublishBtns } from '../../CommonComponents/PublishBtns';
import { PreOrderInfo } from './PreOrderInfo';


interface PropsFromReducer {

    drivingAreaPriceConfig: DrivingAreaPriceConfig | null,
    preOrderConfig: PreOrderConfig ,
    drivingAreaPriceConfigLoading: boolean,
    drivingAreaPriceConfigIsEdited: boolean,
    loading: boolean,
    publishing: boolean,
    saving: boolean,
    resetting: boolean,
    add_pre_order_date:(args:any)=>void
}

type OwnProps = DrivingAreaPriceConfActionsProps & HideEditingBtns;

type PreorderPriceConfigProps = PropsFromReducer & OwnProps;

const PreOrderAvailablePeriodsConfig: React.FC<PreorderPriceConfigProps> = (props) => {

     const { drivingAreaPriceConfig,
        preOrderConfig,
        drivingAreaPriceConfigLoading,
        drivingAreaPriceConfigIsEdited,
        publishing,
        saving,
        resetting,
        add_pre_order_date
     } = props;

    const [addDateFormIsShown, showAddDateForm] = useState<boolean>(false);

    const handleDateClick = (date:Date) => {
        showAddDateForm(false);

        try {
            let resDate = dateTimeHelper.resetDateToStartOfDay(date);
            if (resDate) {
                const str = dateTimeHelper.getLocalIsoStringFromJsDate(resDate);
                add_pre_order_date({date: str});
            }
        }
        catch(err){
            alert('Kunne ikke konvertere dato.')
        }
    }


    const { actions, hideEditingBtns } = props;

    const wrapperStyle = { minHeight: '40vh' };

    if (drivingAreaPriceConfigLoading) {
        return <div className="display-flex-center-both" style={wrapperStyle}><Spinner /></div>
    }

    if (!drivingAreaPriceConfig) {
        return <AppNoItems text={'Velg et kjøreområde'} />
    }

    return <div style={wrapperStyle} className="driving-area-preOrder-price-config-main">

        {
            hideEditingBtns ? null :
                <div className="price-config-publish-reset-btns" style={{ width: '100%', display: 'flex', padding: '0px 0px', justifyContent: 'flex-end', alignItems: 'center' }}>

                    <PublishBtns
                        isPublished={!drivingAreaPriceConfig.isDraft}
                        publishing={publishing}
                        resetting={resetting}
                        saving={saving}
                        isEdited={drivingAreaPriceConfigIsEdited}
                        hasPublishedRecords={drivingAreaPriceConfig.publishedDate !== null}
                        publish={actions.publishOrSaveConfig}
                        resetChanges={() => {
                            actions.resetDraft()
                        }} />

                </div>

        }

        <div className="tab-page">
            <div className='app-week-time-config-wrapper'>
                <div className="preOrder-info-wrapper">
                <PreOrderInfo 
                    preOrderConfig={preOrderConfig} 
                    actions={actions} 
                    hideEditingBtns={hideEditingBtns} />
                </div>

                <div className="weeks-days-wrapper">
                    <AppWeekTimePicker 
                    addDateFormIsShown={addDateFormIsShown} 
                    showAddDateForm={() => showAddDateForm(!addDateFormIsShown)} 
                    hideEditingBtns={hideEditingBtns}
                    />
                    
                    {
                        addDateFormIsShown ?
                            <div style={{marginLeft:'50px'}}>
                                <AppDatePicker  handleDayClick={handleDateClick} selectedDays={[]} />
                            </div> : ''

                    }
                </div>

            </div>
        </div>

    </div>
}


const mapStateToProps = (state: RootState, ownProps: any) => {

    const daPriceConfState = state.drivingAreaPriceConfig;
    const props = ownProps as OwnProps;

    const propsToReturn: any = {
        drivingAreaPriceConfig: daPriceConfState.priceConfig,
        preOrderConfig: daPriceConfState.preOrderConfig,
        actions: props.actions,
        drivingAreaPriceConfigLoading: daPriceConfState.loading,
        drivingAreaPriceConfigIsEdited: daPriceConfState.isEdited,
        publishing: daPriceConfState.publishing || false,
        saving: daPriceConfState.saving,
        resetting: daPriceConfState.resettingDraft || false,
        hideEditingBtns: props.hideEditingBtns
    }

    return propsToReturn;
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        add_pre_order_date:(args:any)=>dispatch(addPreOrderDate(args))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PreOrderAvailablePeriodsConfig)
);



