import { ReportsApi } from "../../../api/report.service";
import { RootState } from "../../../config/reducerConfig/rootReducer";
import { dateTimeHelper } from "../../../utils/dateTimeHelper";
import { reportLoading, setReportData } from "../reducer/financialReportReducers";
import { AppFinancialReport, FinancialReportRequest } from "../types";


export const getFinancialReport = () => {

  return async (dispatch: any, getState: any) => {

    dispatch(reportLoading(true));

    try {

      const reportState: RootState = getState();

      if (!reportState.financialReport.from){
        return;
      }

      if (!reportState.financialReport.to){
        return;
      }

      let fromDateTemp = dateTimeHelper.getStartOfDayFromDate(reportState.financialReport.from);
      let resultFromStr = fromDateTemp ? dateTimeHelper.getUTCStringFromDateTime(fromDateTemp) : '';
      resultFromStr = resultFromStr || '';

      let toDateTemp = dateTimeHelper.getStartOfDayFromDate(reportState.financialReport.to);
      let resultToStr = toDateTemp ? dateTimeHelper.getUTCStringFromDateTime(toDateTemp) : '';
      resultToStr = resultToStr || '';

      const args: FinancialReportRequest = {
        from: resultFromStr,
        to: resultToStr
      }
      let result = await ReportsApi.getFinancialReport(args);
      const data = result.data as Array<AppFinancialReport>;

      dispatch(setReportData(data));

      return data;
    }
    catch (error: any) {
      dispatch(reportLoading(false));
      throw error;
    }
  }
};
