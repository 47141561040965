import { DiscountModalType } from "../features/Discount/types";
import { ModalType } from "../features/Price/CountryPriceConfig/types";
import { DrivingAreaPriceConfigModalType } from "../features/Price/DrvingAreaPriceConfig/types";
import { PriceTestCaseModalType } from "../features/Price/PriceConfigsTest/types";
import { RegionPriceConfigModalType } from "../features/Price/RegionPriceConfigs/types";

  export enum ActionStatus {
    FAIL = 'error',
    NONE = 'none',
    PROCESS = 'pending',
    SUCCESS = 'success'
  };
  

  export interface AppPagination {
    isLastPage: boolean,
    page: number
  }

  export interface ModalTypeData {
    modalType: ModalType | RegionPriceConfigModalType |  DrivingAreaPriceConfigModalType | PriceTestCaseModalType 
    | DiscountModalType ,
    index: number | null
  }


  export const CURRENCY = 'NOK';

  export const IS_SUMMER_TIME = true;
  
  export enum AppActiveStatus {
     Active = 1,
     Inactive = 2
  }

  export const getAppActiveStatusData = (status: AppActiveStatus): {text:string, color:string} => {

    switch (status) {
        case AppActiveStatus.Active:
            return { text: 'Aktivert', color: 'green', }
        case AppActiveStatus.Inactive:
            return { text: 'Inaktiv', color: 'orange',  }
        default:
            return { text: 'Unknown', color: 'black',  }
    }
}

  export enum AppPublishStatus {
    Published = 1,
    Draft = 2
  }

  export const getAppPublishedStatusData = (status: AppPublishStatus): {text:string, color:string} => {

    switch (status) {
        case AppPublishStatus.Published:
            return { text: 'Publisert', color: 'blue', }
        case AppPublishStatus.Draft:
            return { text: 'Utkast', color: 'gray',  }
        default:
            return { text: 'Unknown', color: 'black',  }
    }
}


export enum AppDiscountStatus {
  Draft = 1,
  Waiting = 2,
  Active = 3,
  Inactive = 4
}

export const getDiscountStatusData = (status: AppDiscountStatus | null): {text:string, color:string} => {

  switch (status) {
      case AppDiscountStatus.Draft:
          return { text: 'Utkast', color: 'gray', }
      case AppDiscountStatus.Waiting:
          return { text: 'Venter', color: 'blue',  }
      case AppDiscountStatus.Active:
            return { text: 'Aktiv', color: 'green',  }
      case AppDiscountStatus.Inactive:
        return { text: 'Inaktiv/Fullført', color: 'orange',  }
      default:
          return { text: 'Unknown', color: 'black',  }
  }
}
