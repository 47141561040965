import React, { useEffect } from "react";
// @ts-ignore
import ReactExport from "react-data-export";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RootState } from "../../../config/reducerConfig/rootReducer";
import { AppFinancialReport } from "../types";
import { getAppBookingTripCategory } from "../../TripCategory/types";
import { dateTimeHelper } from "../../../utils/dateTimeHelper";


interface OwnProps {
    button: JSX.Element
};

interface PropsFromReducer {
    dataSet: Array<AppFinancialReport>
};


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/** example https://github.com/rdcalle/react-export-excel/blob/master/examples/simple_excel_export_02.md */
const ReportExcel: React.FC<PropsFromReducer & OwnProps> = (props) => {
    const { button, dataSet = [] } = props;


    const checkIfNull = (str:string | null) => {
      if (str == null || str == undefined)
        return "";
      return str;
    }

    var data:any = dataSet.map((el, index) => {
       
        let dateString = null;
        try {
          // server data utc, convert to local

          dateString = dateTimeHelper.getLocalFromUtcStrFormated(el.captureTime, 'dd.MM.yy, HH:mm')

        } 
        catch(err){
          console.log(err);
        }

        const originalPrice = el.cancellationFee && el.cancellationFee > 0? el.originalCancellationFee: el.originalPrice;
        const discountPercent = originalPrice && originalPrice > 0 && el.discountAmount && el.discountAmount > 0 ? el.discountAmount * 100 / originalPrice: 0;
        const discountAmount = originalPrice && originalPrice > 0? el.discountAmount: 0;

        const tripCategory = getAppBookingTripCategory(el.tripCategory);
        const contractPrice = el.contractPrice? el.contractPrice: 0;

        return  [
            {value: dateString, style: { font: { sz: "11", bold: false }, alignment: {horizontal: "center"}} },
            {value: checkIfNull(el.carId), style: { font: { sz: "11", bold: false } , alignment: {horizontal: "center"} } },
            {value: checkIfNull(el.providerName), style: { font: { sz: "11", bold: false } } },
            {value: checkIfNull(el.driverNumber), style: { font: { sz: "11", bold: false } , alignment: {horizontal: "center"} } },
            {value: checkIfNull(el.driverName), style: { font: { sz: "11", bold: false } } },
            {value: checkIfNull(el.dispatcherBookingNumber), style: { font: { sz: "11", bold: false } , alignment: {horizontal: "center"}} },
            {value: checkIfNull(el.statusName), style: { font: { sz: "11", bold: false } } },
            {value: checkIfNull(tripCategory.text), style: { font: { sz: "11", bold: false } , alignment: {horizontal: "center"} } },
            {value: checkIfNull(el.contractNumber || ''), style: { font: { sz: "11", bold: false } , alignment: {horizontal: "center"} } },
            {value: (!originalPrice? '': (originalPrice == el.price? '': originalPrice)), style: { font: { sz: "11", bold: false }, numFmt: "0.00" } },
            {value: contractPrice, style: { font: { sz: "11", bold: false }, numFmt: "0.00" } },
            {value: checkIfNull(el.discountCode as string), style: { font: { sz: "11", bold: false } , alignment: {horizontal: "center"}} },
            {value: discountAmount || 0, style: { font: { sz: "11", bold: false }, numFmt: "0.00"  } },
            {value: discountPercent, style: { font: { sz: "11", bold: false }, numFmt: "0.00"  } },
            {value: el.price, style: { font: { sz: "11", bold: false }, numFmt: "0.00" } },
            {value: el.refunded, style: { font: { sz: "11", bold: false }, numFmt: "0.00"  } },
            {value: el.vatRate, style: { font: { sz: "11", bold: false } , numFmt: "0.00" } },
            {value: el.serviceCharge? el.serviceCharge: '', style: { font: { sz: "11", bold: false } , alignment: {horizontal: "center"} }, numFmt: "0.00"},
            {value: el.serviceChargeVatRate? el.serviceChargeVatRate: '', style: { font: { sz: "11", bold: false } , alignment: {horizontal: "center"} }, numFmt: "0.00"},
            {value: checkIfNull(el.paymentMethodName), style: { font: { sz: "11", bold: false } , alignment: {horizontal: "center"} } }
           
        ]       
    })

    if (data.length == 0) {
      var dummy = [
        {value:"Ingen turer registrert", style: { font: { sz: "11", bold: false }}},
        {value:""},{value:""},{value:""},{value:""},{value:""},{value:""},{value:""},{value:""},{value:""},{value:""},{value:""},{value:""}
      ]
      
      data.push(dummy)
    }

    const fgColor = "DEE9FB";

    const styledMultiDataSet = [
        {
          columns: [
            {
                title: "Tidspunkt",
                width: {wpx:160},
                style: { font: { sz: "11", bold: true, color:{rgb:"000000"} }, alignment: {horizontal: "center"}, fill: {fgColor:{rgb:fgColor}}},
                
              },
              {
                title: "Løyvenummer",
                width: {wpx:120},
                style: { font: { sz: "11", bold: true }, alignment: {horizontal: "center"} , fill: {fgColor:{rgb:fgColor}}}, 
              },
              {
                title: "Løyvehaver",
                width: {wpx:180},
                style: { font: { sz: "11", bold: true }, fill: {fgColor:{rgb:fgColor}} }, 
              },
              {
                title: "Sjåførnummer",
                width: {wpx:80},
                style: { font: { sz: "11", bold: true }, alignment: {horizontal: "center"} , fill: {fgColor:{rgb:fgColor}}}, 
              },
              {
                title: "Sjåfør",
                width: {wpx:120},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:fgColor}}},
              },
              {
                title: "Turkode",
                width: {wpx:80},
                style: { font: { sz: "11", bold: true }, alignment: {horizontal: "center"} , fill: {fgColor:{rgb:fgColor}}}, 
              },
              {
                title: "Status",
                width: {wpx:150},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:fgColor}}}, 
              },
              {
                title: "Turtype",
                width: {wpx:150},
                style: { font: { sz: "11", bold: true } , alignment: {horizontal: "center"}, fill: {fgColor:{rgb:fgColor}}}, 
              },
              {
                title: "Avtalenummer",
                width: {wpx:150},
                style: { font: { sz: "11", bold: true } , alignment: {horizontal: "center"}, fill: {fgColor:{rgb:fgColor}}}, 
              },
              {
                title: "Ref_pris (ink. MVA)",
                width: {wpx:120},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:fgColor}}}, 
              },  
              {
                title: "Avtalepris",
                width: {wpx:120},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:fgColor}}}, 
              }, 
              {
                title: "Kampanjekode",
                width: {wpx:120},
                style: { font: { sz: "11", bold: true }, alignment: {horizontal: "center"} , fill: {fgColor:{rgb:fgColor}}}, 
              },
              {
                title: "Rabatt, kr",
                width: {wpx:80},
                style: { font: { sz: "11", bold: true }, alignment: {horizontal: "center"} , fill: {fgColor:{rgb:fgColor}}}, 
              },
              {
                title: "Rabatt, %",
                width: {wpx:80},
                style: { font: { sz: "11", bold: true }, alignment: {horizontal: "center"} , fill: {fgColor:{rgb:fgColor}}}, 
              },
              {
                title: "Kundepris",
                width: {wpx:100},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:fgColor}}},                
              },
              {
                title: "Refundert",
                width: {wpx:100},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:fgColor}}}, 
              }, 
              {
                title: "Mva-sats, %",
                width: {wpx:80},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:fgColor}}},
              },   
              {
                title: "Servicetillegg",
                width: {wpx:110},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:fgColor}}},
              },    
              {
                title: "Mva til servicetillegg, %",
                width: {wpx:170},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:fgColor}}},
              },       
              {
                title: "Betalingsmåte",
                width: {wpx:100},
                style: { font: { sz: "11", bold: true }, alignment: {horizontal: "center"}, fill: {fgColor:{rgb:fgColor}}}, 
              }                                                    
          ],
          data: data 
              
        },
      ];
      

    return (
        <div>
            <ExcelFile element={button}>
                <ExcelSheet dataSet={styledMultiDataSet} name="Intercab"/>
            </ExcelFile>
        </div>
    );

}

const mapStateToProps = ((state: RootState, ownProps: any) => {

    const reportState = state.financialReport;
    const props: OwnProps = ownProps;


    return {
        dataSet: reportState.data,
        button: props.button
    }
});


const mapDispatchToProps = (dispatch: any) => {

    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReportExcel)
);
