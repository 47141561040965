import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { CountryPriceConfig, CountryPriceConfigCarType, CountryPriceConfigDayTimeZone, CountryPriceConfigDayTypeFactor, getEmptyCountryPriceConfig, getEmptyCountryPriceConfigCarType, getEmptyDayTimeZones, getEmptyDayTypes, PriceConfigDayTimeZone } from '../../types';
import { CountryPriceConfigServerData, CountryPriceConfigState } from '../types';

const initialState: CountryPriceConfigState = {
  priceConfig: null,
  dayTimeZones: [],
  dayTypes: [],
  carTypes: [],
  loading: false,
  publishing: false,
  saving: false,
  isEdited: false,
  comment: null,
  resettingDraft: false
};

const priceConfigSlice = createSlice({
  name: 'countryPriceConfig',
  initialState,
  reducers: {
    priceConfigLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPriceConfig(state, action: PayloadAction<{priceConfig:CountryPriceConfigServerData, countryId:number}>) {
      const data = action.payload? action.payload.priceConfig : null;

      state.dayTypes = data && data.dayTypes && data.dayTypes.length > 0? data.dayTypes: getEmptyDayTypes();
      state.dayTimeZones = data && data.dayTimeZones && data.dayTimeZones.length > 0 ? data.dayTimeZones : getEmptyDayTimeZones();
      state.priceConfig = data && data.countryPriceConfig  ? data.countryPriceConfig : getEmptyCountryPriceConfig(action.payload.countryId);
      state.carTypes = data && data.carTypes && data.carTypes.length > 0? data.carTypes: [];
      state.comment = data && data.countryPriceConfig && data.countryPriceConfig.isDraft? data.countryPriceConfig.draftComment: null

      state.loading = false;
      state.isEdited = false;
      state.publishing = false;
      state.saving =false;
      state.resettingDraft = false;
    },
    updatePriceConfig(state, action: PayloadAction<CountryPriceConfig>) {

      const countryPriceConfig = action.payload || null;

      if (countryPriceConfig) {
        state.priceConfig = countryPriceConfig;
        state.isEdited = true;
      }

    },
    addOrUpdateDayTimeZone(state, action: PayloadAction<{ dayTimeZone: PriceConfigDayTimeZone, index: number | null | undefined }>) {
      const { dayTimeZone, index } = action.payload;

      if (!state.dayTimeZones) {
        state.dayTimeZones = [];
      }

      //update
      if (dayTimeZone && (index || index === 0)) {
        state.dayTimeZones[index] = dayTimeZone;
        state.isEdited = true;
      }
      // add
      else if (dayTimeZone) {
        state.dayTimeZones.push(dayTimeZone);
        state.isEdited = true;
      }
    },
    updateDayType(state, action: PayloadAction<{ dayType: CountryPriceConfigDayTypeFactor, index: number | null | undefined}>) {

      const { dayType, index } = action.payload;

      if (!state.dayTypes) {
        return state;
      }

      //update
      if (dayType && (index || index === 0) && state.dayTypes.length > 0 && state.dayTypes[index]) {
        state.dayTypes[index] = dayType;
        state.isEdited = true;
      }

    },
    updateCarType(state, action: PayloadAction<{ carType: CountryPriceConfigCarType, index: number | null | undefined}>) {

      const { carType, index } = action.payload;

      if (!state.carTypes) {
        state.carTypes = [];
      }

      //update
      if (carType && (index || index === 0) && state.carTypes[index]) {
        state.carTypes[index] = carType;
        state.isEdited = true;
      }

    },
    changePublishingState(state, action: PayloadAction<boolean>){
      state.publishing = action.payload;
    },
    changeSavingState(state, action: PayloadAction<boolean>){
      state.saving = action.payload;
    }, 
    updateComment(state, action: PayloadAction<string | null>){
      state.comment = action.payload;
      state.isEdited = true;
    },
    resettingDraft(state, action: PayloadAction<boolean>){
      state.resettingDraft = action.payload;
    },
    resetState(state) {
      return initialState;
    }
  },
})

export const {
  priceConfigLoading,
  setPriceConfig,
  updatePriceConfig,
  addOrUpdateDayTimeZone,
  updateDayType,
  updateCarType,
  changePublishingState,
  changeSavingState,
  updateComment,
  resettingDraft,
  resetState

} = priceConfigSlice.actions;

export default priceConfigSlice.reducer;


export const selectDayTypeConfig = (state: any, index: number): CountryPriceConfigDayTypeFactor | null => {
  const conf = state.countryPriceConfig as CountryPriceConfigState;

  if (index || index === 0) {

    if (conf && conf.dayTypes && conf.dayTypes.length > 0) {
      const current = conf.dayTypes[index] || null;
      return current;
    }
  }

  return null;
}


export const selectDateTimeZoneConfig = (state: any, index: number): CountryPriceConfigDayTimeZone | null => {
    const conf = state.countryPriceConfig as CountryPriceConfigState;
  
    if (index || index === 0) {
  
      if (conf && conf.dayTimeZones && conf.dayTimeZones.length > 0) {
        const current = conf.dayTimeZones[index] || null;
        return current;
      }
    }
  
    return null;
  }


  export const selectCarTypeConfig = (state: RootState, index: number): CountryPriceConfigCarType | null => {
    const conf = state.countryPriceConfig;
  
    if (index || index === 0) {
  
      if (conf && conf.carTypes && conf.carTypes.length > 0) {
        const current = conf.carTypes[index] || null;
        return current;
      }
    }
  
    return null;
  }





