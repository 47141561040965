import React from 'react';
import { AppTabs } from '../../../../components';
import { CalenderIcon, MapIcon, PriceIcon } from '../../../../components/Icon';
import { isMobile } from '../../../../utils';
import { HideEditingBtns } from '../../types';
import { DrivingAreaPriceConfActionsProps } from '../types';
import MainDrivingAreaPriceConfig from './MainDrivingAreaPriceConfig';
import SpecialLocations from './SpecialLocations';
import PreOrderPriceConfigEditor from './PreOrderAvailablePeriodsConfig';

export const DrivingAreaPriceConfigTabs: React.FC<DrivingAreaPriceConfActionsProps & HideEditingBtns> = (props) => {
    props = props || {};
    const { actions, hideEditingBtns} = props;

    let screenIsMobile = isMobile();

    let mainText = !screenIsMobile ? 'Prisfaktorer' : '';
    let specialLocationsText = !screenIsMobile ? 'Spesiallokasjoner' : '';
    const preOrderText = 'Forhåndsbestilling';

    const panes = [
        {
            menuItemText: mainText,
            menuItemIcon: <PriceIcon />,
            tabPaneContent: <MainDrivingAreaPriceConfig actions={actions} hideEditingBtns={hideEditingBtns} />
        },
        {
            menuItemText: preOrderText,
            menuItemIcon: <CalenderIcon />,
            testId:'pre-order-price-config-tab-test',
            // eslint-disable-next-line react/jsx-no-undef
            tabPaneContent: <PreOrderPriceConfigEditor actions={actions} hideEditingBtns={hideEditingBtns} />
        },
        {
            menuItemText: specialLocationsText,
            menuItemIcon: <MapIcon />,
            testId:'spec-location-price-config-tab-test',
            // eslint-disable-next-line react/jsx-no-undef
            tabPaneContent: <SpecialLocations actions={actions} hideEditingBtns={hideEditingBtns} />
        }
    ]

    return <><AppTabs panes={panes} /></>
};
