import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Spinner } from "../../../../components";
import { AppTitleMobileOnly } from "../../../../components/AppTitleMobileOnly";
import { CalenderIcon, InfoListIcon } from "../../../../components/Icon";
import { dateTimeHelper } from "../../../../utils/dateTimeHelper";
import { resetBookingLogState } from "../../reducer/bookingsLogReducer";
import { AppBookingLog, AppBookingLogState, getLogStatusData } from "../../types";
import { BookingInfoHeader } from "./BookingInfoHeader";

interface Props {
    bookingLog: Array<AppBookingLog>
    oderLogLoading: boolean,
    reset_BookingLogState: () => void
}

const BookingLog: React.FC<Props> = (props) => {
    let { bookingLog, oderLogLoading, reset_BookingLogState } = props;

    useEffect(() => {

        return () => {
            reset_BookingLogState();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (<>
        <BookingInfoHeader text={'Bookinglogg'} icon={<InfoListIcon />} />
        <div className="ui divider"></div>
        {
            oderLogLoading === false ? (<div className="booking-log-list">
                <BookingLogTable bookingLog={bookingLog} />
            </div>) : <Spinner />
        }

    </>)
};

interface BookingLogProps {
    bookingLog: Array<AppBookingLog>
}

const TABLE_TEXTS = {
    description: 'Beskrivelse',
    changedBy: 'Endret av',
    date: 'Opprettet'
}

const BookingLogTable: React.FC<BookingLogProps> = (props) => {
    const { bookingLog } = props;

    let result = null;

    if (bookingLog && bookingLog.length > 0) {
        result = bookingLog.map((el, index) => {
            return <BookingListElement key={index} bookingLogElement={el} />
        })
    }

    return (<table className="app-booking-list ui very basic table fixed ">
        <thead className="app-booking-list-table-header">
            <tr className="">
                <th style={{width:'70%'}} className="">{TABLE_TEXTS.description}</th>
                <th className="">{TABLE_TEXTS.date}</th>
            </tr>
        </thead>
        {result && result.length > 0 ? <tbody data-testid='test-booking-log' className="">{result}</tbody> : <tbody ><tr style={{ height: "10vh" }}></tr></tbody>}
    </table>)
}

interface BookingListElementProps {
    bookingLogElement: AppBookingLog
}

const BookingListElement: React.FC<BookingListElementProps> = (props) => {
    let { bookingLogElement } = props;

    bookingLogElement = bookingLogElement || {};

    const logStatusData = getLogStatusData(bookingLogElement.statusId, bookingLogElement.type);
    const userFullName = bookingLogElement.userFullName? bookingLogElement.userFullName.trim(): '-';

    return (<tr className="app-bookings-booking">
        <td style={{fontSize: '15px'}} ><AppTitleMobileOnly text={TABLE_TEXTS.description} /> {logStatusData ? logStatusData.description : bookingLogElement.description}</td>
        <td style={{fontSize: '15px'}}><AppTitleMobileOnly text={TABLE_TEXTS.date} /> <CalenderIcon />{dateTimeHelper.getLocalFromUtcStrFormated(bookingLogElement.date) || "-"} </td>
    </tr>)
}



const mapStateToProps = (state: any, ownProps: any) => {
    const bookingsLogState = state.bookingLog as AppBookingLogState;

    const bookingLog = bookingsLogState.list;
    const oderLogLoading = bookingsLogState.loading;

    return {
        bookingLog,
        oderLogLoading
    };
};


const mapDispatchToProps = (dispatch: any) => {

    return {
        reset_BookingLogState: () => dispatch(resetBookingLogState())
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BookingLog)
);

