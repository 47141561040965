import React from 'react';
import { AreYouSureAppButton } from '../../../components';
import { EyeIcon, SaveIcon, UndoIcon } from '../../../components/Icon';


interface Props {
    publishing: boolean,
    resetting: boolean,
    saving: boolean,
    isEdited: boolean,
    isPublished: boolean,
    hasPublishedRecords: boolean,
    publish: (isSaving: boolean) => void,
    resetChanges: () => void
}

export const PublishBtns: React.FC<Props> = (props) => {
    props = props || {};

    const { isEdited, publish, resetChanges, publishing, isPublished, resetting, saving, hasPublishedRecords } = props;

    return (<div className="price-config-ctrls" >

        <AreYouSureAppButton
            disabled={!isEdited}
            isIcon={true}
            loading={saving}
            confirmationText="Lagre"
            mainBtndesignType='teal'
            dataTestIdOk={'test-save-ok'}
            dataTestIdConfirm={'test-save-not-ok'}
            size='medium' text={<SaveIcon />} onClick={() => { publish(true) }} />

        <AreYouSureAppButton
            isIcon={true}
            loading={publishing}
            disabled={(isPublished && isEdited === false) || publishing}
            confirmationText="Publiser"
            mainBtndesignType='negative'
            dataTestIdOk={'test-publish-ok'}
            dataTestIdConfirm={'test-publish-not-ok'}
            size='medium' text={<EyeIcon />} onClick={() => { publish(false) }} />

        <AreYouSureAppButton
            isIcon={true}
            loading={resetting}
            disabled={isPublished || resetting || !hasPublishedRecords}
            mainBtndesignType='grey'
            confirmationText="Angre utkast"
            dataTestIdOk={'test-publish-reset-ok'}
            dataTestIdConfirm={'test-publish-reset-not-ok'}
            text={<UndoIcon />}
            size='medium' onClick={resetChanges} />

    </div>
    )
};
