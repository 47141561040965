import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import { Button, Header, Label } from 'semantic-ui-react';
import { MyTextInput } from '../../../components';
import { ActionStatus } from '../../../config/constants';
import { nameof } from '../../../utils/nameOfPropertyHelper';
import { userForgotPassword } from '../actions/authActions';
import { ForgotPasswordRequestData, forgotPasswordRequestDataValidation } from '../types';


interface Props {
  setSendingStatus: (data: ActionStatus) => void,
  pending: ActionStatus
};

export const ForgotPasswordForm: React.FC<Props> = (props) => {

  const { setSendingStatus, pending} = props;
  const currentState: ForgotPasswordRequestData = { email: ''};

  const handleAuthFormSubmit = async (values: ForgotPasswordRequestData) => {
      await userForgotPassword(values, setSendingStatus);
  }

  return (
    <Formik
      initialValues={Object.assign(currentState, { error: null })}
      validationSchema={forgotPasswordRequestDataValidation}
      onSubmit={(values, { setErrors }) => { handleAuthFormSubmit(values) }}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
          <Header as='h2' content='Glemt passord?' color='teal' textAlign='center' />

          <MyTextInput data-testid={nameof<ForgotPasswordRequestData>('email')} name={nameof<ForgotPasswordRequestData>('email')} placeholder='E-post' />
      
          <ErrorMessage
            name='error' render={() =>
              <Label style={{ marginBottom: 10 }} basic color='red' content={errors.error} />}
          />
          <Button loading={pending === ActionStatus.PROCESS} positive content='Send' type='submit' fluid />
        </Form>
      )}
    </Formik>
  )
};

