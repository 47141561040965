import React from 'react';

interface Props {
    pages: Array<{ name: string }>
}

export const AppBreadcrumbs: React.FC<Props> = (props) => {
    const { pages = [] } = props;

    const result = pages.map((el, index) => {

        if (pages.length-1 <= index) {
            return (<React.Fragment key={index}><AppBreadcrumb key={index} text={el.name} /></React.Fragment>)
        }

        return (<React.Fragment key={index}><AppBreadcrumb text={el.name} />
            <div className="divider"> / </div></React.Fragment>)
    })

    return (<div style={{ marginBottom: '20px' }} className="ui breadcrumb">
       {result? result: ''}
    </div>
    )
};

interface AppBreadcrumbProps {
    text: string
}

export const AppBreadcrumb: React.FC<AppBreadcrumbProps> = ({ text }) => {
    return (<a href="/" className="section breadcrumb">{text || ''}</a>)
};
