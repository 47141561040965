import { withRouter } from "react-router";
import { PublishBtns } from "../../CommonComponents/PublishBtns"
import SpecialLocationsPriceFactors from "./SpecialLocationsPriceFactors"
import { connect } from 'react-redux';
import { RootState } from "../../../../config/reducerConfig/rootReducer";
import { DrivingAreaPriceConfActionsProps } from "../types";
import { DrivingAreaPriceConfig, HideEditingBtns } from "../../types";
import { AppNoItems } from "../../../../components";



interface OwnProps extends DrivingAreaPriceConfActionsProps, HideEditingBtns { }

interface PropsFromRedux {
    drivingAreaPriceConfig: DrivingAreaPriceConfig | null,
    publishing: boolean,
    saving: boolean,
    resetting: boolean,
    drivingAreaPriceConfigIsEdited: boolean
}

export const SpecialLocations = (props: PropsFromRedux & OwnProps) => {
    const { drivingAreaPriceConfig, publishing, saving, resetting, drivingAreaPriceConfigIsEdited, actions, hideEditingBtns } = props;

    if (!drivingAreaPriceConfig) {
        return <AppNoItems text={'Velg et kjøreområde'} />;
    }

    return <div>

        {
            hideEditingBtns ? '' : <div className="price-config-publish-reset-btns" style={{ width: '100%', display: 'flex', padding: '0px 0px', justifyContent: 'flex-end', alignItems: 'center' }}>

                <PublishBtns
                    isPublished={!drivingAreaPriceConfig?.isDraft}
                    publishing={publishing}
                    resetting={resetting}
                    saving={saving}
                    isEdited={drivingAreaPriceConfigIsEdited}
                    hasPublishedRecords={drivingAreaPriceConfig?.publishedDate !== null}
                    publish={actions.publishOrSaveConfig}
                    resetChanges={() => {
                        actions.resetDraft()
                    }} />

            </div>
        }

        <SpecialLocationsPriceFactors actions={actions} hideEditingBtns={hideEditingBtns} />

    </div>
}


const mapStateToProps = (state: RootState, ownProps: any) => {

    const daPriceConfState = state.drivingAreaPriceConfig;
    const props = ownProps as OwnProps;

    return {
        actions: props.actions,
        drivingAreaPriceConfig: daPriceConfState.priceConfig,
        drivingAreaPriceConfigIsEdited: daPriceConfState.isEdited,
        publishing: daPriceConfState.publishing || false,
        saving: daPriceConfState.saving,
        resetting: daPriceConfState.resettingDraft || false,
        hideEditingBtns: props.hideEditingBtns
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SpecialLocations)
);

