import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppBookingLog, AppBookingLogState } from '../types';

const initialState: AppBookingLogState = {
    list: [],
    loading: true
}

const bookingLogSlice = createSlice({
    name: 'bookingLog',
    initialState,
    reducers: {
        bookingLogLoading(state) {
            state.loading = true;
        },
        bookingLogsLoaded(state, action: PayloadAction<Array<AppBookingLog>>) {

            const bookingLog = action.payload? action.payload: null;

            state.list = bookingLog? bookingLog: [];
            state.loading = false;
        },
        bookingLogsLoadingFailed(state){
            state.loading = false;
        },
        resetBookingLogState(state) {
            return initialState;
        }
    },
})

export const {
    bookingLogLoading,
    bookingLogsLoaded,
    bookingLogsLoadingFailed,
    resetBookingLogState,
} = bookingLogSlice.actions;

export default bookingLogSlice.reducer;

