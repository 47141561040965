export const PageHeaderWrapper = (props: { children: React.ReactNode, className?:string}) => {

    const { children, className=''} = props;
    const style = { minHeight: '5vh', justifyContent: 'space-between'}

    return <header
        className={"ui segment page-header-wrapper header-bg-color display-flex-center-y " + className}
        style={style}
    >
        {children}
    </header>
}
