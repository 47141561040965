
export interface DrivingArea {
    readonly drivingAreaId: number,
    name: string,
    readonly regionId: number
}

export const getDrivingAreaId = (item: DrivingArea): number | null => item && item.drivingAreaId? item.drivingAreaId: null;
export const getDrivingAreaName = (item: DrivingArea): string | null => item && item.name? item.name: null;

export interface DrivingAreaGeo {
    drivingAreaId: number,
    drivingAreaGeoId: number,
    regionId: number,
    drivingAreaPoints: Array<DrivingAreaPoint>,
    name?:string //for test
}

export interface DrivingAreaPoint {
    drivingAreaPointId: number,
    drivingAreaGeoId: number,
    latitude: number,
    longitude: number
}


/** STATE */

export interface DrivingAreasState {
    list: Array<DrivingArea>,
    loading: boolean,
    currentItemId: number | null
}

export interface DrivingAreaGeosState {
    list: Array<DrivingAreaGeo>
    loading: boolean
}

