import { getDayOfWeekConfig } from '../../../../commonTypes';
import '../../../../styles/preorder.css';
import { useEffect } from 'react';
import { AppLabel } from '../../../../components/AppLabel';
import { CloseIcon, DeleteIcon, PlusIcon } from '../../../../components/Icon';
import { dateTimeHelper } from '../../../../utils/dateTimeHelper';
import { AppButton } from '../../../../components/AppButton';
import { DrivingAreaPreOrderDateTimesAllowed, DrivingAreaPreOrderWeeklyTimesAllowed } from '../types';
import { DAY_TIME_ZONES } from '../../types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { deletePreOrderDateTime, updatePreOrderDateTime, updatePreOrderWeeklyTimesAllowed } from '../reducers/drivingAreaPriceConfigReducers';



interface OwnProps {
    showAddDateForm: () => any,
    addDateFormIsShown: boolean,
    hideEditingBtns: boolean
}
interface ReduxProps {
    preOrderWeeklyTimes: Array<DrivingAreaPreOrderWeeklyTimesAllowed>,
    preOrderDateTimes: Array<DrivingAreaPreOrderDateTimesAllowed>,
    preOrderDateAllowed: Array<string>,
    update_week_time: (args: any) => void,
    update_preOrder_date_time: (args: any) => void,
    delete_pre_order_date_time: (args: any) => void,
}

interface DateItemProps {
    startTime: string | null;
    endTime: string | null;
    maxNumberOfPreOrdersPerAllCustomers: number | null;
    id: number;
}

const AppWeekTimePicker = (props: OwnProps & ReduxProps) => {
    const { showAddDateForm, update_week_time, update_preOrder_date_time, delete_pre_order_date_time, hideEditingBtns,
        addDateFormIsShown, preOrderDateTimes, preOrderWeeklyTimes, preOrderDateAllowed } = props;

    useEffect(() => { });

    const setMaxAllowed = (id: number | null, data: any, isDate: boolean) => {

        if(hideEditingBtns){
            return;
        }

        if (!data) {
            return;
        }

        let newValue = (data.target || {}).value;


        if (isDate) {
            update_preOrder_date_time({ maxNumberOfPreOrdersPerAllCustomers: newValue, index: id });
        }
        else {
            update_week_time({ maxNumberOfPreOrdersPerAllCustomers: newValue, id });
        }
    }

    const removeNullValue = (id: number | null, numberOfAllowed: number | null, isData: boolean) => {

        if (numberOfAllowed !== 0) {
            return;
        }

        if (isData) {
            update_preOrder_date_time({ maxNumberOfPreOrdersPerAllCustomers: null, index: id });
        }
        else {
            update_week_time({ maxNumberOfPreOrdersPerAllCustomers: null, id });
        }
    }


    const generateDayHtml = (currentTimes: Array<DateItemProps>, weekTimes: Array<any>, isDate: boolean) => {
        for (let j = 0; j <= 23; j++) {
            const currentDayTimeZone = DAY_TIME_ZONES[j];

            let numberOfAllowed: number | null = 0;
            let foundId: number | null = null;

            if (currentTimes.length > 0) {
                const foundItem = currentTimes.find((x, index) => {

                    return x.startTime === currentDayTimeZone.fromTime && x.endTime === currentDayTimeZone.toTime;
                });


                if (foundItem) {
                    foundId = foundItem.id;
                    numberOfAllowed = foundItem.maxNumberOfPreOrdersPerAllCustomers;
                }
            }
                weekTimes.push(
                    <input 
                        className={'week-day-date ' + (numberOfAllowed && numberOfAllowed > 0? 'week-day-time-has-value': '' ) }
                        key={j}
                        value={numberOfAllowed || numberOfAllowed === 0  ? numberOfAllowed: ''}
                        data-testid={"week-day-date-id"} 
                        name={"week-day-date-value"} 
                        type="number"
                        min="0"
                        step={'any'}
                        onClick={()=>removeNullValue(foundId, numberOfAllowed, isDate)}
                        onChange={(data) => setMaxAllowed(foundId, data, isDate)}
                     />
                );
        }
    };

    let resultHtml = [];

    for (let i = 1; i <= 7; i++) {

        let weekTimes: Array<number> = [];

        let currentWeekDay: Array<DateItemProps> = [];

        preOrderWeeklyTimes.map(x => {
            if (x.dayTypeId === i) {
                currentWeekDay.push({
                    startTime: x.startTime,
                    endTime: x.endTime,
                    id: x.drivingAreaPreOrderWeeklyTimesAllowedId,
                    maxNumberOfPreOrdersPerAllCustomers: x.maxNumberOfPreOrdersPerAllCustomers,
                });
            }
        });

        generateDayHtml(currentWeekDay, weekTimes, false);

        const { color, text } = getDayOfWeekConfig(i);
        resultHtml.push(<div key={i} className='week-row' data-testid={'week-day' + i}> <div className="week-day-text">
            <AppLabel color={color} text={text} /></div>
            {weekTimes}</div>);

    }

    const lastRow = ['00-01', '01-02', '02-03', '03-04',
        '04-05', '05-06', '06-07', '07-08', '08-09', '09-10', '10-11', '11-12', '12-13',
        '13-14', '14-15', '15-16', '16-17', '17-18', '18-19', '19-20', '20-21', '21-22',
        '22-23', '23-24'];

    const lastRowHtml: any = [];

    lastRow.forEach(x => {
        lastRowHtml.push(<div key={x} className='week-day-date week-day-date-text' data-testid={x} >{x}</div>);
    });

    resultHtml.unshift(<div key={8} className='week-row'>
        <div className="week-day-text"></div>
        {lastRowHtml}
    </div>);


    resultHtml.push(<div className='week-row extra-dates' key={'week-row-title'} style={{
        textAlign: 'center', 
        fontSize: '0.8rem', fontWeight: 'bolder'
    }}> <span>Dager overstyrer ukedagene</span> </div>);


    if (!hideEditingBtns) {
        resultHtml.push(<div key={"add-btn"} className="btns-preorder-allowed-date">

            <AppButton size='medium'
                designType="teal"
                isIcon={true}
                dataTestId="test-add-preOrder-date"
                onClick={() => { showAddDateForm() }}
            >

                {!addDateFormIsShown ? <PlusIcon /> : <CloseIcon />}
            </AppButton></div>);
    }

    if (preOrderDateTimes && preOrderDateTimes.length > 0) {
        const extraDatesHtml = [];
        for (let i = 0; i < preOrderDateAllowed.length; i++) {

            let dateTimes: Array<any> = [];
            const currentDay = preOrderDateAllowed[i];

            let currentWeekDaytimes: Array<DateItemProps> = [];

            preOrderDateTimes.map((x, index) => {
                if (x.date == currentDay) {
                    currentWeekDaytimes.push({
                        startTime: x.startTime,
                        endTime: x.endTime,
                        id: index,
                        maxNumberOfPreOrdersPerAllCustomers: x.maxNumberOfPreOrdersPerAllCustomers
                    });
                }
            });

            generateDayHtml(currentWeekDaytimes, dateTimes, true);

            if (!hideEditingBtns) {
                dateTimes.push(<div  key={'delete-' + i} data-testid={'delete-date-btn'}  className='' 
                    style={{ marginLeft: '5px', cursor: 'pointer', display:'flex', alignItems:'center', fontSize: 'large' }} 
                    onClick={() => delete_pre_order_date_time(i)} >
                    <DeleteIcon /></div>);
            }
            
            extraDatesHtml.push(<div className='week-row ' key={i} data-testid={currentDay}> <div className="week-day-text">
                <AppLabel color='gray' text={dateTimeHelper.getLocalFromUtcStrFormated(currentDay, 'dd.MM.yy') || ''} />
                </div>{dateTimes}
            </div>);
        }
        
        extraDatesHtml.unshift(<div key={'times-per-day-key'} className='week-row'>
            <div className="week-day-text"></div>
            {lastRowHtml}
        </div>);

        resultHtml.push(<div className='extra-dates-wrapper' style={hideEditingBtns? {overflow:'initial'}: undefined}>
            {extraDatesHtml}
        </div>);

    }

    return (
        <div className="weekTimePicker">
            <table className="ui collapsed table">
                <thead>
                    <tr>
                        <th className="" style={{ display: 'flex' }}>
                            <span className="ui header version-info-header" data-testid="weekdays-days-config">
                                Ukedager/dager
                            </span>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr data-testid="test-version-info" style={{ boxShadow: 'none !important' }}>
                        <td style={{ padding: '24px' }}>
                            {resultHtml}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}


const mapStateToProps = (state: RootState, ownProps: any) => {

    const daPriceConfState = state.drivingAreaPriceConfig;
    const ownPropsResult = ownProps as OwnProps;
    const preOrderConfig = (daPriceConfState || {}).preOrderConfig;

    return {

        showAddDateForm: ownPropsResult.showAddDateForm,
        hideEditingBtns: ownPropsResult.hideEditingBtns,
        addDateFormIsShown: ownPropsResult.addDateFormIsShown,
        preOrderDateTimes: preOrderConfig.preOrderDateTimes,
        preOrderWeeklyTimes: preOrderConfig.preOrderWeeklyTimes,
        preOrderDateAllowed: preOrderConfig.preOrderDateAllowed
    };
};


const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        update_week_time: (args: any) => dispatch(updatePreOrderWeeklyTimesAllowed(args)),
        update_preOrder_date_time: (args: any) => dispatch(updatePreOrderDateTime(args)),
        delete_pre_order_date_time: (args: any) => dispatch(deletePreOrderDateTime(args))
    }

    return actions;
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AppWeekTimePicker)
);
