import { FieldMetaProps } from "formik";

export const AppField = (props: {errors: any, errorName?: string, metadata: FieldMetaProps<any>, 
    showError?: boolean, children: JSX.Element, style?:object, dataTestId?:string}) => {
    const { children, style, metadata, showError=true, dataTestId='app-field'} = props;

    const errorMessageClassName = metadata.touched && metadata.error ? "error": "" ;

    return (<div data-testid={dataTestId || ''} className={"field " + errorMessageClassName} style={style}>
        {children}
        {
            showError? <AppFieldErrorMessage metadata={metadata} />: ''
        }
        
    </div>)

}

export const AppFieldErrorMessage = (props: {metadata: any})=> {
    const {metadata} = props;

    if (!metadata){
        return null;
    }

    if (metadata.touched && metadata.error) 
        return <div style={{color:'red', textAlign:'left', marginTop:'2px'}} className="validation-error" >{metadata.error}</div>
    else 
        return null;
      
}

