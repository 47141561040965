import React from 'react';
import { Tooltip } from "react-leaflet";
import { AppDotMarker } from './AppDotMarker';


export interface MapCircleMarkerProps {
    isDraggable: boolean,
    center: { lat: number, lng: number },
    tooltipText: string
}

export const AppDotMarkerWithTooltip = (props: MapCircleMarkerProps) => {
    const { center, tooltipText } = props;


    return <AppDotMarker draggable={true} position={{ lat: center.lat, lng: center.lng }}
                onClick={function (e: any): void {
                    //todo
                } } onDragend={function (): void {
                   // todo
                } } >
        <Tooltip direction="top" offset={[10, 0]} opacity={0.9} permanent>
            {tooltipText ? tooltipText : ''}
        </Tooltip>
    </AppDotMarker>
}

