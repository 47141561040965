import { useParams } from "react-router";
import { PublishedVersionUrlParam } from "../config/routes";

const useIdPublishedVersionAssigner = () => {

    let { fromUrlIsPublished: isPublishedStr} = useParams<PublishedVersionUrlParam>();
    const isPublished = isPublishedStr === "1"? true:false;

    return isPublished;
  }

/** make it easier to mock this function */
export const useIdPublishedVersionAssignerModule = {
  useIdPublishedVersionAssigner: useIdPublishedVersionAssigner
}