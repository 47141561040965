import { Field } from "formik";
import { MyTextInputProps } from "./MyTextInput"


export const MyRadio = (props: MyTextInputProps) => {
    const {label, name,  value, onChange = () => { } } = props;

    return (<div className="field"><label style={{display: 'flex', alignItems: 'center'}}>
        <Field 
        style={{marginRight: '4px'}}
        className="ui radio checkbox"
        type="radio" 
        name={name || ''} 
        value={value}
        onChange={(e:any)=>{onChange(e)}} />{label}</label>
        </div>)

}



