import { AppDiscountsStatisticState, AppDiscountStatistic } from "../types";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const initialState: AppDiscountsStatisticState = {
    discountStatistics: [],
    discountId: null,
    loading: false,
}

const discountStatisticSlice = createSlice({
    name: 'discountStatistic',
    initialState,
    reducers: {
        discountStatisticLoading(state, action: PayloadAction<number>) {
            state.loading = true;
            state.discountId = action.payload
        },
        discountStatisticLoaded(state, action: PayloadAction<{discountId: number, items: Array<AppDiscountStatistic>}>) {
            state.loading = false;
            state.discountId = (action.payload || {}).discountId || null;
            state.discountStatistics = (action.payload || {}).items || [];
        },
        discountStatisticsLoadingFailed(state) {
            state.loading = false;
            state.discountId = null;
        },
        resetStatisticsState(state) {            
            return initialState;
        }
    },
})

export const {
    discountStatisticLoading,
    discountStatisticLoaded,
    discountStatisticsLoadingFailed,
    resetStatisticsState,
} = discountStatisticSlice.actions;

export default discountStatisticSlice.reducer;



