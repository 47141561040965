import { ActionStatus } from "../../config/constants";
import * as yup from 'yup'

export interface AppUser {
  userId: number | null,
  token: string | null,
  email: string | null,
  role: number | null,
  firstName: string | null,
  lastName: string | null,
  password?: string | null,
  phoneNumber: string | null,
  regionId?: number | null
}

export enum UserRolesEnum {
  Admin = 1,
  User = 2
}

export interface AppUsers {
  [userId: string]: AppUser;
}

export interface UsersState {
  list: AppUsers
  loading: ActionStatus,
  saving: boolean,
  deleting: boolean,
  currentUserId: number | null
};

/** State model */
export interface AuthUserState {
  user: AppUser,
  status: ActionStatus,
  isAuthenticated: boolean;
};

/** Request model */
export interface LoginRequestData {
  email: string | null;
  password: string | null;
};

export interface ForgotPasswordRequestData {
  email: string | null;
};

export interface UserEditorRequestData extends AppUser {
  passwordConfirmation: string
}

/** Validation for LoginRequestData  */
export const forgotPasswordRequestDataValidation = yup.object().shape({
  email: yup.string().max(70, 'Maks. 70 tegn').required('Epost påkrevd')
})


export const loginRequestDataValidation = yup.object().shape({
  email: yup.string().max(70, 'Maks. 70 tegn').required('Epost påkrevd'), //.email('Epost er ugyldig')
  password: yup.string().max(70, 'Maks. 70 tegn').required('Passord påkrevd.')
})


/** Validation for add user  */
export const userAddRequestDataValidation = yup.object().shape({
  email: yup.string().max(70, 'Maks. 70 tegn').email('Epost er ugyldig').required('Epost påkrevd'),
  password: yup.string().min(8, 'Passord bør være minst 8 tegn').max(70, 'Maks. 70 tegn').required('Passord påkrevd.'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passordene samsvarer ikke').required('Passordbekreftelsen er påkrevd.'),
  firstName: yup.string().max(70, 'Maks. 70 tegn').required('Fornavn påkrevd.'),
  lastName: yup.string().max(70, 'Maks. 70 tegn').required('Etternavn påkrevd.'),
  role: yup.string().required('Role påkrevd.'),
  phoneNumber: yup.string()
})

/** validation for update user */
export const userUpdateRequestDataValidation = yup.object().shape({
  email: yup.string().max(70, 'Maks. 70 tegn').email('Epost er ugyldig'),
  password: yup.string().min(8, 'Passord bør være minst 8 tegn').max(70, 'Maks. 70 tegn'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passordene samsvarer ikke'),
  firstName: yup.string().max(70, 'Maks. 70 tegn'),
  lastName: yup.string().max(70, 'Maks. 70 tegn'),
  role: yup.string(),
  phoneNumber: yup.string()
})
