import React from 'react';
import { AppBreadcrumbs, Spinner } from '../../../../components';
import { AppModal } from '../../../../components/AppModal';
import { PageHeaderWrapper } from '../../CommonComponents/PageHeaderWrapper';
import { MapIcon } from '../../../../components/Icon';
import { ModalTypeData } from '../../../../config/constants';
import { CommentEditor } from '../../CommonComponents/CommentEditor';
import { DrivingAreaPriceConfActions, DrivingAreaPriceConfigModalType } from '../types';
import { DrivingAreaPriceConfigTabs } from './DrivingAreaPriceConfigTabs';
import MainDrivingAreaPriceConfigEditor from './MainDrivingAreaPriceConfigEditor';
import DayTimeZoneEditor from './DrivingAreaDayTimeZoneEditor';
import SpecialLocationEditor from './SpecialLocationEditor';
import { HideEditingBtns } from '../../types';
import CountryRegionDrivingAreaPicker from '../../../CountryRegionDrivingAreaPicker/CountryRegionDrivingAreaPicker';
import { DrivingArea } from '../../../DrivingAreas/types';
import DrivingAreasOnMapContainer from '../../../DrivingAreas/DrivingAreasOnMapContainer';
import PreOrderInfoEditor from './PreOrderInfoEditor';

export interface DrivingAreaPriceConfigViewPropsFromReducer {
    currentCountryId: number | null,
    currentRegionId: number | null,
    currentDrivingAreaId: number | null,

    regionsLoading: boolean,
    countriesLoading: boolean,
    drivingAreasLoading: boolean,
    currentDrivingArea: DrivingArea | null,

    comment: string | null
}

interface DrivingAreaPriceConfigViewProps extends HideEditingBtns {
    allProps: DrivingAreaPriceConfigViewPropsFromReducer,
    modalState: ModalTypeData | null,
    actions: DrivingAreaPriceConfActions
}


export const DrivingAreaPriceConfigView: React.FC<DrivingAreaPriceConfigViewProps> = (props) => {

    const { countriesLoading, regionsLoading, drivingAreasLoading,
        currentCountryId, currentRegionId, currentDrivingAreaId, comment, currentDrivingArea } = props.allProps || {};
    const { modalState, actions, hideEditingBtns } = props;

    if (countriesLoading || regionsLoading || drivingAreasLoading) {
        return <Spinner />
    }

    let modalTitleText = null;
    let modalContentHtml = null;

    if (modalState) {
        if (modalState.modalType === DrivingAreaPriceConfigModalType.drivingAreaPriceFactors) {
            modalTitleText = "Rediger/Legg til kjøreområdets variabler";
            modalContentHtml = <MainDrivingAreaPriceConfigEditor actions={actions} />
        }
        else if (modalState.modalType === DrivingAreaPriceConfigModalType.dayTimeZones) {
            modalTitleText = "Rediger/Legg til døgnsone";
            modalContentHtml = <DayTimeZoneEditor currentIndex={modalState.index} actions={actions} />
        }
        else if (modalState.modalType === DrivingAreaPriceConfigModalType.specialLocations) {
            modalTitleText = "Rediger/Legg til spesiallokasjon";
            modalContentHtml = <SpecialLocationEditor actions={actions} currentSpecialLocation={modalState.index} />
        }
        else if (modalState.modalType === DrivingAreaPriceConfigModalType.versionInfo) {
            modalTitleText = "Rediger/Legg til kommentar";
            modalContentHtml = <CommentEditor
                comment={comment}
                update_comment={actions.updateComment}

            />;
        }
        else if (modalState.modalType === DrivingAreaPriceConfigModalType.preOrderInfo) {
            modalTitleText = "Rediger";
            modalContentHtml = <PreOrderInfoEditor actions={actions}/>;
        }
    }

    return (<div className="main-content-item driving-area-price-config-page">

        <AppBreadcrumbs pages={[{ name: 'Pris admin / Kjøreområde variabler' }]} />

        <main className="driving-area-price-config-page-main">

            <PageHeaderWrapper >

                <div className="price-config-area-picker-wrapper display-flex-center-y">

                    <div style={{ marginRight: '4px' }}><MapIcon /></div>

                    {
                        hideEditingBtns ? 
                        <div style={{fontWeight:'bold'}}> {'Publisert versjon: ' + (currentDrivingArea?.name || '') }</div> 
                        : <CountryRegionDrivingAreaPicker
                                addAllDrivingAreas={true}
                                showDrivingAreas={true}
                                showRegions={true}
                                itemsPosition={'row'}
                                showCurrentRegionLabel={false}
                                currentCountryId={currentCountryId}
                                onRegionChanged={actions.changeRegion}
                                onCountryChanged={actions.changeCountry}
                                onDrivingAreaChanged={actions.changeDrivingArea}
                                currentRegionId={currentRegionId || null}
                                currentDrivingAreaId={currentDrivingAreaId || null}
                                propToResetState={currentCountryId}
                            />
                    }

                </div>

            </PageHeaderWrapper>


            {
                currentDrivingAreaId == -1? 
                <DrivingAreasOnMapContainer currentRegionId={currentRegionId}/>
                : <DrivingAreaPriceConfigTabs actions={actions} hideEditingBtns={hideEditingBtns} />

            }

        </main>

        {
            modalContentHtml ?
                <AppModal
                    onClose={actions.closeEditor}
                    isOpen={modalState ? true : false}
                    header={<>{modalTitleText}</>} >

                    {modalContentHtml}

                </AppModal>
                : ''
        }


    </div>
    )
};


