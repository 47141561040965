import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrivingAreaPriceConfig } from '../../types';
import { DrivingAreaPriceConfigsState } from '../types';


const initialState: DrivingAreaPriceConfigsState = {
  list: [],
  loading: false
};

const drivingAreaPriceConfigsSlice = createSlice({
  name: 'drivingAreaPriceConfigs',
  initialState,
  reducers: {
    priceConfigsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPriceConfigs(state, action: PayloadAction<Array<DrivingAreaPriceConfig>>) {
      const data = action.payload || {};

      if (!data) {
        return state;
      }

      state.list = data || [];
      state.loading = false;
    },
    resetDrivingAreaPriceConfigsState(state) {
      return initialState;
    }
  },
})

export const {
  priceConfigsLoading,
  setPriceConfigs,
  resetDrivingAreaPriceConfigsState
} = drivingAreaPriceConfigsSlice.actions;

export default drivingAreaPriceConfigsSlice.reducer;






