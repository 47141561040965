import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { usePrevious } from '../../hooks/usePrevious';
import '../../styles/discount.css'
import { activateDeactivateDiscount, addDiscount, deleteDiscount, getDiscountCustomers, getDiscountCustomersForDuplicate, getDiscounts, getDiscountUsage, updateDiscount } from './actions/discountActions';
import { duplicateDiscount, resetFilters, resetList, resetState, setPage } from './reducers/discountsReducers';
import { AppActivateDeactivateRequest, AppDeleteDiscountRequest, AppDiscountCustomers, AppDiscountEditor, AppDiscountsRequest, AppDiscountsState, DiscountModalType, DiscountsActions } from './types';
import { RootState } from '../../config/reducerConfig/rootReducer';
import { DiscountsView, DiscountsViewPropsFromReducer } from './child-components/discountList/DiscountsView';
import { resetDiscountCustomersState } from './reducers/discountCustomers';
import { resetStatisticsState } from './reducers/discountStatisticReducer';


interface DiscountContainerProps {
   api_get_discounts: (args: AppDiscountsRequest) => any
   set_page: () => void
   reset_discounts: () => void
   reset_filters: () => void,
   reset_list: () => void,
   add_discounts: (args: AppDiscountEditor) => any,
   update_discounts: (args: AppDiscountEditor) => any,
   activate_deactivate_discount: (args: AppActivateDeactivateRequest) => any,
   get_discount_usage: (discountId: number) =>any,
   get_discount_customers: (discountId: number) => any,
   get_discount_customers_for_duplicate: (discountId: number) => any,
   duplicate_discount: (discountId: number, discountCustomers: Array<string>) => any,
   delete_discount: (args: AppDeleteDiscountRequest)=> any,
   reset_discountCustomers_state:()=>any,
   reset_statistics:()=>any
}

const DiscountContainer: React.FC<DiscountsViewPropsFromReducer & DiscountContainerProps> = (props) => {

   const { filters, discounts, discountsPagination, discountsLoading, statisticsLoading, statisticsDiscountId } = props;

   const listTopEl = useRef<any>(null);
   const discountStatisticsBtn = useRef<any>(null);

   const { set_page,
      api_get_discounts,
      reset_filters,
      reset_list,
      add_discounts,
      update_discounts,
      activate_deactivate_discount,
      get_discount_usage,
      get_discount_customers,
      get_discount_customers_for_duplicate,
      reset_discountCustomers_state,
      delete_discount,
      duplicate_discount,
      reset_discounts,
      reset_statistics
   } = props;

   const [modalState, setModalState] = useState<{ modalType: any, index: number | null } | null>(null);

   let prevPageValue = usePrevious((discountsPagination || {}).page);

   const numberOfItemsPerPage = 50;

   const searchItems = () => {
      api_get_discounts({
         pageNumber: 1,
         numberOfItems: numberOfItemsPerPage,
         searchStr: filters.searchText,
      });
   }

   useEffect(() => {

      api_get_discounts({
         pageNumber: discountsPagination.page || 1,
         numberOfItems: numberOfItemsPerPage,
         searchStr: filters.searchText || null,
      });

      return () => {
         reset_discountCustomers_state();
         reset_discounts();
         reset_statistics();
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])


   useEffect(() => {

      if (!discountsPagination.isLastPage && prevPageValue !== discountsPagination.page && discountsPagination.page > 1) {

         api_get_discounts({
            pageNumber: discountsPagination.page,
            numberOfItems: numberOfItemsPerPage,
            searchStr: filters.searchText,

         });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [discountsPagination.page])


   const getItemsOnScroll = (e: any) => {

      var bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;

      if (bottom && !discountsPagination.isLastPage && !discountsLoading) {
         set_page();
      }

   }

   const doSearch = () => {
      reset_list();
      searchItems();
   }

   const resetFilters = () => {

      reset_filters();

      api_get_discounts({
         pageNumber: 1,
         numberOfItems: numberOfItemsPerPage,
      });
   }

   const doOpenEditor = (modalType: DiscountModalType, id: number | null): void => {

      if (!modalType) {
         return;
      }

      setModalState({ modalType, index: id });

      if (id){
         get_discount_customers(id);
      }
   }

   const doOpenStatistics = (modalType: DiscountModalType, id: number | null): void => {

      if (!modalType) {
         return;
      }

      setModalState({ modalType, index: id });

      if (id){
         get_discount_usage(id);
      }

   }

   const addOrUpdateDiscount = (dataToSend: AppDiscountEditor, currentId: number | null): void => {

      const getDiscounts = () => {

         return api_get_discounts({
            pageNumber: 1,
            numberOfItems: numberOfItemsPerPage,
            searchStr: filters.searchText || null,
         });
      }

      // update request
      if (currentId) {
         update_discounts(dataToSend).then(() => {  

            getDiscounts();

            setTimeout(()=>{
               closeEditor();
            }, 500)
         }).catch(() => {})

      }
      // add request
      else {
        
         // to fix problem with scroll, when new item added,
         if (listTopEl && listTopEl.current){
            listTopEl.current.scrollIntoView({ behavior: 'smooth' });
         }

         add_discounts(dataToSend).then(() => {
            
            getDiscounts();

            setTimeout(()=>{
               closeEditor();
            }, 500);

         }).catch(() => { })
      }
   }

   const duplicateDiscount = (discountId: number) => {

      get_discount_customers_for_duplicate(discountId).then((items:Array<AppDiscountCustomers>)=>{

         const itemsTemp: Array<string> = [];

         if (items && items.length > 0) {

            items.forEach((item:AppDiscountCustomers)=>{
               itemsTemp.push(item.phoneNumber);

            })
         }

         duplicate_discount(discountId, itemsTemp);
         doOpenEditor(DiscountModalType.discountEditor, -1);

      })
   }

   const activateOrDeactivateDiscount = (args:any): void => {
      
      activate_deactivate_discount(args).then((x:any)=> {
         closeEditor();
      })
   }

   const deleteDiscount = (args: AppDeleteDiscountRequest)=> {
      delete_discount(args);
   }

   const closeEditor = (): void => {

      setModalState({ modalType: null, index: null });
      reset_discountCustomers_state();
   }

   const getStatistics = (discountId: number) => {
      get_discount_usage(discountId).then((x:any)=>{
        if (discountStatisticsBtn.current !== null) {
            discountStatisticsBtn.current!.click();
        }

        reset_statistics();

      });
   }

   const actions: DiscountsActions = {
      getStatistics: getStatistics,
      getItemsOnScroll: getItemsOnScroll,
      resetFilters: resetFilters,
      doSearch: doSearch,
      doOpenStatistics,
      openEditor: doOpenEditor,
      closeEditor: closeEditor,
      addOrUpdateDiscount: addOrUpdateDiscount,
      deleteDiscount,
      duplicateDiscount,
      activateDeactivateDiscount: activateOrDeactivateDiscount
   }

   return (<DiscountsView
      statisticsLoading={statisticsLoading}
      statisticsDiscountId={statisticsDiscountId}
      discountStatisticsBtn={discountStatisticsBtn}
      listTopEl ={listTopEl}
      discountsLoading={discountsLoading}
      discounts={discounts}
      actions={actions}
      filters={filters}
      discountsPagination={discountsPagination}
      modalState={modalState}
   />)
};


const mapStateToProps = (state: RootState) => {
   const discountsState = state.discounts as AppDiscountsState;
   const discountStatistics = state.discountStatistic;

   return {
      filters: discountsState.filters,
      discountsPagination: discountsState.pagination,
      discountsLoading: discountsState.loading,
      discounts: discountsState.list,
      statisticsLoading:discountStatistics.loading,
      statisticsDiscountId: discountStatistics.discountId
   };
};


const mapDispatchToProps = (dispatch: any) => {

   return {
      api_get_discounts: (args: AppDiscountsRequest) => { dispatch(getDiscounts(args)) },
      set_page: () => { dispatch(setPage()) },
      reset_filters: () => { dispatch(resetFilters()) },
      reset_list: () => dispatch(resetList()),
      add_discounts: (args: AppDiscountEditor) => dispatch(addDiscount(args)),
      update_discounts: (args: AppDiscountEditor) => dispatch(updateDiscount(args)),
      activate_deactivate_discount: (args: AppActivateDeactivateRequest) => dispatch(activateDeactivateDiscount(args)),
      get_discount_customers: (discountId: number) => dispatch(getDiscountCustomers(discountId)),
      get_discount_customers_for_duplicate: (discountId: number) => dispatch(getDiscountCustomersForDuplicate(discountId)),
      get_discount_usage: (discountId: number) => dispatch(getDiscountUsage(discountId)),
      duplicate_discount: (discountId: number, discountCustomers: Array<string>) => dispatch(duplicateDiscount({discountId, discountCustomers})),
      delete_discount:(args: AppDeleteDiscountRequest) => dispatch(deleteDiscount(args)),
      reset_discountCustomers_state: () => dispatch(resetDiscountCustomersState()),
      reset_discounts: () => dispatch(resetState()),
      reset_statistics: () => dispatch(resetStatisticsState())
   };
};

export default withRouter(
   connect(mapStateToProps, mapDispatchToProps)(DiscountContainer)
);


