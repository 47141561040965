import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ModalFormFooter, MyTextInput } from '../../../../components';
import { AppButtonSubmit } from '../../../../components/AppButton';
import { prepareAndRoundNumber } from '../../../../utils/commonHelpers';
import { formPrepareAndRoundNumber, getInputValue } from '../../../../utils/formHelpers';
import { nameof } from '../../../../utils/nameOfPropertyHelper';
import { CountryPriceConfig } from '../../types';
import { updatePriceConfig } from '../reducers/countryPriceConfigReducer';
import { CountryPriceConfActionsProps, CountryPriceConfigState, countryPriceConfigValidation } from '../types';


interface Props {
    priceConfig: CountryPriceConfig | null,
    update_price_Config: (countryPriceConfig: CountryPriceConfig) => void
};

type OwnProps = CountryPriceConfActionsProps;

type MainPriceConfigEditorProps = Props & OwnProps


const MainPriceConfigEditor: React.FC<MainPriceConfigEditorProps> = (props) => {

    let { priceConfig, update_price_Config, actions } = props;

    if (!priceConfig) {
        return null;
    }

    const handleFormSubmit = async (values: any, setErrors: any) => {

        let startPrice = values[nameof<CountryPriceConfig>('startPrice')];
        let pricePerKm = values[nameof<CountryPriceConfig>('pricePerKm')];

        if (startPrice) {
            startPrice = prepareAndRoundNumber(startPrice);
        }

        if (pricePerKm) {
            pricePerKm = prepareAndRoundNumber(pricePerKm);
        }

        if (values && typeof update_price_Config === 'function') {
            update_price_Config(values);
            actions.closeEditor();
        }

    }

    const columnStyle = {width: '48%'};

    return (
        <Formik
            enableReinitialize={true}
            initialValues={priceConfig}
            validationSchema={countryPriceConfigValidation}
            onSubmit={(values, { setErrors }) => { handleFormSubmit(values, setErrors) }}
        >
            {(formik) => (
                <Form className='ui form' onSubmit={formik.handleSubmit} autoComplete='off' data-testid="country-price-main-conf-form">

                    <div style={{display:'flex', justifyContent:'space-evenly', width:'100%', }}>
                        <div style={columnStyle}> <MyTextInput
                            extraProps={{ fullWidth: true }}
                            label={'Startpris'}
                            data-testid={nameof<CountryPriceConfig>('startPrice')}
                            name={nameof<CountryPriceConfig>('startPrice')}
                            type="number"
                            onChange={(e) => {
                                formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                    formik.setFieldValue(nameof<CountryPriceConfig>('startPrice'), newValue)
                                });

                            }}
                        />
                            <div style={{ marginLeft: '20px' }}></div>

                            <MyTextInput
                                extraProps={{ fullWidth: true }}
                                label={'Kilometerpris'}
                                data-testid={nameof<CountryPriceConfig>('pricePerKm')}
                                name={nameof<CountryPriceConfig>('pricePerKm')}
                                type="number"
                                onChange={(e) => {
                                    formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                        formik.setFieldValue(nameof<CountryPriceConfig>('pricePerKm'), newValue)
                                    });

                                }}
                            />

                            <MyTextInput
                                extraProps={{ fullWidth: true }}
                                label={'Minstepris'}
                                data-testid={nameof<CountryPriceConfig>('minPrice')}
                                name={nameof<CountryPriceConfig>('minPrice')}
                                type="number"
                                onChange={(e) => {
                                    formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                        formik.setFieldValue(nameof<CountryPriceConfig>('minPrice'), newValue)
                                    });

                                }}
                            />

                            <MyTextInput
                                extraProps={{ fullWidth: true }}
                                label={'Waypoint tillegg'}
                                data-testid={nameof<CountryPriceConfig>('waypointAddition')}
                                name={nameof<CountryPriceConfig>('waypointAddition')}
                                type="number"
                                onChange={(e) => {
                                    formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                        formik.setFieldValue(nameof<CountryPriceConfig>('waypointAddition'), newValue)
                                    });

                                }}
                            />

                            <MyTextInput
                                extraProps={{ fullWidth: true }}
                                label={'Forhåndsbestilling'}
                                data-testid={nameof<CountryPriceConfig>('preOrderAddition')}
                                name={nameof<CountryPriceConfig>('preOrderAddition')}
                                type="number"
                                onChange={(e) => {
                                    formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                        formik.setFieldValue(nameof<CountryPriceConfig>('preOrderAddition'), newValue)
                                    });
                                }}
                            />

                            <MyTextInput
                                    extraProps={{ fullWidth: true }}
                                    label={'Cancel grace time (sek.)'}
                                    data-testid={nameof<CountryPriceConfig>('cancellationGraceTime')}
                                    name={nameof<CountryPriceConfig>('cancellationGraceTime')}
                                    type="number"
                                    step={1}
                                    onChange={(e) => {
                                        formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                            const result = newValue? newValue: null;
                                            formik.setFieldValue(nameof<CountryPriceConfig>('cancellationGraceTime'), result)
                                        });
                                    }}
                                />

                        </div>
                        <div style={columnStyle}>
                            <MyTextInput
                                extraProps={{ fullWidth: true }}
                                label={'Start helligdagperiode'}
                                data-testid={nameof<CountryPriceConfig>('startHolidayExtraHours')}
                                name={nameof<CountryPriceConfig>('startHolidayExtraHours')}
                                type="number"
                                onChange={(e) => {
                                    formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                        formik.setFieldValue(nameof<CountryPriceConfig>('startHolidayExtraHours'), newValue)
                                    }, 0);
                                }}
                            />

                            <MyTextInput
                                extraProps={{ fullWidth: true }}
                                label={'Slutt helligdagperiode'}
                                data-testid={nameof<CountryPriceConfig>('endHolidayExtraHours')}
                                name={nameof<CountryPriceConfig>('endHolidayExtraHours')}
                                type="number"
                                onChange={(e) => {
                                    formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                        formik.setFieldValue(nameof<CountryPriceConfig>('endHolidayExtraHours'), newValue)
                                    }, 0);
                                }}
                            />

                            <MyTextInput
                                extraProps={{ fullWidth: true }}
                                label={'MVA-sats'}
                                data-testid={nameof<CountryPriceConfig>('vatRate')}
                                name={nameof<CountryPriceConfig>('vatRate')}
                                type="number"
                                step={0.01}
                                onChange={(e) => {
                                    formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                        formik.setFieldValue(nameof<CountryPriceConfig>('vatRate'), newValue)
                                    });
                                }}
                            />

                            <MyTextInput
                                extraProps={{ fullWidth: true }}
                                label={'LateCancel maks gebyr'}
                                data-testid={nameof<CountryPriceConfig>('lateCancelMaxPrice')}
                                name={nameof<CountryPriceConfig>('lateCancelMaxPrice')}
                                type="number"
                                onChange={(e) => {
                                    formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                        formik.setFieldValue(nameof<CountryPriceConfig>('lateCancelMaxPrice'), newValue)
                                    });
                                }}
                            />

                            <MyTextInput
                                extraProps={{ fullWidth: true }}
                                label={'NoShow maks gebyr'}
                                data-testid={nameof<CountryPriceConfig>('noShowMaxPrice')}
                                name={nameof<CountryPriceConfig>('noShowMaxPrice')}
                                type="number"
                                onChange={(e) => {
                                    formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                        formik.setFieldValue(nameof<CountryPriceConfig>('noShowMaxPrice'), newValue)
                                    });
                                }}
                            />

                            <MyTextInput
                                extraProps={{fullWidth: true}}
                                label={'Maks. antall aktive bestillinger:'}
                                step={1}
                                data-testid={nameof<CountryPriceConfig>('maximumNumberOfActiveBookingsPerCustomer')}
                                name={nameof<CountryPriceConfig>('maximumNumberOfActiveBookingsPerCustomer')}
                                type="number"
                            />

                            <MyTextInput
                                extraProps={{fullWidth: true}}
                                label={'Maks. antall forhåndsbestillinger'}
                                data-testid={nameof<CountryPriceConfig>('maximumNumberOfPreOrderBookingsPerCustomer')}
                                name={nameof<CountryPriceConfig>('maximumNumberOfPreOrderBookingsPerCustomer')}
                                type="number"
                            
                            />

                        </div>
                    </div>

                    <ModalFormFooter>
                        <AppButtonSubmit dataTestId="form-submit" onClick={formik.handleSubmit}>Ferdig</AppButtonSubmit>
                    </ModalFormFooter>

                </Form>
            )}
        </Formik>
    )
};


const mapStateToProps = (state: any, ownProps: any) => {

    const countryPriceConfState = state.countryPriceConfig as CountryPriceConfigState;
    const props = ownProps as OwnProps;

    return {
        actions: props.actions,
        priceConfig: countryPriceConfState.priceConfig

    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        update_price_Config: (countryPriceConfig: CountryPriceConfig) => dispatch(updatePriceConfig(countryPriceConfig)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainPriceConfigEditor)
);
