import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDayOfWeekConfig } from '../../../../commonTypes';
import { ModalFormFooter, MyTextInput } from '../../../../components';
import { AppButtonSubmit } from '../../../../components/AppButton';
import { formPrepareAndRoundNumber, getInputValue } from '../../../../utils/formHelpers';
import { nameof } from '../../../../utils/nameOfPropertyHelper';
import { CountryPriceConfigDayTypeFactor } from '../../types';
import { selectDayTypeConfig, updateDayType } from '../reducers/countryPriceConfigReducer';
import { dateTypeFactorValidation, CountryPriceConfActionsProps } from '../types';

interface OwnProps {
    currentIndex: number,
}

interface Props {
    dayTypeFactor: CountryPriceConfigDayTypeFactor | null,
    update_date_type: (dayType: CountryPriceConfigDayTypeFactor, index: number) => any
};

type DayTypeEditorProps = Props & CountryPriceConfActionsProps & OwnProps;


const DayTypeEditor: React.FC<DayTypeEditorProps> = (props) => {

    const { dayTypeFactor, currentIndex, update_date_type, actions } = props;

    if (!dayTypeFactor) {
        return null;
    }

    const handleFormSubmit = async (values: any, setErrors: any) => {

        if (values && typeof update_date_type === 'function') {
            update_date_type(values, currentIndex);
            actions.closeEditor();
        }
    }

    const dayTypeId = dayTypeFactor.dayTypeId;
    const dayTypeText = getDayOfWeekConfig(dayTypeId).text;

    return (
        <Formik
            enableReinitialize={true}
            initialValues={dayTypeFactor}
            validationSchema={dateTypeFactorValidation}
            onSubmit={(values, { setErrors }) => { handleFormSubmit(values, setErrors) }}
        >
            {(formik) => (
                <Form className='ui form' onSubmit={formik.handleSubmit} autoComplete='off' data-testid='day-type-factor-form'>

                    <div>

                        <MyTextInput
                            label={'Prisfaktor (' + (dayTypeText || '') + ')'}
                            data-testid={nameof<CountryPriceConfigDayTypeFactor>('priceFactor')}
                            name={nameof<CountryPriceConfigDayTypeFactor>('priceFactor')}
                            type="number"
                            step={0.05}
                            onChange={(e) => {
                                formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                    formik.setFieldValue(nameof<CountryPriceConfigDayTypeFactor>('priceFactor'), newValue)
                                },
                                2);

                            }}
                        />

                    </div>

                    <ModalFormFooter>
                        <AppButtonSubmit dataTestId="form-submit" onClick={formik.handleSubmit}>Ferdig</AppButtonSubmit>
                    </ModalFormFooter>

                </Form>
            )}
        </Formik>
    )
};


const mapStateToProps = (state: any, ownProps: any) => {

    const props = ownProps as OwnProps;
    const currentIndex = props.currentIndex;

    return {
        dayTypeFactor: selectDayTypeConfig(state, currentIndex) || null,
        currentIndex: currentIndex
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        update_date_type: (dayType: CountryPriceConfigDayTypeFactor, index: number) => { return dispatch(updateDayType({ dayType, index })) },

    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DayTypeEditor)
);

