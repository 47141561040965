
import React from 'react'
import { AppUser } from '../../Login/types'
import { UsersList } from './UsersList'
import '../../../styles/userManager.css'
import { AppButton } from '../../../components';
import UsersEditor from './UsersEditor';
import { AppBreadcrumbs } from '../../../components/Layout/Breadcrumbs';

interface UsersViewProps {
    users: Array<AppUser>,
    authUserAccessAllRegions: boolean,
    setCurrentUser: (userId: any) => void,
    handleDeletUser: (userId: number) => void
}


export const UsersView: React.FC<UsersViewProps> = (props) => {
    const { users, authUserAccessAllRegions, setCurrentUser, handleDeletUser } = props;

    return (<div className='main-content-item users-manager'>

        <AppBreadcrumbs pages={[{ name: 'Hjem' }, { name: 'Brukere' }]} />

        <div className="user-manager-col user-manager-main">

            <div className="user-manager-col-user-editor user-manager-element wrapperWithBorder">
                <h2 className="ui header">Opprett ny/rediger</h2>
                <AppButton dataTestId="add-new-user-test" designType='teal' onClick={(e) => setCurrentUser(0)} size='small' >Ny bruker</AppButton>
                <UsersEditor />
            </div>
            <div className="user-manager-col-user-list user-manager-element wrapperWithBorder">
                <h2 className="ui header">Brukere</h2>
                <UsersList showRegion={authUserAccessAllRegions} users={users} setCurrentUser={setCurrentUser} handleDeletUser={handleDeletUser} />
            </div>

        </div>

    </div>)
}


