import { AuthUserState, AppUser, UserRolesEnum } from '../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionStatus } from '../../../config/constants';

const initialState: AuthUserState = {
  user: { email: '', role: null, token: null, userId: null, firstName:'', lastName:'', phoneNumber:''},
  isAuthenticated: false,
  status: ActionStatus.NONE
};


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      login(state) {
          state.status = ActionStatus.PROCESS;
      },
      loginSuccess(state, action: PayloadAction<AppUser>) {
        const user = action.payload;
        
        if (!user)
            return state;
        
        state.user = user;
        state.isAuthenticated = true;
        state.status = ActionStatus.SUCCESS;
      },
      setAuthtUser(state, action: PayloadAction<AppUser>){
        state.user = action.payload? action.payload: state.user;
      },
      loginFailed(state) {
        state.status = ActionStatus.FAIL;
      },
      logout () {
        return initialState;
      },
      reset() {
        return initialState;
      },
    },
  })

  export const {
    login,
    loginSuccess,
    loginFailed,
    setAuthtUser,
    logout,
    reset,
  } = authSlice.actions;

  export default authSlice.reducer;


export const selectAuthUser = (state:any): AppUser => state.auth.user;
export const selectAuthUserId = (state: any) => state.auth.user? state.auth.user.id: 0;
export const isAdmin = (state: any) => state.auth.user? state.auth.user.role === UserRolesEnum.Admin: false;