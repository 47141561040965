import { AppBreadcrumbs } from "../../../components";
import { AppButtonSubmit } from "../../../components/AppButton";
import { MonthPicker } from "../../../components/AppTimePicker";
import { BookingFinancialReportActions } from "../types";
import ReportExcel from "./ReportExcel";

export interface PriceConfigTestViewPropsFromReducer {
    fromDate: Date | null,
    toDate: Date | null,
    loading: boolean
}

interface PropsFromLocalState {
    actions: BookingFinancialReportActions,
    getReportBtn: any
}

export const BookingFinancialReportView: React.FC<PriceConfigTestViewPropsFromReducer & PropsFromLocalState> = (props) => {
    const { actions, fromDate, getReportBtn, loading } = props;


    return (<div className="main-content-item financial-report-page">
        <AppBreadcrumbs pages={[{ name: 'Regnskapsrapport /' }]} />
        <div className="app-fin-report" style={{ width: '325px', }}>

            <MonthPicker selectedDateInit={fromDate} dateChangedHandler={actions.dateChangedHandler} />

            <div style={{ textAlign: 'right' }}>
                <AppButtonSubmit dataTestId="get-report-btn-test" onClick={actions.getReportBtnClick}
                    loading={loading} fluid designType='teal' >Last ned rapport</AppButtonSubmit>
            </div>

            <ReportExcel button={<button style={{ display: 'none' }} ref={getReportBtn}></button>} />

        </div>
    </div>
    )
};

