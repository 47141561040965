import * as yup from 'yup';
import { DAY_TYPES } from '../../../commonTypes';

/* ENTITIES */

export interface DrivingAreaWithPriceTestCases {
    drivingAreaId: number,
    drivingAreaName: string,
    regionId: number,
    priceTestCases: Array<PriceTestCase>
    withSpecialLocations: boolean,
    withCarTypes:boolean
}

export interface PriceTestCaseBase {
    priceTestCaseId: number | null
    name: string | null
    drivingDistance: number
    pickupLocalTime: string | null
    dayType: number | null
    numberOfPassengers: number
    numberOfStops: number
    preOrder: boolean 
}

export interface PriceTestCase extends PriceTestCaseBase {
    drivingAreaId: number | null,
    specialLocationName: string | null
    specialLocationIsFrom: boolean
    specialLocationId: number | null
    carTypeName: string | null
    carTypeId: number | null
    draftPrice: number | null
    publishedPrice: number | null
}

export const getEmptyPriceTestCaseBase = (): PriceTestCaseBase => {
    return {
        priceTestCaseId: null, 
        name: null,
        drivingDistance: 1,
        pickupLocalTime: null,
        dayType: DAY_TYPES.Monday,
        numberOfPassengers: 1,
        numberOfStops: 0,
        preOrder: false
    }
}

/** state */
export interface PriceTestCasesState {
    testCases: Array<PriceTestCaseBase>,
    drivingAreasWithPriceTestCases: Array<DrivingAreaWithPriceTestCases>
    loading: boolean,
    // showWithSpecialLocations
    // showWithCarTypes    
}

/* REQUESTS AND RESPONSES FROM/TO SERVER */

export interface PriceTestCasesServerData{
    testCases: Array<PriceTestCaseBase>,
    drivingAreasWithPriceTestCases: Array<DrivingAreaWithPriceTestCases>
}

export interface AddOrUpdatePriceTestCaseRequest {
    priceTestCase: PriceTestCaseBase
}

export interface DeletePriceTestCaseRequest {
    priceTestCaseId: number
}

/** other types, enums, constants */
export enum PriceTestCaseModalType {
    priceTestCaseEditor = 1
}

/** container actions */
export interface PriceTestCaseContainerActions {
    changeCountry: (countryId: number) => void,
    openEditor: (index:number | null, modalType: PriceTestCaseModalType) => void,
    closeEditor: () => void,
    addOrUpdatePriceTestCase: (priceTestCase: PriceTestCaseBase, id:number | null) => void,
    deletePriceTestCase:(id:number)=>void,
    changeFilterWithSpecialLocations: () =>void,
    changeFilterWithCarTypes:()=>void,
    getPriceTestCase: ()=>void,
    resetFilters:()=>void
}

export interface PriceTestCaseContainerActionsProps {
    actions: PriceTestCaseContainerActions
}

/* Validation */
export const priceTestCaseValidation = yup.object().shape({
    name: yup.string().typeError('Navn er påkrevd.').required('Navn er påkrevd'),
    pickupLocalTime: yup.string().required("Pickup tidspunkt er påkrevd").typeError('Pickup tidspunkt er påkrevd.'),
    drivingDistance: yup.number().typeError("Kjøreavstand er påkrevd").min(0.001, "Kjøreavstand må være større enn 0.").required('Kjøreavstand er påkrevd.'),
    numberOfPassengers: yup.number().typeError("Antall passasjerer er påkrevd").min(1, "Antall passasjerer må være større eller lik 1.").required('Antall passasjerer er påkrevd.'),
    numberOfStops: yup.number().typeError("Ugyldig antall stopp").min(0, "Ugyldig antall stopp.")
})


