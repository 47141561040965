import React from 'react';
import { AppBreadcrumbs, AppButton, Spinner } from '../../../components';
import { AppBooking } from '../types';
import BookingsFilterBar from './bookingList/BookingsFilterBar';
import { BookingsList } from './BookingsList';

interface BookingsProps {
   getBookingsOnScroll: (event: any) => void,
   goToBookingDetails: (bookingId: number) => void,
   bookings: Array<AppBooking>
   bookingsLoading: boolean,
   resetFilters: ()=>void,
   doSearchByDate: (dateFrom: string | null, dateTo: string | null)=> void,
   doSearch: () => void,
   showOfferChange: (showOffers:boolean) => void,
   showOffers: boolean
}

export const BookingsView: React.FC<BookingsProps> = (props) => {
   const { goToBookingDetails, getBookingsOnScroll, resetFilters, bookings, bookingsLoading, showOfferChange,
      doSearch, doSearchByDate, showOffers } = props;

   const OfferClick = () => {
      showOfferChange(!showOffers);
   }

   return (<div className="main-content-item booking-page">
      <AppBreadcrumbs pages={[{ name: 'Bestillinger /' }]} />

      <div className="app-bookings-wrapper">

         <BookingsFilterBar doSearch={doSearch} doSearchByDate={doSearchByDate} />

         <div style={{ marginBottom: '40px' }}></div>

         <div className="app-booking-table-title">
               <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>Bestillinger</div>

               <div>
                  <input type="checkbox" id="scales" name="scales" checked={showOffers} onChange={OfferClick} />
                     <span style={{paddingLeft:'10px'}}>Inkluder forespørsler</span>
                  </div>
               <div>

                  <AppButton size='mini' designType='teal' onClick={resetFilters} >Fjern filter</AppButton>
               </div>
         </div>

         <div onScroll={getBookingsOnScroll} data-testid="appbookinglistwrapper" className="app-booking-list-wrapper">
               <BookingsList bookings={bookings} goToBookingDetails={goToBookingDetails} />
               {
                  bookingsLoading ? <Spinner /> : ''
               }
         </div>

      </div>
   </div>
   )


};

