interface BookingInfoHeaderProps {
    icon: JSX.Element,
    text: string
}


export const BookingInfoHeader: React.FC<BookingInfoHeaderProps> = (props) => {
    const { icon, text } = props;
    return (<div className="header"><span className="booking-element-header-text">{icon || ''} {text || ''}</span></div>)
};
