import { UsersApi } from '../../../api/users.service';
import { AppUser, AuthUserState, UserEditorRequestData } from '../../Login/types';
import { getEntityHelper, usersNormalizerConfig } from '../reducers/normalizerConfig';
import { normalize } from "normalizr";
import {
    usersLoading,
    usersLoaded,
    usersLoadingFail,
    savingUser,
    setUser,
    resetSaving,
    setCurrentUserId,
    deletingUser,
    removeUserFromList,
} from '../reducers/usersReducer';
import { setAuthtUser } from '../../Login/reducers/authReducers';

export const getUsers = () => {
    return async (dispatch: any) => {

        dispatch(usersLoading());

        try {

            const result = await UsersApi.getUsers();
            if (!result) {
                return null;
            }

            const data = result.data as Array<AppUser>;

            const normalizedData = normalize(data, [usersNormalizerConfig]);


            if (normalizedData) {
                let users = getEntityHelper(normalizedData).users;
                dispatch(usersLoaded(users));

            }

            return data;
        }
        catch (error: any) {
            dispatch(usersLoadingFail());
            return new Error(error);
        }
    }
};

export const saveUser = (user: UserEditorRequestData, userId: number | null, currenUserId: number) => {
    return async (dispatch: any, getState: any) => {

        dispatch(savingUser());

        try {

            user.userId = userId || 0;
            const result = await UsersApi.addOrUpdateUser(user);

            if (!result) return;

            const newUserId = result.data as number;
            if (newUserId) {
                user.userId = newUserId;
                dispatch(setCurrentUserId(newUserId));
            }

            const currentState = getState();
            const currenUserId = ((currentState.auth as AuthUserState).user || {}).userId || 0;

            // update data for current user
            if (currenUserId === userId) {
                dispatch(setAuthtUser(user));
            }

            dispatch(setUser(user));


            return result;
        }
        catch (error) {
            dispatch(resetSaving());
            return;
        }
    }
};

export const deleteUser = (userId: number) => {
    return async (dispatch: any) => {

        dispatch(deletingUser());

        try {
            const result = await UsersApi.deleteUser(userId);

            if (result) {
                dispatch(removeUserFromList(userId));
            }

            return;
        }
        catch (error) {
            dispatch(deletingUser());
            return;
        }
    }
}