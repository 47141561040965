import { ApiResponse } from '../commonTypes';
import { AppBookingsRequest, AppRefundRequest } from '../features/Bookings/types';
import { BaseService } from './base.service';

class BookingsService extends BaseService {
  private static _authService: BookingsService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): BookingsService {
    return this._authService || (this._authService = new this('booking'));
  }

  public async getBookings(args: AppBookingsRequest): Promise<ApiResponse> {

    const { pageNumber = 1, numberOfItems, searchStr = null, from = null, to = null, 
      showOffers = false, searchBookingNumber = null, searchTelephone = null} = args || {};

    let axiosResponse = null;
    let url = `getBookings?pageNumber=${pageNumber || ''}`;

    if (numberOfItems) {
      url += `&numberOfItemsToTake=${numberOfItems || ''}`;
    }

    if (searchBookingNumber){
      url += `&searchTextBookingNumber=${searchBookingNumber}&searchByOneProperty=true`;
    }
    else if (searchTelephone){
      url += `&searchTextPhoneNumber=${searchTelephone}&searchByOneProperty=true`;
    }
    else if  (searchStr) {
      url += `&searchByFreeText=${searchStr}&searchByOneProperty=false`;
    }

    if (from) {
      url += `&fromCreatedDate=${from}`;
    }
    if (to) {
      url += `&toCreatedDate=${to}`;
    }

    url += `&showOffers=${showOffers}`;

    try {

      axiosResponse = await this.$http.get(url);
      return { response: axiosResponse, data: axiosResponse.data };

    }
    catch (error: any) {
      this.handleAllErrors(error);
      throw error;
    }
  }

  public async getBookingsAdditionalData(bookingId: number): Promise<ApiResponse> {

    if (!bookingId)
      throw new Error("bookingId is missing");

    let axiosResponse = null;

    try {
      axiosResponse = await this.$http.get(`getBookingDetails?bookingId=${bookingId}`);
      return { response: axiosResponse, data: axiosResponse.data };
    }
    catch (error) {
      this.handleAllErrors(error);
      throw error;
    }
  }

  public async refundBookingPayment(args: AppRefundRequest): Promise<ApiResponse> {
    const { paymentId, amount } = args;

    if (!paymentId)
      throw "paymentId is missing";

    if (!amount)
      throw "amount is missing";

    let axiosResponse = null;

    try {
      axiosResponse = await this.$http.post('refundBookingPayment', args);
      return { response: axiosResponse, data: axiosResponse.data };
    }
    catch (error) {
      this.handleAllErrors(error);
      throw error;
    }

  }

}

export const BookingsApi = BookingsService.Instance;
