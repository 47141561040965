import React from 'react';
import { AppBreadcrumbs, AppButton } from '../../../components';
import '../../../styles/bookingDetails.css'
import { ArrowLeftIcon } from '../../../components/Icon';
import BookingDetails, { BookingDetailsProps } from './bookingDetails/BookingDetails';


interface Props {
    goToBookingsList: () => void
}

type BookingDetailsViewProps = BookingDetailsProps & Props;

export const BookingDetailsView: React.FC<BookingDetailsViewProps> = (props) => {
    const {goToBookingsList, setRefundedPaymentId, handleRefund, bookingId, refundingPaymentId} = props;

    return (<div className="main-content-item booking-details-page">

        <AppBreadcrumbs pages={[{ name: 'Ordredetaljer /' }]} />

        <div className="app-booking-details-back-to-list" style={{margin: '20px 0px'}}>
            <AppButton designType='teal' onClick={(e)=> goToBookingsList()} >
                   <> <ArrowLeftIcon/> Bestillinger </>
            </AppButton>
        </div>
        
        <div className="app-booking-details-wrapper">
           <BookingDetails 
            bookingId={bookingId} 
            setRefundedPaymentId={setRefundedPaymentId} 
            refundingPaymentId={refundingPaymentId}
            handleRefund={handleRefund}
          />
        </div>
    </div>
    )
};
