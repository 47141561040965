//import createRootReducer from './rootReducer';
//import { reducer as AuthReducer } from './auth';
//import { configureStore } from './configureStore';
import { applyMiddleware, compose, createStore } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import rootReducer from './rootReducer';


export const configureStore = (initialState: any, history:any) => {
  /*  const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;*/
  
    const middlewares = [thunk];
    const enhancer = compose(
      applyMiddleware(...middlewares)
      // other store enhancers if any
    );
  
    const store = createStore(rootReducer(history), initialState, enhancer);
    return store;
  };
  
