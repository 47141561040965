import React from 'react';
import { AppTabs } from '../../../../components';
import { MapIcon, PriceIcon } from '../../../../components/Icon';
import { isMobile } from '../../../../utils';
import { HideEditingBtns } from '../../types';
import { RegionPriceConfActionsProps } from '../types';
import DrivingAreasWithPriceFactors from './DrivingAreasWithPriceFactors';
import MainRegionPriceConfig from './MainRegionPriceConfig';


export const RegionPriceConfigTabs: React.FC<RegionPriceConfActionsProps  & HideEditingBtns> = (props) => {
    props = props || {};
    const { actions, hideEditingBtns } = props;

    let screenIsMobile = isMobile();

    let mainText = !screenIsMobile ? 'Prisfaktorer' : '';
    let regionsText = !screenIsMobile ? 'Kjøreområder' : '';

    const panes = [
        {
            menuItemText: mainText,
            testId:'driving-price-config-default-tab',
            menuItemIcon: <PriceIcon />,
            tabPaneContent: <MainRegionPriceConfig hideEditingBtns={hideEditingBtns} actions={actions} />
        }
    ]

    if (!hideEditingBtns){
        panes.push({
            menuItemText: regionsText,
            testId:'driving-price-config-tab-test',
            menuItemIcon: <MapIcon />,
            tabPaneContent: <DrivingAreasWithPriceFactors actions={actions} />
        });
    }

    return <><AppTabs panes={panes} /></>
};
