import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DAY_TYPES, getDayOfWeekConfig } from '../../../../commonTypes';
import { AppDropdown, AppTimePicker, ModalFormFooter, MyCheckbox, MyTextInput } from '../../../../components';
import { AppButtonSubmit } from '../../../../components/AppButton';
import { AppDropdownItemProps } from '../../../../components/AppDropdown';
import { TimeResponceData } from '../../../../components/AppTimePicker';
import { AppField } from '../../../../components/Form/MyField';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { dateTimeHelper } from '../../../../utils/dateTimeHelper';
import { formPrepareAndRoundNumber, getInputValue } from '../../../../utils/formHelpers';
import { nameof } from '../../../../utils/nameOfPropertyHelper';
import { selectPriceTestCaseById } from '../reducers/priceTestCasesReducer';
import { getEmptyPriceTestCaseBase, PriceTestCaseBase, PriceTestCaseContainerActionsProps, priceTestCaseValidation } from '../types';


interface OwnProps {
    currentPriceTestCaseId: number | null,
    priceTestCase: PriceTestCaseBase | null,
    addOrUpdatePriceTestCase: (item: PriceTestCaseBase, id: number | null) => void,
    closeEditor: () => void
};

type PriceTestCaseEditorProps = OwnProps & PriceTestCaseContainerActionsProps


const PriceTestCaseEditor: React.FC<PriceTestCaseEditorProps> = (props) => {

    const { priceTestCase, currentPriceTestCaseId, addOrUpdatePriceTestCase, closeEditor } = props;

    let initialData: PriceTestCaseBase = priceTestCase ? priceTestCase : getEmptyPriceTestCaseBase();

    const handleFormSubmit = async (values: any, setErrors: any) => {

        if (values && typeof addOrUpdatePriceTestCase === 'function') {
            addOrUpdatePriceTestCase(values, currentPriceTestCaseId);
            closeEditor();
        }
    }

    const handleTimeChanged = (time: TimeResponceData): string => {
        let timeStr = '';

        if (time && time.dateTime) {
            timeStr = dateTimeHelper.getTimeStrFromDateTime(time.dateTime, "HH:mm:ss") || '';
        }

        return timeStr;
    }

    const dayTypes: Array<AppDropdownItemProps> = [];

    for (let i = DAY_TYPES.Monday; i <= DAY_TYPES.Holiday; ++i) {
        const dayType = getDayOfWeekConfig(i);

        const temp = {
            key: i.toString(),
            text: dayType.text || '',
            value: i.toString()
        };

        dayTypes.push(temp);
    }

    const time = priceTestCase?.pickupLocalTime ? dateTimeHelper.getTimeFromString(priceTestCase.pickupLocalTime, "D HH:mm:ss") : null;

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialData}
            validationSchema={priceTestCaseValidation}
            onSubmit={(values, { setErrors }) => { handleFormSubmit(values, setErrors) }}
        >
            {(formik) => (
                <Form className='ui form' onSubmit={formik.handleSubmit} autoComplete='off' data-testid="price-test-case-editor-form">

                    <AppField style={{ width: '100%' }}
                        metadata={formik.getFieldMeta('pickupLocalTime')}
                        errors={formik.errors}
                        dataTestId={nameof<PriceTestCaseBase>('pickupLocalTime')}
                        errorName={nameof<PriceTestCaseBase>('pickupLocalTime')}>
                        <>
                            <label className="label">Tid</label>
                            <AppTimePicker selectedDate={time || null} timeChangedHandler={(time) => {
                                formik.setFieldValue(nameof<PriceTestCaseBase>('pickupLocalTime'), handleTimeChanged(time));
                            }} />
                        </>

                    </AppField>
                    <MyTextInput
                        data-testid={nameof<PriceTestCaseBase>('name')}
                        label={'Navn'}
                        name={nameof<PriceTestCaseBase>('name')}
                        type="text"
                        onChange={(e) => {
                            const newValue = e && e.target && e.target.value && e.target.value === ' ' ? '' : e.target.value;
                            formik.setFieldValue(nameof<PriceTestCaseBase>('name'), newValue)
                        }}
                    />
                    <MyTextInput
                        data-testid={nameof<PriceTestCaseBase>('drivingDistance')}
                        label={'Kjøreavstand (km)'}
                        name={nameof<PriceTestCaseBase>('drivingDistance')}
                        type="number"
                        onChange={(e) => {
                            formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                formik.setFieldValue(nameof<PriceTestCaseBase>('drivingDistance'), newValue)
                            });
                        }}
                    />

                    <div className='field' data-testid='dayType-input'><label>Dagtype</label>
                        <AppDropdown

                            showAsDisabledWhenEmpty={false}
                            disabled={false}
                            width={'100%'}
                            onChanged={(newValue) => {
                                formik.setFieldValue(nameof<PriceTestCaseBase>('dayType'), parseInt(newValue))
                            }}
                            items={dayTypes}
                            placeholderText={'Dagtype'}
                            currentValue={(priceTestCase?.dayType || 1).toString()} />
                    </div>

                    <MyTextInput
                        data-testid={nameof<PriceTestCaseBase>('numberOfPassengers')}
                        label={'Default antall passasjerer (brukes når biltype ikke er valgt.)'}
                        name={nameof<PriceTestCaseBase>('numberOfPassengers')}
                        type="number"
                        onChange={(e) => {
                            const result = getInputValue(e) ? Math.round(getInputValue(e)) : null;
                            formik.setFieldValue(nameof<PriceTestCaseBase>('numberOfPassengers'), result)
                        }}
                    />

                    <MyTextInput
                        data-testid={nameof<PriceTestCaseBase>('numberOfStops')}
                        label={'Waypoints'}
                        name={nameof<PriceTestCaseBase>('numberOfStops')}
                        type="number"
                        onChange={(e) => {
                            const result = getInputValue(e) ? Math.round(getInputValue(e)) : null;
                            formik.setFieldValue(nameof<PriceTestCaseBase>('numberOfStops'), result)
                        }}
                    />

                    <div className='field'><label>Forhåndsbestilling</label>
                        <MyCheckbox

                            dataTestId={'input-' + nameof<PriceTestCaseBase>('preOrder')}
                            customClassName="price-test-case-preOrder"
                            value={formik.values.preOrder || false}
                            name={nameof<PriceTestCaseBase>('preOrder')}

                            onChange={(checked) => {
                                formik.setFieldValue(nameof<PriceTestCaseBase>('preOrder'), checked)
                            }}
                        />
                    </div>

                    <ModalFormFooter>
                        <AppButtonSubmit dataTestId="form-submit" onClick={formik.handleSubmit}>Ferdig</AppButtonSubmit>
                    </ModalFormFooter>

                </Form>
            )}
        </Formik>
    )
};


const mapStateToProps = (state: RootState, ownProps: any) => {
    const ownPropsResult: PriceTestCaseEditorProps = ownProps;
    const currentPriceTestCaseId = ownProps.currentPriceTestCaseId || null;

    return {
        currentPriceTestCaseId,
        priceTestCase: currentPriceTestCaseId ? selectPriceTestCaseById(state, currentPriceTestCaseId) : null,
        addOrUpdatePriceTestCase: (item: PriceTestCaseBase, id: number | null) => ownPropsResult.actions.addOrUpdatePriceTestCase(item, id),
        closeEditor: ownPropsResult.actions.closeEditor
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PriceTestCaseEditor)
);
