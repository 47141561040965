import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDayOfWeekConfig } from '../../../../commonTypes';
import { AppButton, AppLabel, AppNoItems, AppTitleMobileOnly, AreYouSureAppButton, Spinner, TableHeaderMobileOnly } from '../../../../components';
import { DeleteIcon, EditIcon } from '../../../../components/Icon';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { dateTimeHelper } from '../../../../utils/dateTimeHelper';
import { Region } from '../../../Regions/types';
import { DrivingAreaWithPriceTestCases, PriceTestCase, PriceTestCaseContainerActionsProps, PriceTestCaseModalType } from '../types';

interface OwnProps extends PriceTestCaseContainerActionsProps {
    withSpecialLocations: boolean,
    withCarTypes: boolean
}

interface PriceTestCasesPropsFromReducer {
    priceTestCases: Array<DrivingAreaWithPriceTestCases>,
    regions: Array<Region>,
    priceTestCaseLoading: boolean
}

type PriceTestCasesProps = PriceTestCasesPropsFromReducer & OwnProps;

export const TEST_CASES_TABLE_TEXTS = {
    name: 'Navn',
    distance: 'Kjøreavstand (km)',
    daytime: 'Tidspunkt',
    dayType: 'Dagtype',
    specialLocation: 'Spesiallokasjon',
    specialLocationFromTo: 'Fra/Til',
    numberOfPassengers: "Antall passasjerer",
    numberOfStops: "Waypoints",
    carType: "Biltype",
    isPreOrder: "Forhåndsbestilling",
    draftPrice: "Draft pris",
    publishedPrice: "Publisert pris",
    editAction: "Rediger/Slett",
}

const PriceTestCaseList = (props: PriceTestCasesProps) => {
    const { priceTestCases, actions, regions, priceTestCaseLoading } = props || {};

    let regionsMap = new Map<number, any>();

    priceTestCases.forEach((item, index) => {

        const htmlDrivingArea = <PriceTestCasePerDrivingArea
            key={index}
            drivingAreaName={item.drivingAreaName}
            drivingAreaId={item.drivingAreaId}
            priceTestCases={item.priceTestCases}
            actions={actions}
            withCarTypes={item.withCarTypes}
            withSpecialLocations={item.withSpecialLocations}
        />

        if (!regionsMap.has(item.regionId)) {
            regionsMap.set(item.regionId, [htmlDrivingArea]);
        }
        else {
            regionsMap.get(item.regionId).push(htmlDrivingArea);
        }


        return
    });

    let htmlResult: any = [];

    regionsMap.forEach((value, key) => {

        const currentRegion = regions.find(x => x.regionId === key);
        const nameOfRegion = currentRegion ? currentRegion.name : '';

        htmlResult.push(<div key={key} data-testid='test-region-price-test-case'>
            <PriceTestCaseRegion key={currentRegion?.regionId} nameOfRegion={nameOfRegion} />
            {value} </div>);
    })

    return <div data-testid='test-region-price-test-cases'> {priceTestCaseLoading ? <div style={{marginBottom: '4px'}}> <Spinner /></div> : ''}
        {htmlResult && htmlResult.length > 0 ? htmlResult:  <AppNoItems text={'Ingen tester'} />}
    </div>;
}

interface PriceTestCaseRegionProps {
    nameOfRegion: string
};

const PriceTestCaseRegion = (props: PriceTestCaseRegionProps) => {

    const { nameOfRegion } = props;

    return <div data-testid='test-price-test-case-region-info' className="ui segment price-test-case-region-info" >
        Region: {nameOfRegion}</div>
}


interface PriceTestCasePerDrivingAreaProps extends PriceTestCaseContainerActionsProps {
    priceTestCases: Array<PriceTestCase>,
    withSpecialLocations: boolean,
    withCarTypes: boolean,
    drivingAreaName: string,
    drivingAreaId: number
}

const PriceTestCasePerDrivingArea = (props: PriceTestCasePerDrivingAreaProps) => {
    const { priceTestCases, actions, drivingAreaName, drivingAreaId, withCarTypes, withSpecialLocations} = props || {};

    let currentTestCaseId = 0;

    const htmlResult = priceTestCases.map((item, index) => {

        let showEditBtn = false;
        if (currentTestCaseId !== item.priceTestCaseId){
            currentTestCaseId = item.priceTestCaseId as number;
            showEditBtn = true;
        }

        return <PriceTestCaseRow
            key={index}
            index={index}
            priceTestCase={item}
            showEditBtn={showEditBtn}
            editPriceTestCase={() => actions.openEditor(item.priceTestCaseId, PriceTestCaseModalType.priceTestCaseEditor)}
            deletePriceTestCase={() => actions.deletePriceTestCase(item?.priceTestCaseId as number)}
            withCarTypes={withCarTypes}
            withSpecialLocations={withSpecialLocations}
        />
    })


    let colspan = 10;
    if (withSpecialLocations) {
        colspan += 2;
    }
    if (withCarTypes) {
        colspan += 1;
    }

    return <div data-testid={'test-price-test-case-driving-area-' + drivingAreaId} className="price-test-case-list mobile-100-table" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
        <table className=" ui collapsing table price-test-case-table" style={{ width: '100%' }} >
            <thead className="mobile-hidden">

                <tr><th data-testid='test-price-test-case-da-name' colSpan={colspan} className="title-th">Kjøreområde: {drivingAreaName || ''}</th></tr>

                <tr className="left-mobile ">
                    <th className="table-cell-content-center-non-mobile table-cell-content-left-mobile two wide" >{TEST_CASES_TABLE_TEXTS.name}</th>
                    {!withSpecialLocations ? null :
                        <>
                            <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile two wide' >{TEST_CASES_TABLE_TEXTS.specialLocation}</th>
                            <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.specialLocationFromTo}</th>
                        </>
                    }

                    {
                        !withCarTypes ? null : <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.carType}</th>
                    }

                    <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.distance}</th>
                    <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.dayType}</th>
                    <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.daytime}</th>
                    <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.numberOfPassengers}</th>
                    <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.numberOfStops}</th>
                    <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.isPreOrder}</th>
                    <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.draftPrice}</th>
                    <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.publishedPrice}</th>
                    <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{TEST_CASES_TABLE_TEXTS.editAction}</th>
                </tr>

            </thead>

            <TableHeaderMobileOnly text={"Kjøreområde: " + drivingAreaName} />

            {htmlResult && htmlResult.length > 0 ? <tbody>{htmlResult}</tbody> : <tbody >
                <tr><td className="price-test-case-no-draft-no-published" colSpan={colspan}> 
                Ingen draft eller publisert versjon </td></tr></tbody>}

        </table>
    </div>

}

interface PriceTestCaseRowProps {
    priceTestCase: PriceTestCase,
    index: number,
    editPriceTestCase: any,
    deletePriceTestCase: any,
    withSpecialLocations: boolean,
    withCarTypes: boolean,
    showEditBtn:boolean
}

const PriceTestCaseRow: React.FC<PriceTestCaseRowProps> = (props) => {
    let { priceTestCase, index, withSpecialLocations, withCarTypes, editPriceTestCase, deletePriceTestCase, showEditBtn} = props;

    if (!priceTestCase) {
        return null;
    }

    const { color, text } = getDayOfWeekConfig(priceTestCase.dayType);

    const pickupLocalTime = dateTimeHelper.removeSecondsFromTimeStr(priceTestCase.pickupLocalTime);

    return (<tr data-testid={'test-price-test-case-row-' + index} className="table-cell-content-center-non-mobile" >
        <td style={{ textAlign: 'left' }}>
            <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.name} />  {priceTestCase.name || ''}
        </td>

        {!withSpecialLocations ? null :
            <>
                <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
                    <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.specialLocation} />
                    {priceTestCase.specialLocationName ? priceTestCase.specialLocationName : ''}
                </td>

                <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
                    <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.specialLocationFromTo} />
                    {priceTestCase.specialLocationIsFrom ? 'Fra' : 'Til'}
                </td>
            </>
        }

        {
            !withCarTypes ? null : <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
                <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.carType} />
                {priceTestCase.carTypeName ? priceTestCase.carTypeName : '-'}
            </td>
        }

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.distance} />
            {priceTestCase.drivingDistance ? priceTestCase.drivingDistance : '-'}
        </td>

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.dayType} />
            <AppLabel color={color} text={text} />

        </td>

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.daytime} />
            {pickupLocalTime}
        </td>

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.numberOfPassengers} />
            {priceTestCase.numberOfPassengers || '-'}
        </td>

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.numberOfStops} />
            {priceTestCase.numberOfStops || '0'}
        </td>

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.isPreOrder} />
            {priceTestCase.preOrder ? "y" : "n"}
        </td>

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.draftPrice} />
            {priceTestCase.draftPrice || '-'}
        </td>

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={TEST_CASES_TABLE_TEXTS.publishedPrice} />
            {priceTestCase.publishedPrice || '-'}
        </td>

        <td className="price-test-case-edit-btns" >
            {showEditBtn?
                <div className="ui icon buttons">
                <AppButton dataTestId={"test-driving-area-pf-editor-" + index} size='mini' onClick={editPriceTestCase}><>
                    <EditIcon /></>
                </AppButton>


                <AreYouSureAppButton
                    
                    dataTestIdOk={'test-delete-price-test-case-confirm-sp-' + index}
                    dataTestIdConfirm={'test-delete-price-test-case-' + index}
                    mainBtndesignType="grey" size='mini'
                    text={<><DeleteIcon /></>}
                    onClick={deletePriceTestCase}
                />

            </div>: ''
            }
            
        </td>

    </tr>)
}


const mapStateToProps = (state: RootState, ownProps: any) => {
    const props = ownProps as OwnProps;
    const priceTestCasesState = state.priceTestCases;
    const priceTestCaseLoading = priceTestCasesState.loading;
    const regionsState = state.regions;

    return {
        priceTestCaseLoading,
        regions: regionsState.list,
        actions: props.actions,
        priceTestCases: priceTestCasesState.drivingAreasWithPriceTestCases,
        withSpecialLocations: props.withSpecialLocations,
        withCarTypes: props.withCarTypes

    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PriceTestCaseList)
);

