import { DrivingAreaApi } from "../../../api/drivingArea.service";
import { drivingAreaGeosLoaded, drivingAreaGeosLoading } from "../reducers/drivingAreaGeoPointsReducer";
import { drivingAreasLoaded, drivingAreasLoading } from "../reducers/drivingAreasReducers";
import { DrivingArea, DrivingAreaGeo } from "../types";

export const getDrivingAreas = () => {

    return async (dispatch: any) => {

        dispatch(drivingAreasLoading(true));

        try {
            const result = await DrivingAreaApi.getDrivingAreas();
            const items: Array<DrivingArea> = result ? result.data : [];
    
            dispatch(drivingAreasLoaded(items));        

            return items;
        }
        catch (error) {
            dispatch(drivingAreasLoading(false));
            return error;
        }
    }
};


export const getDrivingAreaGeoPointsByRegionId = (regionId: number) => {

    return async (dispatch: any) => {

        dispatch(drivingAreaGeosLoading(true));

        try {
            const result = await DrivingAreaApi.getDrivingAreaPoints(regionId);
           
            const dataFromServer: Array<DrivingAreaGeo> = result ? result.data : [];
            dispatch(drivingAreaGeosLoaded(dataFromServer));        

            return dataFromServer;
        }
        catch (error) {
            dispatch(drivingAreasLoading(false));
            return error;
        }
    }
};

