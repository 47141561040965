import { ApiResponse } from '../commonTypes';
import { BaseService } from './base.service';

class RegionService extends BaseService {
  private static _authService: RegionService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): RegionService {
    return this._authService || (this._authService = new this('region'));
  }

  public async getRegions(): Promise<ApiResponse | { data: any }> {

    let axiosResponse = null;

    try {

      axiosResponse = await this.$http.get('getRegions');
      return { response: axiosResponse, data: axiosResponse.data };
    }
    catch (error:any) {
      this.handleAllErrors(error);
      throw error;
    }
  }

}

export const RegionApi = RegionService.Instance;
