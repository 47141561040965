

import React, {SyntheticEvent, useState} from 'react';
import { useEffect } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';


export interface AppDropdownItemProps {
    key: string,
    text: string,
    value: string
}

interface AppDropDownProps {
    disabled?:boolean | null,
    items: Array<AppDropdownItemProps>,
    width?: string | null,
    placeholderText: string,
    currentValue?: string | null,
    showAsDisabledWhenEmpty?: boolean,
    onChanged: (value: any)=>void
}

/** based on Semantic UI 
 * https://react.semantic-ui.com/modules/dropdown/#types-selection */

export const AppDropdown = (props: AppDropDownProps) => {
    let { placeholderText = 'Velg', items, currentValue, width, disabled=false, showAsDisabledWhenEmpty=false, onChanged } = props;


    const [ value, setValue ] = useState<any>();

    useEffect(()=>{

        setValue(currentValue);

    },[currentValue])

    if (showAsDisabledWhenEmpty === false && (!items || items.length <= 0)){
        return null;
    }

    const handleChange= (e: SyntheticEvent<HTMLElement, Event>, data:DropdownProps) => {

        if (e){
            e.preventDefault();
        }

        setValue(data.value);

        if (typeof onChanged === 'function'){
            onChanged(data.value);
        }

    };

    return <Dropdown
        
        disabled={disabled === true? true: false}
        placeholder={placeholderText}
        onChange={handleChange}
        style={{minWidth: '150px', width: width || 'auto'}}
        selection
        options={items}
        value={value}
    />
}


