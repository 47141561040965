import { Form, Formik } from 'formik';
import React from 'react';
import { ModalFormFooter, MyTextInput } from '../../../components';
import { AppButtonSubmit } from '../../../components/AppButton';
import { nameof } from '../../../utils/nameOfPropertyHelper';
import { PriceConfigCommonData, priceConfigCommonDataValidation } from '../types';

// from redux
interface Props {
    comment: string | null,
    update_comment: (comment: string ) => any
};

type CommentEditorProps = Props;

export const CommentEditor: React.FC<CommentEditorProps> = (props) => {

    const { comment = '', update_comment } = props;

    const handleFormSubmit = async (values: any, setErrors: any) => {

        if (typeof update_comment === 'function') {
            const result = values[nameof<PriceConfigCommonData>('comment')] || null;
            update_comment(result);
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ comment }}
            validationSchema={priceConfigCommonDataValidation}
            onSubmit={(values, { setErrors }) => { handleFormSubmit(values, setErrors) }}
        >
            {(formik) => (
                <Form className='ui form' onSubmit={formik.handleSubmit} autoComplete='off' data-testid='comment-form'>

                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>

                        <MyTextInput
                            rows={4}
                            label={'Kommentar'}
                            data-testid={'test-price-conf-comment'}
                            name={nameof<PriceConfigCommonData>('comment')}
                            extraProps={{fullWidth: true, isTextarea:true}}
                        />

                    </div>

                    <ModalFormFooter>
                        <AppButtonSubmit dataTestId="form-submit" onClick={formik.handleSubmit}>Ferdig</AppButtonSubmit>
                    </ModalFormFooter>

                </Form>
            )}
        </Formik>
    )
};


