import React from 'react';
import { withRouter } from 'react-router';
import { userLogout } from '../../features/Login/actions/authActions';
import { connect } from 'react-redux';
import { OffIcon, UserIcon } from '../Icon';
import { AppUser } from '../../features/Login/types';
import { history } from '../../config/historyConfig';
import { RoutesConfig } from '../../config/routes';

interface Props {
  handleUserLogout?: () => void,
  user: AppUser
}

const UserMenu: React.FC<Props> = (props) => {
  const { handleUserLogout = () => { } } = props;

  return (
    <div className="user-menu right menu">

      <div className="ui item" style={{ color: 'black', paddingLeft: '0px' }}>

        <button
          style={{ backgroundColor: 'lightGray', color: 'white' }}
          onClick={() => { history.push(RoutesConfig.UserProfile.path) }}
          className="ui icon button circular user-menu-element"
        >
          <UserIcon />
        </button>

        <button style={{ marginLeft: '5px', backgroundColor: 'lightGray', color: 'white' }}
          onClick={() => { handleUserLogout() }} className="ui icon button circular user-menu-element">
            
          <OffIcon />
        </button>

      </div>
    </div>
  )
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleUserLogout: () => { dispatch(userLogout()) },
  };
};

const mapStateToProps = (state: any) => {

  return {
    user: state.auth.user
  };
};


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserMenu)
);
