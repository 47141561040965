import { ApiResponse } from '../commonTypes';
import { AppActivateDeactivateRequest, AppDeleteDiscountRequest, AppDiscountEditor, AppDiscountsRequest } from '../features/Discount/types';
import { BaseService } from './base.service';

class DiscountService extends BaseService {
    private static _authService: DiscountService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): DiscountService {
        return this._authService || (this._authService = new this('discount'));
    }

    public async getDiscounts(args: AppDiscountsRequest): Promise<ApiResponse> {

        const { pageNumber = 1, numberOfItems, searchStr = null } = args || {};

        let axiosResponse = null;
        let url = `getDiscounts?pageNumber=${pageNumber || ''}`;

        if (numberOfItems) {
            url += `&numberOfItemsToTake=${numberOfItems || ''}`;
        }

        if (searchStr) {
            url += `&searchText=${searchStr}`;
          }

        try {

            axiosResponse = await this.$http.get(url);
            //return { response: {}, data: {discounts: discountsMock, isLastPage: true}} as ApiResponse; 
            return { response: axiosResponse, data: axiosResponse.data };

        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }
    
    public async getDiscountUsage(args: {discountId: number}): Promise<ApiResponse> {

        const { discountId = null } = args || {};

        try {

            const axiosResponse = await this.$http.get('getDiscountUsage?discountId=' + discountId);
            return { response: axiosResponse, data: axiosResponse.data };

        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async getDiscountCustomers(args: {discountId: number}): Promise<ApiResponse> {

        const { discountId = null } = args || {};

        try {
            const axiosResponse = await this.$http.get('getDiscountCustomers?discountId=' + discountId);
            return { response: axiosResponse, data: axiosResponse.data };

        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async addDiscount(dataToServer: AppDiscountEditor): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('addDiscount', dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async updateDiscount(dataToServer: AppDiscountEditor): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('updateDiscount', dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async activateOrDeactivateDiscount(dataToServer:AppActivateDeactivateRequest): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('activateOrDeactivateDiscount', dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async deleteDiscount(dataToServer: AppDeleteDiscountRequest): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('deleteDiscount', dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

}

export const DiscountsApi = DiscountService.Instance;
