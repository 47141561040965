import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppDropdown, AppLabel, MyCheckbox, MyTextInput } from '../../../../components';
import { AppButton } from '../../../../components/AppButton';
import { AppDropdownItemProps } from '../../../../components/AppDropdown';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { getInputValue } from '../../../../utils/formHelpers';
import { nameof } from '../../../../utils/nameOfPropertyHelper';
import { AppDiscountAmountType, AppDiscountCustomers, AppDiscountEditor, discountValidation, getDiscountAmountTypeName, getEmptyDiscountObject } from '../../types';
import { Label, List } from 'semantic-ui-react'
import { CopyIcon, DeleteIcon, SaveIcon } from '../../../../components/Icon';
import { getDiscountStatus, selectDiscountById } from '../../reducers/discountsReducers';
import { AppActiveStatus, AppDiscountStatus, AppPublishStatus, getAppActiveStatusData, getAppPublishedStatusData, getDiscountStatusData } from '../../../../config/constants';
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import Select, { ClassNamesConfig, components } from 'react-select';
import { AppField } from '../../../../components/Form/MyField';
import { AppDateTimePicker } from '../../../../components/AppTimePicker';
import { dateTimeHelper } from '../../../../utils/dateTimeHelper';

interface OwnProps {
    currentId: number | null,
    itemToEdit: AppDiscountEditor | null,
    customersLoading: boolean,
    customersFromDb: Array<AppDiscountCustomers>,
    saving: boolean,
    addOrUpdateDiscount: (item: AppDiscountEditor, currentId: number | null) => void,
};

type DiscountEditorProps = OwnProps;
let timer: any = null;
let deleteTimer: any = null;


const DiscountEditor: React.FC<DiscountEditorProps> = (props) => {

    let { currentId } = props;
    const { addOrUpdateDiscount, saving, itemToEdit, customersFromDb, customersLoading,
    } = props;


    const [formValues, setFormValues] = useState<any>(null);
    const [customers, setCustomers] = useState<Array<string> | null>(null);
    const [someCustomersDeleted, setSomeCustomersDeleted] = useState<boolean>(false); // message which is shown that it was customers which deleted profiles
    const [chosenTels, setChosenTels] = useState<Array<{ value: any, label: any }> | null>(null);
    const [currentTel, setCurrentTel] = useState<string | null>("");
    const [thisIsDuplicate, setThisIsDuplicate] = useState<boolean>(false);
    const [telAddedNum, setTelAddedNum] = useState<string | null>(null); // used to show added message 
    const [telDeletedNum, setTelDeletedNum] = useState<string | null>(null); // used to show deleted message 
    const [telValidationMessage, setTelValidationMessage] = useState<string | null>(null); // used to show incorrect tel. message 

    useEffect(() => {
        let initialData = currentId && itemToEdit ? itemToEdit : getEmptyDiscountObject();
        setFormValues(initialData);

        return () => {
            clearTimeout(deleteTimer);
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemToEdit])

    useEffect(() => {

        let customersFromDbTemp: Array<string> = [];

        if (customersFromDb != null && customersFromDb.length > 0) {
            customersFromDb.forEach(x => {

                if (x.phoneNumber) {
                    customersFromDbTemp.push(x.phoneNumber);
                }
                else if (x.customerId && (!x.phoneNumber)) {
                    setSomeCustomersDeleted(true);
                }
            });
        }

        setCustomers(customersFromDbTemp);


    }, [customersFromDb])


    const renderCustomMultiValue = (props: any, args: any) => {

        const value = props && props.data ? props.data.value : '';

        return (
            <div className="telLabel">
                <components.MultiValueLabel {...props} />
                <div style={{  cursor: 'pointer', fontSize: '1.1rem', padding: '4px', paddingRight:'0px' }} 
                onClick={(e: any) => { e.preventDefault(); e.stopPropagation(); deleteTelephoneFromCustomers(value); }}>
                    <DeleteIcon />
                </div>
            </div>
        );
    };

    const addTelephoneToCustomers = async (setErrors: any) => {
        clearTimeout(timer);
        const tel = currentTel;

        setTelValidationMessage(null);

        if (!tel) {
            setTelValidationMessage('Oppgi telefonnummer');
            return;
        }


        if (!isPossiblePhoneNumber(tel)){
            setTelValidationMessage('Inkorrekt telefonnummer');
            return;
        }

        // update array with tel. numbers .
        const tempCustomers: any = customers ? [...customers] : [];

        const item = tempCustomers.find((x: string) => x === tel);
        if (item) {
           setTelValidationMessage(tel + ' er allerede lagt til.');
           return;
        }

        tempCustomers.push(tel);
        setCustomers(tempCustomers);

        // reset input
        setCurrentTel('');

        // show added success message
        setTelAddedNum(tel);

        timer = setTimeout(() => {
            setTelAddedNum(null);
        }, 2000)

    }

    const deleteTelephoneFromCustomers = (tel: any) => {

        // delete from array which will be sent with post request to server
        const tempCustomers = customers ? customers.filter(item => item !== tel) : null;
        setCustomers(tempCustomers);

        // delete from selected from dropdown
        const tempChosenTels = chosenTels ? chosenTels.filter(item => item.value !== tel) : null;
        setChosenTels(tempChosenTels);

        // show message about that tel was deleted
        setTelDeletedNum(tel);
        deleteTimer = setTimeout(() => {
            setTelDeletedNum(null);
        }, 2000)

    }

    const deselectTelephones = () => {
        setChosenTels(null);
    }


    const deleteSelectedTelephones = () => {

        let tempCustomers = customers? [...customers]: null;

        if (!tempCustomers || !chosenTels || chosenTels.length <= 0){
            return;
        }

        chosenTels.forEach(x=> {
            const filtredResult = tempCustomers?.filter(t=>t != x.value);
            tempCustomers = filtredResult || [];

        });

        setCustomers(tempCustomers);
        setChosenTels(null);

          // show message about that tel was deleted
          setTelDeletedNum('Valgte telefonnumre ');

          deleteTimer = setTimeout(() => {
              setTelDeletedNum(null);
          }, 2000)

    }

    const handleFormSubmit = (values: AppDiscountEditor, setErrors: any) => {

        if (values && typeof addOrUpdateDiscount === 'function') {

            if (values.forAllCustomers === false && (customers == null || (customers || []).length === 0)) {
                setTelValidationMessage('Mangler kunder');
                return;
            }

            values.customers = customers;
            addOrUpdateDiscount(values, values.discountId as number | null);
            setCustomers(customers);
        }
    }

    const onChangeTelNumbersSelected = (selected: any, obj: any) => {

        if (!obj) {
            return;
        }

        const action = obj.action;

        if (action === 'select-option') {

            const tel = (obj.option || {}).value || null;
            const tempTels: any = chosenTels ? [...chosenTels] : [];
            const item = tempTels.find((x: string) => x === tel);

            if (!item) {
                const tempItem = { value: tel, label: tel };
                tempTels.push(tempItem);
                setChosenTels(tempTels);
            }

        }
        // reset selected number
        else if (action === 'remove-value') {
            const tel = (obj.removedValue || {}).value || null;
            const tempChosenTels = chosenTels ? chosenTels.filter(item => item.value !== tel) : null;
            setChosenTels(tempChosenTels);
        }

    }

    const duplicateItem = (values: AppDiscountEditor, setErrors: any) => {
        currentId = null;
        values.discountId = null;
        values.publishedDate = null;
        values.isActive = false;

        setSomeCustomersDeleted(false);

        setFormValues(values);

        setThisIsDuplicate(true);

    }

    const amountTypes: Array<AppDropdownItemProps> = [];

    for (let i = AppDiscountAmountType.Percent; i <= AppDiscountAmountType.Amount; ++i) {
        const amountType = getDiscountAmountTypeName(i);

        const temp = {
            key: i.toString(),
            text: amountType.text || '',
            value: i.toString()
        };

        amountTypes.push(temp);
    }

    /** customers */

    const customerTels: any = [];

    if (customers && customers.length > 0) {
        customers.forEach((el) => {
            customerTels.push({ value: el, label: el, color: '#FFC400' });
        });

    }

    const formValuesTemp: AppDiscountEditor = formValues || getEmptyDiscountObject();
    const isPublished = formValuesTemp && formValuesTemp.publishedDate ? true : false;

    const classesForTelephoneDropdown: ClassNamesConfig = {
        menuList: () => "tels-list",
        option:()=> "tels-list-option",
        input:()=>"tels-list-search-input"
    }

    const dateTypeStart = formValuesTemp.start? dateTimeHelper.getLocalFromUtcStr(formValuesTemp.start): null;
    const dateTypeEnd = formValuesTemp.end? dateTimeHelper.getLocalFromUtcStr(formValuesTemp.end): null;

    let discountStatus = getDiscountStatus(formValuesTemp);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={Object.assign({}, formValuesTemp, { 'TelephoneNumber': '' })}
            validationSchema={discountValidation}
            onSubmit={(values, { setErrors }) => { handleFormSubmit(values, setErrors) }}
        >
            {(formik) => (
                <Form className='ui form discount-editor-form' 
                onSubmit={formik.handleSubmit} 
                autoComplete='off' 
                data-testid="discount-editor-form">

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                        <Label.Group className='discount-statuses'>
                            <List.Item>
                                <AppLabel dataTestId="activation-label" color={discountStatus.color} text={discountStatus.text} >
                                </AppLabel>
                            </List.Item>
                            {
                                thisIsDuplicate ? (<List.Item>
                                    <AppLabel dataTestId={'copy-label'} color={'red'} text={'Kopi'} >
                                    </AppLabel>
                                </List.Item>) : null
                            }

                        </Label.Group>

                        <div> {currentId && !thisIsDuplicate ?
                            <AppButton size='medium'
                                designType="primary"
                                isIcon={true}
                                loading={false}
                                disabled={false}
                                dataTestId="test-copy-btn"
                                onClick={() => duplicateItem(formik.values, formik.setErrors)}
                            >
                                <>Kopi <CopyIcon /></>
                            </AppButton> : null
                        }

                            <AppButton size='medium'
                                designType="teal"
                                isIcon={true}
                                loading={saving}
                                disabled={isPublished}
                                dataTestId="test-add-special-location"
                                onClick={() => formik.handleSubmit()}
                            >
                                <> {thisIsDuplicate ? 'Lagre kopi ' : 'Lagre '}<SaveIcon /></>
                            </AppButton>

                        </div>

                    </div>

                    <MyTextInput
                        data-testid={nameof<AppDiscountEditor>('name')}
                        label={'Kampanjenavn:'}
                        disabled={isPublished}
                        autoComplete={'nope'}
                        name={nameof<AppDiscountEditor>('name')}
                        type="text"
                        onChange={(e) => {
                            const newValue = e && e.target && e.target.value && e.target.value === ' ' ? '' : e.target.value;
                            formik.setFieldValue(nameof<AppDiscountEditor>('name'), newValue)
                        }}
                    />

                    <MyTextInput

                        data-testid={nameof<AppDiscountEditor>('code')}
                        label={'Kode:'}
                        disabled={isPublished}
                        name={nameof<AppDiscountEditor>('code')}
                        type="text"
                        onChange={(e) => {
                            const newValue = e && e.target && e.target.value && e.target.value === ' ' ? '' : e.target.value;
                            formik.setFieldValue(nameof<AppDiscountEditor>('code'), newValue)
                        }}
                    />


                    <MyTextInput
                        data-testid={nameof<AppDiscountEditor>('amount')}
                        label={'Beløp:'}
                        disabled={isPublished}
                        name={nameof<AppDiscountEditor>('amount')}
                        type="number"
                        onChange={(e) => {
                            const result = getInputValue(e) ? Math.round(getInputValue(e)) : null;
                            formik.setFieldValue(nameof<AppDiscountEditor>('amount'), result)
                        }}
                    />

                    <MyTextInput
                        disabled={isPublished}
                        data-testid={nameof<AppDiscountEditor>('minPriceForApplication')}
                        label={'Gyldig kun ved ordinærpris over kroner:'}
                        name={nameof<AppDiscountEditor>('minPriceForApplication')}
                        type="number"
                        onChange={(e) => {
                            const result = getInputValue(e) ? Math.round(getInputValue(e)) : null;
                            formik.setFieldValue(nameof<AppDiscountEditor>('minPriceForApplication'), result)
                        }}
                    />
                    <div className='form-part'>
                        <div className='field discount-amount-type'><label>Prosent/beløp:</label>
                            <AppDropdown
                                showAsDisabledWhenEmpty={false}
                                disabled={isPublished}
                                width={'100%'}
                                onChanged={(newValue) => {
                                    formik.setFieldValue(nameof<AppDiscountEditor>('amountTypeId'), parseInt(newValue))
                                }}
                                items={amountTypes}
                                placeholderText={'Prosent/beløp'}
                                currentValue={formik.values.amountTypeId.toString()} />
                        </div>

                        {
                            formik.values.amountTypeId === AppDiscountAmountType.Percent ?
                                <MyTextInput
                                    data-testid={nameof<AppDiscountEditor>('maxValueForPercentType')}
                                    label={'Maks rabatt i kroner:'}
                                    name={nameof<AppDiscountEditor>('maxValueForPercentType')}
                                    type="number"
                                    disabled={isPublished}
                                    onChange={(e) => {
                                        const result = getInputValue(e) ? Math.round(getInputValue(e)) : null;
                                        formik.setFieldValue(nameof<AppDiscountEditor>('maxValueForPercentType'), result)
                                    }} />
                                : null
                        }
                    </div>



                    <div className='form-part' style={{ display: 'none' }}>

                        {(isPublished && !formValuesTemp.numberOfTimesPerCustomer) ? '' :
                            (<MyTextInput
                                data-testid={nameof<AppDiscountEditor>('numberOfTimesPerCustomer')}
                                label={'Antall bruk per bruker:'}
                                name={nameof<AppDiscountEditor>('numberOfTimesPerCustomer')}
                                type="number"
                                disabled={(formik.values['anyNumberOfTimesPerCustomer'] || isPublished) ? true : false}
                                value={formik.values.numberOfTimesPerCustomer || ''}
                                onChange={(e) => {
                                    const result = getInputValue(e);

                                    formik.setFieldValue(nameof<AppDiscountEditor>('numberOfTimesPerCustomer'), result)

                                    if (result > 0) {
                                        formik.setFieldValue('anyNumberOfTimesPerCustomer', false)
                                    }
                                }}
                            />)
                        }

                        <div className='field' style={{ display: 'flex' }}>
                            <MyCheckbox

                                dataTestId={'input-anyNumberOfTimesPerCustomer'}
                                customClassName=""
                                value={formik.values.numberOfTimesPerCustomer ? false : true}
                                name={'anyNumberOfTimesPerCustomer'}

                                onChange={(checked) => {
                                    if (checked) {
                                        formik.setFieldValue(nameof<AppDiscountEditor>('numberOfTimesPerCustomer'), 0)
                                    }
                                    else {
                                        formik.setFieldValue(nameof<AppDiscountEditor>('numberOfTimesPerCustomer'), 1)
                                    }
                                }}
                            />
                            <label>Ingen begrensning</label>
                        </div>

                    </div>


                    <div className='form-part'>
                        <div className='field discount-start-end'>
                                    <AppField style={{ width: '100%' }}
                                        metadata={formik.getFieldMeta('start')}
                                        errors={formik.errors}
                                        dataTestId={nameof<AppDiscountEditor>('start')}
                                        errorName={nameof<AppDiscountEditor>('start')}>
                                        <>
                                            <label className="label">Starttidspunkt:</label>
                                            <AppDateTimePicker selectedDateInit={dateTypeStart} dateChangedHandler={(time) => {
 
                                                 let newDate = null;

                                                 if (time.value){
                                                    newDate = dateTimeHelper.getUTCStringFromDateTime(time.value);
                                                 }
                                                formik.setFieldValue(nameof<AppDiscountEditor>('start'), newDate);
                                            }} />
                                        </>

                                    </AppField>
                                    <AppField style={{ width: '100%' }}
                                        metadata={formik.getFieldMeta('end')}
                                        errors={formik.errors}
                                        dataTestId={nameof<AppDiscountEditor>('end')}
                                        errorName={nameof<AppDiscountEditor>('end')}>
                                        <>
                                            <label className="label">Slutttidspunkt:</label>
                                            <AppDateTimePicker selectedDateInit={dateTypeEnd} dateChangedHandler={(time) => {
                                                
                                                let newDate = null;

                                                 if (time.value){
                                                    newDate = dateTimeHelper.getUTCStringFromDateTime(time.value);
                                                 }
                                                
                                                formik.setFieldValue(nameof<AppDiscountEditor>('end'), newDate);
                                            }} />
                                        </>

                                    </AppField>
                        </div>   
                    </div>


                    <div className='form-part' >
                        <div className='field' style={{ display: 'flex' }}>
                            <MyCheckbox
                                dataTestId={'input-forAllCustomers'}
                                customClassName="discount-for-all"
                                value={!formik.values.forAllCustomers}
                                name={nameof<AppDiscountEditor>('forAllCustomers')}

                                onChange={(checked) => {
                                    if (!checked) {
                                        formik.setFieldValue(nameof<AppDiscountEditor>('forAllCustomers'), true);
                                        setCurrentTel('');
                                        setTelValidationMessage(null);
                                    }
                                    formik.setFieldValue(nameof<AppDiscountEditor>('forAllCustomers'), !checked)
                                }}
                            />

                            <label>Koble mot spesifikke brukere?</label>
                        </div>

                        {
                            someCustomersDeleted ? <div className='field' data-testid="user-removed" style={{ fontSize: '12px', marginTop: '2px', color: 'orangered' }}>Det var brukerprofil som er slettet.</div> : ''
                        }

                        {
                            (isPublished) ? '' :
                                <><PhoneInput
                                    data-testid={'input-customer-tel'}
                                    autoComplete={'nope'}
                                    label={''}
                                    name={'TelephoneNumber'}
                                    disabled={(formik.values.forAllCustomers || isPublished || customersLoading) ? true : false}
                                    placeholder={(formik.values.forAllCustomers || isPublished || customersLoading) ? 'Deaktivert' : 'Oppgi nummer (uten landskode) og klikk på ENTER.'}
                                    className="telNumber"
                                    defaultCountry="NO"
                                    value={currentTel as string || ''}
                                    onKeyDown={(e: any) => {

                                        if (e.key === 'Enter') {

                                            e.preventDefault();

                                            setTelValidationMessage(null);

                                            if (formik.values.forAllCustomers) {
                                                return;
                                            }

                                            const newValue = e && e.target && e.target.value && e.target.value.trim() === ' ' ? null : e.target.value.trim();

                                            if (newValue) {

                                                addTelephoneToCustomers(formik.setErrors);
                                                formik.setFieldValue(nameof<AppDiscountEditor>('forAllCustomers'), false);
                                                formik.setFieldValue('TelephoneNumber', '')
                                            }


                                        }
                                    }}
                                    onChange={(newValue: any) => {
                                        setCurrentTel(newValue);
                                    }
                                    } />
                                    
                                    {
                                        telValidationMessage? (<div className="validation-error" 
                                        style={{ color: 'red', fontSize: '1rem', marginTop:'2px' }}>
                                            {telValidationMessage}
                                        </div>) : ''
                                    }
                                    </>            
                        }

                        {
                            (customerTels && customerTels.length > 0) || telDeletedNum ?
                                (<div data-testid='input-customer-tel-list' className="field" style={{ marginBottom: '12px' }}>

                                    <div style={{ height: '14px', marginTop: '5px', marginBottom: '10px' }} >
                                        {telDeletedNum ?
                                            (<div style={{ color: 'green', fontSize: '1rem' }}>
                                                {telDeletedNum + '  har blitt slettet.'}
                                            </div>) : ''
                                        }
                                        {telAddedNum ?
                                            (<div style={{ color: 'green', fontSize: '1rem' }}>
                                                {telAddedNum + ' har blitt lagt til.'}
                                            </div>) : ''
                                        }
                                    </div>

                                    <label data-testid="list-with-tel-label" style={{ marginBottom: '14px' }}>
                                        Liste av telefonnumre. For å fjerne et nummer: velg det og klikk på <DeleteIcon />.
                                    </label>
                                    {
                                        formik.values.forAllCustomers && customers && customers.length > 0? <label style={{ marginBottom: '14px', color:'orange' }}>
                                        Telefonnumre vil bli ignorert og kan slettes, da rabatten gjelder for alle.
                                        </label>:''
                                    }
                                    
                                    <div className="do-with-selected-item-btns">
                                        {
                                            chosenTels && chosenTels.length > 0 ? (<><button className="do-with-seleted-item-delete-selected"
                                                onClick={(event) => { event.preventDefault(); deleteSelectedTelephones();}} >
                                                <> Slett numre </>
                                            </button>
                                            <button className="do-with-seleted-item-deselect-selected"
                                                onClick={(event) => { event.preventDefault(); deselectTelephones()}} >
                                            <> Avvelg</>
                                            </button></>
                                            ) 
                                            : null
                                        }

                                    </div>

                                    <Select
                                        openMenuOnClick={false}
                                        classNames={classesForTelephoneDropdown}
                                        isClearable={false}
                                        className="search-discount-tels"
                                        data-testid={'search-discount-tels'}
                                        closeMenuOnSelect={true}
                                        onChange={onChangeTelNumbersSelected}
                                        components={{ MultiValueLabel: renderCustomMultiValue }}
                                        defaultValue={null}
                                        isMulti
                                        defaultMenuIsOpen={false}
                                        options={customerTels}
                                        value={chosenTels}
                                        noOptionsMessage={() => null}
                                    />

                                </div>) : ''
                        }

                    </div>

                </Form>
            )}
        </Formik>
    )
};


const mapStateToProps = (state: RootState, ownProps: any) => {
    const ownPropsResult: DiscountEditorProps = ownProps;
    const currentId = ownProps.currentId || null;
    const customersLoading = state.discountCustomers.loading;
    const customersFromDb = state.discountCustomers.discountCustomers || [];

    return {
        addOrUpdateDiscount: (item: AppDiscountEditor, currentId: number | null) => ownPropsResult.addOrUpdateDiscount(item, currentId),
        currentId,
        itemToEdit: selectDiscountById(state, currentId),
        customersFromDb: customersFromDb,
        customersLoading: customersLoading,
        saving: state.discounts.saving
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DiscountEditor)
);
