import React, { useRef } from 'react';
import { Marker } from "react-leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from 'leaflet';


interface AppDotMarkerProps {
    position: { lat: number, lng: number },
    draggable: boolean,
    children: JSX.Element,
    onClick: (e:any)=>void,
    onDragend: ()=>void
}

export const AppDotMarker = (props: AppDotMarkerProps) => {

    const { position, children, onClick, onDragend} = props;

    const markerRef = useRef<any>(null)

    if (!position) {
        return null;
    }

    const iconMarkup = renderToStaticMarkup(
        <i style={{ color: 'rgb(3, 61, 141)' }} className="dot circle icon"></i>
    );

    const customMarkerIcon = divIcon({
        html: iconMarkup
    });


    return <Marker
        ref={markerRef}
        eventHandlers={{
            click: (e) => {
                console.log('marker clicked', e)
                if (typeof onClick === 'function'){
                    onClick(e);
                }

            },
            dragend() {
                const marker = markerRef.current;

                console.log('dragend');
              
                if (marker != null) {
                    console.log( marker.getLatLng())
                 // const ltLng = marker.getLatLng()
                  //todo: ondragend();
                }
              }
        }}
        icon={customMarkerIcon} draggable={true} position={[position.lat, position.lng]} >
        {children}
    </Marker>
}
