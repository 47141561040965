import React from "react";
// @ts-ignore
import ReactExport from "react-data-export";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RootState } from "../../../../config/reducerConfig/rootReducer";
import { selectDiscountById } from "../../reducers/discountsReducers";
import { AppDiscountStatistic } from "../../types";


interface OwnProps {
    button: JSX.Element,
    discountName: string
};

interface PropsFromReducer {
    dataSet: Array<AppDiscountStatistic>
};


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const DiscountUsageExcel: React.FC<PropsFromReducer & OwnProps> = (props) => {
    const { button, dataSet = [], discountName} = props;

    const checkIfNull = (str:string) => {
      if (str == null || str == undefined)
        return "";
      return str;
    }

    var data:any = dataSet.map((el) => {
        
        return  [
            {value: checkIfNull(el.phoneNumber || 'Brukerprofil slettet'), style: { font: { sz: "11", bold: false } , alignment: {horizontal: "center"} } },
            {value: el.used, style: { font: { sz: "11", bold: false }, numFmt: "0" } }
        ]       
    })

    if (data.length === 0) {
      var dummy = [
        {value:"Ingen bruk registrert.", style: { font: { sz: "11", bold: false }}},
        {value:""},{value:""}
      ]
      
      data.push(dummy)
    }

    const styledMultiDataSet = [
        {
          columns: [
            {
                title: "Kunde",
                width: {wpx:160},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:"DEE9FB"}}}, 
                
              },
              {
                title: "Brukt",
                width: {wpx:160},
                style: { font: { sz: "11", bold: true } , fill: {fgColor:{rgb:"DEE9FB"}}},                
              },                               
          ],
          data: data 
        },
      ];
      

    return ( <div>
            <ExcelFile element={button}>
                <ExcelSheet dataSet={styledMultiDataSet} name={discountName || 'Kampanje' }/>
            </ExcelFile>
        </div>
    );

}

const mapStateToProps = ((state: RootState, ownProps:any) => {

    const reportState = state.discountStatistic;
    const props: OwnProps = ownProps;
    const discountId = reportState.discountId || 0;
    const discount = selectDiscountById(state, discountId);

    return {
        dataSet: reportState.discountStatistics,
        button: props.button,
        discountName: (discount || {}).name || ""
    }
});


const mapDispatchToProps = (dispatch: any) => {

    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DiscountUsageExcel)
);
