import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppButton, AppNoItems, AppTitleMobileOnly, AreYouSureAppButton, Spinner, TableHeaderMobileOnly } from '../../../../components';
import { AppEmptyTableBody } from '../../../../components/AppEmptyTableBody';
import { DeleteIcon, EditIcon, PlusIcon } from '../../../../components/Icon';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { showIntWith0Decimal } from '../../../../utils/commonHelpers';
import { DrivingAreaSpecialLocation, HideEditingBtns } from '../../types';
import { DrivingAreaPriceConfActionsProps, DrivingAreaPriceConfigModalType } from '../types';

interface Props {
    specialLocations: Array<DrivingAreaSpecialLocation>,
    loading: boolean
}

type OwnProps = DrivingAreaPriceConfActionsProps & HideEditingBtns;

export const SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS = {
    name: 'Navn',
    lat: 'Latitude',
    lng: 'Longitude',
    radius: 'Radius',
    faktorFrom: 'Fra rabatt',
    faktorTo: 'Til rabatt',
    editDelete: "Rediger/Slett"
}


const SpecialLocationsPriceFactors = (props: Props & OwnProps) => {
    const { specialLocations, loading, actions, hideEditingBtns} = props || {};


    if (loading) {
        return <AppNoItems text={<Spinner />} />
    }

    const htmlResult = specialLocations.map((item, index) => {
        return <SpecialLocationPriceConfigRow
            key={index}
            index={index}
            specialLocation={item}
            editLocation={() => actions.edit(index, DrivingAreaPriceConfigModalType.specialLocations)}
            deleteLocation={() => actions.deleteSpecialLocation(index)}
            hideEditingBtns={hideEditingBtns}
        />
    })

    return <div className="driving-area-special-locations tab-page mobile-100-table" style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
            <table className=" ui collapsing table " >
                <thead className="mobile-hidden">

                    <tr className="left-mobile">
                        <th className="three wide" >{SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.name}</th>
                        <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.lat}</th>
                        <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.lng}</th>
                        <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.radius}</th>
                        <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.faktorFrom}</th>
                        <th className='table-cell-content-center-non-mobile table-cell-content-left-mobile' >{SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.faktorTo}</th>

                        {hideEditingBtns? null: <th style={{ textAlign: 'right' }} className="four wide" >{SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.editDelete}</th>}
                        
                    </tr>

                </thead>

                <TableHeaderMobileOnly text={'Spesiallokasjoner'} />

                {htmlResult && htmlResult.length > 0 ? <tbody>{htmlResult}</tbody> :
                    <AppEmptyTableBody />}

                {
                    hideEditingBtns?  null:
                    <tfoot style={{}} >
                        <tr>
                            <th colSpan={7} >
                                <div className="display-flex-center-both" style={{ marginTop: '0px' }}>
                                    <AppButton size='medium'
                                        designType="teal"
                                        isIcon={true}
                                        dataTestId="test-add-special-location"
                                        onClick={() => actions.edit(null, DrivingAreaPriceConfigModalType.specialLocations)} >
    
                                        <PlusIcon />
                                    </AppButton>
                                </div>
                            </th>
                        </tr>
                    </tfoot>
                }

            </table>
        </div>



    </div>


}

interface RegionPriceConfigRowProps {
    specialLocation: DrivingAreaSpecialLocation,
    index: number,
    editLocation: any,
    deleteLocation: any
}

const SpecialLocationPriceConfigRow: React.FC<RegionPriceConfigRowProps & HideEditingBtns> = (props) => {
    let { specialLocation, index, editLocation, deleteLocation, hideEditingBtns} = props;

    if (!specialLocation) {
        return null;
    }

    return (<tr data-testid={'test-sl-price-factor-row-' + index} className="driving-area-price-factor-row-data" >
        <td style={{ textAlign: 'left' }}>
            <AppTitleMobileOnly text={SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.name} />  {specialLocation.name || ''}
        </td>
        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.lat} />
            {specialLocation.latitude ? specialLocation.latitude : '-'}
        </td>
        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.lng} />
            {specialLocation.longitude ? specialLocation.longitude : '-'}
        </td>

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.radius} />
            {specialLocation.radiusMeters ? specialLocation.radiusMeters : '-'}
        </td>

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.faktorFrom} />
            {showIntWith0Decimal(specialLocation.fromPriceFactor) || specialLocation.fromPriceFactor} 
        </td>

        <td className='table-cell-content-center-non-mobile table-cell-content-left-mobile'>
            <AppTitleMobileOnly text={SPECIAL_LOCATIONS_FACTOR_TABLE_TEXTS.faktorTo} />
            {showIntWith0Decimal(specialLocation.toPriceFactor ) || specialLocation.toPriceFactor } 
        </td>

        {
            hideEditingBtns? null:  <td style={{ textAlign: 'right' }}>
            <div className="ui icon buttons">
                <AppButton dataTestId={"test-driving-area-pf-editor-" + index} size='mini' onClick={editLocation}><>
                    <EditIcon /></>
                </AppButton>

                
                <AreYouSureAppButton dataTestIdOk={'test-delete-confirm-sp-' + index} dataTestIdConfirm={'test-delete-sp-' + index} mainBtndesignType="grey" size='mini' text={<><DeleteIcon /></>} onClick={(e) => { deleteLocation() }} />

            </div>
        </td>

        }

      
    </tr>)
}


const mapStateToProps = (state: RootState, ownProps: any) => {

    const drivingAreaPriceConfigState = state.drivingAreaPriceConfig;
    const props = ownProps as OwnProps;
    const hideEditingBtns = ownProps.hideEditingBtns;

    return {
        actions: props.actions,
        specialLocations: drivingAreaPriceConfigState.specialLocations || [],
        loading: drivingAreaPriceConfigState.loading,
        hideEditingBtns
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SpecialLocationsPriceFactors)
);

