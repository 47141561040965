import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDiscountCustomers, AppDiscountCustomersState } from '../types';


const initialState: AppDiscountCustomersState = {
    discountCustomers: [],
    discountId: null,
    loading: false
}

const discountCustomersSlice = createSlice({
    name: 'discountCustomers',
    initialState,
    reducers: {
        discountCustomersLoading(state) {
            state.loading = true;
        },
        discountCustomersLoaded(state, action: PayloadAction<{discountId: number, items: Array<AppDiscountCustomers>}>) {
            state.loading = false;
            state.discountId = (action.payload || {}).discountId || null;
            state.discountCustomers = (action.payload || {}).items || [];
        },
        discountCustomersLoadingFailed(state) {
            state.loading = false;
        },
        resetDiscountCustomersState(state) {
            return initialState;
        }
    },
})

export const {
    discountCustomersLoading,
    discountCustomersLoaded,
    discountCustomersLoadingFailed,
    resetDiscountCustomersState
} = discountCustomersSlice.actions;

export default discountCustomersSlice.reducer;
