import React from 'react';
import { AppMap } from '../../../components/Map/AppMap';
import { AppDotMarkerWithTooltip } from '../../../components/Map/AppDotMarkerWithTooltip';
import { setToTest } from '../../../utils/testHelpers';
import { DrivingArea, DrivingAreaGeo } from '../types';

export interface MapPoint {
    lat: number,
    lng: number,
    name: string | null
}

export interface MapWithDrivingAreasProps {
    points: Array<DrivingAreaGeo>,
    drivingAreas: Array<DrivingArea>
}

export const MapWithDrivingAreas: React.FC<MapWithDrivingAreasProps> = (props) => {
    const { points, drivingAreas } = props;

    let markers: any = [];
    let counter = 1;

    if (points && points.length > 0) {
        points.forEach(dr => {

            let drName = drivingAreas && drivingAreas.length > 0? (drivingAreas.find(x => x.drivingAreaId === dr.drivingAreaId) || null):null;

            dr.drivingAreaPoints.forEach((point) => {

                markers.push(<AppDotMarkerWithTooltip key={counter} tooltipText={(drName || {}).name || 'Kjøreområdets navn mangler'}
                center={{ lat: point.latitude, lng: point.longitude }} isDraggable={true} />);
                counter++;
            })

            counter++;
        })
    }

    setToTest('drGeos', markers);

    return <>
       <AppMap markers={markers} />
    </>
};

