import React, { useEffect } from "react";
import { UsersView } from "./child-components/UsersView";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { deleteUser, getUsers } from "./actions/usersAction";
import { AppUser } from "../Login/types";
import { selectUsers, setCurrentUserId } from "./reducers/usersReducer";
import { isAdmin, selectAuthUser } from "../Login/reducers/authReducers";
import { history } from '../../config/historyConfig';


interface Props {
    getAllUsers: () => any,
    setCurrentUser: (userId: number) => void,
    handleDeletUser: (userId: number) => void,
    authUserAccessAllRegions: boolean,
    users: Array<AppUser>,
    userIsAsmin: boolean
}

const UsersContainer: React.FC<Props> = props => {
    const { getAllUsers, users, setCurrentUser, handleDeletUser, userIsAsmin, authUserAccessAllRegions } = props;

    useEffect(() => {
        getAllUsers();
    }, [getAllUsers]);

    if (!userIsAsmin){
        history.push('/');
    }

    return (<UsersView authUserAccessAllRegions={authUserAccessAllRegions} users={users} setCurrentUser={setCurrentUser} handleDeletUser={handleDeletUser} />);
};


const mapStateToProps = (state: any) => {

    const authuser = selectAuthUser(state);

    return {
        users: selectUsers(state),
        userIsAsmin: isAdmin(state),
        authUserAccessAllRegions: authuser.regionId !== null? false: true
    };
};

const mapDispatchToProps = (dispatch: any) => {

    return {
        getAllUsers: () => { dispatch(getUsers()) },
        setCurrentUser: (userId: number) => { dispatch(setCurrentUserId(userId)) },
        handleDeletUser: (userId: number) => { dispatch(deleteUser(userId)) }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UsersContainer)
);

