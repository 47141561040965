import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dateTimeHelper } from "../../../utils/dateTimeHelper";
import { AppFinancialReport, FinancialReportState } from "../types";


const getInitState = (): FinancialReportState => {
    const fromDate = dateTimeHelper.getDateNow();
    const toDate = dateTimeHelper.getStartOfNextMonth(fromDate);
    return {
        from: dateTimeHelper.getStartOfMonth(fromDate),
        to: toDate,
        loading: false,
        data: []
    };
}

const financialReportSlice = createSlice({
    name: 'financialReport',
    initialState: getInitState(),
    reducers: {
        reportLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setReportDateFromTo(state, action: PayloadAction<Date | null>) {
            const dataFrom = action.payload ? action.payload : null;
            if (dataFrom) {
                state.from = dateTimeHelper.getStartOfMonth(dataFrom);
                state.to = dateTimeHelper.getStartOfNextMonth(dataFrom);
            }
        },
        setReportData (state, action: PayloadAction<Array<AppFinancialReport>>) {
            state.data = action.payload;
            state.loading = false;
        },
        resetFinancialReportState() {
            return getInitState();
        }
    },
})

export const {
    reportLoading,
    setReportDateFromTo,
    setReportData,
    resetFinancialReportState,

} = financialReportSlice.actions;

export default financialReportSlice.reducer;

