import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectAuthUser, selectAuthUserId } from '../../Login/reducers/authReducers';
import { saveUser } from '../actions/usersAction';
import { selectSaving } from '../reducers/usersReducer';
import { UserEditorForm, UserEditorFormProps } from './UserEditorForm';
import { AppBreadcrumbs } from '../../../components/index';

const UserProfileEditorView = (props: UserEditorFormProps) => {

  return <div className="main-content-item user-profile">
    <AppBreadcrumbs pages={[{ name: 'Hjem' }, { name: 'Brukerprofil' }]} />
    <div className="ui grid centered middle aligned padded ">

      <div className="column five wide computer eight wide tablet sixteen wide mobile wrapperWithBorder">
        <h2 className="ui header" style={{ textAlign: 'center' }}>Brukerprofil</h2>
        <UserEditorForm {...props} />
      </div></div></div>
}

const mapStateToProps = (state: any) => {

  return {
    pending: selectSaving(state),
    user: selectAuthUser(state),
    currentUserId: selectAuthUserId(state),
    showRoles: false,
    showRegion: false,
    userCountry:null
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleFormSubmit: (data: any, userId: number | null, currentUserId: number) => { dispatch(saveUser(data, userId, currentUserId)) },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfileEditorView)
);
