import { FoodIcon, PhoneIcon } from "../../components/Icon";

interface GetBookingTripCategory {
    text: string,
    id: number | null,
    icon?: any,
    isOrdinary?: boolean
}

export enum AppBookingTripCategory {
    Ordinary = 0,
    Public = 1,
    FoodDelivery = 2,
    
}

export const getAppBookingTripCategory = (cat: AppBookingTripCategory | null): GetBookingTripCategory => {
    switch (cat) {
      case AppBookingTripCategory.Ordinary:
        return { text: 'ORD', icon: <PhoneIcon />, id: 0, isOrdinary: true};
      case AppBookingTripCategory.Public:
          return { text: 'PUB', icon: null, id: 1, isOrdinary: false};
      case AppBookingTripCategory.FoodDelivery:
        return { text: 'FOOD', icon: <FoodIcon/>, id: 2, isOrdinary: false};
      default:
        return { text: 'Unknown', id: null, icon: null, isOrdinary: false};
    }
  };
