import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import { AppActivateDeactivateResponse, AppDiscount, AppDiscountListElement, AppDiscountsState } from '../types';
import { AppDiscountStatus, getDiscountStatusData } from '../../../config/constants';
import { dateTimeHelper } from '../../../utils/dateTimeHelper';

let initFilters = {
    searchText: null,
    showActive: false
};

const initPages = {
    isLastPage: false,
    page: 1
}

const initialState: AppDiscountsState = {
    list: [],
    loading: true,
    filters: initFilters,
    pagination: initPages,
    saving: false,
    activationPending: false,
    deletePending: false
}

const discountsSlice = createSlice({
    name: 'discounts',
    initialState,
    reducers: {
        discountsLoading(state) {
            state.loading = true;
        },
        discountsLoaded(state, action: PayloadAction<{ discounts: Array<AppDiscountListElement>, isLastPage: boolean, pageNumber: number}>) {
            const items = action.payload && action.payload.discounts ? action.payload.discounts : [];
            const isLastPage = action.payload ? action.payload.isLastPage : false;
            const pagination = action.payload && action.payload.pageNumber ? action.payload.pageNumber :  1;

            if (pagination !== 1){
                const resultList = state.list.concat(items);
                state.list = resultList;
            }
            else {
                state.list = items;
                state.pagination.page = 1;
            }

            state.loading = false;
            state.pagination.isLastPage = isLastPage;
        },
        discountsLoadingFailed(state) {
            state.loading = false;
        },
        discountSaving(state, action: PayloadAction<boolean>) {
            state.saving = action.payload;
        },
        discountActivatedOrDeactivated(state, action: PayloadAction<AppActivateDeactivateResponse>) {
            state.saving = false;
            const { discountId} = action.payload;
            if (!discountId){
                return;
            }
            const currentItemIndex = state.list.findIndex(x=> x.discountId === discountId);
            
            if (currentItemIndex >= 0){

                const isActive = !state.list[currentItemIndex].isActive;
                state.list[currentItemIndex].isActive = isActive;
                
                if(isActive && state.list[currentItemIndex].publishedDate == null){
                    state.list[currentItemIndex].publishedDate = Date.now.toString();
                }

            }
            state.activationPending = false;
        },
        updateDiscountInList(state, action: PayloadAction<AppDiscountListElement>) {
            const d = action.payload;
            const currentItemIndex = state.list.findIndex(x=> x.discountId === d.discountId);
            if (currentItemIndex >= 0){
                state.list[currentItemIndex] = d;
            }
            state.saving = false;
        },
        searchedDiscountsLoaded(state, action: PayloadAction<{ discounts: Array<AppDiscountListElement>, isLastPage: boolean }>) {
            const items = action.payload ? action.payload.discounts : null;
            const isLastPage = action.payload ? action.payload.isLastPage : false;

            if (!items)
                return state;

            state.list = items;
            state.loading = false;
            state.pagination.isLastPage = isLastPage;
        },
        setSearchText(state, action: PayloadAction<string>) {
            state.filters.searchText = action.payload;
        },
        setShowActive(state, action: PayloadAction<boolean>) {
            state.list = [];
            state.filters.showActive = action.payload;
        },
        setPage(state) {
            state.pagination.page = state.pagination.page + 1;
        },
        duplicateDiscount(state, action: PayloadAction<{discountId: number, discountCustomers: Array<string>}>){
            const { discountId, discountCustomers} = action.payload;

            if (!discountId){
               return;
            }

            let discTemp0 = state.list.find(x=> x.discountId === discountId);

            if(!discTemp0){
                return;
            }

            let tempList = state.list.filter(x=> x.discountId !== -1);

            let discTemp = {...discTemp0};
            discTemp.discountId = -1;
            discTemp.customers = discountCustomers;
            tempList.unshift(discTemp);
            state.list = tempList;

        },
        discountDeleting(state, action: PayloadAction<boolean>){
            state.deletePending = action.payload;
        },
        discountDeleted(state, action: PayloadAction<number>){
            const discountId = action.payload;
            state.deletePending = false;

            if (!discountId){
                return;
            }
            
            const listTemp = state.list.filter(x=> x.discountId !== discountId);
            state.list = listTemp;

        },
        resetFilters(state) {
            state.filters.searchText = null;
            state.filters.showActive = true;
            state.pagination = initPages;
            state.list = [];
        },
        resetList(state) {
            state.pagination = initPages;
            state.list = [];
        },
        resetPage(state) {
            state.pagination = initPages;
            state.list = [];
            state.loading = true;
        },
        resetState(state) {
            return initialState;
        }
    },
})

export const {
    discountsLoading,
    discountsLoaded,
    discountsLoadingFailed,
    searchedDiscountsLoaded,
    setSearchText,
    setShowActive,
    setPage,
    discountSaving,
    discountActivatedOrDeactivated,
    updateDiscountInList,
    discountDeleted,
    discountDeleting,
    duplicateDiscount,
    resetState,
    resetList,
    resetPage,
    resetFilters,
} = discountsSlice.actions;

export default discountsSlice.reducer;

export const selectDiscountById = (state: RootState, id: number): AppDiscountListElement | null => {
    const conf = state.discounts;
  
    if (id && conf && conf.list && conf.list.length > 0) {
        const current = conf.list.find(x=>x.discountId === id);
        return current || null;
    }
  
    return null;
  }
  
export const getDiscountStatus = (item: AppDiscount): {text: string,color:string} => {

    let goingOn = false;

    const dateTypeStart = item.start? dateTimeHelper.getLocalFromUtcStr(item.start): null;
    const dateTypeEnd = item.end? dateTimeHelper.getLocalFromUtcStr(item.end): null;

    if (dateTypeStart != null && dateTypeEnd != null && item.isActive){
        goingOn = dateTimeHelper.checkIfTodayIsInsideRange(dateTypeStart, dateTypeEnd);
    }

    let discountStatus = getDiscountStatusData(null);

    if (item.publishedDate == null && !item.isActive ){
        discountStatus = getDiscountStatusData(AppDiscountStatus.Draft);
    }
    else if (item.publishedDate != null && !item.isActive){
        discountStatus = getDiscountStatusData(AppDiscountStatus.Inactive);
    }
    else if (item.publishedDate != null && item.isActive && item.start == null){
        discountStatus = getDiscountStatusData(AppDiscountStatus.Active);
    }
    else if (item.publishedDate != null && item.isActive && !goingOn){
        discountStatus = getDiscountStatusData(AppDiscountStatus.Waiting);
    }
    else if (item.publishedDate != null && item.isActive && goingOn){
        discountStatus = getDiscountStatusData(AppDiscountStatus.Active);
    }

    return discountStatus;

}

