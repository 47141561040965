import React from 'react';
import { AppBreadcrumbs, FetchingState } from '../../../components';
import { ActionStatus } from '../../../config/constants';
import '../../../styles/dashbord.css'
import { CustomersWidget } from './CustomersWidget';
import { AppDashboard } from '../type';


interface HomeViewsProps {
    dashboard: AppDashboard,
    pending: ActionStatus
}

export const HomeView: React.FC<HomeViewsProps> = (props) => {
    const { pending, dashboard } = props;

    return (<div className="main-content-item home-page">
        <AppBreadcrumbs pages={[{ name: 'Dashbord /' }]} />
        <div className="app-dashbord">
            <FetchingState fetchState={pending}>
                <CustomersWidget
                    totalCustomers={dashboard ? dashboard.totalNumberOfCustomers : 0}
                    newCustomersPerDay={dashboard ? dashboard.numberOfCustomersLastDay : 0}
                    newCustomersPerMonth={dashboard ? dashboard.numberOfCustomersLastMonth : 0}
                />
            </FetchingState>
        </div>
    </div>
    )
};

