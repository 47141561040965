import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEmptyRegionPriceConfig, RegionPriceConfig } from '../../types';
import { RegionPriceConfigServerData, RegionPriceConfigState } from '../types';


const initialState: RegionPriceConfigState = {
  priceConfig: null,
  loading: false,
  publishing: false,
  saving: false,
  isEdited: false,
  comment: null,
  resettingDraft: false
};

const regionPriceConfigSlice = createSlice({
  name: 'regionPriceConfig',
  initialState,
  reducers: {
    priceConfigLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPriceConfig(state, action: PayloadAction<{priceConfig:RegionPriceConfigServerData, countryId:number, regionId:number}>) {
      const data = action.payload? action.payload.priceConfig : null;
      const countryId = action.payload? action.payload.countryId : 0;
      const regionId = action.payload? action.payload.regionId : 0;

      state.priceConfig = data && data.regionPriceConfig  ? data.regionPriceConfig : getEmptyRegionPriceConfig(countryId, regionId);
      state.comment = data && data.regionPriceConfig && data.regionPriceConfig.isDraft? data.regionPriceConfig.draftComment: null

      state.loading = false;
      state.isEdited = false;
      state.publishing = false;
      state.saving =false;
      state.resettingDraft = false;
    },
    updatePriceConfig(state, action: PayloadAction<RegionPriceConfig>) {

      const priceConfig = action.payload || null;

      if (priceConfig) {
        state.priceConfig = priceConfig;
        state.isEdited = true;
      }

    },
    changePublishingState(state, action: PayloadAction<boolean>){
      state.publishing = action.payload;
    },
    changeSavingState(state, action: PayloadAction<boolean>){
      state.saving = action.payload;
    }, 
    updateComment(state, action: PayloadAction<string | null>){
      state.comment = action.payload;
      state.isEdited = true;
    },
    resettingDraft(state, action: PayloadAction<boolean>){
      state.resettingDraft = action.payload;
    },
    resetRegionPriceConfigState(state) {
      return initialState;
    }
  },
})

export const {
  priceConfigLoading,
  setPriceConfig,
  updatePriceConfig,
  changePublishingState,
  changeSavingState,
  updateComment,
  resettingDraft,
  resetRegionPriceConfigState

} = regionPriceConfigSlice.actions;

export default regionPriceConfigSlice.reducer;



