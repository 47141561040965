import { useEffect } from "react";
import { useParams } from "react-router";
import { DrivingAreaUrlParam } from "../config/routes";

export function useDrivingAreaIdAssigner(set_current_driving_area: (drivingAreaId:number)=> void) {

    let { fromUrlDrivingAreaId: fromUrlDrivingAreaIdStr } = useParams<DrivingAreaUrlParam>();

    let fromUrlDrivingAreaId = 0;

    try {
        fromUrlDrivingAreaId = fromUrlDrivingAreaIdStr? parseInt(fromUrlDrivingAreaIdStr): 0;
     }
     catch (e) {
        console.log("Could not parseInt(fromUrlDrivingAreaIdStr)");
     }

   
    useEffect(() => {

        if (fromUrlDrivingAreaId){
            set_current_driving_area(fromUrlDrivingAreaId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return fromUrlDrivingAreaId;
  }