
export const prepareAndRoundNumber = (num: number | string, numberOfDecimals: number | null | 0 = 2 ): any => {

    if (!num) {
        return 0;
    }

    try {
        const temp1 = typeof num === 'string' ? num.replace(',', '.') : num.toString().replace(',', '.');

        let temp2 = parseFloat(temp1);

        let temp3;
      
        if (numberOfDecimals === 1){
            temp3 = Math.round((temp2 + Number.EPSILON) * 10) / 10;
        }
        else if (numberOfDecimals === 2) {
            temp3 = Math.round((temp2 + Number.EPSILON) * 100) / 100;
        }
        else if (numberOfDecimals === 0) {
           temp3 = Math.round(temp2);
        }
        else {
           temp3 = temp2;
        }
      
        return temp3;
    }
    catch (error) {
        console.log(error);
        return 0;
    }
}


export const getRandomInteger = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const showIntWith0Decimal = (num: any, numberOfDecimals:number = 2): string | undefined => {

   try {
      var number = parseFloat(num.toString()); 
      if (number == NaN) {
         return undefined;
      } 
      return number.toFixed(numberOfDecimals);     
   }
   catch (error) {
      console.log(error);
      return undefined;
   }
}