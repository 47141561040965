import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles/common.css'
import { configureStore } from './config/reducerConfig';
import {history} from './config/historyConfig';


const store = configureStore({}, history)



const renderApp = () => {

  ReactDOM.render( <Provider store={store}>
    <Router history={history}>
      <App history={history} />
    </Router></Provider>,
    document.getElementById('root')
  );
}

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (module.hot) {
  module.hot.accept('./App', () => {
    renderApp();
    
  });
}

