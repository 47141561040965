import React, { useEffect, useState } from "react";
import { ActionStatus } from "../config/constants";
import { Spinner } from "./Spinner";

interface FetchingStateProps {
  isTest?: boolean,
  children: JSX.Element,
  fetchState: ActionStatus,
  height?: string,
  width?:string
}

const FetchingState: React.FC<FetchingStateProps> = (props) => {

  const { fetchState, children, height, width, isTest, } = props;


  const [fetching, setFetching] = useState<ActionStatus>(ActionStatus.PROCESS);

  useEffect(() => {

    if (fetching !== fetchState) {
      if (!isTest){ // for testing. Tests do not work with delay.
      
        setTimeout(() => {
          setFetching(fetchState);
        }, 1000)
      }
      else {
        setFetching(fetchState);
      }
    
    }

  }, [fetchState, fetching]);
    
  if (
    fetching === ActionStatus.PROCESS ||
    ((fetching === null || fetching === ActionStatus.NONE))
  ) {
    return (
      <div className="fetching-state" style={{
        display: "flex",
        justifyContent: "center",
        width: width || '100%',
        height: height ||'100%',
        backgroundColor: 'white',
        alignItems: 'center'
      }}>
        <Spinner />
      </div>
    );
  }


  return <>{children}</>;
};


export default FetchingState;
