import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ModalFormFooter, MyTextInput } from '../../../../components';
import { AppButtonSubmit } from '../../../../components/AppButton';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { nameof } from '../../../../utils/nameOfPropertyHelper';
import { updatePreOrderMainInfo } from '../reducers/drivingAreaPriceConfigReducers';
import { DrivingAreaPriceConfActionsProps, PreOrderConfig, preOrderMainInfoValidation } from '../types';



interface PropsFromReducer {
    preOrderConfig: PreOrderConfig | null,
    update_pre_order_main_info: (priceConfig: PreOrderConfig) => void
};

type PreOrderInfoEditorProps = PropsFromReducer & DrivingAreaPriceConfActionsProps


const PreOrderInfoEditor: React.FC<PreOrderInfoEditorProps> = (props) => {

    let { preOrderConfig, update_pre_order_main_info, actions } = props;

    if (!preOrderConfig) {
        return null;
    }

    const handleFormSubmit = async (values: any, setErrors: any) => {

        if (values && typeof update_pre_order_main_info === 'function') {
            update_pre_order_main_info(values);
            actions.closeEditor();
        }

    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={preOrderConfig}
            validationSchema={preOrderMainInfoValidation}
            onSubmit={(values, { setErrors }) => { handleFormSubmit(values, setErrors) }}
        >
            {(formik) => (
                <Form className='ui form' onSubmit={formik.handleSubmit} autoComplete='off' data-testid="driving-area-price-main-conf-form">

                    <MyTextInput
                        extraProps={{fullWidth: true}}
                        label={'Min. tid før forhåndsbestilt tur (i minutter)'}
                        data-testid={nameof<PreOrderConfig>('minimumTimeUntilPickupInMinutes')}
                        name={nameof<PreOrderConfig>('minimumTimeUntilPickupInMinutes')}
                        type="number"
                      
                    />

                    <MyTextInput
                        extraProps={{fullWidth: true}}
                        label={'Maks tid for forhåndsbestilt tur (i minutter)'}
                        data-testid={nameof<PreOrderConfig>('maximumTimeUntilPickupInMinutes')}
                        name={nameof<PreOrderConfig>('maximumTimeUntilPickupInMinutes')}
                        type="number"
                      
                    />

                    <ModalFormFooter>
                        <AppButtonSubmit dataTestId="form-submit" onClick={formik.handleSubmit}>Ferdig</AppButtonSubmit>
                    </ModalFormFooter>

                </Form>
            )}
        </Formik>
    )
};


const mapStateToProps = (state: RootState, ownProps: any) => {

    const priceConfState = state.drivingAreaPriceConfig;
    const props = ownProps as DrivingAreaPriceConfActionsProps;

    return {
        actions: props.actions,
        preOrderConfig: priceConfState.preOrderConfig

    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        update_pre_order_main_info: (priceConfig: PreOrderConfig) => dispatch(updatePreOrderMainInfo(priceConfig)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PreOrderInfoEditor)
);
