import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppNoItems, Spinner } from '../../../../components';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { DayTimeZones } from '../../CommonComponents/DayTimeZones';
import { PublishBtns } from '../../CommonComponents/PublishBtns';
import { VersionInfo } from '../../CommonComponents/VersionInfo';
import { DrivingAreaPriceConfig, HideEditingBtns, PriceConfigDayTimeZone } from '../../types';
import { DrivingAreaPriceConfActionsProps, DrivingAreaPriceConfigModalType } from '../types';
import { MainDrivingAreaPriceConfigInfo } from './MainDrivingAreaPriceConfigInfo';


interface PropsFromReducer {
    dayTimeZoneFactors: Array<PriceConfigDayTimeZone>,
    drivingAreaPriceConfig: DrivingAreaPriceConfig | null,
    drivingAreaPriceConfigLoading: boolean,
    drivingAreaPriceConfigIsEdited: boolean,
    publishing: boolean,
    saving: boolean,
    comment: string | null,
    resetting: boolean
}

type OwnProps = DrivingAreaPriceConfActionsProps & HideEditingBtns;

type MainDrivingAreaPriceConfigProps = PropsFromReducer & OwnProps;

const MainDrivingAreaPriceConfig: React.FC<MainDrivingAreaPriceConfigProps> = (props) => {
    const { dayTimeZoneFactors,
        drivingAreaPriceConfig,
        drivingAreaPriceConfigLoading,
        drivingAreaPriceConfigIsEdited,
        publishing,
        saving,
        resetting } = props;

    const { actions, hideEditingBtns } = props;

    const wrapperStyle = { minHeight: '40vh' };

    if (drivingAreaPriceConfigLoading) {
        return <div className="display-flex-center-both" style={wrapperStyle}><Spinner /></div>
    }

    if (!drivingAreaPriceConfig) {
        return <AppNoItems text={'Velg et kjøreområde'} />
    }

    return <div style={wrapperStyle} className="driving-area-price-config-main">

        {
            hideEditingBtns ? null :
                <div className="price-config-publish-reset-btns" style={{ width: '100%', display: 'flex', padding: '0px 0px', justifyContent: 'flex-end', alignItems: 'center' }}>

                    <PublishBtns
                        isPublished={!drivingAreaPriceConfig.isDraft}
                        publishing={publishing}
                        resetting={resetting}
                        saving={saving}
                        isEdited={drivingAreaPriceConfigIsEdited}
                        hasPublishedRecords={drivingAreaPriceConfig.publishedDate !== null}
                        publish={actions.publishOrSaveConfig}
                        resetChanges={() => {
                            actions.resetDraft()
                        }} />

                </div>

        }

        <div className="tab-page driving-area-price-config-main-cols">

            <div className="driving-area-price-config-main-col1 price-conf-col" >
                <div className="driving-area-price-config-el">
                    <VersionInfo
                        hideEditingBtns={hideEditingBtns}
                        publishedComment={drivingAreaPriceConfig.publishedComment || ''}
                        publishDate={drivingAreaPriceConfig.publishedDate}
                        publishedBy={drivingAreaPriceConfig.publishedByName}
                        draftComment={drivingAreaPriceConfig.draftComment}
                        isDraft={drivingAreaPriceConfig.isDraft}
                        goToPublishedVersion={actions.goToPublishedVersion}
                        draftLastModified={drivingAreaPriceConfig.draftLastModifiedDate}
                        draftBy={drivingAreaPriceConfig.draftModifiedByName}
                        onEdit={() => actions.edit(null, DrivingAreaPriceConfigModalType.versionInfo)}
                    />

                </div>

            </div>

            <div className="driving-area-price-config-main-col2 price-conf-col" >
                <div className="driving-area-price-config-el">
                    <MainDrivingAreaPriceConfigInfo hideEditingBtns={hideEditingBtns} actions={actions} priceConfig={drivingAreaPriceConfig} />
                </div>
            </div>

            <div className="driving-area-price-config-main-col3 price-conf-col" >
                <div className="driving-area-price-config-el">
                    <DayTimeZones
                        hideEditingBtns={hideEditingBtns}
                        dayTimeZoneFactors={dayTimeZoneFactors}
                        edit={(index: number) => actions.edit(index, DrivingAreaPriceConfigModalType.dayTimeZones)} />
                </div>
            </div>


        </div>

    </div>
}


const mapStateToProps = (state: RootState, ownProps: any) => {

    const daPriceConfState = state.drivingAreaPriceConfig;
    const props = ownProps as OwnProps;

    const propsToReturn: MainDrivingAreaPriceConfigProps = {
        dayTimeZoneFactors: daPriceConfState.dayTimeZones || [],
        actions: props.actions,
        drivingAreaPriceConfig: daPriceConfState.priceConfig,
        drivingAreaPriceConfigLoading: daPriceConfState.loading,
        drivingAreaPriceConfigIsEdited: daPriceConfState.isEdited,
        publishing: daPriceConfState.publishing || false,
        saving: daPriceConfState.saving,
        comment: daPriceConfState.comment || null,
        resetting: daPriceConfState.resettingDraft || false,
        hideEditingBtns: props.hideEditingBtns
    }

    return propsToReturn;
};


const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainDrivingAreaPriceConfig)
);



