import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ModalFormFooter, MyTextInput } from '../../../../components';
import { AppButtonSubmit } from '../../../../components/AppButton';
import { RootState } from '../../../../config/reducerConfig/rootReducer';
import { showIntWith0Decimal } from '../../../../utils/commonHelpers';
import { formPrepareAndRoundNumber, getInputValue } from '../../../../utils/formHelpers';
import { nameof } from '../../../../utils/nameOfPropertyHelper';
import { RegionPriceConfig } from '../../types';
import { updatePriceConfig } from '../reducers/regionPriceConfigReducer';
import { RegionPriceConfActionsProps, regionPriceConfigValidation } from '../types';


interface Props {
    priceConfig: RegionPriceConfig | null,
    update_price_config: (priceConfig: RegionPriceConfig) => void
};

type OwnProps = RegionPriceConfActionsProps;

type MainPriceConfigEditorProps = Props & OwnProps


const MainRegionPriceConfigEditor: React.FC<MainPriceConfigEditorProps> = (props) => {

    let { priceConfig, update_price_config, actions } = props;

    if (!priceConfig) {
        return null;
    }

    const handleFormSubmit = async (values: any, setErrors: any) => {

        if (values && typeof update_price_config === 'function') {
            update_price_config(values);
            actions.closeEditor();
        }

    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={priceConfig}
            validationSchema={regionPriceConfigValidation}
            onSubmit={(values, { setErrors }) => { handleFormSubmit(values, setErrors) }}
        >
            {(formik) => (
                <Form className='ui form' onSubmit={formik.handleSubmit} autoComplete='off' data-testid="region-price-main-conf-form">

                    <MyTextInput
                        extraProps={{fullWidth: true}}
                        label={'Prisfaktor'}
                        data-testid={nameof<RegionPriceConfig>('priceFactor')}
                        name={nameof<RegionPriceConfig>('priceFactor')}
                        type="number"
                        step={0.05}
                        onChange={(e) => {

                            formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                formik.setFieldValue(nameof<RegionPriceConfig>('priceFactor'), newValue)
                            },
                            2);
                            
                        }}
                    />

                    <MyTextInput
                        extraProps={{fullWidth: true}}
                        label={'Minstepris'}
                        data-testid={nameof<RegionPriceConfig>('minPrice')}
                        name={nameof<RegionPriceConfig>('minPrice')}
                        type="number"
                        onChange={(e) => {
                            formPrepareAndRoundNumber(getInputValue(e), (newValue: any) => {
                                formik.setFieldValue(nameof<RegionPriceConfig>('minPrice'), newValue)
                            });

                        }}
                    />

                    <ModalFormFooter>
                        <AppButtonSubmit dataTestId="form-submit" onClick={formik.handleSubmit}>Ferdig</AppButtonSubmit>
                    </ModalFormFooter>

                </Form>
            )}
        </Formik>
    )
};


const mapStateToProps = (state: RootState, ownProps: any) => {

    const priceConfState = state.regionPriceConfig;
    const props = ownProps as OwnProps;

    return {
        actions: props.actions,
        priceConfig: priceConfState.priceConfig

    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        update_price_config: (regionPriceConfig: RegionPriceConfig) => dispatch(updatePriceConfig(regionPriceConfig)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainRegionPriceConfigEditor)
);
