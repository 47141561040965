import { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { DateResponseData } from "../../components/AppTimePicker";
import { RootState } from "../../config/reducerConfig/rootReducer";
import { getFinancialReport } from "./actions/reportsActions";
import { BookingFinancialReportView, PriceConfigTestViewPropsFromReducer } from "./child-components/BookingFinancialReportView";
import { setReportDateFromTo } from "./reducer/financialReportReducers";
import { BookingFinancialReportActions } from "./types";


interface BookingFinancialReportContainerProps {
    get_financial_report: () => any,
    set_date:  (date: Date | null) => void
}

const BookingFinancialReportContainer: React.FC<BookingFinancialReportContainerProps
    & PriceConfigTestViewPropsFromReducer> = (props) => {

        const { fromDate, toDate, loading, get_financial_report, set_date } = props;

        const getReportBtn = useRef<any>(null);

        const getReportBtnClick = () => {

            get_financial_report().then(()=> {
              
                if (getReportBtn.current !== null) {
                    getReportBtn.current!.click();
                }
                
            }, (error:any)=> {
                console.log(error);
            });

        }

        const dateChangedHandler = (date: DateResponseData) => {
            const dateData = date.jsDate ?? null;
            
            set_date(dateData);
        }

        const actions: BookingFinancialReportActions = {
            getReportBtnClick,
            dateChangedHandler
        };


        return (<BookingFinancialReportView loading={loading} actions={actions} fromDate={fromDate} toDate={toDate} getReportBtn={getReportBtn}/>)
    };


const mapStateToProps = ((state: RootState) => {

    const reportState = state.financialReport;

    return {
        loading: reportState.loading,
        fromDate: reportState.from,
        toDate: reportState.to
    }
});


const mapDispatchToProps = (dispatch: any) => {

    return {
        get_financial_report: () => dispatch(getFinancialReport()),
        set_date: (date: Date | null) => dispatch(setReportDateFromTo(date)),

    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BookingFinancialReportContainer)
);


